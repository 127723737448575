@font-face {
    font-family: 'Secular One';
    src: url('../fonts/Secular_One/SecularOne-Regular.ttf') format('truetype');
    /* Replace with the correct path */
    /* Add other font formats (woff, woff2, etc.) with their respective paths */
    /* Add font-weight and font-style if necessary */
}

body {
    font-family: Open sans;
    /* background: linear-gradient(to bottom, #fed80014, #d0a72500); */
    margin: 0 !important;
    padding: 0 !important;
    height: 100vh;
    display: flex;
    flex-direction: column;

}

body.no-scroll {
    overflow: hidden;
}

.body-background {
    background: #fff;
}


.bot-response-container h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 0.5rem;
}


.background-container {
    position: absolute;
    top: 95px;
    left: 110px;
    width: 85%;
    height: 80%;
    background-image: url('../images/landing-page/circles.gif');
    /* Path to your GIF */
    background-position: center;
    background-repeat: repeat;
    background-size: contain;
    /* Keeps the aspect ratio intact */
    z-index: -1;
    /* Pushes the background behind other content */
    opacity: .5;
}

.background-blurred {
    filter: blur(20px);
    /* Blurs the background content */
    opacity: 0.1;
    /* Slight opacity for the background */
    transition: opacity 1.3s ease;
    visibility: hidden;
    background-color: #fff;
}

.background-container.hide {
    display: none!important;
}


/* Hidden button initially */
.cta-button-page-0.hidden {
    opacity: 0;
    transform: scale(0.8);
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.cta-button-page-0.visible {
    opacity: 1;
    transform: scale(1);
    transform: translateY(0);
    /* Move to its normal position */
    animation: buttonBounceIn 0.6s ease-out forwards, pulseEffect 3s infinite;
}

.cta-button-page-0 {
    background-color: #fde871;
    border: none;
    color: #323232;
    padding: 15px 30px;
    font-size: 16px;
    font-weight: 400;
    border-radius: 30px;
    cursor: pointer;
    margin-top: 35px;
    transition: all 0.4s ease;
    transform: translateY(50px);
    opacity: 0;
    animation: pulseEffect 1s infinite;
    animation-play-state: running;
    /* Ensure the animation is running initially */

}

.animated-icon {
    width: 70%;
}

@keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

/* Bouncing effect when the button appears */
@keyframes buttonBounceIn {
    0% {
        transform: translateY(50px);
        opacity: 0;
    }

    60% {
        transform: translateY(-10px);
        opacity: 1;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}


/* Pulse effect */
@keyframes pulseEffect {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
        /* Increase the size of the button slightly */
    }

    100% {
        transform: scale(1);
        /* Return to normal size */
    }
}

.cta-button-page-0:hover {
    background: #FED800;
    transform: translateY(-5px) scale(1.05);
    transition: all 0.3s ease;
    animation-play-state: paused;
    /* Stop pulse on hover */
}

.page-0 {
    /* background: #000; */
}

/* Circular and geometric shapes */
.circle-left-page-0 {
    position: absolute;
    top: 30%;
    left: -86px;
    width: 224px;
    height: 224px;
    border: 25px solid #FFD700;
    border-radius: 50%;
    z-index: -1;
}

.triangle-page-0 {
    position: absolute;
    top: 12%;
    left: 30%;
    width: 179px;
    height: 118px;
    border-left: 100px solid transparent;
    border-right: 100px solid transparent;
    border-bottom: 100px solid #FFF4B4;
    transform: rotate(65deg);
    z-index: -1;
}

.small-circle-left-page-0 {
    position: absolute;
    top: 27%;
    left: 22%;
    width: 20px;
    height: 20px;
    background-color: #323232;
    border-radius: 50%;
    z-index: -1;
}

/* Curved Arc on the Right */
.curved-arc-page-0 {
    position: absolute;
    top: -9%;
    right: -7%;
    width: 239px;
    height: 401px;
    border: 33px solid #FFD700;
    border-radius: 50%;
    border-top-color: transparent;
    transform: rotate(135deg);
    border-bottom-color: transparent;
    border-left-color: transparent;
    z-index: -1;
}

/* Diagonal Ellipse on the Right */
.diagonal-ellipse-page-0 {
    position: absolute;
    bottom: 1%;
    right: 3%;
    width: 68px;
    height: 132px;
    background-color: #FFD700;
    border-radius: 50px;
    transform: rotate(32deg);
    z-index: -1;
}

/* Footer circles */
.footer-circles-page-0 {
    position: absolute;
    bottom: 10%;
    display: flex;
    justify-content: center;
    width: 100%;
    z-index: -1;
}

.footer-circles-page-0 .circle-page-0 {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 0 10px;
}

.circle-page-0.yellow-page-0 {
    background: #F6F47C;
}

.circle-page-0.light-yellow-page-0 {
    background: #FFBD0029;
}


/*############Page-1###############*/

/* Circular and geometric shapes */
.circle-left-page-1 {
    position: absolute;
    bottom: 15%;
    left: -86px;
    width: 224px;
    height: 224px;
    border: 25px solid #FDDD82;
    border-radius: 50%;
    z-index: -1;
}

.triangle-page-1 {
    position: absolute;
    top: 1%;
    left: 25%;
    width: 179px;
    height: 118px;
    border-left: 100px solid transparent;
    border-right: 100px solid transparent;
    border-bottom: 100px solid #FFF4B4;
    transform: rotate(13deg);
    z-index: -1;
}

.small-circle-left-page-1 {
    position: absolute;
    top: 27%;
    left: 22%;
    width: 20px;
    height: 20px;
    background-color: #323232;
    border-radius: 50%;
    z-index: -1;
}

/* Curved Arc on the Right */
.curved-arc-page-1 {
    position: absolute;
    top: 0%;
    right: -7%;
    width: 239px;
    height: 401px;
    border: 33px solid #FFD700;
    border-radius: 50%;
    border-top-color: transparent;
    transform: rotate(-105deg);
    border-bottom-color: transparent;
    border-left-color: transparent;
    z-index: -1;
}

/* Diagonal Ellipse on the Right */
.diagonal-ellipse-page-1 {
    position: absolute;
    bottom: 1%;
    right: 3%;
    width: 68px;
    height: 132px;
    background-color: #FFD700;
    border-radius: 50px;
    transform: rotate(-45deg);
    z-index: -1;
}

/* Footer circles */
.footer-circles-page-1 {
    position: absolute;
    bottom: 10%;
    display: flex;
    justify-content: center;
    width: 100%;
    z-index: -1;
}

.footer-circles-page-1 .circle-page-1 {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 0 10px;
}

.circle-page-1.yellow-page-1 {
    background: #F6F47C;
}

.circle-page-1.light-yellow-page-1 {
    background: #FFBD0029;
}

/*###################################*/



/*############Page-2###############*/

/* Circular and geometric shapes */
.circle-left-page-2 {
    position: absolute;
    bottom: 8%;
    left: -86px;
    width: 224px;
    height: 224px;
    border: 25px solid #BFECF6;
    border-radius: 50%;
    z-index: -1;
}


.triangle-page-2 {
    position: absolute;
    top: 3%;
    left: 33%;
    width: 179px;
    height: 118px;
    border-left: 100px solid transparent;
    border-right: 100px solid transparent;
    border-bottom: 100px solid #CEF4FF;
    transform: rotate(60deg);
    z-index: -1;
}

.small-circle-left-page-2 {
    position: absolute;
    top: 27%;
    left: 22%;
    width: 20px;
    height: 20px;
    background-color: #323232;
    border-radius: 50%;
    z-index: -1;
}

/* Curved Arc on the Right */
.curved-arc-page-2 {
    position: absolute;
    top: 0%;
    right: -7%;
    width: 239px;
    height: 401px;
    border: 33px solid #00D2FF;
    border-radius: 50%;
    border-top-color: transparent;
    transform: rotate(-80deg);
    border-bottom-color: transparent;
    border-left-color: transparent;
    z-index: -1;
}


/* Diagonal Ellipse on the Right */
.diagonal-ellipse-page-2 {
    position: absolute;
    bottom: 1%;
    right: 3%;
    width: 68px;
    height: 132px;
    background-color: #00D2FF;
    border-radius: 50px;
    transform: rotate(90deg);
    z-index: -1;
}

/* Footer circles */
.footer-circles-page-2 {
    position: absolute;
    bottom: 10%;
    display: flex;
    justify-content: center;
    width: 100%;
    z-index: -1;
}

.footer-circles-page-2 .circle-page-2 {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 0 10px;
}

.circle-page-2.yellow-page-2 {
    background: #F6F47C;
}

.circle-page-2.light-yellow-page-2 {
    background: #FFBD0029;
}

/*###################################*/


/*############Page-3###############*/

/* Circular and geometric shapes */
.circle-left-page-3 {
    position: absolute;
    top: 35%;
    left: -86px;
    width: 180px;
    height: 187px;
    border: 25px solid #8643FF78;
    border-radius: 50%;
    z-index: -1;
}



.triangle-page-3 {
    position: absolute;
    top: 3%;
    left: 33%;
    width: 179px;
    height: 118px;
    border-left: 100px solid transparent;
    border-right: 100px solid transparent;
    border-bottom: 100px solid #CDB4DB66;
    transform: rotate(0deg);
    z-index: -1;
}

.small-circle-left-page-3 {
    position: absolute;
    top: 27%;
    left: 22%;
    width: 20px;
    height: 20px;
    background-color: #323232;
    border-radius: 50%;
    z-index: -1;
}

/* Curved Arc on the Right */
.curved-arc-page-3 {
    position: absolute;
    top: -15%;
    right: -7%;
    width: 239px;
    height: 401px;
    border: 33px solid #B377FF36;
    border-radius: 50%;
    border-top-color: transparent;
    transform: rotate(90deg);
    border-bottom-color: transparent;
    border-left-color: transparent;
    z-index: -1;
}


/* Diagonal Ellipse on the Right */
.diagonal-ellipse-page-3 {
    position: absolute;
    bottom: 10%;
    right: 3%;
    width: 68px;
    height: 132px;
    background-color: #CDB4DB;
    border-radius: 50px;
    transform: rotate(-165deg);
    z-index: -1;
}

/* Footer circles */
.footer-circles-page-3 {
    position: absolute;
    bottom: 10%;
    display: flex;
    justify-content: center;
    width: 100%;
    z-index: -1;
}

.footer-circles-page-3 .circle-page-3 {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 0 10px;
}

.circle-page-3.yellow-page-3 {
    background: #F6F47C;
}

.circle-page-3.light-yellow-page-3 {
    background: #FFBD0029;
}

/*###################################*/



#typewriter-text {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
}

#cursor {
    position: absolute;
    display: inline-block;
    width: 2px;
    margin-left: 2px;
    animation: blink 0.7s steps(1) infinite;
}

@keyframes blink {
    50% {
        opacity: 0;
    }
}

.text-sequence {
    display: flex;
    justify-content: center;
    color: #323232;
    gap: 10px;
    font-family: "Open Sans";
    font-size: 44px;
    line-height: 14px;
    text-align: center;
    visibility: hidden;
}

.word {
    opacity: 0;
    transform: translateY(50px);
    /* Move words down initially */
    animation: slideInFade 1.2s ease forwards;
    visibility: visible;
}

.word:nth-child(1),
.word:nth-child(2),
.word:nth-child(3),
.word:nth-child(4) {
    animation-delay: 2.5s;

}

/* .word:nth-child(2) {
    animation-delay: 1.5s;
    
}

.word:nth-child(3) {
    animation-delay: 2.0s;
    
}

.word:nth-child(4) {
    animation-delay: 2.5s;
   
} */

@keyframes slideInFade {
    from {
        opacity: 0;
        transform: translateY(50px);
        /* Start below */
    }

    to {
        opacity: 1;
        transform: translateY(0);
        /* End at normal position */
    }
}

#centerText .topText {
    /* font-family: 'Secular One'; */
    font-size: 44px;
    font-weight: 400;
}

#centerText .bottomText {
    display: block;
    text-align: left;
    font-size: 32px;
    font-weight: 300;
}

.header_nav_left,
.header_nav_right {
    position: absolute;
    top: 0;
    height: 100%;
    /* Full height of the navbar */
    width: 50%;
    /* Each div will cover 40% width */
    background-size: cover;
    /* Adjust size of the image to fit */
    background-repeat: no-repeat;
    background-position: center;
    /* Make sure the image is centered */
    pointer-events: none;
    /* This ensures the image doesn't block clicks */
}

.footer_bg_left,
.footer_bg_right {
    position: absolute;
    bottom: 0;
    height: 100%;
    /* Full height of the navbar */
    width: 50%;
    /* Each div will cover 40% width */
    background-size: cover;
    /* Adjust size of the image to fit */
    background-repeat: no-repeat;
    background-position: center;
    /* Make sure the image is centered */
    pointer-events: none;
    /* This ensures the image doesn't block clicks */
}


.header_nav_left {
    left: 0;
    background-image: url('../images/landing-page/vector_img_nav.png');
    /* Add the left background image */
}

.header_nav_right {
    right: 0;
    background-image: url('../images/landing-page/vector_img_nav.png');
    /* Add the right background image */
}


.footer_bg_left {
    left: 0px;
    background-image: url('../images/landing-page/vector_img_nav.png');
    /* Add the left background image */
}

.footer_bg_right {
    right: 0px;
    background-image: url('../images/landing-page/vector_img_nav.png');
    /* Add the right background image */
}

.slide-up-overlay-slide-6 .footer-container {
    padding: 170px 20px 25px 36px;
}

.askvasi-home .navbar-nav .nav-item {
    margin-left: 25px;
    /* Adjust this value to control the space between the links */
}

.askvasi-home .navbar-nav .nav-link {
    /* padding: 5px 17px 5px 18px; */
    /* Optional: adds padding inside each link */
    color: #000 !important;
}

.askvasi-home .navbar-nav .nav-link{
    font-size: 16px!important;
    font-weight: 600!important;
    line-height: 26px!important;
    text-align: center!important;
}

.askvasi-home .nav-item .try-btn {
    background-color: #FED800;
    /* border-color: #FED800; */
    border-radius: 11px;
    font-size: 15px;
    font-weight: 600;
    line-height: 21.79px;
    /* padding: 8px 30px 8px 30px; */
    position: relative;
    overflow: hidden;
    /* Ensure overlay is contained inside the button */
    z-index: 1;
    width: 136px;
    height: 36px;
}

.askvasi-home .nav-item .try-btn::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    /* Black color with opacity */
    transition: width 0.5s ease-in-out;
    /* Smooth transition */
    z-index: -1;
    /* Behind the button text */
}

.askvasi-home .nav-item .try-btn:hover::before {
    width: 100%;
    /* The overlay grows from 0% to 100% on hover */
}

.askvasi-home .nav-item .try-btn:hover {
    color: #fff !important;
    /* Optional: Change text color on hover if needed */
}


.askvasi-home .navbar {
    position: fixed;
    width: 100%;
    padding: 25px 120px 25px 120px;
    gap: 0px;
    justify: space-between;
    /* opacity: 0px; */
    background: #FED80008;
    backdrop-filter: blur(40px);
    z-index: 1005;
}

.landing-page .navbar.hidden {
    display: none;
}

.page-headings {
    /* font-family: Secular One; */
    font-size: 32px;
    font-weight: 400;
    line-height: 46.56px;
    text-align: center;
}

.grid-container {
    flex: 1;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    overflow: hidden;
}

.grid-container.active {
    display: flex;
}

.grid-container h1 {
    font-size: 36px;
    color: #323232;
    position: absolute;
    z-index: 1;
}

/* CSS for Fade-In and Fade-Out */
@keyframes fadeInHome {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeOutHome {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.fade-in-home {
    animation: fadeInHome 1.5s forwards;
}

.fade-out-home {
    animation: fadeOutHome 1.5s forwards;
}


.grid {
    position: absolute;
    width: 100%;
    height: 100%;
}

.grid-item {
    position: absolute;
    width: 250px;
    height: 150px;
    overflow: visible;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    opacity: 0;
    transform: scale(0.5);
    transition: transform 0.8s ease, opacity 0.8s ease;
    /*z-index: 20;*/
}

.grid-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}


.grid-item .caption {
    position: absolute;
    background: #FFBD00;
    color: #000;
    padding: 7px 9px 7px 9px;
    border-radius: 28px;
    font-size: 16px;
    opacity: 0;
    /* transform: translate(50%, -50%); */
    /* transition: transform 0.8s ease, opacity 0.8s ease; */
}

.label-animation-page-3-bottom-2 {
    left: -30%;
}


.page-2 .grid-item .caption {
    background: #65CBE9;
}

.page-3 .grid-item .caption {
    background: #CDB4DB;
}

/* Positioning the thumbnails for the first screen */
.page-1 .grid-item:nth-child(1) {
    top: 25%;
    left: 16%;
}

.page-1 .grid-item:nth-child(2) {
    top: 15%;
    right: 42%;
}

.page-1 .grid-item:nth-child(3) {
    top: 23%;
    right: 15%;
}

.page-1 .grid-item:nth-child(4) {
    bottom: 14%;
    left: 46%;
    transform: translateX(-50%);
}

.page-1 .grid-item:nth-child(5) {
    bottom: 20%;
    left: 20%;
}

.page-1 .grid-item:nth-child(6) {
    bottom: 25%;
    right: 14%;
}

/* Positioning the thumbnails for the second screen */
.page-2 .grid-item:nth-child(1) {
    top: 20%;
    left: 15%;
}

.page-2 .grid-item:nth-child(2) {
    top: 14%;
    right: 36%;
}

.page-2 .grid-item:nth-child(3) {
    top: 40%;
    right: 15%;
}

.page-2 .grid-item:nth-child(4) {
    bottom: 10%;
    left: 50%;
    transform: translateX(-45%);
}

.page-2 .grid-item:nth-child(5) {
    bottom: 20%;
    left: 20%;
}

.page-2 .grid-item:nth-child(6) {
    bottom: 5%;
    right: 15%;
}

/* Positioning the thumbnails for the third screen */
.page-3 .grid-item:nth-child(1) {
    top: 25%;
    left: 15%;
}

.page-3 .grid-item:nth-child(2) {
    top: 16%;
    right: 35%;
}

.page-3 .grid-item:nth-child(3) {
    top: 35%;
    right: 15%;
}

.page-3 .grid-item:nth-child(4) {
    bottom: 15%;
    left: 55%;
    transform: translateX(-50%);
}

.page-3 .grid-item:nth-child(5) {
    bottom: 15%;
    left: 20%;
}

.page-3 .grid-item:nth-child(6) {
    bottom: 10%;
    right: 16%;
}

/* Global Next Arrow Styling */
.next-arrow-global {
    position: fixed;
    bottom: 20px;
    margin: auto;
    left: 0;
    right: 0;
    /* left: 48%; */
    background-color: #FED800;
    color: #000;
    border: none;
    border-radius: 37px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
    z-index: 1100;
    width: 107px;
    height: 47px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    /* padding: 10px 30px 10px 30px;
    gap: 10px;    
    opacity: 0px; */
}

.next-arrow-global::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    /* Black color with opacity */
    transition: width 0.5s ease-in-out;
    /* Smooth transition */
    z-index: -1;
    /* Behind the button text */
    border-radius: 37px;
}

.next-arrow-global:hover::before {
    width: 100%;
    /* The overlay grows from 0% to 100% on hover */
}

.next-arrow-global:hover {
    color: #fff;
    /* Optional: Change text color on hover if needed */
}


/* .next-arrow-global:hover {
   
    border: none;
} */


/* First Page Shuffle Animation */
@keyframes firstPageShuffleLeft {
    0% {
        transform: translate(-100%, 0) scale(0.5);
        opacity: 0;
    }

    100% {
        transform: translate(0, 0) scale(1);
        opacity: 1;
    }
}

@keyframes firstPageShuffleRight {
    0% {
        transform: translate(100%, 0) scale(0.5);
        opacity: 0;
    }

    100% {
        transform: translate(0, 0) scale(1);
        opacity: 1;
    }
}

.first-page-shuffle-left {
    animation: firstPageShuffleLeft 1s ease forwards;
}

.first-page-shuffle-right {
    animation: firstPageShuffleRight 1s ease forwards;
}

/* Second Page Shuffle Animation */
@keyframes secondPageShuffleTop {
    0% {
        transform: translate(0, -100%) scale(0.5);
        opacity: 0;
    }

    100% {
        transform: translate(0, 0) scale(1);
        opacity: 1;
    }
}

@keyframes secondPageShuffleBottom {
    0% {
        transform: translate(0, 100%) scale(0.5);
        opacity: 0;
    }

    100% {
        transform: translate(0, 0) scale(1);
        opacity: 1;
    }
}

.second-page-shuffle-top {
    animation: secondPageShuffleTop 1s ease forwards;
}

.second-page-shuffle-bottom {
    animation: secondPageShuffleBottom 1s ease forwards;
}

/* Third Page Shuffle Animation */
@keyframes thirdPageShuffleLeft {
    0% {
        transform: translate(-100%, 0) scale(0.5);
        opacity: 0;
    }

    100% {
        transform: translate(0, 0) scale(1);
        opacity: 1;
    }
}

@keyframes thirdPageShuffleRight {
    0% {
        transform: translate(100%, 0) scale(0.5);
        opacity: 0;
    }

    100% {
        transform: translate(0, 0) scale(1);
        opacity: 1;
    }
}

.third-page-shuffle-left {
    animation: thirdPageShuffleLeft 1s ease forwards;
}

.third-page-shuffle-right {
    animation: thirdPageShuffleRight 1s ease forwards;
}

/* Label Animation */
@keyframes labelAnimationTop {
    0% {
        transform: translate(50%, -50%);
        opacity: 0;
    }

    100% {
        transform: translate(0, 0);
        opacity: 1;
    }
}

@keyframes labelAnimationBottom {
    0% {
        transform: translate(50%, 50%);
        opacity: 0;
    }

    100% {
        transform: translate(0, 0);
        opacity: 1;
    }
}

.label-animation-page-1-top-1,
.label-animation-page-1-top-2,
.label-animation-page-1-top-3 {
    /* animation: labelAnimationTop 0.8s ease forwards; */
    top: -20px;
    /* Position above the thumbnail */
    left: -5%;
    /* transform: translateX(-50%); */
    width: 100%;
}

.label-animation-page-1-top-1 {
    width: 140px;
}

.label-animation-page-1-top-2 {
    width: 150px;
}

.label-animation-page-1-top-3 {
    width: 210px;
}

.label-animation-page-1-bottom-1,
.label-animation-page-1-bottom-2,
.label-animation-page-1-bottom-3 {
    /* animation: labelAnimationBottom 0.8s ease forwards; */
    bottom: -20px;
    /* Position below the thumbnail */
    left: -5%;
    /* transform: translateX(-50%); */
    width: 100%;
}

.label-animation-page-1-bottom-1 {
    width: 200px;
}

.label-animation-page-1-bottom-2 {
    width: 215px;
}

.label-animation-page-1-bottom-3 {
    width: 250px;
}

.label-animation-page-2-top-2 {
    /* animation: labelAnimationTop 0.8s ease forwards; */
    top: -20px;
    /* Position above the thumbnail */
    left: -5%;
    /* transform: translateX(-50%); */
    width: 100%;
}

.label-animation-page-2-top-1,
.label-animation-page-2-top-3 {
    /* animation: labelAnimationTop 0.8s ease forwards; */
    top: -20px;
    /* Position above the thumbnail */
    left: -5%;
    /* transform: translateX(-50%); */
    width: 320px;
}

.label-animation-page-2-bottom-1,
.label-animation-page-2-bottom-2,
.label-animation-page-2-bottom-3 {
    /* animation: labelAnimationBottom 0.8s ease forwards; */
    bottom: -20px;
    /* Position below the thumbnail */
    left: -5%;
    /* transform: translateX(-50%); */
    width: 320px;
}

.label-animation-page-2-bottom-1 {
    width: 150px;
}

.label-animation-page-2-top-1 {
    width: 200px;
}

.label-animation-page-2-top-3 {
    width: 100%;
}


.label-animation-page-3-top-1,
.label-animation-page-3-top-2,
.label-animation-page-3-top-3 {
    /* animation: labelAnimationTop 0.8s ease forwards; */
    top: -20px;
    /* Position above the thumbnail */
    left: -5%;
    /* transform: translateX(-50%); */
    /* width: 100%; */
}

.label-animation-page-3-bottom-1,
.label-animation-page-3-bottom-2,
.label-animation-page-3-bottom-3 {
    /* animation: labelAnimationBottom 0.8s ease forwards; */
    bottom: -20px;
    /* Position below the thumbnail */
    left: -5%;
    /* transform: translateX(-50%); */
    /* width: 100%; */
}

.label-animation-page-2-bottom-2 {
    width: 100%;
}

.label-animation-page-3-bottom-2 {
    width: 200px;
    left: -10%;
}

.label-animation-page-3-top-3 {
    width: 175px;
    left: -5%;
}


.new-section {
    /*margin-top: 50px;*/
    text-align: center;
    padding: 35px 0;
}




.new-section h2 {
    font-weight: 600;
    font-size: 32px;
}

.circle-container {
    position: absolute;
    width: 100%;
    height: 730px;
    margin: 0 auto;
    /* display: flex; */
    /* justify-content: center; */
    align-items: center;
    bottom: -35px;
    display: none;
}

.circle-container:before {
    content: '';
    position: absolute;
    width: 60%;
    height: 80%;
    border: 5px dashed #FFD700;
    border-top-left-radius: 470px;
    border-top-right-radius: 470px;
    z-index: 0;
    bottom: 0;

}


.askvasi-home .card-container {
    display: flex;
    justify-content: center;
    /*margin-top: 30px;*/
}

.askvasi-home .card {
    border: 2px solid;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    margin: 0 15px;
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
}

.askvasi-home .card:hover {
    /* transform: translateY(-10px); */
}

.askvasi-home .card img {
    margin-bottom: 15px;
}

.askvasi-home .card h3 {
    font-weight: 600;
}

.askvasi-home .card p {
    margin: 0;
}

/* Container for each card */
.askvasi-home .card {
    position: relative;
    overflow: hidden;
    /* Ensure elements don't overflow the card */
}

/* Initially, all images are stacked in the center */
#box-1 img,
#box-2 img,
#box-3 img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
    /* All stacked on top of each other in the center */
    opacity: 1;
    transition: all 0.7s ease;
    /* Smooth transition for movement and opacity */
    z-index: 1;
    /* Stack images one on top of the other */
    max-width: fit-content;
}

/* Position the three cards in a circular layout */
.card1 {
    top: -3%;
    left: -6%;
}

.card2 {
    top: -25%;
    right: 0%;
}

.card3 {
    top: -3%;
    left: 6%;
}

/* Initially smaller icons */

.card-container-mobile {
    padding: 10px;
}

.card-container-mobile .card-1,
.card-container-mobile .card-2,
.card-container-mobile .card-3 {
    width: 93% !important;
    margin: auto;
    margin-bottom: 20px;
}

.card-1 {
    border: 4px solid #FFD700 !important;
    border-radius: 11px !important;
    width: 20% !important;
    height: 215px;
    z-index: 2;
    background: #FFF7E8;
}

#box-1 img:nth-child(2) {
    z-index: 3;
}

/* Hover effect for card-1 (expand and move) */
.card-1:hover #box-1 img:nth-child(1) {
    transform: translate(-50%, -150%) scale(2.1);
    opacity: 1;
    z-index: 3;
    position: relative;
    top: -40%;
    left: 90%;
}

.card-1:hover #box-1 img:nth-child(2) {
    transform: translate(150%, -50%) scale(1.6);
    opacity: 1;
    z-index: 2;
    left: 120%;
    top: 78%;
    width: 30px;
}

.card-1:hover #box-1 img:nth-child(3) {
    transform: translate(-150%, -50%) scale(1.7);
    opacity: 1;
    z-index: 1;
    left: 30%;
    width: 60px;
}

.card-2 {
    border: 4px solid #00BFFF !important;
    border-radius: 11px !important;
    width: 20% !important;
    height: 215px;
    position: relative;
    background: #E3F7FD;
}


/* Hover effect for card-2 */
.card-2:hover #box-2 img:nth-child(1) {
    transform: translate(-50%, -150%) scale(1);
    /* Move upwards */
    opacity: 1;
    z-index: 3;
    position: relative;
    top: 97%;
    width: 65px;
}

.card-2:hover #box-2 img:nth-child(2) {
    transform: translate(150%, -50%) scale(1);
    /* Move to the right */
    opacity: 1;
    z-index: 2;
    left: -100%;
    top: 60%;
    width: 90px;
}

.card-2:hover #box-2 img:nth-child(3) {
    transform: translate(-150%, -50%) scale(1);
    /* Move to the left */
    opacity: 1;
    z-index: 1;
    left: 10%;
    top: 60%;
    width: 55px;
}

.card-3 {
    border: 4px solid #DDA0DD !important;
    border-radius: 11px !important;
    width: 20% !important;
    height: 215px;
    position: relative;
    background: #F9F5FB;
}

/* Hover effect for card-3 */
.card-3:hover #box-3 img:nth-child(1) {
    transform: translate(-50%, -150%) scale(1);
    /* Move upwards */
    opacity: 1;
    z-index: 3;
    position: relative;
    top: 97%;
    width: 90px;
    left: 60%;
}

.card-3:hover #box-3 img:nth-child(2) {
    transform: translate(150%, -50%) scale(1);
    /* Move to the right */
    opacity: 1;
    z-index: 2;
    left: -130%;
    top: 60%;
    width: 114px;
}

.card-3:hover #box-3 img:nth-child(3) {
    transform: translate(-150%, -50%) scale(1);
    /* Move to the left */
    opacity: 1;
    z-index: 1;
    left: 39%;
    top: 60%;
    width: 63px;
}

/* Hide the center image on hover */
.askvasi-home .card img.center-image {
    transition: opacity 0.5s ease;
    /* Smooth fade-out */
}

.askvasi-home .card:hover img.center-image {
    opacity: 0;
    /* Hide the center image */
}


/* Suggestions Section */

.suggestion-heading {
    font-size: 52px;
    font-weight: 700;
    line-height: 62.4px;
    text-align: center;
    color: #32323233;
    ;
}




/* .cta-button:hover {
    background-color: #F7C200;
} */

/* Content wrapper to hold suggestions and chat */
.content-wrapper {
    /*flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: 150px;*/
    text-align: center;
}

.suggestions-section-top {
    text-align: center;
    width: 60%;
    display: block;
    margin: auto;
    padding-top: 30px;
}

.slide-up-overlay-slide-5.animated .suggestions-section-top {
    width: 60%;
}

.slide-up-overlay-slide-5 .suggestion-heading {
    display: none;
}

.slide-up-overlay-slide-5.animated .suggestion-heading,
.slide-up-overlay-slide-5.active .suggestion-heading {
    display: block;
}

/* .slide-up-overlay-slide-5.animated,
.slide-up-overlay-slide-5.active{
    overflow-y: scroll;
} */

.slide-up-overlay-slide-5 .chat-bar {
    display: none;
}

.slide-up-overlay-slide-5.animated .chat-bar,
.slide-up-overlay-slide-5.active .chat-bar {
    display: flex;
}



.suggestions-section {
    text-align: center;
    margin-top: 50px;
    padding: 20px;
}

.suggestions-header {
    /*display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    margin-bottom: 20px;*/
    font-size: 20px;
    width: 62%;
    margin: auto;
    padding-bottom: 20px;
    text-align: left;
}

.suggestions-header img {
    width: 42px;
    height: 42px;
    margin-right: 10px;
    border-radius: 50%;
}

.suggestions-container {
    position: fixed;
    bottom: 80px;
    left: 50%;
    transform: translateX(-50%);
    width: 60%;
    display: flex;
    justify-content: center;
    /* z-index: 1000; */
    padding: 10px;
    border-radius: 8px;
    display: none;
    color: #323232;
    /* display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 70%;
    margin: auto; */
}

.suggestions-section-top {
    margin-top: 6%;
}

.slide-up-overlay-slide-5.animated .suggestions-container,
.slide-up-overlay-slide-5.active .suggestions-container {
    display: flex;
}

.suggestion-button {
    background-color: transparent;
    /* border: 1px solid #00BFFF; */
    border-radius: 20px;
    padding: 4px 8px;
    font-size: 16px;
    margin: 10px;
    cursor: pointer;
    transition: all 1.3s ease;
    /* height: 78px; */
    width: 40%;
    white-space: nowrap;
    /* Prevents text from wrapping to the next line */
    overflow: hidden;
    /* Hides overflowing text */
    text-overflow: ellipsis;
    /* Adds '...' to indicate truncated text */
}

.suggestions-wrapper {
    display: flex;
    /* gap: 10px; */
    /* Space between buttons */
    transition: opacity 0.5s ease-in-out;
    /* Smooth transition */
    justify-content: center;
    width: 80%;
}

/* Animation classes */
.fade-in {
    opacity: 1;
}

.fade-out {
    opacity: 0;
}

.suggestion-button.personal:hover {
    background-color: #fff7e8;
    /* transform: translateY(-5px); */
}

.suggestion-button.explore:hover {
    background-color: #e3f7fd;
    /* transform: translateY(-5px); */
}

.suggestion-button.publish:hover {
    background-color: #f9f5fb;
    /* transform: translateY(-5px); */
}

/* .suggestion-button:hover {
    background-color: #e3e3e3;
    
} */

.suggestion-button {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 50px;
    padding: 10px;
    height: 44px;
    font-size: 14px;
    /* border: 1px solid #FFBD00 */
}

/* .suggestion-button.explore {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 50px;
    padding: 10px;
    height: 60px;
    /* border: 1px solid #65CBE9 */
/* }

.suggestion-button.publish {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 50px;
    padding: 10px;
    height: 60px; */
/* border: 1px solid #CDB4DB */
/*} */

.chat-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 60px;
    margin-top: 150px;
    position: relative;
}

.slide-up-overlay-slide-5.animated .chat-bar,
.slide-up-overlay-slide-5.active .chat-bar {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 10px 10px 16px 10px;
    background-color: white;
    text-align: center;
    /* display: block; */
    /* display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 60px;
    margin-top: 30px; */
}


.slide-up-overlay-slide-5.animated .app-buttons {
    display: flex;
    gap: 20px;
    margin-top: 10px;
    justify-content: center;
    align-items: center;
}

.chat-input {
    padding: 10px;
    font-size: 16px;
    width: 45%;
    border: 2px solid #D3D3D3;
    border-radius: 16px;
    outline: none;
    resize: none;
    overflow-y: auto;
    max-height: 80px;
    line-height: 20px;
    box-sizing: border-box;
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
    /* height: 50px !important; */
    white-space: pre-wrap;
    text-align: left;
}

.chat-input::-webkit-scrollbar {
    width: 10px;
    /* Width of the scrollbar */
}

.chat-input::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* Background of the scrollbar track */
    border-radius: 10px;
    /* Rounded corners for track */
    margin: 5px;
    /* Add some margin to create padding effect */
}

.chat-input::-webkit-scrollbar-thumb {
    background-color: #888;
    /* Scrollbar thumb color */
    border-radius: 10px;
    /* Rounded scrollbar thumb */
    border: 3px solid #f1f1f1;
    /* Add padding between the thumb and the track */
}

.chat-input::-webkit-scrollbar-thumb:hover {
    background-color: #555;
    /* Darker color when hovering over the thumb */
}

/* Placeholder styles */
.chat-input[data-placeholder]:empty:before {
    content: attr(data-placeholder);
    color: #888;
    pointer-events: none;
}


.chat-send-btn {
    background-color: #FFD700;
    border: none;
    padding: 11px 11px;
    border-radius: 50%;
    cursor: pointer;
    /* font-size: 20px; */
    outline: none;
    margin-left: 10px;
    width: 50px;
    height: 50px;
    background-image: url('../images/landing-page/send-btn.png');
    /* Replace with the actual image URL */
    background-size: cover;
    /* Ensures the image covers the button */
    background-position: center;
    /* Centers the image inside the button */
    cursor: pointer;
    transition: opacity 0.3s ease;
}

.chat-send-btn.disabled {
    background-color: #a9a9a987;
    cursor: progress;
    background-image: url('../images/landing-page/disabled-send-icon.png');
}

.bot-icon {
    width: 32px;
    height: 32px;
    margin-right: 20px;
    vertical-align: middle;
}

/* Full-screen slide-up section */
.slide-up-overlay-features {
    /* position: fixed;
    width: 100%;
    height: 100%;
    bottom: -100%;
    left: 0;
    z-index: 1000;
    transition: bottom 1.5s ease-in-out; */

    position: fixed;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    z-index: 1000;
    opacity: 0;
    /* Initially hidden */
    visibility: hidden;
    /* Ensure it's hidden when not active */
    transition: opacity 0.4s ease-in-out, visibility 0s 1.5s;
    /* Fade-in/out animation */
}

/* Active state to trigger slide-up */
.slide-up-overlay-features.active {
    /* bottom: 0;
    background: linear-gradient(to bottom, #fed80014, #d0a72500); */
    opacity: 1;
    /* Fully visible when active */
    visibility: visible;
    /* Make sure it's visible when active */
    transition: opacity 1.5s ease-in-out, visibility 0s 0s;
    /* Instant visibility, fade-in/out opacity */
    background: linear-gradient(to bottom, #fed80014, #d0a72500);
    /* Gradient background */
}


.askvasi-home .page-0 {
    background: linear-gradient(to bottom, #fed80014, #d0a72500);
}


/* Full-screen slide-up section */
.slide-up-overlay-slide-4 {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 100%;
    /* Initially place the section off-screen */
    left: 0;
    background: #FEFDF6D4;
    z-index: 1000;
    transition: transform 1.5s ease-in-out;
    visibility: hidden;
    /* Smooth slide-up transition */
}

/* Active state to trigger slide-up */
.slide-up-overlay-slide-4.active {
    transform: translateY(-87%);
}


/* Add a yellow circle in the middle */
.center-circle {
    position: absolute;
    bottom: 80px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #FFD700;
    width: 28%;
    border-top-left-radius: 423px;
    border-top-right-radius: 423px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
}

.center-circle h3 {
    font-size: 40px;
    font-weight: 600;
    color: #323232;
}

.center-circle p {
    font-size: 16px;
    color: #323232;
    text-align: center;
    margin-top: 5px;
}


/* Styles for the overlay page */
.overlay-page {
    position: fixed;
    bottom: -100%;
    /* Start position off-screen */
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: rgba(255, 255, 255, 99%);
    transition: bottom 2.0s ease-in-out;
    /* Smooth slide-up transition */
    overflow-y: auto;
    /* Allow scrolling if content is taller than the viewport */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    justify-content: center;
    align-items: center;
}

.slide-up-overlay-slide-5 {
    position: fixed;
    bottom: -100%;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: white;
    display: none;
    /* transition: bottom 1.5s ease-in-out; */
    /* position: fixed;
    width: 100%;
    height: 100%;
    bottom: -100%;    
    top: 100%;   
    left: 0;
    background: #FEFDF6D4;
    z-index: 1000;
    transition: transform 1.5s ease-in-out; */

}

/* Active state to trigger slide-up */
.slide-up-overlay-slide-5.active {
    bottom: 0;
    /* Slide up to the viewport */
    padding-top: 135px;
    /* transform: translateY(-87%);
    position: absolute; */
    display: block;
}

/* Active state to trigger slide-up */
.slide-up-overlay-slide-5.animated {
    position: relative;
    transform: none;
    bottom: auto;
    top: 0%;
    padding-top: 100px;
    display: block;
}

.slide-up-overlay-slide-5.animated .container {
    display: flex;
    /* width: 60%; */
    flex-direction: column;
    /* min-height: 100vh; */
    padding-bottom: 80px;
}

.slide-up-overlay-slide-5.animated .container.conversation {
    width: 50%;
}

/* Bot response container: logo and response side by side */
.bot-response-container {
    display: flex;
    align-items: flex-start;
    /* Aligns the response and logo vertically */
    margin-bottom: 1px;
}

/* .bot-response-container h3 {
    font-size: 1.3rem;
} */

.overlay-page.visible {
    bottom: 0;
    /* Slide to the top of the viewport */
    padding-top: 570px;
}


.page-wrapper {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.slide-up-overlay-slide-6 {
    /*position: relative;
    width: 100%;
    height: 100%;*/
    background: #fff;
    display: none;
    z-index: 999;
}

/* Active state to trigger slide-up */
.slide-up-overlay-slide-6.active {
    display: grid;
}


.slide-up-overlay-slide-6.active .footer-container {
    background: none;
    position: relative;
}



/* Blurred images container - relative positioning to allow absolute child positioning */
/* .blurred-images-container-hero-section {
    position: relative;
    width: 100%;
    height: 100%;
}

.blurred-image {
    position: absolute;
    width: 150px;
    height: 120px;
    background-color: #fff;
    overflow: hidden;
    transition: 0.5s ease;
    cursor: pointer;
}

.blurred-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: blur(8px);
    transition: 0.5s ease;
    border-radius: 8px;
    opacity: 0.2;
}

.blurred-image img:hover {
    filter: blur(0);   
    opacity: 1;    
}

.blurred-image.image-1:hover, .blurred-image.image-2:hover, .blurred-image.image-3:hover, .blurred-image.image-4:hover, .blurred-image.image-5:hover, .blurred-image.image-6:hover{
    z-index: 3;
    height: 200px;
} */


/* Individual image positions */
/* .blurred-image.image-1 {
    top: 20%;
    left: 20%;
}

.blurred-image.image-2 {
    top: 18%;
    right: 22%;
}

.blurred-image.image-3 {
    bottom: 18%;
    left: 20%;
}

.blurred-image.image-4 {
    bottom: 20%;
    right: 20%;
}

.blurred-image.image-5 {
    top: 15%;
    left: 45%;
}


.blurred-image.image-6 {
    bottom: 15%;
    right: 40%;
} */

/* Overlay content */
/* .overlay-content-hero-section {
    text-align: center;
    position: absolute;
    z-index: 2;
    width: 60%;
    top: 35%;
    margin: auto;
    left: 0;
    right: 0;

}

.overlay-content-hero-section h1 {
    font-size: 48px;
    font-weight: bold;
    color: #323232;
    margin-bottom: 20px;
}

.overlay-content-hero-section p {
    font-size: 18px;
    color: #777;
    margin-bottom: 30px;
} */



/* Hero section container with two columns */
.hero-section-container {
    display: flex;
    align-items: center;
    /* Vertically centers the content in both columns */
    justify-content: center;
    /* Keep both columns horizontally centered */
    width: 100%;
    /* max-width: 1200px; */
    /* Set a max width to avoid stretching too much */
    padding: 20px;
    margin-top: 18%;
}

/* Left column: Overlay content */
.overlay-content-hero-section {
    flex: 1;
    /* Takes up half the width */
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* Vertically centers content in the left column */
}

.overlay-content-hero-section .hero-title {
    /* font-family: Secular One; */
    font-family: Open Sans;
    font-size: 55px;
    font-weight: 600;
    line-height: 60.4px;
    text-align: left;
    color: #323232;
    position: relative;
}

.overlay-content-hero-section .text-slide {
    top: 0px;
    margin-left: 10px;
    width: auto;
    height: 30px;
}


.overlay-content-hero-section .fade-in-title {
    color: #FED800;

}

.overlay-content-hero-section p {
    font-family: Open Sans;
    font-size: 24px;
    font-weight: 400;
    line-height: 33px;
    text-align: left;
    margin-bottom: 30px;
    margin-top: 15px;
}

/* Reminder card styling */
.reminder-card {
    /* background-color: #e0f7ff; */
    border: 1px solid #6bb4d2;
    border-radius: 10px;
    padding: 8px;
    margin: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* display: flex;
    flex-direction: column;
    justify-content: space-between; */

}

.reminder-title {
    font-size: 15px;
    font-weight: bold;
    color: #323232;
    /* CSS for truncating with ellipsis */
    white-space: nowrap;
    /* Prevents text from wrapping to multiple lines */
    overflow: hidden;
    /* Hides the overflowing content */
    text-overflow: ellipsis;
    /* Adds the ellipsis (...) when content is too long */
    width: 150px;
}

.reminder-time {
    font-size: 11px;
    color: #323232;
    margin-top: 10px;
    margin-bottom: 10px;
    /* display: flex;
    align-items: center; */
    padding: 3px;
    background: #6bb4d2ab;
    width: 100%;
    border-radius: 8px;
}

.reminder-time i {
    margin-right: 8px;
    color: #323232;
}

/* Container for the list of reminders */
.reminders-container {
    padding: 20px;
}

.reminder-list .slick-track {
    margin: inherit;
    /* display: flex;
    flex-wrap: wrap;
    justify-content: space-between; */
}

.try-btn-mobile {
    display: none;
}

.blurred-images-container-hero-section {
    display: flex !important;
}

.slide-up-overlay-slide-5.active .suggestions-container-mobile,
.slide-up-overlay-slide-5.animated .suggestions-container-mobile {
    display: none;
}


.display-none{
    display: none;
}


.flex{
    display: flex !important;
}


/* Mobile styles (for screens with a max width of 768px) */
@media only screen and (max-width: 768px) {
    /* .blurred-images-container-hero-section {
        display: none !important;
    } */

    .chat-bubble {
        max-width: 85% !important;
    }

    .slide-up-overlay-slide-6.active {
        display: block;
    }

    .slide-up-overlay-slide-5.active .suggestions-container,
    .slide-up-overlay-slide-5.animated .suggestions-container {
        display: none;
    }

    .text-sequence {
        font-size: 32px;
    }

    .next-arrow-global::before {
        background-color: transparent;
        color: #323232;
    }

    .next-arrow-global:hover {
        color: #323232;
    }

    .suggestions-container-mobile {
        position: fixed;
        bottom: 70px;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 10px;
        border-radius: 8px;
        display: none;
    }

    /* .slide-up-overlay-slide-5.active .suggestions-container-mobile {
        display: block;
    } */

    .slide-up-overlay-slide-5.active .suggestions-container-mobile,
    .slide-up-overlay-slide-5.animated .suggestions-container-mobile {
        display: block;
    }

    .slide-up-overlay-slide-5.active .suggestions-container-mobile .slick-slide,
    .slide-up-overlay-slide-5.animated .suggestions-container-mobile .slick-slide {
        margin-right: 10px;
    }

    .slide-up-overlay-slide-5.active .suggestions-container-mobile .suggestion-button.personal,
    .slide-up-overlay-slide-5.active .suggestions-container-mobile .suggestion-button.explore,
    .slide-up-overlay-slide-5.animated .suggestions-container-mobile .suggestion-button.personal,
    .slide-up-overlay-slide-5.animated .suggestions-container-mobile .suggestion-button.explore {
        min-height: 44px;
        color: #323232;
        border: none;
        box-shadow: 0 0px 8px rgba(0, 0, 0, 0.1);
    }



    .slide-up-overlay-slide-5.active .suggestions-container-mobile .suggestion-button.publish,
    .slide-up-overlay-slide-5.animated .suggestions-container-mobile .suggestion-button.publish {
        min-height: 50px;
        color: #323232;
        border: none;
        box-shadow: 0 0px 8px rgba(0, 0, 0, 0.1);
    }



    .askvasi-home .navbar-nav .nav-item {
        margin-left: 0px;
    }

    .mobile-only-carousel-mobile {
        display: block;
        width: 100%;
        margin-bottom: 80px;
    }

    .slide-container-mobile .slick-slider {
        /* width: 320px; */
        /* margin: auto; */
        /* display: block; */
    }

    .mobile-only-carousel-mobile {
        margin-top: 10%;
    }

    .suggestions-section-top {
        margin-top: 30%;
    }

    .suggestions-wrapper {
        width: 100%;
    }


    .slide-container-mobile .slider-container .slick-prev {
        right: 53px !important;
    }

    .carousel-image-mobile {
        margin-right: 10px;
    }

    .carousel-image-mobile img {
        border-radius: 8px;
    }

    .slide-container-mobile .similar-dots-overlay {
        width: 150px;
    }

    .slide-container-mobile .similar-dot-image {
        width: 150px;
        height: 150px;
    }

    .slide-up-overlay-slide-5 .container.conversation {
        font-size: 18px;
    }

    .try-btn-mobile {
        font-size: 14px;
        font-weight: 700;
        line-height: 14.14px;
        /* text-align: center; */
        text-decoration: underline;
        /* margin-left: 100px; */
        display: block;
    }

    .try-btn {
        display: none;
    }

    .label-animation-page-1-top-1,
    .label-animation-page-1-top-2,
    .label-animation-page-1-top-3 {
        width: 100%;
    }

    .label-animation-page-1-bottom-1,
    .label-animation-page-1-bottom-2,
    .label-animation-page-1-bottom-3 {
        width: 100%;
    }

    .label-animation-page-1-top-3 {
        width: 170px;
        left: -24%;
    }

    .label-animation-page-1-bottom-2 {
        width: 170px;
        left: -12%;
    }

    .label-animation-page-1-bottom-1 {
        width: 150px;
        left: -10%;
    }

    .next-arrow-global {
        width: 95%;
    }

    .new-section {
        padding: 50px 0;
    }

    .similar-dot-image {
        width: 90% !important;
        height: 145px !important;
    }

    .similar-dots-overlay {
        width: 90% !important;
    }

    .conversation-box {
        font-size: 14px;
    }

    .slide-up-overlay-slide-4.active {
        position: relative;
        /* overflow: scroll;
        top: 93%; */
    }

    .gif-container{
        /* height: 100vh;
        margin-top: 50%; */
    }

}

/* For larger screens (tablets and desktops), hide the component */
@media only screen and (min-width: 769px) {
    .mobile-only-carousel-mobile {
        display: none;
    }
}

.grid-item .caption {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;

}



.overlay-content-hero-section {
    /* margin-bottom: 15%; */
}



.discover-header {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    /* text-align: center; */
    margin-bottom: 10px;
}

.blurred-images-carousel-mobile {
    /* display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 330px;
    overflow: visible; */
}

.carousel-image-mobile {
    /* position: absolute;
    width: 180px;
    height: 180px;
    opacity: 0.5;
    filter: blur(10px);
    transition: all 0.5s ease-in-out; */
}

.carousel-image-mobile img {
    /* width: 100%;
    height: 100%;
    transition: transform 0.5s ease-in-out;
    border-radius: 8px; */
}

.carousel-image-mobile.active-mobile {
    /* width: 200px;
   
    height: 200px;
    opacity: 1;
    filter: blur(0);
   
    z-index: 10;
   
    transform: scale(1.2); */

}

.carousel-image-mobile.adjacent-mobile {
    /* width: 180px;
  
    height: 180px;
    opacity: 0.7;
    filter: blur(5px);
 
    z-index: 5; */

}

.carousel-image-mobile:not(.active-mobile):not(.adjacent-mobile) {
    /* width: 100px;
   
    height: 100px;
    opacity: 0.5;
    filter: blur(10px);
   
    z-index: 1; */
}

/* Position the images centrally */
.carousel-image-mobile {
    /* left: 33%;
    transform: translateX(-50%); */
}

.cta-button {
    background-color: #FFD700;
    border: none;
    color: #323232;
    /* padding: 15px 30px; */
    font-size: 16px;
    font-weight: 600;
    border-radius: 30px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 170px;
    padding: 10px 12px 10px 12px;
    /* height: 44px; */

    position: relative;
    /* This is necessary for the overlay */
    overflow: hidden;
    /* Ensure overlay is contained inside the button */
    z-index: 1;

}

.cta-button::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    /* Black color with opacity */
    transition: width 0.5s ease-in-out;
    /* Smooth transition */
    z-index: -1;
    /* Behind the button text */
}

.cta-button:hover::before {
    width: 100%;
    /* The overlay grows from 0% to 100% on hover */
}

.cta-button:hover {
    color: #fff;
    /* Optional: Change text color on hover if needed */
}


/* Container for the CTA button */
.cta-container {
    display: flex;
    /* justify-content: center; */
    margin-top: 10px;
}

/* Styling the anchor tag to look like a rounded button */
.cta-button-link {
    display: inline-block;
    padding: 10px 20px;
    color: #323232;
    text-decoration: none;
    border-radius: 25px;
    /* This makes the button rounded */
    border: 1px solid #FED800;
    font-size: 14px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

/* Hover effect for the anchor tag */
.cta-button-link:hover {
    background-color: white;
    color: #323232;
    border: 1px solid #FED800;
    /* border-color: #323232; */
}



/* Right column: Blurred images */
.blurred-images-container-hero-section {
    flex: 1;
    /* Takes up the other half of the width */
    display: flex;
    flex-wrap: wrap;
    /* Wraps images if needed */
    justify-content: center;
    /* Horizontally centers the images */
    align-items: center;
    /* Vertically centers the images */
    gap: 10px;
    /* Gap between images */
    padding: 20px;
    position: relative;
    /* height: 300px; */
}

/* .blurred-image img {
    width: 100%;
    height: auto;
    max-width: 150px;
   
    border-radius: 8px;
    filter: blur(4px);
    
} */

.blurred-image {
    /* position: absolute; */
    width: 150px;
    height: 120px;
    /* background-color: #fff; */
    overflow: hidden;
    transition: 0.5s ease;
    cursor: pointer;
}

/* 
.blurred-image.image-1{
    position: absolute;
    right: 10px;
    top: 24px;
}

.blurred-image.image-2{
    position: absolute;
    right: -59px;
    top: -145px;
}




.blurred-image.image-4{
    position:absolute;
    top: -227px;
}

.blurred-image.image-5{
    position:absolute;
    left:25px;
    right: 10px;
}

.blurred-image.image-6{
    position:absolute;
    left: 94px;
    right: 26px;
    top: -133px;
} */
/* Default style: All images are blurred except for images 3 and 5 */
.blurred-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: blur(8px);
    transition: filter 0.5s ease, opacity 0.5s ease;
    border-radius: 8px;
    opacity: 0.2;
}

/* Specific image positioning */
.blurred-image.image-3 {
    position: absolute;
    top: 74px;
    left: 133px;
    height: 200px;
}

.blurred-image.image-5 {
    position: absolute;
    right: 150px;
    height: 200px;
}

/* Make images 3 and 5 non-blurred by default */
.blurred-image.image-3 img,
.blurred-image.image-5 img {
    filter: blur(0);
    opacity: 1;
}

/* On hovering over the container, blur all images */
.blurred-images-container-hero-section:hover .blurred-image img {
    filter: blur(8px);
    opacity: 0.2;
}

/* On hovering over a specific image, make it non-blurred */
.blurred-image:hover img {
    filter: blur(0) !important;
    /* Ensure the hovered image overrides the container rule */
    opacity: 1 !important;
    /* Ensure the opacity is restored */
    transition: filter 0.5s ease, opacity 0.5s ease;
}

/* Bring the hovered image to the front */
.blurred-image.image-1:hover,
.blurred-image.image-2:hover,
.blurred-image.image-3:hover,
.blurred-image.image-4:hover,
.blurred-image.image-5:hover,
.blurred-image.image-6:hover {
    z-index: 3;
    height: 200px;
}




.footer-container.visible {
    display: block;
    width: 100%;
}

/* Footer Container */
.footer-container {
    display: flex;
    flex-direction: column;
    padding: 170px 120px 25px 120px;
    justify-content: space-between;
    background: #FED80008;
    position: relative;
}

/* Left Section Styling */
.left-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* Align content to the left */
    margin-bottom: 20px;
}

.footer-heading {
    font-size: 48px;
    /* font-weight: 300; */
    margin-bottom: 10px;
    font-weight: 600;
    line-height: 100%;
}


.footer-heading span.outlined-text {
    color: transparent;
    /* Transparent fill */
    -webkit-text-stroke: 1px #323232;
    /* Outline with black color, adjust thickness as needed */
    text-stroke: 1px #000;
    /* For non-WebKit browsers */
    font-weight: 600;
    /* Optional: Make the outline bold */
}

.footer-heading span {
    font-weight: 600;
}

.footer-description {
    margin-bottom: 30px;
    /* color: #000; */
    font-size: 24px;
    font-weight: 400;
    line-height: 26.15px;
    letter-spacing: -0.005em;
    margin-bottom: 30px;
}

.app-buttons {
    display: flex;
    gap: 10px;
}

.app-buttons img {
    /* width: 160px; */
    border-radius: 8px;
}

/* Bottom Full-Width Section */
.bottom-section {
    width: 100%;
    padding-top: 20px;
    text-align: center;
}

/* Footer row that contains links and social icons */
.footer-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 20px 0;
    flex-wrap: wrap;
    /* Allows wrapping on smaller screens */
}

/* Footer Links Styling */
.footer-links {
    display: flex;
    justify-content: space-between;
    width: 45%;
}

.footer-links a {
    color: #323232;
    text-decoration: none;
    margin: 0 20px;
}

/* Footer Social Icons */
.footer-social-icons {
    display: flex;
    justify-content: center;
}

.footer-social-icons img {
    /* border-radius: 50%; */
}

.footer-social-icons a {
    text-decoration: none;
    /* border: 2px solid #D3D3D3; */
    /* border-radius: 50%; */
    margin: 0 10px;
    padding: 10px;
    /* color: #323232; */
    /* font-size: 20px; */
    display: inline-block;
    /* line-height: 26px; */
    /* width: 60px;
    height: 60px;
    transition: all 0.3s ease; */
}

.footer-social-icons a:hover {
    background-color: #FFD700;
    border-color: #FFD700;
    color: #fff;
    border-radius: 50%;
}

/* Copyright Text */
.footer-bottom-text {
    font-size: 14px;
    color: #999;
    margin-top: 20px;
    text-align: center;
}

.footer-bottom-chat {
    font-size: 9px;
    position: fixed;
    bottom: 0;
    text-align: center;
    margin: auto;
    width: 100%;
    background: #fff;
}

.conversation-box {
    display: flex;
    flex-direction: column;
    max-height: 600px;
    height: 570px;
    width: 70%;
    margin: auto;
    margin-bottom: 20px;
    overflow-y: auto;
}

.slide-up-overlay-slide-5.animated .conversation-box {
    flex-grow: 1;
    margin-bottom: 60px;
    display: contents;
    overflow-y: hidden;
    height: 0px;
    max-height: 0px;
    /* display: flex;
    flex-direction: column;
    max-height: 600px;
    height: 570px;
    width: 70%;
    margin: auto;
    margin-bottom: 20px;
    overflow-y: auto; */
}


.chat-bubble {
    max-width: 100%;
    padding: 10px;
    margin: 10px 0;
    border-radius: 15px;
    word-wrap: break-word;
}

.bot-response-container .chat-bubble {
    margin: 3px 0;
    padding: 0;
}

.scroll-container::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari, Edge */
}

.user-bubble {
    align-self: flex-end;
    background-color: #FEF8DE;
    color: #323232;
    float: right;
    max-width: 50%;
}

.bot-bubble {
    align-self: flex-start;
    /* background-color: #f1f0f0; */
    color: #323232;
}

.loading {
    font-size: 12px;
    color: #777;
    font-style: italic;
    margin: 5px 0;
}

.similar-dots-container {
    /* display: flex; */
    flex-wrap: nowrap;
    gap: 10px;
    justify-content: left;
    padding: 5px;
    /* overflow-x: auto; */
    scroll-behavior: smooth;
    white-space: nowrap;
}

.similar-dots-container .slick-initialized .slick-slide {
    position: relative;
}



.dot-card {
    width: 150px;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 0px;
    text-align: center;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.similar-dot-user-avatar {
    width: 24px;
    height: 24px;
    border-radius: 100%;
}


/* Each card */
.similar-dot-card {
    position: relative;
    width: 160px;
    /* Adjust the size as per your design */
    height: 160px;
    /* Adjust the size as per your design */
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.similar-dot-card:hover {
    transform: scale(1.02);
    cursor: pointer;
}

/* The image should cover the entire card */
.similar-dot-image {
    width: 150px;
    height: 170px;
    /* object-fit: cover; */
}

.featured-dot-image {
    width: 94%;
    height: 200px;
    border-radius: 8px;
}

/* Overlay for title and creator name */
.similar-dots-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    /* Black with transparency */
    background: linear-gradient(to top, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.1));
    backdrop-filter: blur(8px);
    /* Adjust blur as needed */
    color: white;
    padding: 8px;
    width: 150px;
    border-radius: 0px 0px 8px 8px;
}

@media (min-width: 1024px) and (max-width: 1223px) {
    .similar-dot-image {
        width: 115px;
        height: 130px;
    }

    .similar-dots-overlay {
        width: 115px;
    }
}

@media (min-width: 1224px) and (max-width: 1323px) {
    .similar-dot-image {
        width: 135px;
        height: 140px;
    }

    .similar-dots-overlay {
        width: 135px;
    }
}

@media (min-width: 1324px) and (max-width: 1370px) {
    .similar-dot-image {
        width: 150px;
        height: 170px;
    }

    .similar-dots-overlay {
        width: 150px;
    }
}



.featured-dots-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    /* Black with transparency */
    background: linear-gradient(to top, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.1));
    backdrop-filter: blur(8px);
    /* Adjust blur as needed */
    color: white;
    padding: 8px;
    width: 94%;
    border-radius: 0px 0px 8px 8px;
}


.similar-dots-container .slick-slide .similar-dot-image {
    /* border-radius: 8px 8px 0px 0px; */
    border-radius: 8px;
}

.similar-dots-container .slick-slide img {
    display: inline-block;
}

.similar-dot-title {
    font-size: 12px;
    font-weight: 600;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    line-height: 1.2em;
    max-height: 3.6em;
    word-wrap: break-word;
}

.similar-dot-creator {
    font-size: 12px;
    margin-top: 5px;
    font-weight: 400;
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    word-wrap: break-word;
}

/* Arrow styling */
.scroll-arrow {
    position: fixed;
    bottom: 105px;
    left: 50%;
    transform: translate(-50%, 50%);
    /* border: 1px solid #323232;
    color: #323232; */
    /* background-color: #FED800;
    color: #fff; */
    border-radius: 50%;
    /* width: 40px;
    height: 40px; */
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    /* font-size: 20px; */
    display: none;
    z-index: 1;
}

.slide-up-overlay-slide-5.animated .scroll-arrow {
    display: flex;
}


/* .chat-bar-container {
    position: relative;
    display: flex;
    align-items: center;
} */

.chat-switch-icon {
    /* position: relative;
    left: 0px;
    margin-right: 8px;
    float: left; */
    background-color: #FFD700;
    border: none;
    padding: 11px 11px;
    border-radius: 50%;
    cursor: pointer;
    /* font-size: 20px; */
    outline: none;
    margin-right: 10px;
    width: 50px;
}

.slide-up-overlay-slide-5.animated .chat-switch-icon {
    /* left: 20%; */
    margin-right: 10px;
}

.chat-options-menu {
    position: absolute;
    top: -135px;
    left: 20%;
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    padding: 10px;
    z-index: 100;
    text-align: left;
    border-radius: 8px;
    /* display: none; */
}

.slide-up-overlay-slide-5.animated .chat-options-menu {
    display: block;
    top: -140px;
}

.chat-options-menu button {
    display: block;
    width: 100%;
    margin-bottom: 5px;
    cursor: pointer;
}


.chat-option {
    padding-bottom: 10px;
    cursor: pointer;
}

.chat-option>img {
    padding: 3px;
}

.categoryType {
    margin-left: 5px;
}


.checkmark {
    color: #FFD700;
    /* Gold checkmark color */
    font-size: 18px;
    margin-left: 10px;
}

.blinking-circle {
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #FFD700;
    animation: blink 1s infinite;
    margin-left: 5px;
    /* Add some space before the circle */
}

@keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.similar-dots-container .slick-track {
    float: left;
}

/* .chat-message h1,
h2 {
    font-size: 1.6rem;
} */

.chat-message, .chat-message p,
li {
    font-size: 15px;
    line-height: 24px;
}

.similar-dots-container a {
    text-decoration: none;
}

.navbar-nav .nav-item .nav-link.try-btn:hover {
    border-bottom: none;
}

/* .askvasi-home .nav-item .try-btn:hover{
    color: red;
} */

/* Keyframes for fade in and fade out */
@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

/* Initial fade class */
.fade {
    display: inline-block;
    transition: opacity 0.5s ease-in-out;
}

/* Fade in effect */
.fade.in {
    animation: fadeIn 0.5s forwards;
}

/* Fade out effect */
.fade.out {
    animation: fadeOut 0.5s forwards;
}

.card-container-mobile {
    display: none;
}

/* .center-circle, .circle-container{
    display: block!important;
} */


.left-section {
    width: 33%;
}

 .footer-description {
    font-family: Poppins;
    font-weight: 400;
    font-size: 20px;
    line-height: 130%;
    letter-spacing: 0px;
}

 .footer-row {
    border-top: 1px solid #E6E6E6;
}

 .footer-container {
    padding: 10px 120px 0px 120px;
}

 .footer-container{
    background: #FFFCED;
}

@media (max-width: 1920px) and (min-width: 1080px) {
    .page-2 .grid-item:nth-child(4) {
        bottom: 17%;
    }

    .page-3 .grid-item:nth-child(2) {
        right: 37%;
    }

    .circle-container {
        bottom: -70px;
    }

    .chat-bar {
        margin-top: 100px;
    }

    .blurred-image.image-6 {
        bottom: 7%;
    }

    .blurred-image.image-4 {
        bottom: 10%;
    }
}

@media (max-width: 1365px) and (min-width: 1023px) {
    .label-animation-page-2-top-2 {
        width: 216px;
    }

    .label-animation-page-2-top-3 {
        width: 230px;
    }

    .label-animation-page-2-bottom-2 {
        width: 230px;
    }

    .page-1 .grid-item:nth-child(1) {
        top: 25%;
        left: 10%;
    }

    .page-1 .grid-item:nth-child(2) {
        top: 10%;
        right: 35%;
    }

    .page-1 .grid-item:nth-child(3) {
        top: 30%;
        right: 10%;
    }

    .page-1 .grid-item:nth-child(4) {
        left: 42%;
    }

    .page-1 .grid-item:nth-child(5) {
        left: 10%;
    }

    .page-1 .grid-item:nth-child(6) {
        bottom: 32%;
        right: 7%;
    }


    .page-2 .grid-item:nth-child(1) {
        top: 20%;
        left: 15%;
    }

    .page-2 .grid-item:nth-child(2) {
        top: 16%;
        right: 18%;
    }

    .page-2 .grid-item:nth-child(3) {
        top: 42%;
        right: 4%;
    }

    .page-2 .grid-item:nth-child(4) {
        bottom: 16%;
        left: 65%;
    }

    .page-2 .grid-item:nth-child(5) {
        bottom: 20%;
        left: 10%;
    }

    .page-3 .grid-item:nth-child(2) {
        right: 10%;
    }

    .page-3 .grid-item:nth-child(3) {
        top: 40%;
        right: 10%;
    }

    .page-3 .grid-item:nth-child(4) {
        bottom: 22%;
        left: 65%;
    }

    .page-3 .grid-item:nth-child(5) {
        bottom: 15%;
        left: 10%;
    }

}

@media (max-width: 1024px) and (min-width: 769px) {

    .suggestions-container {
        width: 100%;
    }

    .chat-input {
        width: 70%;
    }


    .grid-item {
        width: 190px;
        height: 120px;
    }

    .label-animation-bottom {
        width: 255px;
        left: 10%;
    }

    .label-animation-top {
        width: 215px;
    }

    .label-animation-page-2-top-2 {
        width: 216px;
    }

    .label-animation-page-2-top-3 {
        width: 230px;
    }

    .label-animation-page-2-bottom-2 {
        width: 230px;
    }

    .page-1 .grid-item:nth-child(1) {
        top: 25%;
        left: 10%;
    }

    .page-1 .grid-item:nth-child(2) {
        top: 10%;
        right: 35%;
    }

    .page-1 .grid-item:nth-child(3) {
        top: 30%;
        right: 10%;
    }

    .page-1 .grid-item:nth-child(4) {
        left: 42%;
    }

    .page-1 .grid-item:nth-child(5) {
        left: 10%;
    }

    .page-1 .grid-item:nth-child(6) {
        bottom: 32%;
        right: 7%;
    }


    .page-2 .grid-item:nth-child(1) {
        top: 20%;
        left: 15%;
    }

    .page-2 .grid-item:nth-child(2) {
        top: 16%;
        right: 18%;
    }

    .page-2 .grid-item:nth-child(3) {
        top: 42%;
        right: 4%;
    }

    .page-2 .grid-item:nth-child(4) {
        bottom: 16%;
        left: 65%;
    }

    .page-2 .grid-item:nth-child(5) {
        bottom: 20%;
        left: 10%;
    }

    .page-3 .grid-item:nth-child(2) {
        right: 10%;
    }

    .page-3 .grid-item:nth-child(3) {
        top: 40%;
        right: 10%;
    }

    .page-3 .grid-item:nth-child(4) {
        bottom: 22%;
        left: 65%;
    }

    .page-3 .grid-item:nth-child(5) {
        bottom: 15%;
        left: 10%;
    }

    .card-1,
    .card-2,
    .card-3 {
        width: 24%;
    }

    .card-1 {
        height: 200px;
    }

    .blurred-image.image-2 {
        right: 17%;
    }

    .blurred-image.image-4 {
        right: 15%;
    }

    .blurred-image.image-6 {
        bottom: 6%;
    }

    /* .slide-up-overlay-slide-5.animated .chat-switch-icon {
        left: 80px;
    } */

    .chat-options-menu {
        left: 60px;
    }

    .slide-up-overlay-slide-5.animated .container,
    .slide-up-overlay-slide-5.animated .container.conversation {
        width: 100%;
    }

    .overlay-content-hero-section {
        width: 100%;
    }


}

@media (max-width: 768px) and (min-width: 424px) {

    .askvasi-home .navbar {
        padding: 15px;
    }

    .suggestions-container {
        width: 100%;
    }

    .chat-input {
        width: 70%;
    }

    .background-container {
        top: 95px;
        left: 0px;
        width: 100%;
        height: 100%;
    }

    #centerText .topText {
        font-size: 32px;
        font-weight: 400;
    }

    #centerText .bottomText {
        display: block;
        text-align: left;
        font-size: 18px;
        font-weight: 400;
    }

    .grid-container #centerText {
        flex: auto;
        text-align: left;
        margin-left: 33px;
    }

    .label-animation-page-1-top-3,
    .label-animation-page-1-bottom-2 {
        width: 205px;
        left: -4%;
    }

    .label-animation-page-1-bottom-1 {
        width: 180px;
    }

    .page-1 .grid-item:nth-child(1) {
        top: 16%;
        left: 5%;
    }

    .page-1 .grid-item:nth-child(2) {
        top: 22%;
        /* right: 27%; */
        right: 0%;
    }

    .page-1 .grid-item:nth-child(3) {
        top: 45%;
        right: 2%;
    }

    .page-1 .grid-item:nth-child(4) {
        left: 56%;
        bottom: 16%;
    }

    .page-1 .grid-item:nth-child(5) {
        left: 3%;
        bottom: 23%;
    }


    .page-1 .grid-item:nth-child(6) {
        display: none;
    }

    .grid-item {
        width: 190px;
        height: 120px;
    }

    .label-animation-bottom {
        width: 255px;
        left: 10%;
    }

    .label-animation-top {
        width: 215px;
    }


    .label-animation-page-2-top-2 {
        width: 210px;
    }

    .label-animation-page-2-top-3 {
        width: 230px;
        left: -15%;
    }

    .label-animation-page-2-bottom-2 {
        width: 230px;
    }

    .label-animation-page-3-top-3 {
        left: -4%;
    }

    .page-2 .grid-item:nth-child(1) {
        top: 20%;
        left: 3%;
    }

    .page-2 .grid-item:nth-child(2) {
        top: 15%;
        right: 3%;
    }

    .page-2 .grid-item:nth-child(3) {
        top: 42%;
        right: 3%;
    }

    .page-2 .grid-item:nth-child(4) {
        bottom: 15%;
        left: 57%;
    }

    .page-2 .grid-item:nth-child(5) {
        bottom: 20%;
        left: 3%;
    }

    .page-3 .grid-item:nth-child(1) {
        top: 14%;
        left: 3%;
    }

    .page-3 .grid-item:nth-child(2) {
        right: 2%;
    }

    .page-3 .grid-item:nth-child(3) {
        top: 40%;
        right: 3%;
    }

    .page-3 .grid-item:nth-child(4) {
        bottom: 24%;
        left: 56%;
    }

    .page-3 .grid-item:nth-child(5) {
        bottom: 18%;
        left: 7%;
    }

    .card-1,
    .card-2,
    .card-3 {
        width: 30%;
        height: 200px;
    }

    .card-1 {
        top: -3%;
        left: 0%;
    }

    .card3 {
        top: -3%;
        left: 0%;
    }

    .suggestion-heading {
        font-size: 36px;
        line-height: 45.4px;
    }

    .suggestions-section-top {
        width: 100%;
    }

    .blurred-image.image-1 {
        top: 20%;
        left: 13%;
    }

    .blurred-image.image-2 {
        right: 9%;
    }

    .blurred-image.image-3 {
        bottom: 18%;
        left: 9%;
    }

    .blurred-image.image-4 {
        right: 10%;
        bottom: 20%;
    }

    .blurred-image.image-5 {
        top: 15%;
        left: 45%;
    }

    .blurred-image.image-6 {
        bottom: 6%;
    }

    .footer-container {
        padding: 10px;
    }

    /* .slide-up-overlay-slide-5.animated .chat-switch-icon {
        left: 48px;
    } */

    .chat-options-menu {
        left: 23px;
    }

    .slide-up-overlay-slide-5.animated .container,
    .slide-up-overlay-slide-5.animated .container.conversation {
        width: 100%;
    }

    .overlay-content-hero-section {
        width: 100%;
    }

    .card-container-mobile {
        display: block;
    }

    .center-circle,
    .circle-container {
        display: none !important;
    }

    .suggestions-header {
        width: 100%;
    }

    .slide-up-overlay-slide-5.animated .suggestions-section-top {
        width: 100%;
    }


}

/* Media query for mobile devices (max-width: 768px or any desired size) */
@media (max-width: 768px) {
    .hero-section-container {
        /* flex-direction: column-reverse; */
        /* Blurred images on top, overlay content on bottom */
        /* margin-top: 30%; */
        display: block;
        margin-bottom: 10%;
        padding-top: 100px;
        margin-top: 0px;
    }

    .overlay-content-hero-section p {
        margin-top: 15px;
        text-align: center;
        font-size: 15px;
        line-height: 21.5px;
    }

    .hero-title-1 {
        margin-right: 20%;
        position: relative;
    }

    .overlay-content-hero-section .text-slide {
        top: 2px;
        margin-left: 5px;
    }

    .overlay-content-hero-section {
        text-align: center;
        /* Center-align the text for smaller screens */
        padding: 10px;
        /* Adjust padding for smaller screens */
    }

    .blurred-images-container-hero-section {
        margin-top: 20%;
        /* Add spacing between blurred images and content */
    }

    .overlay-content-hero-section .hero-title {
        font-size: 32px;
        line-height: 37.4px;
        text-align: center;
        font-weight: 600;
    }

    .overlay-content-hero-section .cta-button {
        margin: auto;
    }

    .footer-heading {
        display: grid;
        /* text-align: center; */
        font-size: 28px;
        width: 100%;
        word-wrap: break-word;
        line-height: 34.5px;
        letter-spacing: -1.1395477056503296px;
    }

    .footer-social-icons {
        text-align: center;
        margin: auto;
        margin-top: 20px;
    }

    .animated-icon {
        width: 100%;
    }

    .footer-links {
        width: 100%;
        display: block;
        font-size: 12px;
        text-align: center;
    }

    .slide-up-overlay-slide-6 .footer-container {
        padding: 0px 20px 25px 20px;
    }

}


@media (max-width: 426px) and (min-width: 374px) {

    .suggestions-header img {
        width: 40px;
        height: 40px;
    }

    .suggestion-button {
        font-size: 14px;
    }

    .suggestions-section {
        padding: 0px;
    }

    .suggestions-container {
        width: 100%;
    }

    .chat-input {
        width: 70%;
    }

    .chat-bar {
        margin-top: 50px;
    }

    .app-buttons {
        /* display: grid; */
    }

    .bottom-section {
        padding-top: 0px;
        text-align: left;
    }

    .background-container {
        top: 95px;
        left: 0px;
        width: 100%;
        height: 100%;
    }

    .overlay-page.visible {
        display: grid;
        padding-top: 100px;
    }

    .content-wrapper {
        padding-bottom: 110px;
        margin-top: 40px;
    }

    .footer-links {
        width: 100%;
    }

    .footer-heading {
        display: grid;
        /* font-size: 35px; */
        width: 100%;
        word-wrap: break-word;
        font-weight: 600;
    }

    .footer-social-icons {
        text-align: center;
        margin: auto;
        margin-top: 20px;
    }

    .background-container {
        top: 95px;
        left: 0px;
        width: 100%;
        height: 100%;
    }

    .grid-item .caption {
        font-size: 11px;
    }

    .label-animation-page-3-top-1 {
        width: 185px;
    }

    .label-animation-page-3-top-2 {
        width: 180px;
        left: -13%;
    }

    .label-animation-page-3-top-3 {
        width: 175px;
        left: -10%;
    }

    .grid-container {
        overflow-y: scroll;
    }

    .askvasi-home .navbar {
        padding: 15px;
        z-index: 1001;
    }

    #centerText .topText {
        font-size: 32px;
        font-weight: 400;
    }

    #centerText .bottomText {
        display: block;
        text-align: left;
        font-size: 18px;
        font-weight: 400;
    }

    .grid-container #centerText {
        flex: auto;
        text-align: left;
        margin-left: 15px;
    }

    .label-animation-page-2-top-1 {
        width: 175px;
    }

    .label-animation-page-2-top-2 {
        width: 208px;
        left: -25%;
    }

    .label-animation-page-2-top-3 {
        width: 225px;
        left: -15%;
    }

    .label-animation-page-2-bottom-2 {
        width: 228px;
        left: -13%;
    }

    .label-animation-page-1-top-3 {
        width: 200px;
    }

    .label-animation-page-1-bottom-2 {
        width: 200px;
        left: -7%;
    }

    .label-animation-page-1-bottom-1 {
        width: 180px;
    }

    .page-1 .grid-item:nth-child(1) {
        top: 15%;
        left: 5%;
    }

    .page-1 .grid-item:nth-child(2) {
        top: 14%;
        right: 2%;
    }

    .page-1 .grid-item:nth-child(3) {
        top: 40%;
        right: 10%;
    }

    .page-1 .grid-item:nth-child(4) {
        left: 58%;
        bottom: 14%;
    }

    .page-1 .grid-item:nth-child(5) {
        left: 4%;
        bottom: 17%;
    }


    .page-1 .grid-item:nth-child(6) {
        display: none;
    }

    .grid-item {
        width: 150px;
        height: 120px;
    }

    .label-animation-bottom {
        left: 0%;
        width: 100%;
    }

    .label-animation-top {
        width: 100%;
        left: 0%;
    }


    .page-2 .grid-item:nth-child(1) {
        top: 25%;
        left: 2%;
    }

    .page-2 .grid-item:nth-child(2) {
        top: 13%;
        right: 3%;
    }

    .page-2 .grid-item:nth-child(3) {
        top: 38%;
        right: 3%;
    }

    .page-2 .grid-item:nth-child(4) {
        bottom: 18%;
        left: 57%;

    }

    .page-2 .grid-item:nth-child(5) {
        bottom: 20%;
        left: 7%;
    }

    .label-animation-page-2-top-1 {
        width: 180px;
    }

    .label-animation-page-2-top-2 {
        width: 208px;
        left: -31%;
    }

    .label-animation-page-2-top-3 {
        width: 210px;
        left: -33%;
    }

    .label-animation-page-2-bottom-2 {
        width: 228px;
        left: -13%;
        bottom: 112px;
    }

    .page-3 .grid-item:nth-child(1) {
        top: 25%;
        left: 5%;
    }

    .page-3 .grid-item:nth-child(2) {
        right: 3%;
        top: 14%;
    }

    .page-3 .grid-item:nth-child(3) {
        top: 40%;
        right: 3%;
    }

    .page-3 .grid-item:nth-child(4) {
        bottom: 20%;
        left: 57%;
    }

    .page-3 .grid-item:nth-child(5) {
        bottom: 25%;
        left: 6%;
    }

    .card-1,
    .card-2,
    .card-3 {
        width: 100%;
    }

    .card1,
    .card2,
    .card3 {
        top: 0%;
        right: 0%;
    }

    .card-container {
        display: grid;
        justify-content: center;
        gap: 30px;
    }

    .card {
        margin: 0px;
    }

    .circle-container:before,
    .center-circle {
        display: none;
    }

    .circle-container {
        bottom: -160px;
    }

    .circle-container,
    .grid {
        /*position: relative;*/
    }

    .slide-up-overlay-slide-4.active {
        position: relative;
        /* overflow: scroll;
        top: 93%; */
    }

    .suggestions-section-top {
        width: 100%;
    }

    .footer-container {
        padding: 10px;
    }

    .suggestions-header {
        width: 100%;
        text-align: center;
    }

    .suggestion-heading {
        font-size: 36px;
        line-height: 45.4px;
    }

    .blurred-image.image-1 {
        top: 15%;
        left: 13%;
    }

    .blurred-image.image-2 {
        display: none;
    }

    .blurred-image.image-3 {
        bottom: 7%;
        left: 9%;
    }

    .blurred-image.image-4 {
        right: 6%;
        bottom: 5%;
    }

    .blurred-image.image-5 {
        top: 25%;
        left: 55%;
    }

    .blurred-image.image-6 {
        display: none;
    }

    .overlay-content-hero-section h1 {
        font-size: 35px;
    }

    .left-section {
        /* align-items: center;
        text-align: center; */
    }

    /* .slide-up-overlay-slide-5.animated .chat-switch-icon {
        left: 12px;
    } */

    .chat-options-menu {
        left: 23px;
    }

    .slide-up-overlay-slide-5.animated .container,
    .slide-up-overlay-slide-5.animated .container.conversation {
        width: 100%;
    }


    .slide-up-overlay-slide-5.animated .chat-bar {
        padding: 10px 10px 15px 10px;
    }

    /* .chat-send-btn{
        padding: 5px 9px;
        margin-left: 5px;
    } */

    .similar-dot-image {
        width: 90%;
        height: 170px;
    }

    .similar-dots-overlay {
        width: 90%;
    }

    .overlay-content-hero-section {
        width: 100%;
    }

    .card-container-mobile {
        display: block;
    }

    .center-circle,
    .circle-container {
        display: none !important;
    }

    .suggestions-header {
        width: 100%;
    }

    .slide-up-overlay-slide-5.animated .suggestions-section-top {
        width: 100%;
    }

    .blurred-images-container-hero-section {
        margin-bottom: 50%;
        /* Add spacing between blurred images and content */
    }


}


@media (max-width: 376px) and (min-width: 320px) {

    .suggestions-header img {
        width: 40px;
        height: 40px;
    }

    .suggestion-button {
        font-size: 14px;
    }

    .suggestions-section {
        padding: 0px;
    }

    .suggestions-container {
        width: 100%;
    }

    .chat-bar {
        margin-top: 50px;
    }

    .chat-input {
        width: 70%;
    }

    .app-buttons {
        /* display: grid; */
    }

    .bottom-section {
        padding-top: 0px;
        text-align: left;
    }

    .background-container {
        top: 95px;
        left: 0px;
        width: 100%;
        height: 100%;
    }

    .overlay-page.visible {
        display: grid;
        padding-top: 100px;
    }

    .content-wrapper {
        padding-bottom: 110px;
        margin-top: 40px;
    }

    .footer-links {
        width: 100%;
        margin-bottom: 20px;
        text-align: center;
    }

    .footer-heading {
        width: 100%;
        word-wrap: break-word;
        font-weight: 600;
    }

    .footer-social-icons {
        text-align: center;
        margin: auto;
        margin-top: 20px;
    }

    .background-container {
        top: 95px;
        left: 0px;
        width: 100%;
        height: 100%;
    }

    .grid-item .caption {
        font-size: 12px;
    }

    .label-animation-page-1-top-3 {
        width: 200px;
    }

    .label-animation-page-1-bottom-2 {
        width: 205px;
        left: -8%;
    }

    .label-animation-page-1-bottom-1 {
        width: 172px;
    }


    /* .slide-up-overlay-slide-4.active */
    .grid-container {
        overflow-y: scroll;
    }


    .slide-up-overlay-slide-4.active {
        position: relative;
        top: 100%
            /* overflow: scroll;
        top: 93%; */
    }

    .askvasi-home .navbar {
        padding: 15px;
        z-index: 1001;
    }

    #centerText .topText {
        font-size: 32px;
        font-weight: 400;
    }

    #centerText .bottomText {
        display: block;
        text-align: left;
        font-size: 18px;
        font-weight: 400;
    }

    .grid-container #centerText {
        flex: auto;
        text-align: left;
        margin-left: 10px;
    }

    .page-1 .grid-item:nth-child(1) {
        top: 10%;
        left: 5%;
    }

    .page-1 .grid-item:nth-child(2) {
        top: 20%;
        right: 2%;
    }

    .page-1 .grid-item:nth-child(3) {
        top: 45%;
        right: 4%;
    }

    .page-1 .grid-item:nth-child(4) {
        left: 57%;
        bottom: 17%;
    }

    .page-1 .grid-item:nth-child(5) {
        left: 4%;
        bottom: 23%;
    }

    .label-animation-page-3-top-1 {
        width: 200px;
    }

    .label-animation-page-3-top-2 {
        width: 180px;
    }

    .label-animation-page-2-top-1 {
        width: 180px;
    }

    .label-animation-page-2-top-2 {
        width: 207px;
        left: -25%;
    }

    .label-animation-page-2-top-3 {
        width: 226px;
        left: -42%;
    }


    .label-animation-page-3-top-3 {
        width: 175px;
        left: -4%;
    }

    .label-animation-page-3-bottom-2 {
        left: -11%;
    }

    /* .label-animation-page-2-top-3 {
        left: 0%;
    } */

    .label-animation-page-2-bottom-2 {
        width: 228px;
        bottom: 90%;
        left: -3%;
    }


    .page-1 .grid-item:nth-child(6) {
        display: none;
    }

    .grid-item {
        width: 160px;
        height: 115px;
    }

    .label-animation-bottom {
        left: 0%;
        width: 100%;
    }

    .label-animation-top {
        width: 100%;
        left: 0%;
    }


    .page-2 .grid-item:nth-child(1) {
        top: 19%;
        left: 3%;
    }

    .page-2 .grid-item:nth-child(2) {
        top: 12%;
        right: 2%;
    }

    .page-2 .grid-item:nth-child(3) {
        top: 40%;
        right: 0%;
    }

    .page-2 .grid-item:nth-child(4) {
        bottom: 15%;
        left: 56%;
    }

    .page-2 .grid-item:nth-child(5) {
        bottom: 24%;
        left: 5%;
    }

    .page-3 .grid-item:nth-child(1) {
        top: 10%;
        left: 3%;
    }

    .page-3 .grid-item:nth-child(2) {
        top: 17%;
        right: 5%;
    }

    .page-3 .grid-item:nth-child(3) {
        top: 40%;
        right: 7%;
    }

    .page-3 .grid-item:nth-child(4) {
        bottom: 23%;
        left: 55%;
    }

    .page-3 .grid-item:nth-child(5) {
        bottom: 15%;
        left: 5%;
    }

    .card-1,
    .card-2,
    .card-3 {
        width: 100%;
    }

    .card-container {
        display: grid;
        justify-content: center;
        gap: 30px;
    }

    .suggestion-heading {
        font-size: 24px;
        line-height: 34.4px;
    }

    .footer-links {
        width: 100%;
        margin-bottom: 20px;
        text-align: center;
    }

    .footer-container {
        padding: 10px;
    }

    .suggestions-header {
        width: 100%;
        text-align: center;
    }


    .left-section {
        margin-top: 20px;
        /* align-items: center; */
    }

    .footer-heading {
        font-size: 30px;
        /* text-align: center; */
    }

    .card1,
    .card2,
    .card3 {
        top: 0%;
        right: 0%;
    }

    .card1,
    .card3 {
        left: 0%;
    }

    .circle-container:before,
    .center-circle {
        display: none;
    }

    .blurred-image.image-2 {
        right: 17%;
    }

    .blurred-image.image-4 {
        right: 15%;
    }

    .blurred-image.image-6 {
        bottom: 6%;
    }


    .blurred-image.image-1 {
        top: 15%;
        left: 5%;
    }

    .blurred-image.image-2 {
        display: none;
    }

    .blurred-image.image-3 {
        bottom: 2%;
        left: 5%;
    }

    .blurred-image.image-4 {
        right: 6%;
        bottom: 5%;
    }

    .blurred-image.image-5 {
        top: 23%;
        left: 55%;
    }

    .blurred-image.image-6 {
        display: none;
    }

    .overlay-content-hero-section h1 {
        font-size: 30px;
    }

    .suggestions-section-top {
        width: 100%;
    }

    /* .slide-up-overlay-slide-5.animated .chat-switch-icon {
        left: 6px;
    } */

    .chat-options-menu {
        left: 23px;
    }

    .slide-up-overlay-slide-5.animated .container,
    .slide-up-overlay-slide-5.animated .container.conversation {
        width: 100%;
    }

    .similar-dot-image {
        width: 90%;
        height: 170px;
    }

    .similar-dots-overlay {
        width: 90%;
    }

    .overlay-content-hero-section {
        width: 100%;
    }

    .card-container-mobile {
        display: block;
    }

    .center-circle,
    .circle-container {
        display: none !important;
    }

    .suggestions-header {
        width: 100%;
    }

    .slide-up-overlay-slide-5.animated .suggestions-section-top {
        width: 100%;
    }


}


@media (max-width: 321px) {

    .suggestions-header img {
        width: 40px;
        height: 40px;
    }

    .suggestion-button {
        font-size: 14px;
    }

    .suggestions-section {
        padding: 0px;
    }

    .suggestions-container {
        width: 100%;
    }

    .chat-bar {
        margin-top: 50px;
    }

    .chat-input {
        width: 65%;
        padding: 8px;
        font-size: 12px;
    }

    .app-buttons {
        display: grid;
    }

    .bottom-section {
        padding-top: 0px;
        text-align: left;
    }

    .background-container {
        top: 95px;
        left: 0px;
        width: 100%;
        height: 100%;
    }

    .overlay-page.visible {
        display: grid;
        padding-top: 100px;
    }

    .content-wrapper {
        padding-bottom: 110px;
        margin-top: 40px;
    }

    .footer-links {
        width: 100%;
        text-align: center;
    }

    .grid-item .caption {
        font-size: 10px;
    }

    .grid-container {
        overflow-y: scroll;
    }

    .askvasi-home .navbar {
        padding: 15px;
        z-index: 1001;
    }

    #centerText .topText {
        font-size: 32px;
        font-weight: 400;
    }

    #centerText .bottomText {
        display: block;
        text-align: left;
        font-size: 18px;
        font-weight: 400;
    }

    .grid-container #centerText {
        flex: auto;
        text-align: left;
        margin-left: 10px;
    }

    .label-animation-page-1-top-3 {
        width: 170px;
    }

    .label-animation-page-3-top-1 {
        width: 200px;
    }

    .label-animation-page-1-top-3 {
        left: -35%;
    }

    .label-animation-page-1-bottom-1 {
        left: -12%;
        width: 150px;
    }

    .label-animation-page-1-bottom-2 {
        width: 172px;
    }

    .page-1 .grid-item:nth-child(1) {
        top: 10%;
        left: 5%;
    }

    .page-1 .grid-item:nth-child(2) {
        top: 17%;
        right: 5%;
    }

    .page-1 .grid-item:nth-child(3) {
        top: 40%;
        right: 1%;
    }

    .page-1 .grid-item:nth-child(4) {
        left: 57%;
        bottom: 26%;
    }

    .page-1 .grid-item:nth-child(5) {
        left: 5%;
        bottom: 20%;
    }


    .page-1 .grid-item:nth-child(6) {
        display: none;
    }

    .grid-item {
        width: 130px;
        height: 100px;
    }

    .label-animation-bottom {
        left: 0%;
        width: 100%;
    }

    .label-animation-top {
        width: 100%;
        left: 0%;
    }

    .label-animation-page-3-top-3 {
        left: -20%;
        width: 175px;
    }


    .page-2 .grid-item:nth-child(1) {
        top: 9%;
        left: 6%;
    }

    .page-2 .grid-item:nth-child(2) {
        top: 27%;
        right: 3%;
    }

    .page-2 .grid-item:nth-child(3) {
        top: 53%;
        right: 0%;
    }

    .page-2 .grid-item:nth-child(4) {
        bottom: 14%;
        left: 55%;
    }

    .page-2 .grid-item:nth-child(5) {
        bottom: 16%;
        left: 4%;
    }

    .page-3 .grid-item:nth-child(1) {
        top: 12%;
        left: 3%;
    }

    .page-3 .grid-item:nth-child(2) {
        right: 4%;
    }

    .page-3 .grid-item:nth-child(3) {
        top: 38%;
        right: 11%;
    }

    .page-3 .grid-item:nth-child(4) {
        bottom: 29%;
        left: 55%;
    }

    .page-3 .grid-item:nth-child(5) {
        bottom: 22%;
        left: 5%;
    }

    .card-1,
    .card-2,
    .card-3 {
        width: 100%;
    }

    .card-container {
        display: grid;
        justify-content: center;
        gap: 30px;
    }

    .card {
        margin: 0px;
    }



    .blurred-image.image-2 {
        right: 17%;
    }

    .blurred-image.image-4 {
        right: 15%;
    }

    .blurred-image.image-6 {
        bottom: 6%;
    }


    .blurred-image.image-1 {
        display: none;
    }

    .blurred-image.image-2 {
        display: none;
    }

    .blurred-image.image-3 {
        display: none;
    }

    .blurred-image.image-4 {
        display: none;
    }

    .blurred-image.image-5 {
        display: none;
    }

    .blurred-image.image-6 {
        display: none;
    }

    .overlay-content-hero-section h1 {
        font-size: 30px;
    }

    .suggestions-section-top {
        width: 100%;
    }

    .suggestion-heading {
        font-size: 30px;
        line-height: 38.4px;
    }

    .footer-container {
        padding: 10px;
    }

    .suggestions-header {
        width: 100%;
        text-align: center;
    }

    .slide-up-overlay-slide-5.animated .container,
    .slide-up-overlay-slide-5.animated .container.conversation {
        width: 100%;
    }

    .slide-up-overlay-slide-5.animated .chat-switch-icon {
        /* left: 0%; */
        margin-top: 5px;
        padding: 7px 7px;
    }

    .chat-send-btn {
        padding: 5px 9px;
        margin-left: 5px;
    }

    .similar-dot-image {
        width: 90%;
        height: 170px;
    }

    .similar-dots-overlay {
        width: 90%;
    }

    .overlay-content-hero-section {
        width: 100%;
    }

    .card-container-mobile {
        display: block;
    }

    .center-circle,
    .circle-container {
        display: none !important;
    }

    .suggestions-header {
        width: 100%;
    }

    body.no-scroll {
        overflow: scroll;
    }

    .slide-up-overlay-slide-4.active {
        position: relative;
        /* overflow: scroll;
        top: 93%; */
    }

    .slide-up-overlay-slide-5.animated .suggestions-section-top {
        width: 100%;
    }

    .try-btn-mobile {
        margin-left: 0px;
    }

}

.page-1 .grid-item:nth-child(4) {
    display: block;
}

#centerText {
    margin-top: 0px;
}

.suggestion-heading-title {
    display: inline-block;
    background-color: #32323233;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    vertical-align: middle;
}

@media only screen and (max-width: 1024px) and (orientation: landscape) {

    #centerText .topText {
        font-size: 30px;
    }

    #centerText .bottomText {
        font-size: 24px;
    }

    .page-1 .grid-item:nth-child(1) {
        top: 10%;
        left: 7%;
    }

    .page-1 .grid-item:nth-child(2) {
        top: 1%;
        right: 40%;
    }

    .page-1 .grid-item:nth-child(3) {
        top: 10%;
        right: 10%;
    }

    .page-1 .grid-item:nth-child(4) {
        left: 73%;
        bottom: 10%;
    }

    .page-1 .grid-item:nth-child(5) {
        left: 10%;
        bottom: 8%;
    }

    .page-1 .grid-item:nth-child(6) {
        bottom: 8%;
        right: 14%;
    }

    .page-2 .grid-item:nth-child(1) {
        top: 10%;
        left: 9%;
    }

    .page-2 .grid-item:nth-child(2) {
        top: 1%;
        right: 40%;
    }

    .page-2 .grid-item:nth-child(3) {
        top: 3%;
        right: 10%;
    }

    .page-2 .grid-item:nth-child(4) {
        bottom: 9%;
        left: 63%;
    }

    .page-2 .grid-item:nth-child(5) {
        bottom: 7%;
        left: 6%;
    }

    .page-3 .grid-item:nth-child(1) {
        top: 10%;
        left: 10%;
    }

    .page-3 .grid-item:nth-child(2) {
        right: 35%;
        top: 1%;
    }

    .page-3 .grid-item:nth-child(3) {
        top: 8%;
        right: 10%;
    }

    .page-3 .grid-item:nth-child(4) {
        bottom: 7%;
        left: 65%;
    }

    .page-3 .grid-item:nth-child(5) {
        bottom: 7%;
        left: 5%;
    }

    .label-animation-page-3-top-1 {
        width: 182px;
    }

    .center-circle,
    .circle-container {
        display: none !important;
    }

    .card-container-mobile {
        display: block;
    }


    .slide-up-overlay-slide-4.active {
        overflow: scroll;
        padding-top: 15px;
    }

    .suggestions-section-top {
        margin-top: 0px;
        width: 100%;
        padding-top: 10px;
    }

    .suggestion-heading {
        font-size: 24px;
    }

    /* .collapse:not(.show){
        display: none;
    } */

    .askvasi-home .navbar-nav .nav-item {
        margin-left: 2px;
    }

    .label-animation-page-1-top-2,
    .label-animation-page-2-top-2,
    .label-animation-page-2-top-3 {
        top: 85px
    }

    .label-animation-page-3-top-2 {
        top: 85px;
    }

    .next-arrow-global::before {
        background-color: transparent;
        color: #323232;
    }

    .slide-up-overlay-slide-5.active {
        padding-top: 110px;
    }

    .chat-bar {
        margin-top: 50px;
    }

    .slide-up-overlay-slide-6 .footer-container {
        padding: 70px 20px 25px 20px;
    }

    .hero-section-container {
        margin-top: 12%;
    }

    .animated-icon {
        width: 50%;
    }

    .text-sequence {
        font-size: 32px;
    }

    #centerText {
        margin-top: 40px;
    }

    .card-container-mobile .card-1,
    .card-container-mobile .card-2,
    .card-container-mobile .card-3 {
        width: 33% !important;
    }

    .slide-up-overlay-slide-5.active .suggestions-container .suggestion-button.publish {
        font-size: 12px;
        min-height: 40px;
    }

    .slide-up-overlay-slide-5.active .suggestions-container .suggestion-button.personal,
    .slide-up-overlay-slide-5.active .suggestions-container .suggestion-button.explore {
        font-size: 12px;
        min-height: 40px;
    }

    .grid-item {
        width: 170px;
        height: 100px;
    }

    .suggestion-heading-title {
        width: 24px;
        height: 24px;
    }

    .askvasi-home .navbar {
        padding: 25px 30px 25px 30px;
    }

}

@media only screen and (max-width: 786px) and (orientation: landscape) {
    .grid-container #centerText {
        flex: none
    }

    #centerText .topText {
        font-size: 30px;
    }

    #centerText .bottomText {
        font-size: 24px;
    }

    .next-arrow-global {
        width: 13%;
    }

    .page-1 .grid-item:nth-child(1) {
        top: 8%;
        left: 7%;
    }

    .page-1 .grid-item:nth-child(2) {
        top: 1%;
        right: 33%;
    }

    .page-1 .grid-item:nth-child(3) {
        top: 10%;
        right: 2%;
    }

    .page-1 .grid-item:nth-child(5) {
        left: 10%;
        bottom: 8%;
    }

    .page-1 .grid-item:nth-child(6) {
        bottom: 6%;
        right: 9%;
    }


    .page-2 .grid-item:nth-child(1) {
        top: 10%;
        left: 9%;
    }

    .page-2 .grid-item:nth-child(2) {
        top: 1%;
        right: 35%;
    }

    .page-2 .grid-item:nth-child(3) {
        top: 5%;
        right: 3%;
    }

    .page-2 .grid-item:nth-child(4) {
        bottom: 9%;
        left: 73%;
    }

    .page-2 .grid-item:nth-child(5) {
        bottom: 11%;
        left: 6%;
    }

    .page-3 .grid-item:nth-child(1) {
        top: 10%;
        left: 5%;
    }

    .page-3 .grid-item:nth-child(2) {
        right: 35%;
        top: 1%;
    }

    .page-3 .grid-item:nth-child(3) {
        top: 15%;
        right: 2%;
    }

    .page-3 .grid-item:nth-child(4) {
        bottom: 5%;
        left: 65%;
    }

    .label-animation-page-3-top-3 {
        left: -29%;
    }

    .label-animation-page-3-top-1 {
        width: 182px;
    }

    .label-animation-page-1-top-3 {
        left: -10%;
    }

    .new-section {
        padding: 40px;
    }

    .suggestions-section-top {
        padding-top: 0px;
    }

    .slide-up-overlay-slide-5.active .suggestions-container-mobile .suggestion-button.publish {
        font-size: 12px;
        min-height: 40px;
    }

    .slide-up-overlay-slide-5.active .suggestions-container-mobile .suggestion-button.personal,
    .slide-up-overlay-slide-5.active .suggestions-container-mobile .suggestion-button.explore {
        font-size: 12px;
        min-height: 40px;
    }

    .askvasi-home .navbar {
        padding: 25px 30px 25px 30px;
    }

    .grid-item {
        width: 170px;
        height: 100px;
    }

    .suggestion-heading-title {
        width: 24px;
        height: 24px;
    }

    .slide-up-overlay-slide-5.active .suggestions-section-top {
        padding-top: 5px;
    }

}

