  @font-face {
      font-family: "Poppins";
      src: url("../fonts/poppins/Poppins-Regular.ttf") format("truetype"),
          url("../fonts/poppins/Poppins-Regular.ttf") format("truetype");
      font-weight: normal;
      font-style: normal;
  }

  @font-face {
      font-family: "Poppins";
      src: url("../fonts/poppins/Poppins-Bold.ttf") format("truetype"),
          url("../fonts/poppins/Poppins-Bold.ttf") format("truetype");
      font-weight: bold;
      font-style: normal;
  }

  li.nav-item a.nav-link.active {
      color: #000 !important;
  }

  .navbar {
      border-bottom: 1px solid #E6E6E6;
  }

  .askvasi-home .navbar {
      background: #fff !important;
  }

  .consumer-page-landing .hero-title-1 {
      font-weight: 600;
      font-size: 64px;
      line-height: 89.6px;
  }

  .consumer-page-landing .hero-title-1 i {
      font-weight: 300;
      font-size: 64px;
      line-height: 89.6px;
      text-align: center;
  }

  .consumer-page-landing .hero-section-container {
      background: #fff;
      display: block;
      text-align: center;
      margin-top: 10%;
      border: 0;
      padding: 0;
  }

  .consumer-page-landing .overlay-content-hero-section .hero-title {
      text-align: center;
  }


  .consumer-page-landing .overlay-content-hero-section .cta-button {
      width: 327px;
      height: 40px;
      border-radius: 9px;
      border: 0;
      font-weight: 600;
      font-size: 15px;
      line-height: 21.5px;
  }

  .consumer-page-landing .overlay-content-hero-section p {
      text-align: center;
      font-family: Poppins;
      font-weight: 400;
      font-size: 20px;
      line-height: 34px;
  }

  .consumer-page-landing .phone-container {
      position: relative;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      margin-top: 0px;
      width: 70%;
  }


  .consumer-page-landing .blurred-images-container-hero-section {
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 15%, rgba(254, 216, 0, 0.32) 100%);
      margin-top: 50px;
  }

  .consumer-page-landing .beauty,
  .strength,
  .travel,
  .theatremask,
  .productivity,
  .mentalhealth,
  .mentalhealth-right {
      position: absolute;
  }

  .consumer-page-landing .travel {
      left: -50%;
      opacity: 0.45;
      top: 4%;
  }

  .consumer-page-landing .mentalhealth {
      left: -30%;
      top: 19%;
      /* opacity: 0.20; */
  }

  .consumer-page-landing .theatremask {
      left: -50%;
      top: 35%;
      opacity: 0.45;
  }

  .consumer-page-landing .strength {
      left: -40%;
      bottom: 15%;
      opacity: 0.30;
  }

  .consumer-page-landing .mentalhealth-right {
      right: -30%;
      top: 19%;
      /* opacity: 0.20; */
  }

  .consumer-page-landing .beauty {
      right: -55%;
      top: 50%;
      opacity: 0.60;
  }

  .consumer-page-landing .productivity {
      right: -50%;
      bottom: 10%;
      opacity: 0.60;
  }

  .consumer-page-landing .advice-heading {
      font-weight: 700;
      font-size: 48px;
      line-height: 57.6px;
      letter-spacing: 0px;
      text-align: center;
      width: 33%;
      margin: auto;
  }


  .best-lessons {
      padding: 50px 0;
      background-color: #fff;
  }

  .advice-heading {
      font-size: 32px;
      font-weight: 700;
      color: #000;
      text-align: center;
  }

  .best-lessons .d-flex {
      flex-wrap: wrap;
      justify-content: center;
      gap: 40px;
  }

  .best-lessons .lesson-card {
      display: flex;
      align-items: start;
      /* max-width: 350px; */
      gap: 15px;
      text-align: left;
  }



  .best-lessons .testimonials-title {
      font-weight: 700;
      font-size: 22px;
      line-height: 29.96px;
      margin-bottom: 8px;
      display: block;
  }

  .best-lessons .testimonials-text {
      font-size: 16px;
      color: #666;
      margin: 0;
  }

  .lesson-card-1,
  .lesson-card-2,
  .lesson-card-3 {
      max-width: 300px;
  }

  .lesson-icon {
      width: 110px;
      height: auto;
  }

  .consumer-page-landing .best-lessons-btn .cta-button {
      width: 327px;
      height: 40px;
      border-radius: 9px;
      border: 0;
      font-weight: 600;
      font-size: 15px;
      line-height: 21.5px;
  }

  .consumer-page-landing .advice-paragraph {
      font-family: Poppins;
      font-weight: 400;
      font-style: italic;
      font-size: 20px;
      line-height: 30px;
      text-align: center;
      width: 60%;
      margin: auto;
  }

  .consumer-page-landing .advice-paragraph span {
      font-family: Poppins;
      font-weight: 600;
      font-style: italic;
      font-size: 20px;
      line-height: 30px;
      text-align: center;
  }

  .consumer-page-landing .advice-paragraph-name {
      font-family: Poppins;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;

  }

  .consumer-page-landing .testimonials-number {
      font-weight: 700;
  }

  .consumer-page-landing .testimonials-section .fire-icon,
  .consumer-page-landing .testimonials-section .list-icon {
      width: 32px;
  }

  .consumer-page-landing .testimonials-text {
      font-family: Poppins;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
  }

  .consumer-page-landing .explore-dots-section .section-title {
      font-weight: 700;
      font-size: 48px;
      line-height: 120%;
      letter-spacing: 0px;
      text-align: center;
      vertical-align: bottom;
      text-transform: capitalize;
  }

  .consumer-page-landing .explore-dots-section .section-subtitle {
      font-family: Poppins;
      font-weight: 300;
      font-size: 20px;
      line-height: 130%;
      letter-spacing: 0px;
      text-align: center;
      vertical-align: bottom;

  }

  .consumer-page-landing .explore-dots-section .dot-item .dot-image {
      height: 160px;
      border-radius: 10px;
  }

  .consumer-page-landing .explore-dots-section .category-buttons button {
      border: 1px solid #ddd;
      padding: 8px 12px;
      border-radius: 21px;
      cursor: pointer;
      transition: all 0.3sease;
      font-family: Poppins;
      font-weight: 400;
      font-size: 16px;
      line-height: 109%;
      letter-spacing: 0px;
      text-align: center;
      vertical-align: middle;
  }

  .consumer-page-landing .explore-dots-section .cta-button {
      width: 327px;
      height: 40px;
      border-radius: 9px;
      border: 0;
      font-weight: 600;
      font-size: 15px;
      line-height: 21.5px;
      margin-top: 20px;
  }



  .consumer-page-landing .how-to-create .section-title {
      width: 50%;
  }


  .how-to-create {
      margin-top: 3rem;
  }

  /* Testimonial Section */
  .testimonials-section {
      padding: 60px 0;
      text-align: center;
      background-color: #fff;
  }

  .testimonials-section .testimonials-title-heading {
      font-size: 48px !important;
  }

  .testimonials-title {
      font-weight: 700;
      font-size: 48px;
      line-height: 120%;
      letter-spacing: 0px;
      text-align: center;
      vertical-align: bottom;
      text-transform: capitalize;
      width: 61%;
      margin: auto;
      margin-bottom: 75px;
  }

  /* Testimonial Container */
  .testimonials-container {
      display: flex;
      justify-content: center;
      gap: 40px;
  }

  /* Individual Testimonial Card */
  .testimonial-card {
      max-width: 300px;
      text-align: center;
  }

  /* Profile Image */
  .testimonial-image {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      object-fit: cover;
      margin-bottom: 10px;
  }

  /* Star Ratings */
  .testimonial-stars {
      color: #ffcc00;
      /* Yellow stars */
      font-size: 18px;
  }

  /* Name */
  .testimonial-name {
      font-family: Poppins;
      font-weight: 600;
      font-size: 16px;
      line-height: 130%;
      text-align: center;
      margin: 8px 0;
  }

  /* Role */
  .testimonial-role {
      color: #A6A6A6;
      margin-bottom: 10px;
      font-family: Poppins;
      font-weight: 400;
      font-size: 14px;
      line-height: 130%;
      text-align: center;
  }

  /* Testimonial Text */
  .testimonial-text {
      font-family: Poppins;
      font-weight: 400;
      font-size: 14px;
      line-height: 130%;
      text-align: center;
      /* width: 50%; */
  }

  .consumer-page-landing .ready-to-unlock .section-sub-title {
      font-family: Poppins;
      font-weight: 400;
      font-size: 20px;
      line-height: 130%;
      text-align: center;
      width: 50%;
      margin-bottom: 10px;
  }

  .consumer-page-landing .explore-dots-section .view-all button {
      padding: 0;
  }

  .consumer-hero-section-img {
      display: block;
  }

  .consumer-hero-section-mob-img {
      display: none;
  }

  .consumer-page-landing .explore-dots-section .similar-dot-title {
      font-weight: 500;
      font-size: 16px;
      line-height: 120%;
      letter-spacing: 0px;
      vertical-align: bottom;
  }

  .consumer-page-landing .similar-dot-creator {
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      vertical-align: bottom;
      color: #515151;
  }

  .lesson-card .testimonials-title {
      text-align: left;
      width: 80%;
      margin: 0;
  }

  .lesson-card .testimonials-text {
      text-align: left;
  }

  .best-lessons .my-4 {
      margin-top: 3rem !important;
  }

  .ratings-icon {
      width: 100px;
  }


  .testimonials-grid .testimonials-text {
      color: #5d5d5d;
  }

  .testimonials-grid.my-4 {
      margin-top: 3rem !important;
  }

  .consumer-page-landing .explore-dots-section {
      padding-top: 0rem;
      display: block;
  }

  /* Responsive Design */
  @media (max-width: 768px) {
      .testimonials-container {
          flex-direction: column;
          align-items: center;
      }

      .testimonial-card {
          max-width: 100%;
      }

      .consumer-page-landing .similar-dot-creator {
          font-weight: 500;
      }

  }

  /* Default: Keep it as a Flexbox */
  .testimonials-grid {
      display: flex;
      justify-content: center;
      gap: 40px;
      margin-top: 20px;
  }

  /* 📌 Mobile View: Content on Top, Image Below */
  @media (max-width: 768px) {

      .consumer-hero-section-img {
          display: none;
      }

      .consumer-hero-section-mob-img {
          display: block;
      }

      .consumer-page-landing .hero-title-1 {
          font-weight: 600;
          font-size: 40px;
          line-height: 140%;
          text-align: center;
          margin: auto;
      }

      .consumer-page-landing .blurred-images-container-hero-section {
          margin-top: 50px;
          margin-bottom: 24px;
      }

      .consumer-page-landing .hero-section-container {
          margin-bottom: 24px;
      }

      .consumer-page-landing .hero-title-1 i {
          font-weight: 300;
          font-style: italic;
          font-size: 40px;
          line-height: 140%;
          text-align: center;
      }

      .consumer-page-landing .overlay-content-hero-section p {
          font-weight: 300;
          font-size: 15px;
          line-height: 170%;
          text-align: center;
          width: 90%;
      }


      .consumer-page-landing .travel {
          left: -15%;
          opacity: 0.45;
          display: none;
      }

      .consumer-page-landing .mentalhealth {
          left: -15%;
          top: 10%;
          /* opacity: 0.20; */
      }

      .consumer-page-landing .theatremask {
          left: -35%;
          top: 40%;
          opacity: 0.45;
      }

      .consumer-page-landing .strength {
          left: -15%;
          bottom: 15%;
          opacity: 0.30;
      }

      .consumer-page-landing .mentalhealth-right {
          right: -14%;
          top: 19%;
          /* opacity: 0.20; */
      }

      .consumer-page-landing .beauty {
          right: -14%;
          top: 50%;
          opacity: 0.60;
      }

      .consumer-page-landing .productivity {
          right: -14%;
          bottom: 10%;
          opacity: 0.60;
      }



      .best-lessons .d-flex {
          flex-wrap: nowrap;
      }

      .lesson-icon {
          width: 125px;
      }

      .best-lessons .testimonials-title {
          text-align: left;
          font-weight: 600;
          font-size: 20px;
          line-height: 130%;
      }


      .consumer-page-landing .testimonials-text {
          text-align: left;
          font-weight: 400;
          font-size: 14px;
          line-height: 130%;
          margin-top: 0.4rem;
          padding: 0px 2px 0px 2px;
      }

      .consumer-page-landing .testimonials-section {
          padding: 0px 0;
      }

      .consumer-page-landing .advice-paragraph {
          font-size: 14px;
          line-height: 130%;
          width: 90%;
      }

      .consumer-page-landing .advice-paragraph span {
          font-size: 14px;
          line-height: 140%;
      }

      .ratings-icon {
          width: 33%;
      }

      .consumer-page-landing .advice-paragraph-name {
          font-size: 14px;
          line-height: 130%;
      }

      .testimonials-grid {
          display: grid;
          gap: 20px;
          justify-content: center;
          text-align: center;
      }

      .testimonials-grid div {
          display: flex;
          flex-direction: column;
          align-items: center;
      }

      .consumer-page-landing .advice-heading {
          font-weight: 600;
          font-size: 28px;
          line-height: 120%;
          letter-spacing: 0px;
          width: 80%;
      }

      .consumer-page-landing .testimonials-section .fire-icon,
      .consumer-page-landing .testimonials-section .list-icon {
          width: 48px;
      }

      .consumer-page-landing .testimonials-number {
          font-size: 32px;
          font-weight: 600;
      }

      .consumer-page-landing .explore-dots-section .section-title {
          font-weight: 600;
          font-size: 28px;
      }

      .consumer-page-landing .explore-dots-section .section-subtitle {
          font-weight: 300;
          font-size: 15px;
          line-height: 130%;
      }


      .consumer-page-landing .explore-dots-section .category-buttons button {
          font-weight: 400;
          font-size: 14px;
          line-height: 109.00000000000001%;
          letter-spacing: 0px;
          text-align: center;
          vertical-align: middle;
      }


      .consumer-page-landing .testimonials-title {
          font-size: 20px !important;
          width: 100%;
          font-weight: 600;
          margin-bottom: 10px;
      }

      .consumer-page-landing .testimonials-title-heading {
          font-size: 28px;
          width: 90%;
          margin-bottom: 40px;
      }

      .consumer-page-landing .how-to-create .section-title {
          width: 100%;
      }

      .consumer-page-landing .ready-to-unlock .section-sub-title {
          width: 90%;
          font-size: 14px;
      }

      .consumer-page-landing .explore-dots-section .view-all {
          margin-top: 0px;
      }
  }


  .consumer-page-landing .testimonials-title-heading {
      font-size: 28px;
  }


  /* FAQ Section */
  .consumer-page-landing .faq-section {
      padding: 60px 0;
      text-align: center;
      background-color: #fff;
  }

  .consumer-page-landing .faq-title {
      font-weight: 700;
      font-size: 48px;
      line-height: 120%;
      text-align: center;
      margin-bottom: 20px;
  }

  /* FAQ Container */
  .consumer-page-landing .faq-container {
      max-width: 700px;
      margin: 0 auto;
  }

  /* Individual FAQ Item */
  .consumer-page-landing .faq-item {
      cursor: pointer;
      text-align: left;
  }

  /* FAQ Question Row */
  .consumer-page-landing .faq-question {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 18px;
      font-family: Poppins;
      font-weight: 500;
      line-height: 100%;
      vertical-align: middle;
      color: #323232;
      padding-bottom: 20px;
  }

  /* Expand/Collapse Icon */
  .consumer-page-landing .faq-icon {
      font-size: 20px;
      font-weight: 600;
      color: #A6A6A6;
  }

  /* FAQ Answer (Initially Hidden) */
  .consumer-page-landing .faq-answer {
      font-family: 'Poppins';
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
      color: #949391;
      padding: 10px 0 5px;
      transition: all 0.3s ease-in-out;
  }

  .footer-social-icons {
      width: 20%;
  }

  .consumer-page-landing .similar-dot-user-avatar {
      width: 16px;
      height: 16px;
  }

  /* .consumer-page-landing .explore-dots-section  .category-slider {
    margin-top: 20px!important;
    display: flex!important;
    justify-content: center!important;
}

.consumer-page-landing .explore-dots-section  .category-slide {
    padding: 8px!important;
    display: flex!important;
    justify-content: center!important;
    align-items: center!important;
} */


  .consumer-page-landing .explore-dots-section .category-buttons button:hover {
      color: #202020;
  }


  /* Responsive Design */
  @media (max-width: 768px) {


      .consumer-page-landing .faq-section {
          padding: 0px 0;
      }

      .consumer-page-landing .faq-question {
          font-size: 16px;
      }

      .consumer-page-landing .faq-answer {
          font-size: 14px;
      }

      .consumer-page-landing .faq-title {
          font-size: 28px;
          font-weight: 600;
      }

      .left-section {
          width: 100%;
      }

      .footer-row {
          display: flex;
          flex-direction: column-reverse;
          align-items: center;
          padding: 20px;
          gap: 15px;
      }

      .footer-social-icons {
          order: 1;
          /* Social icons move to the top */
          justify-content: flex-start;
          width: 100%;
      }

      .footer-links {
          order: 1;
          /* Links move to the middle */
          flex-direction: column;
          align-items: flex-start;
      }

      .footer-bottom-text {
          order: 3;
          /* Copyright moves to the bottom */
      }

      .footer-social-icons img {
          width: 80%;
      }

      .footer-container {
          padding-top: 20px;
      }

      .consumer-page-landing .explore-dots-section .category-buttons button {
          margin: 5px !important;
          border: 1px solid #ccc !important;
          min-width: 140px !important;
          /* Ensure all buttons are wide enough */
          justify-content: center !important;
          align-items: center !important;
          text-align: center !important;
          padding: 8px 12px !important;
      }

      .category-buttons button:hover {
          color: #202020;
          border: 1px solid #ccc !important;
      }

      .consumer-page-landing .explore-dots-section.category-slider .slick-slide {
          display: flex !important;
          justify-content: center;
          align-items: center;
          padding: 5px;
      }

  }

  @media (max-width: 370px) {
      .consumer-page-landing .explore-dots-section .category-buttons button {
          min-width: 115px !important;
          padding: 8px 8px !important;
      }
  }