.how-can-learn-section .section-title {
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    letter-spacing: 0px;
    text-align: center;
    vertical-align: bottom;
    text-transform: capitalize;
    margin-bottom: 16px;
}

.how-can-learn-section .section-subtitle {
    font-family: Poppins;
    font-weight: 300;
    font-size: 20px;
    line-height: 130%;
    letter-spacing: 0px;
    text-align: center;
    vertical-align: bottom;
}





.how-can-learn-section {
    padding: 60px 0;
    background-color: #fff;
}

.how-can-learn-section .section-title {
    font-size: 32px;
    font-weight: 700;
    color: #000;
}

.how-can-learn-section .section-subtitle {
    font-size: 18px;
    color: #666;
    margin-bottom: 40px;
}

/* Custom carousel with smooth infinite sliding */
.how-can-learn-section .custom-carousel {
    overflow: hidden;
    position: relative;
    width: 100%;
}

/* Wrapper that slides smoothly */
.how-can-learn-section .carousel-wrapper {
    display: flex;
    transition: transform 0.8s ease-in-out;
}

/* Each slide */
.how-can-learn-section .carousel-card {
    flex: 0 0 70%;
    padding-right: 2%;
}

 .how-can-learn-section .info-card {
    background: #fff9d6;
    /* Light yellow background */
    padding: 30px;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    /* Ensure content starts from the top */
    width: 100%;
    min-height: 450px;
}

/* Left Section (Text) */
.how-can-learn-section .info-card .text-left {
    flex: 1 1 40%;
    /* Occupies 20% of the container */
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    /* Ensures text starts from the top */
}

/* Title Styling */
.how-can-learn-section .info-title {
    font-weight: 600;
    font-size: 40px;
    line-height: 130%;
    margin-bottom: 15px;
}

/* Description Styling */
.how-can-learn-section .info-text {
    font-family: Poppins;
    font-weight: 300;
    font-size: 18px;
    line-height: 130%;
    vertical-align: bottom;
}

/* Right Section (Image) */
.how-can-learn-section .info-image-container {
    flex: 1 1 60%;
    /* Takes 80% of the width */
    /* display: flex; */
    justify-content: flex-end;
    /* Push image to the right */
    align-items: center;
}

/* Image Styling */
.how-can-learn-section .info-image {
    max-width: 100%;
    /* Ensures the image scales properly */
    height: 350px;
}



/* Carousel Indicators */
.how-can-learn-section .carousel-indicators {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    position: absolute;
    right: 0;
    bottom: -50px;
    left: 0;
    z-index: 2;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 15%;
    list-style: none;
    cursor: pointer;
}

.how-can-learn-section .indicator {
    width: 166px;
    height: 6px;
    border-radius: 27px;
    background-color: #ccc;
    margin: 0 5px;
    transition: background-color 0.3s ease-in-out;
}

.how-can-learn-section .indicator.active {
    background-color: #ffcc00;
    /* Highlight active slide */
}

.how-can-learn-section .custom-carousel-section {
    position: relative;
}

/* 📌 Mobile View: Content on Top, Image Below */
@media (max-width: 768px) {

    .how-can-learn-section .info-card {
        flex-direction: column;
        /* Stack text and image vertically */
        align-items: center;
        /* Center align content */
        text-align: center;
    }

    .how-can-learn-section .section-title {
        font-weight: 600;
        font-size: 28px;
    }

    .how-can-learn-section .section-subtitle {
        font-weight: 300;
        font-size: 15px;
        width: 90%;
        line-height: 130%;
        margin: auto;
    }

    .how-can-learn-section .info-title {
        font-weight: 600;
        font-size: 24px;
        line-height: 130%;
        margin-top: 0px;
    }

    .how-can-learn-section .info-text {
        font-weight: 300;
        font-size: 15px;
        line-height: 130%;
    }

    .how-can-learn-section .info-card {
        padding: 15px;
        /* min-height: 450px;
        height: 250px; */
    }

    .how-can-learn-section .carousel-card {
        /* flex: 0 0 85%; */
    }

    .how-can-learn-section .slick-slide {
        width: 95%;
    }

    .how-can-learn-section .carousel-card {
        width: 95%;
        padding: 10px;
    }

    .how-can-learn-section .info-card {
        flex-direction: column;
        text-align: center;
    }

    .how-can-learn-section .info-image-container {
        /* margin-top: 10px; */
        width: 100%;
        height: 100%;
    }


    .how-can-learn-section .info-card .text-left {
        max-width: 100%;
        /* Full width on mobile */
        flex: unset;
        /* Remove flex ratio */
        margin-bottom: 5px;
        /* Add spacing between text & image */
    }

    .how-can-learn-section .info-image-container {
        max-width: 100%;
        /* Full width on mobile */
        flex: unset;
    }

    .how-can-learn-section .info-image {
        max-width: 95%;
        /* Slightly bigger image for better visibility */
    }

    .how-can-learn-section .info-image{
        height: auto;
    }


}