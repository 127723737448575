.container .faq-page{
    margin-top: 10%!important;
}

.faq-separator {
    position: relative;
    text-align: center;
    margin: 50px 0;
    padding: 20px;
  }
  
  .separator-content {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .line {
    flex-grow: 1;
    height: 2px;
    background: linear-gradient(to right, #FED800, #FED800, #FED800);
    max-width: 20%;
  }
  
  .faq-section-consumer,
  .faq-section-creators {
    /* background: #f8f9fa; */
    padding: 40px;
    border-radius: 10px;
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
  }
  
  h2 {
    color: #343a40;
    font-weight: bold;
  }
  
  .bi-star-fill {
    font-size: 1.5rem;
    margin: 0 10px;
  }

  .faq-page .consumer-page-landing .faq-section{
    padding: 0px;
  }

  .faq-page .faq-section-consumer,   .faq-page .faq-section-creators{
    padding: 0px;
  } 
  