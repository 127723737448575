.terms-container {
  line-height: 1.6;
  color: #333;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.tos-buyer .outer-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;

}

.tos-buyer .section {
  margin-bottom: 20px;
}

.tos-buyer h1 {
  color: #1a1a1a;
  display: block;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.tos-buyer h2 {
  color: #1a1a1a;
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.tos-buyer ul {
  list-style-type: disc;
  margin-left: 20px;
}

.tos-buyer li {
  margin-bottom: 10px;
}

.tos-buyer aside.inner_pages {
  padding-top: .1rem !important;
}

.tos-buyer .faq-item {
  margin-bottom: 15px;
}

.tos-buyer .faq-question {
  font-weight: bold;
  color: #1a1a1a;
  cursor: auto;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  unicode-bidi: isolate;
  padding: 0;
  border-bottom: 0;

}