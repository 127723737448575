
@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/Open_Sans/OpenSans-VariableFont_wdth,wght.ttf') format('truetype'); /* Replace with the correct path */
    /* Add other font formats (woff, woff2, etc.) with their respective paths */
    /* Add font-weight and font-style if necessary */
  }

.page-title-guid {
    color: #000;
    font-family: Open Sans;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.guidlines aside.inner_pages{
    padding-top: 0.2rem!important;
}

.margin-top-30{
    margin-top: 30px;
}


.border-line {
    display: block;
    border-bottom: 5px solid #FAD5E5;
    width: 100px;
    margin-top: 12px;
}

.page-font {
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Open Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 152%;
}
.margin-top-1rem{
    margin-top: 1rem;
}
.ice-container {
    position: absolute;
    top: -90px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9999;
    width: 100%;
}

.floating-ice {
    display: block;
    position: relative;    
    height: auto; /* Adjusted for aspect ratio */
    max-width: 100%; /* Prevents stretching */
    /* animation: float 40s linear infinite; */
    margin: auto;
}

.coloured-title{
    color: #DE4384;
}

@keyframes float {
    0% {
        transform: translateY(-100%); 
    }
    100% {
        transform: translateY(100vh); 
    }
}

.guidlines-faq{
    position: absolute;
    right: 0px;
    top: 0px;
}

.faq-question{
    position: relative;
}

.width-95{
    width: 90%
}

.page-font-weight{
    font-weight: 600;
}
