.consumer-page .overlay-content-hero-section {
    flex: .5 1;
    padding: 65px;
}

.consumer-page li.nav-item a.nav-link.active {
    color: #000000 !important;
    font-weight: 700 !important;
}

.consumer-page .navbar-nav .nav-link {
    font-weight: 500 !important;
}


.consumer-page .navbar {
    background: #FED80042 !important;
}

.consumer-page .hero-section-container {
    background: #fff !important;
}

.consumer-page .fst-italic,
.consumer-page .bg-dark p {
    font-weight: 400;
    font-style: italic;
    font-size: 52px;
    line-height: 70.81px;
    letter-spacing: 0px;
    text-align: center;
}

.consumer-page .bg-dark {
    background-color: #323232 !important;
}

.consumer-page .overlay-content-hero-section p {
    font-size: 20px;
}


.consumer-page .rounded-pill {
    background: #FFF4B6;
    border: 0.5px solid #000000;
    font-weight: 600;
    font-size: 20px;
    line-height: 27.24px;
    text-align: center;
    border-width: 0.5px;
    opacity: 0.62;
    border-radius: 15px !important;
    padding-top: 4px;
    padding-right: 15px;
    padding-bottom: 4px;
    padding-left: 15px;
}

.advice-heading {
    font-weight: 600;
    font-size: 52px;
    line-height: 62.4px;
    letter-spacing: 0px;
    text-align: center;
}

.advice-paragraph {
    font-weight: 400;
    font-size: 24px;
    line-height: 32.68px;
    letter-spacing: 0px;
    text-align: center;
}

.consumer-page .beyond-chat-section {
    margin-top: 0px;
}

.consumer-page .beyond-chat-section .beyond-chat-image {
    border: 1px solid #000000;
    height: auto;
}

.consumer-page section {
    padding-top: 0rem;
    padding-bottom: 0rem;
}

.code-system {
    border: 1px solid #000000;
    background-color: #FFF5BD !important;
}

.code-system .fw-bold {
    font-size: 32px;
    line-height: 43.58px;
    letter-spacing: 0px;
}

.code-system .paragraph-1,
.code-system .paragraph-2,
.code-system .paragraph-3,
.code-system .paragraph-4 {
    border: 1.41px solid #000000;
    width: 47%;
    border-width: 1.41px;
    border-radius: 11.31px;
    font-weight: 600;
    font-size: 20px;
    line-height: 27.24px;
}

.code-system .paragraph-2 {
    width: 40%;
}

.code-system .paragraph-3 {
    width: 30%;
}

.code-system .paragraph-4 {
    width: 20%;
}

/* Style for connecting lines */

.connector-img {
    position: absolute;
    width: 60px;
    /* Adjust as needed */
}

.code-system .right {
    right: -23px;
    top: 30%;
    /* transform: translateY(-50%); */
}

.code-system .right-2 {
    right: -23px;
    top: 40%;
    /* transform: translateY(-50%); */
}

.code-system .left {
    left: -24px;
    /* Adjust as needed */
    top: 50%;
    /* transform: translateY(-50%); */
}

.consumer-page .explore-dots-section {
    display: flow;
    padding-top: 4rem;
}



.consumer-page .explore-dots-section.ready-to-unlock {
    padding-top: 8rem;
}

.consumer-page .explore-dots-section .section-title {
    font-weight: 600;
    font-size: 52px;
    line-height: 62.4px;
    letter-spacing: 0px;
    text-align: center;
}

.consumer-page .explore-dots-section .section-subtitle {
    font-weight: 400;
    font-size: 24px;
    line-height: 32.68px;
    letter-spacing: 0px;
    text-align: center;
}

.consumer-page .explore-dots-section .category-buttons button:hover {
    /* border-color: transparent; */
    /* color: #fff !important; */
}

.consumer-page .explore-dots-section .dot-item span {
    display: inline-block;
    color: #00171F;
    margin: 0;
}

.consumer-page .explore-dots-section .dot-item {
    background: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 5px;
}

.consumer-page .explore-dots-section .dot-item .dot-image {
    height: 160px;
}

.consumer-page .similar-dots-overlay {
    position: relative;
    color: #00171F;
    background: transparent;
}

.consumer-page section.testimonials-section {
    padding-top: 75px;
}

.testimonials-number {
    font-weight: 600;
    font-size: 32px;
    line-height: 43.58px;
    text-align: center;
}

.testimonials-number p {
    font-weight: 300;
    font-size: 20px;
    line-height: 27.24px;
}

.testimonials-section .fire-icon,
.testimonials-section .list-icon {
    width: 20px;
}

.ready-to-unlock .section-title {
    position: relative;
    z-index: 1;
}

.ready-to-unlock .section-sub-title {
    font-weight: 400;
    font-size: 24px;
    line-height: 32.68px;
    letter-spacing: 0px;
    text-align: center;
    position: relative;
    z-index: 1;
    width: 65%;
    margin: auto;
    margin-bottom: 30px;
}

.explore-dots-section.ready-to-unlock .view-all button {
    width: 295px !important;
}

.why-vasi {
    padding-top: 35px !important;
    padding-bottom: 25px !important;
    width: 40%;
    margin: auto;
}

.how-it-helps {
    padding-top: 35px !important;
    padding-bottom: 25px !important;
    width: 40%;
    margin: auto;
}

.consumer-page .beyound-limit .fst-italic,
.consumer-page .bg-dark p {
    width: 80%;
    margin: auto;
}

.consumer-page .py-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
}

.consumer-page .slick-list {
    padding: 5px;
}

.consumer-page .hero-title-1 i {
    font-weight: 400;
}

.self-help-btn {
    border: 1px solid #AA7DCE;
    width: auto;
    height: auto;
    border-radius: 32px;
    border-width: 1px;
    display: inline-block;
    background: #F7EDFF;
    padding: 0px 10px 0px 10px;
    cursor: pointer;
    line-height: 28px;
    font-size: 15px;
}


.nutrition-btn {
    border: 1px solid #F95738;
    width: auto;
    height: auto;
    border-radius: 32px;
    border-width: 1px;
    display: inline-block;
    background: #FCE8E5;
    padding: 0px 10px 0px 10px;
    cursor: pointer;
    line-height: 28px;
    font-size: 15px;
}

.fitness-btn {
    border: 1px solid #3E6990;
    width: auto;
    height: auto;
    display: inline-block;
    border-radius: 32px;
    border-width: 1px;
    background: #E4F2FF;
    padding: 0px 10px 0px 10px;
    cursor: pointer;
    line-height: 28px;
    font-size: 15px;
}


@media (min-width: 1650px) and (max-width: 1688px) {
    .download-page .hero-title-1 {
        padding-right: 60px !important;
    }
}


@media (min-width: 1551px) {
    .download-page .hero-title-1 {
        padding-right: 30px;
    }
}

@media (min-width: 1345px) and (max-width: 1468px) {
    .consumer-page .overlay-content-hero-section {
        flex: .5 1;
        padding: 0px;
    }

    .overlay-content-hero-section .hero-title {
        padding-right: 30px;
    }
}

@media (min-width: 1244px) and (max-width: 1344px) {
    .consumer-page .overlay-content-hero-section {
        flex: .6 1;
        padding: 0px;
    }

    .overlay-content-hero-section .hero-title {
        padding-right: 30px;
    }
}



@media only screen and (max-width: 768px) {
    .consumer-page .hero-title-1 {
        font-weight: 500;
        font-size: 48px;
        line-height: 57.6px;
        letter-spacing: 0px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .consumer-page .hero-title-1 i {
        display: block;
    }

    /* .consumer-page .hero-title-1 {
        font-size: 40px;
        font-weight: 600;
        line-height: 48px;
        text-align: center;
        margin-right: 0%;
    } */
}

@media (max-width: 998px) {

    .consumer-page .hero-title-1 {
        font-weight: 500;
        font-size: 48px;
        line-height: 57.6px;
        letter-spacing: 0px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .consumer-page .hero-title-1 i {
        display: block;
    }

    .consumer-page .overlay-content-hero-section {
        padding: 0px;
    }


    .consumer-page .overlay-content-hero-section p {
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        letter-spacing: 0px;
        text-align: center;
        width: 100%;
        padding: 20px 10px 20px 10px;
    }

    .phone-container .phone-frame {
        border-radius: 24px !important;
    }

    .consumer-page .overlay-content-hero-section .cta-button {
        width: 50%;
        font-size: 16px;
        line-height: 24px;
        height: 44px;
        padding: 8px 12px;
    }

    .consumer-page .blurred-images-container-hero-section {
        padding: 0px !important;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .phone-container {
        width: 260px !important;
        height: 600px !important;
        margin-top: 0px !important;
    }

    .consumer-page .hero-section-container {
        margin-bottom: 0px;
    }

    .consumer-page .fst-italic,
    .consumer-page .bg-dark p {
        font-weight: 400;
        font-style: italic;
        font-size: 32px;
        line-height: 43.58px;
        letter-spacing: 0px;
        text-align: center;
        width: 80%;
        margin: auto;
    }

    .advice-heading {
        font-size: 32px;
        line-height: 38.4px;
        width: 80%;
        margin: auto;
        font-weight: 500;
    }

    .consumer-page .rounded-pill {
        font-size: 12px;
        line-height: 16.34px;
    }

    .advice-paragraph {
        font-weight: 400;
        font-size: 16px;
        line-height: 21.79px;
        margin: auto;
        width: 80%;
        color: #000;
    }

    .consumer-page .beyond-chat-section .beyond-chat-image {
        height: auto;
    }

    .consumer-page .overlay-content-hero-section .hero-title {
        margin-top: 50px;
    }

    .code-system .fw-bold {
        font-size: 20px;
        line-height: 27.24px;
        letter-spacing: 0px;
    }

    .code-system .paragraph-1,
    .code-system .paragraph-2,
    .code-system .paragraph-3,
    .code-system .paragraph-4 {
        font-size: 14px;
        line-height: 19.07px;
    }

    .code-system .paragraph-1,
    .code-system .paragraph-2,
    .code-system .paragraph-3,
    .code-system .paragraph-4 {
        width: 85% !important;
    }

    .code-system .paragraph-2 {
        width: 75% !important;
    }

    .code-system .paragraph-3 {
        width: 70% !important;
    }

    .code-system .paragraph-4 {
        width: 60% !important;
    }

    .connector-img.right {
        width: 40px;
    }

    .connector-img.right-2 {
        width: 40px;
    }

    .code-system .right-2 {
        right: -25px;
        top: 50%;
    }

    .code-system .right {
        right: -24px;
        top: 60%;
    }

    .code-system .left {
        left: -38px;
        top: 65%;
    }

    .connector-img.left {
        width: 45px !important;
    }

    .beyound-limit .fst-italic {
        font-weight: 400;
        font-size: 24px;
        line-height: 32.68px;
        letter-spacing: 0px;
        text-align: center;
    }

    .consumer-page .explore-dots-section {
        padding-top: 18px;
    }

    .consumer-page section.testimonials-section {
        padding-top: 32px;
    }

    .consumer-page .explore-dots-section .section-title {
        font-weight: 500;
        font-size: 34px;
        line-height: 40.8px;
        letter-spacing: 0px;
        text-align: center;
        margin-top: 12px;
        margin-bottom: 12px;
    }

    .consumer-page .explore-dots-section .section-subtitle {
        font-weight: 500;
        font-size: 16px;
        line-height: 21.79px;
        letter-spacing: 0px;
        text-align: center;
    }

    .advice-paragraph-mobile {
        font-weight: 500;
        font-size: 16px;
        line-height: 21.79px;
        letter-spacing: 0px;
        text-align: center;
        width: 100%;
        margin: auto;
    }

    .testimonials-section .gap-5 {
        gap: 1.5rem !important;
    }

    .explore-dots-section.ready-to-unlock .section-title {
        font-weight: 500;
        font-size: 32px;
        line-height: 38.4px;
        letter-spacing: 0px;
        text-align: center;
    }

    .ready-to-unlock .section-sub-title {
        font-weight: 500;
        font-size: 16px;
        line-height: 21.79px;
        letter-spacing: 0px;
        text-align: center;
        width: 100%;
    }

    .explore-dots-section.ready-to-unlock .view-all button {
        width: 230px !important;
    }

    .how-it-helps,
    .why-vasi {
        width: 100%;
    }

    .consumer-page .similar-dots-container {
        padding: 0;
    }

    .consumer-page .explore-dots-section .slider-container .slick-slide {
        margin-bottom: 10px;
    }

    .testimonials-number {
        font-weight: 500;
        font-size: 28px;
        line-height: 38.13px;
    }

    .testimonials-text {
        font-weight: 300;
        font-size: 14px;
        line-height: 19.07px;
        text-align: center;
    }

    .consumer-page .explore-dots-section.ready-to-unlock {
        padding-top: 1rem;
    }

    .beyond-title-p {
        display: block;
        width: 90%;
        margin: auto;
    }

    .consumer-page .explore-dots-section .view-all button {
        height: 44px;
        padding: 10px 24px;
    }


}

@media (width: 853px), (width: 912px), (width: 980px), (width: 820px), (width: 1024px) {
    .download-page .hero-section-container {
        display: block;
        margin-top: 10%;
    }

    .download-page .blurred-images-container-hero-section {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .download-page .hero-section-container {
        padding: 50px;
    }

    .download-page .container {
        max-width: 100%;
        margin-top: 3%;
    }

    .download-page .steps-container{
        display: flex;
    }

    .beyond-chat-section .content-container{
        display: flex;
    }

    .overlay-content-hero-section p {
        width: 100%;
        text-align: left;
    }
}


@media (width: 1024px) {

    .download-page .slide-up-overlay-slide-6.active{
        display: inline-block;
    }

    .download-page .page-wrapper{
        height: auto;
    }
}

@media (max-width: 320px) {
    .download-page .overlay-content-hero-section .cta-button{
        width: 300px;
    }
}