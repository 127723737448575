img.emoji {
  display: inline !important;
  border: none !important;
  box-shadow: none !important;
  height: 1em !important;
  width: 1em !important;
  margin: 0 .07em !important;
  vertical-align: -.1em !important;
  background: 0 0 !important;
  padding: 0 !important
}

.home {
  --wp--preset--color--black: #000000;
  --wp--preset--color--cyan-bluish-gray: #abb8c3;
  --wp--preset--color--white: #ffffff;
  --wp--preset--color--pale-pink: #f78da7;
  --wp--preset--color--vivid-red: #cf2e2e;
  --wp--preset--color--luminous-vivid-orange: #ff6900;
  --wp--preset--color--luminous-vivid-amber: #fcb900;
  --wp--preset--color--light-green-cyan: #7bdcb5;
  --wp--preset--color--vivid-green-cyan: #00d084;
  --wp--preset--color--pale-cyan-blue: #8ed1fc;
  --wp--preset--color--vivid-cyan-blue: #0693e3;
  --wp--preset--color--vivid-purple: #9b51e0;
  --wp--preset--gradient--vivid-cyan-blue-to-vivid-purple: linear-gradient(135deg, rgba(6, 147, 227, 1) 0%, rgb(155, 81, 224) 100%);
  --wp--preset--gradient--light-green-cyan-to-vivid-green-cyan: linear-gradient(135deg, rgb(122, 220, 180) 0%, rgb(0, 208, 130) 100%);
  --wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange: linear-gradient(135deg, rgba(252, 185, 0, 1) 0%, rgba(255, 105, 0, 1) 100%);
  --wp--preset--gradient--luminous-vivid-orange-to-vivid-red: linear-gradient(135deg, rgba(255, 105, 0, 1) 0%, rgb(207, 46, 46) 100%);
  --wp--preset--gradient--very-light-gray-to-cyan-bluish-gray: linear-gradient(135deg, rgb(238, 238, 238) 0%, rgb(169, 184, 195) 100%);
  --wp--preset--gradient--cool-to-warm-spectrum: linear-gradient(135deg, rgb(74, 234, 220) 0%, rgb(151, 120, 209) 20%, rgb(207, 42, 186) 40%, rgb(238, 44, 130) 60%, rgb(251, 105, 98) 80%, rgb(254, 248, 76) 100%);
  --wp--preset--gradient--blush-light-purple: linear-gradient(135deg, rgb(255, 206, 236) 0%, rgb(152, 150, 240) 100%);
  --wp--preset--gradient--blush-bordeaux: linear-gradient(135deg, rgb(254, 205, 165) 0%, rgb(254, 45, 45) 50%, rgb(107, 0, 62) 100%);
  --wp--preset--gradient--luminous-dusk: linear-gradient(135deg, rgb(255, 203, 112) 0%, rgb(199, 81, 192) 50%, rgb(65, 88, 208) 100%);
  --wp--preset--gradient--pale-ocean: linear-gradient(135deg, rgb(255, 245, 203) 0%, rgb(182, 227, 212) 50%, rgb(51, 167, 181) 100%);
  --wp--preset--gradient--electric-grass: linear-gradient(135deg, rgb(202, 248, 128) 0%, rgb(113, 206, 126) 100%);
  --wp--preset--gradient--midnight: linear-gradient(135deg, rgb(2, 3, 129) 0%, rgb(40, 116, 252) 100%);
  --wp--preset--duotone--dark-grayscale: url('#wp-duotone-dark-grayscale');
  --wp--preset--duotone--grayscale: url('#wp-duotone-grayscale');
  --wp--preset--duotone--purple-yellow: url('#wp-duotone-purple-yellow');
  --wp--preset--duotone--blue-red: url('#wp-duotone-blue-red');
  --wp--preset--duotone--midnight: url('#wp-duotone-midnight');
  --wp--preset--duotone--magenta-yellow: url('#wp-duotone-magenta-yellow');
  --wp--preset--duotone--purple-green: url('#wp-duotone-purple-green');
  --wp--preset--duotone--blue-orange: url('#wp-duotone-blue-orange');
  --wp--preset--font-size--small: 13px;
  --wp--preset--font-size--medium: 20px;
  --wp--preset--font-size--large: 36px;
  --wp--preset--font-size--x-large: 42px;
  --wp--preset--spacing--20: 0.44rem;
  --wp--preset--spacing--30: 0.67rem;
  --wp--preset--spacing--40: 1rem;
  --wp--preset--spacing--50: 1.5rem;
  --wp--preset--spacing--60: 2.25rem;
  --wp--preset--spacing--70: 3.38rem;
  --wp--preset--spacing--80: 5.06rem
}

:where(.is-layout-flex) {
  gap: .5em
}

:where(.wp-block-columns.is-layout-flex) {
  gap: 2em
}

:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, .15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, .175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #0d6efd;
  --bs-link-hover-color: #0a58ca;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd
}

@media (prefers-reduced-motion:no-preference) {
  :root {
    scroll-behavior: smooth
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: .25
}

.h1,
.h2,
.h3,
h1,
h2,
h3,
h4 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2
}

.h1,
h1 {
  font-size: calc(1.375rem + 1.5vw)
}

@media (min-width:1200px) {

  .h1,
  h1 {
    font-size: 2.5rem
  }
}

.h2,
h2 {
  font-size: calc(1.325rem + .9vw)
}

@media (min-width:1200px) {

  .h2,
  h2 {
    font-size: 2rem
  }
}

.h3,
h3 {
  font-size: calc(1.3rem + .6vw)
}

@media (min-width:1200px) {

  .h3,
  h3 {
    font-size: 1.75rem
  }
}

h4 {
  font-size: calc(1.275rem + .3vw)
}

@media (min-width:1200px) {
  h4 {
    font-size: 1.5rem
  }
}

p {
  margin-top: 0;
  margin-bottom: 1rem
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit
}

ol,
ul {
  padding-left: 2rem
}

dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0
}

dt {
  font-weight: 700
}

dd {
  margin-bottom: .5rem;
  margin-left: 0
}

.mark,
mark {
  padding: .1875em;
  background-color: var(--bs-highlight-bg)
}

a {
  color: var(--bs-link-color);
  text-decoration: underline
}

a:hover {
  color: var(--bs-link-hover-color)
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none
}

code {
  font-family: var(--bs-font-monospace);
  font-size: 1em
}

code {
  font-size: .875em;
  color: var(--bs-code-color);
  word-wrap: break-word
}

a>code {
  color: inherit
}

figure {
  margin: 0 0 1rem
}

img,
svg {
  vertical-align: middle
}

table {
  caption-side: bottom;
  border-collapse: collapse
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent
}

tbody,
td,
th,
tr {
  border-color: inherit;
  border-style: solid;
  border-width: 0
}

label {
  display: inline-block
}

button {
  border-radius: 0
}

button:focus:not(:focus-visible) {
  outline: 0
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit
}

[role=button] {
  cursor: pointer
}

select {
  word-wrap: normal
}

select:disabled {
  opacity: 1
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important
}

[type=button],
[type=reset],
[type=submit],
button {
  -webkit-appearance: button
}

[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled),
button:not(:disabled) {
  cursor: pointer
}

::-moz-focus-inner {
  padding: 0;
  border-style: none
}

textarea {
  resize: vertical
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: calc(1.275rem + .3vw);
  line-height: inherit
}

legend+* {
  clear: left
}

::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-year-field {
  padding: 0
}

::-webkit-inner-spin-button {
  height: auto
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield
}

::-webkit-search-decoration {
  -webkit-appearance: none
}

::-webkit-color-swatch-wrapper {
  padding: 0
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button
}

iframe {
  border: 0
}

summary {
  display: list-item;
  cursor: pointer
}

[hidden] {
  display: none !important
}

.lead {
  font-size: 1.25rem;
  font-weight: 300
}

.container,
.container-fluid,
.container-xl {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-right: auto;
  margin-left: auto
}

@media (min-width:576px) {
  .container {
    max-width: 540px
  }
}

@media (min-width:768px) {
  .container {
    max-width: 720px
  }
}


@media (max-width:768px) {
  .app-btn {
    width: 100%;
    display: inline-block;
    text-align: center;

  }
}


@media (min-width:992px) {
  .container {
    max-width: 960px
  }
}

@media (min-width:1200px) {
  legend {
    font-size: 1.5rem
  }

  .container,
  .container-xl {
    max-width: 1140px
  }
}

@media (min-width:1400px) {

  .container,
  .container-xl {
    max-width: 1320px
  }
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x))
}

.row>* {
  /* flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y) */
}

.col {
  flex: 1 0 0%
}

@media (min-width:992px) {
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%
  }
}

@media (min-width:1200px) {
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%
  }
}

.table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: rgba(0, 0, 0, .05);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(0, 0, 0, .1);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: rgba(0, 0, 0, .075);
  width: 100%;
  margin-bottom: 1rem;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color)
}

.table>:not(caption)>*>* {
  padding: .5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg)
}

.table>tbody {
  vertical-align: inherit
}

.form-select {
  display: block;
  width: 100%;
  padding: .375rem 2.25rem .375rem .75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right .75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: .375rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none
}

@media (prefers-reduced-motion:reduce) {
  .form-select {
    transition: none
  }
}

.form-select:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25)
}

.form-select[multiple],
.form-select[size]:not([size="1"]) {
  padding-right: .75rem;
  background-image: none
}

.form-select:disabled {
  background-color: #e9ecef
}

.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #212529
}

.btn {
  /* --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #212529;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.375rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(0, 0, 0, .075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out */
}

@media (prefers-reduced-motion:reduce) {
  .btn {
    transition: none
  }
}

.btn:hover {
  /* color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color) */
}

.btn:focus-visible {
  /* color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow) */
}

.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
  /* color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color) */
}

.btn.active:focus-visible,
.btn.show:focus-visible,
.btn:first-child:active:focus-visible,
:not(.btn-check)+.btn:active:focus-visible {
  /* box-shadow: var(--bs-btn-focus-box-shadow) */
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  /* color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity) */
}

.btn-success {
  /* --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754 */
}

.btn-link {
  /* --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  text-decoration: underline */
}

.btn-link:focus-visible {
  /* color: var(--bs-btn-color) */
}

.btn-link:hover {
  /* color: var(--bs-btn-hover-color) */
}

.fade {
  transition: opacity .15s linear
}

@media (prefers-reduced-motion:reduce) {
  .fade {
    transition: none
  }
}

.fade:not(.show) {
  opacity: 0
}

.collapse:not(.show) {
  display: block
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height .35s ease
}

.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width .35s ease
}

@media (prefers-reduced-motion:reduce) {
  .collapsing {
    transition: none
  }

  .collapsing.collapse-horizontal {
    transition: none
  }
}

.dropdown,
.dropdown-center,
.dropend,
.dropstart,
.dropup,
.dropup-center {
  position: relative
}

.dropdown-toggle {
  white-space: nowrap
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent
}

.dropdown-toggle:empty::after {
  margin-left: 0
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: #212529;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: 0.375rem;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(0.375rem - 1px);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .15);
  --bs-dropdown-link-color: #212529;
  --bs-dropdown-link-hover-color: #1e2125;
  --bs-dropdown-link-hover-bg: #e9ecef;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius)
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: 0;
  border-right: .3em solid transparent;
  border-bottom: .3em solid;
  border-left: .3em solid transparent
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0
}

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid transparent;
  border-right: 0;
  border-bottom: .3em solid transparent;
  border-left: .3em solid
}

.dropend .dropdown-toggle:empty::after {
  margin-left: 0
}

.dropend .dropdown-toggle::after {
  vertical-align: 0
}

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: ""
}

.dropstart .dropdown-toggle::after {
  display: none
}

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid transparent;
  border-right: .3em solid;
  border-bottom: .3em solid transparent
}

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0
}

.dropstart .dropdown-toggle::before {
  vertical-align: 0
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg)
}

.dropdown-item.active,
.dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg)
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent
}

.dropdown-menu.show {
  display: block
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
  .nav-link {
    transition: none
  }
}

.nav-link:focus,
.nav-link:hover {
  color: var(--bs-nav-link-hover-color)
}

.nav-link.disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(0, 0, 0, .55);
  --bs-navbar-hover-color: rgba(0, 0, 0, .7);
  --bs-navbar-disabled-color: rgba(0, 0, 0, .3);
  --bs-navbar-active-color: rgba(0, 0, 0, .9);
  --bs-navbar-brand-padding-y: 0.3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: rgba(0, 0, 0, .9);
  --bs-navbar-brand-hover-color: rgba(0, 0, 0, .9);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(0, 0, 0, .1);
  --bs-navbar-toggler-border-radius: 0.375rem;
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x)
}

.navbar>.container,
.navbar>.container-fluid,
.navbar>.container-xl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between
}

.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap
}

.navbar-brand:focus,
.navbar-brand:hover {
  color: var(--bs-navbar-brand-hover-color)
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none
}

.navbar-nav .nav-link.active,
.navbar-nav .show>.nav-link {
  color: var(--bs-navbar-active-color)
}

.navbar-nav .dropdown-menu {
  position: static
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition)
}

.navbar-toggler:hover {
  text-decoration: none
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width)
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: url("../images/landing-page/hamburger_icon.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%
}

@media (min-width:992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x)
  }

  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none
  }

  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none
  }
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-border-width: 1px;
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: 0.375rem;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(0.375rem - 1px);
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(0, 0, 0, .03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius)
}

.card>hr {
  margin-right: 0;
  margin-left: 0
}

.card>.list-group {
  border-top: inherit;
  border-bottom: inherit
}

.card>.list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius)
}

.card>.list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius)
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: #fff;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: #dee2e6;
  --bs-pagination-border-radius: 0.375rem;
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: #e9ecef;
  --bs-pagination-hover-border-color: #dee2e6;
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: #e9ecef;
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, .25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #0d6efd;
  --bs-pagination-active-border-color: #0d6efd;
  --bs-pagination-disabled-color: #6c757d;
  --bs-pagination-disabled-bg: #fff;
  --bs-pagination-disabled-border-color: #dee2e6;
  display: flex;
  padding-left: 0;
  list-style: none
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 1px solid var(--bs-alert-border-color);
  --bs-alert-border-radius: 0.375rem;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius)
}

.progress {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: #e9ecef;
  --bs-progress-border-radius: 0.375rem;
  --bs-progress-box-shadow: inset 0 1px 2px rgba(0, 0, 0, .075);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #0d6efd;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius)
}

.list-group {
  --bs-list-group-color: #212529;
  --bs-list-group-bg: #fff;
  --bs-list-group-border-color: rgba(0, 0, 0, .125);
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: 0.375rem;
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: #495057;
  --bs-list-group-action-hover-color: #495057;
  --bs-list-group-action-hover-bg: #f8f9fa;
  --bs-list-group-action-active-color: #212529;
  --bs-list-group-action-active-bg: #e9ecef;
  --bs-list-group-disabled-color: #6c757d;
  --bs-list-group-disabled-bg: #fff;
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #0d6efd;
  --bs-list-group-active-border-color: #0d6efd;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius)
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit
}

.list-group-item-action:focus,
.list-group-item-action:hover {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg)
}

.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg)
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color)
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg)
}

.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color)
}

.list-group-item+.list-group-item {
  border-top-width: 0
}

.list-group-item+.list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width)
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(255, 255, 255, .85);
  --bs-toast-border-width: 1px;
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: 0.375rem;
  --bs-toast-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .15);
  --bs-toast-header-color: #6c757d;
  --bs-toast-header-bg: rgba(255, 255, 255, .85);
  --bs-toast-header-border-color: rgba(0, 0, 0, .05);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius)
}

.toast.showing {
  opacity: 0
}

.toast:not(.show) {
  display: none
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: 0.5rem;
  --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, .075);
  --bs-modal-inner-border-radius: calc(0.5rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none
}

.modal.fade .modal-dialog {
  transition: transform .3s ease-out;
  transform: translate(0, -50px)
}

@media (prefers-reduced-motion:reduce) {
  .navbar-toggler {
    transition: none
  }

  .modal.fade .modal-dialog {
    transition: none
  }
}

.modal.show .modal-dialog {
  transform: none
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02)
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg)
}

.modal-backdrop.fade {
  opacity: 0
}

.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity)
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding)
}

@media (min-width:576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .15)
  }

  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto
  }

  body:not([class*=elementor-page-]) .site-main {
    max-width: 500px
  }
}

.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.875rem;
  --bs-tooltip-color: #fff;
  --bs-tooltip-bg: #000;
  --bs-tooltip-border-radius: 0.375rem;
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  padding: var(--bs-tooltip-arrow-height);
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0
}

.tooltip.show {
  opacity: var(--bs-tooltip-opacity)
}

.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height)
}

.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid
}

.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius)
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.875rem;
  --bs-popover-bg: #fff;
  --bs-popover-border-width: 1px;
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: 0.5rem;
  --bs-popover-inner-border-radius: calc(0.5rem - 1px);
  --bs-popover-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .15);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: ;
  --bs-popover-header-bg: #f0f0f0;
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: #212529;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius)
}

.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height)
}

.popover .popover-arrow::after,
.popover .popover-arrow::before {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0
}

.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius)
}

.popover-header:empty {
  display: none
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color)
}

.carousel {
  position: relative
}

.carousel.pointer-event {
  touch-action: pan-y
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform .6s ease-in-out
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: block
}

.active.carousel-item-end,
.carousel-item-next:not(.carousel-item-start) {
  transform: translateX(100%)
}

.active.carousel-item-start,
.carousel-item-prev:not(.carousel-item-end) {
  transform: translateX(-100%)
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity .6s ease
}

@media (prefers-reduced-motion:reduce) {
  .carousel-item {
    transition: none
  }

  .carousel-indicators [data-bs-target] {
    transition: none
  }
}

.carousel-indicators .active {
  opacity: 1
}

.offcanvas {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: ;
  --bs-offcanvas-bg: #fff;
  --bs-offcanvas-border-width: 1px;
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, .075)
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: transform .3s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
  .offcanvas {
    transition: none
  }
}

.offcanvas.show:not(.hiding),
.offcanvas.showing {
  transform: none
}

.offcanvas.hiding,
.offcanvas.show,
.offcanvas.showing {
  visibility: visible
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000
}

.offcanvas-backdrop.fade {
  opacity: 0
}

.offcanvas-backdrop.show {
  opacity: .5
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: .5
}

.placeholder.btn::before {
  display: inline-block;
  content: ""
}

.clearfix::after {
  display: block;
  clear: both;
  content: ""
}

.ratio {
  position: relative;
  width: 100%
}

.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: ""
}

.ratio>* {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%
}

.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1020
}

.opacity-0 {
  opacity: 0 !important
}

.opacity-50 {
  opacity: .5 !important
}

.opacity-100 {
  opacity: 1 !important
}

.overflow-hidden {
  overflow: hidden !important
}

.d-block {
  display: block !important
}

.d-flex {
  display: flex !important
}

.d-none {
  display: none !important
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important
}

.shadow-none {
  box-shadow: none !important
}

.position-static {
  position: static !important
}

.top-0 {
  top: 0 !important
}

.bottom-0 {
  bottom: 0 !important
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
}

.border-0 {
  border: 0 !important
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important
}

.border-1 {
  --bs-border-width: 1px
}

.border-4 {
  --bs-border-width: 4px
}

.h-100 {
  height: 100% !important
}

.flex-row {
  flex-direction: row !important
}

.flex-column {
  flex-direction: column !important
}

.justify-content-center {
  justify-content: center !important
}

.align-items-start {
  align-items: flex-start !important
}

.align-items-center {
  align-items: center !important
}

.m-auto {
  margin: auto !important
}

.mx-1 {
  margin-right: .25rem !important;
  margin-left: .25rem !important
}

.mx-2 {
  margin-right: .5rem !important;
  margin-left: .5rem !important
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important
}

.my-2 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important
}

.mt-2 {
  margin-top: .5rem !important
}

.mt-3 {
  margin-top: 1rem !important
}

.me-2 {
  margin-right: .5rem !important
}

.me-3 {
  margin-right: 1rem !important
}

.me-5 {
  margin-right: 3rem !important
}

.mb-1 {
  margin-bottom: .25rem !important
}

.mb-2 {
  margin-bottom: .5rem !important
}

.mb-3 {
  margin-bottom: 1rem !important
}

.mb-4 {
  margin-bottom: 1.5rem !important
}

.ms-2 {
  margin-left: .5rem !important
}

.ms-auto {
  margin-left: auto !important
}

.p-0 {
  padding: 0 !important
}

.p-2 {
  padding: .5rem !important
}

.p-3 {
  padding: 1rem !important
}

.p-4 {
  padding: 1.5rem !important
}

.p-5 {
  padding: 3rem !important
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important
}

.px-1 {
  padding-right: .25rem !important;
  padding-left: .25rem !important
}

.px-2 {
  padding-right: .5rem !important;
  padding-left: .5rem !important
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important
}

.py-1 {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important
}

.py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important
}

.pt-4 {
  padding-top: 1.5rem !important
}

.pe-5 {
  padding-right: 3rem !important
}

.pb-4 {
  padding-bottom: 1.5rem !important
}

.ps-5 {
  padding-left: 3rem !important
}

.gap-1 {
  gap: .25rem !important
}

.gap-3 {
  gap: 1rem !important
}

.text-start {
  text-align: left !important
}

.text-center {
  text-align: center !important
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important
}

.visible {
  visibility: visible !important
}

@media (min-width:992px) {
  .d-lg-inline {
    display: inline !important
  }

  .mb-lg-0 {
    margin-bottom: 0 !important
  }
}

*,
::after,
::before {
  box-sizing: border-box
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none
}

a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none
}

template {
  display: none
}

[hidden] {
  display: none
}

input[type=date],
input[type=email],
input[type=number],
input[type=password],
input[type=search],
input[type=tel],
input[type=text],
input[type=url],
select,
textarea {
  /* width: 100%;
  border: 1px solid #666;
  border-radius: 3px;
  padding: .5rem 1rem;
  transition: all .3s */
}

[type=button],
[type=submit],
button {
  /* display: inline-block;
  font-weight: 400;
  color: #c36;
  text-align: center;
  white-space: nowrap;
  user-select: none;
  background-color: transparent;
  border: 1px solid #c36;
  padding: .5rem 1rem;
  font-size: 1rem;
  border-radius: 3px;
  transition: all .3s */
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal
}

[type=checkbox],
[type=radio] {
  box-sizing: border-box;
  padding: 0
}

table td,
table th {
  padding: 15px;
  line-height: 1.5;
  vertical-align: top;
  border: 1px solid #80808080
}

table tbody>tr:nth-child(odd)>td,
table tbody>tr:nth-child(odd)>th {
  background-color: #80808012
}

table tbody tr:hover>td,
table tbody tr:hover>th {
  background-color: #8080801a
}

table tbody+tbody {
  border-top: 2px solid #80808080
}

@media all and (min-width:992px) {
  .navbar .nav-item .dropdown-menu {
    display: none
  }

  .navbar .nav-item:hover .dropdown-menu {
    display: block
  }
}

.navbar {
  background-color: #fff
}

.navbar .nav-link {
  pointer-events: auto
}

.navbar .nav-item .dropdown-menu {
  pointer-events: auto;
  padding-bottom: 0;
  padding-top: 0;
  margin-top: 0;
  border: none;
  border-radius: 0;
  left: auto;
  top: auto;
  background-color: #fff;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, .2) !important;
  -webkit-transition: opacity .3s cubic-bezier(.48, .01, .5, .99), visibility .1s linear !important;
  -o-transition: opacity .3s cubic-bezier(.48, .01, .5, .99), visibility .1s linear !important;
  transition: opacity .3s cubic-bezier(.48, .01, .5, .99), visibility .1s linear !important
}

.navbar-nav .nav-item .nav-link {
  border-bottom: 3px solid transparent !important
}

.navbar-nav .nav-item .nav-link.active,
.navbar-nav .nav-item:hover .nav-link {
  border-bottom: 3px solid #000 !important
}

@media (max-width: 998px) {

  .navbar-nav .nav-item .nav-link.active,
  .navbar-nav .nav-item:hover .nav-link {
    width: 120px;
    margin: auto;
  }
}


.navbar-nav>.nav-item>.nav-link {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 16px;
  font-weight: 400
}

.navbar-nav .dropdown-item,
.navbar-nav .nav-item .nav-link {
  color: #000 !important
}

.navbar-nav .dropdown-menu.container-xl {
  max-width: 1080px
}

.navbar-nav .dropdown-menu h3 {
  color: #818a91 !important;
  border-bottom: 1px solid !important;
  background-color: transparent !important;
  text-align: center !important;
  font-family: Roboto, Sans-serif !important;
  font-size: 22px !important;
  font-weight: 700 !important;
  line-height: 40px !important;
  letter-spacing: 2px !important
}

.navbar-nav .dropdown-item {
  white-space: normal !important
}

.navbar-nav .dropdown-item.list-text {
  font-family: Roboto, Sans-serif !important;
  text-align: center !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  line-height: 32px !important
}

.navbar-nav .dropdown-item.list-text:hover {
  color: #f3c500 !important;
  background-color: transparent !important
}

.navbar-nav .dropdown-item.list-rich-text:hover {
  background-color: #f2f2f2 !important
}

.navbar-nav .dropdown-item .rich-text-icon {
  width: 55px;
  height: 55px
}

.navbar-nav .dropdown-item .rich-text-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  text-align: left
}

.navbar-nav .dropdown-item .rich-text-title.large {
  font-size: 20px;
  line-height: 30px
}

.navbar-nav .dropdown-item .rich-text-description {
  font-size: 15px;
  font-weight: 400;
  line-height: 16px;
  text-align: left
}

.navbar-nav .dropdown-item .rich-text-description.large {
  font-size: 17px;
  line-height: 24px
}

.navbar-nav .dropdown-menu-cta {
  background-color: #f3f2f2
}

.navbar-nav .dropdown-menu-cta .cta-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 32px
}

.navbar-nav .dropdown-menu-cta .cta-description {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px
}

.navbar-nav .dropdown-menu-cta:hover {
  background-color: #e6e6e6
}

.navbar-nav .dropdown-toggle::after {
  display: none !important
}

.btn-login.btn-success,
.btn-login.btn-success:hover,
.navbar-nav .btn-success,
.navbar-nav .btn-success:hover {
  background-color: #0abf3c;
  font-size: 18px;
  line-height: 18px;
  font-weight: 700;
  fill: #FFFFFF;
  color: #fff;
  border-style: solid;
  border-width: 1px;
  border-color: #000;
  padding: 15px 30px;
  border-radius: 4px;
  transition: all .3s
}

.navbar-nav .btn-success,
.navbar-nav .btn-success:hover {
  color: #000
}

.navbar .navbar-toggler,
.navbar .navbar-toggler:active,
.navbar .navbar-toggler:focus,
.navbar .navbar-toggler:hover {
  background-color: transparent;
  border-style: solid;
  border-width: 1px;
  border-color: #000
}

.dropdown-menu-center {
  left: 50% !important;
  right: auto !important;
  text-align: center !important;
  transform: translate(-50%, 0) !important
}

form[data-drip-embedded-form] {
  display: flex
}

form[data-drip-embedded-form] .drip-form {
  flex: 1 1 auto
}

form[data-drip-embedded-form] .drip-input-button .input-button {
  font-size: 20px;
  font-weight: 500;
  text-decoration: none;
  fill: #FFFFFF;
  color: #fff;
  background-color: #0abf3c;
  border-style: solid;
  border-width: 1px;
  border-color: #000;
  padding: 5px 20px;
  border-radius: 3px;
  margin-left: 10px
}

#footer-sb {
  background-color: #333
}

#footer-sb>.container {
  padding: 0
}

@media all and (min-width:1400px) {
  .navbar .nav-item .dropdown-menu.container-xl {
    max-width: 1320px
  }

  .navbar .nav-item .dropdown-menu.container-xl .container {
    display: block;
    max-width: 1320px
  }

  .navbar .nav-item .dropdown-menu.container-xl .container-fluid {
    display: block
  }

  #footer-sb>.container {
    max-width: 1320px
  }

  #footer-sb>.container>.row {
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-.5 * var(--bs-gutter-x));
    margin-left: calc(-.5 * var(--bs-gutter-x))
  }
}

@media all and (min-width:992px) {
  #footer-sb .col-lg-auto.col-logo {
    min-width: 280px
  }

  #footer-sb .col-lg-auto.col-networks {
    min-width: 335px
  }

  #footer-sb .col-lg-auto.col-knowledge {
    min-width: 305px
  }

  #footer-sb .col-lg-auto.with-border {
    border-right: 1px solid #5d5d5d
  }
}

#footer-sb .logo-sb {
  max-width: 220px;
  max-height: 34px
}

#footer-sb .list-title {
  color: #f5c400;
  font-size: 28px;
  font-weight: 700;
  line-height: 28px
}

#footer-sb .list-group a {
  color: #fff;
  background-color: transparent;
  border: none;
  padding-left: 0;
  padding-right: 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 32px
}

#footer-sb .list-group a .social-icon {
  background-color: gray;
  border-radius: 50%;
  border: 1px solid #fff;
  color: #fff;
  display: inline-block;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  margin-right: 6px
}

#footer-sb .list-group a .social-icon.facebook {
  background-color: #007aff
}

#footer-sb .list-group a .social-icon.twitter {
  background-color: #2baae1
}

#footer-sb .list-group a .social-icon.instagram {
  background-color: #cf1c97
}

#footer-sb .list-group a .social-icon.instagram i {
  font-size: 20px
}

#footer-sb .list-group a .social-icon.linkedin {
  background-color: #037bb6
}

#footer-sb .list-group a .social-icon.pinterest {
  background-color: #cb1e26
}

#footer-sb .list-group a .social-icon.google {
  background: 0 0;
  border: none
}

#footer-sb .list-group a .social-icon.tiktok {
  background-color: #000
}

#footer-sb .list-group a .social-icon.youtube {
  background-color: #f10207
}

#footer-sb .networks a {
  background-color: #fff;
  border-radius: 50%;
  padding: 12px;
  min-width: 32px;
  min-height: 32px;
  flex: 0 0 auto;
  line-height: 22px;
  color: #333
}

#footer-sb .networks i {
  font-size: 20px
}

#cookie-notice {
  z-index: 9998 !important
}

div.tooltip-inner {
  max-width: 500px
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%
}

*,
:after,
:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

h1,
h2,
h3,
h4 {
  margin-top: .5rem;
  margin-bottom: 1rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit
}

h1 {
  font-size: 2.5rem
}

h2 {
  font-size: 2rem
}

h3 {
  font-size: 1.75rem
}

h4 {
  font-size: 1.5rem
}

p {
  margin-top: 0;
  margin-bottom: .9rem
}

hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
  overflow: visible
}

a {
  background-color: transparent;
  text-decoration: none;
  color: #c36
}

a:active,
a:hover {
  color: #336
}

a:not([href]):not([tabindex]),
a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none
}

a:not([href]):not([tabindex]):focus {
  outline: 0
}

b,
strong {
  font-weight: bolder
}

code {
  font-family: monospace, monospace;
  font-size: 1em
}

img {
  border-style: none;
  height: auto;
  max-width: 100%
}

details {
  display: block
}

summary {
  display: list-item
}

figcaption {
  font-size: 16px;
  color: #333;
  line-height: 1.4;
  font-style: italic;
  font-weight: 400
}

[hidden],
template {
  display: none
}

@media print {

  *,
  :after,
  :before {
    background: 0 0 !important;
    color: #000 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    text-shadow: none !important
  }

  a,
  a:visited {
    text-decoration: underline
  }

  a[href]:after {
    content: " (" attr(href) ")"
  }

  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: ""
  }

  img,
  tr {
    page-break-inside: avoid
  }

  h2,
  h3,
  p {
    orphans: 3;
    widows: 3
  }

  h2,
  h3 {
    page-break-after: avoid
  }
}

label {
  display: inline-block;
  line-height: 1;
  vertical-align: middle
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 1rem;
  line-height: 1.5;
  margin: 0
}

input[type=date],
input[type=email],
input[type=number],
input[type=password],
input[type=search],
input[type=tel],
input[type=text],
input[type=url],
select,
textarea {
  /* width: 100%;
  border: 1px solid #666;
  border-radius: 3px;
  padding: .5rem 1rem;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: all .3s */
}

input[type=date]:focus,
input[type=email]:focus,
input[type=number]:focus,
input[type=password]:focus,
input[type=search]:focus,
input[type=tel]:focus,
input[type=text]:focus,
input[type=url]:focus,
select:focus,
textarea:focus {
  /* border-color: #333 */
}

button,
input {
  overflow: visible
}

button,
select {
  text-transform: none
}

[type=button],
[type=reset],
[type=submit],
button {
  width: auto;
  -webkit-appearance: button
}

[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
button::-moz-focus-inner {
  border-style: none;
  padding: 0
}

[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring,
button:-moz-focusring {
  outline: ButtonText dotted 1px
}

[type=button],
[type=submit],
button {
  /* display: inline-block;
  font-weight: 400;
  color: #c36;
  text-align: center;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid #c36;
  padding: .5rem 1rem;
  font-size: 1rem;
  border-radius: 3px;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: all .3s */
}

[type=button]:focus,
[type=submit]:focus,
button:focus {
  outline: -webkit-focus-ring-color auto 5px
}

[type=button]:focus,
[type=button]:hover,
[type=submit]:focus,
[type=submit]:hover,
button:focus,
button:hover {
  /* color: #fff;
  background-color: #c36;
  text-decoration: none */
}

[type=button]:not(:disabled),
[type=submit]:not(:disabled),
button:not(:disabled) {
  cursor: pointer
}

fieldset {
  padding: .35em .75em .625em
}

legend {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal
}

progress {
  vertical-align: baseline
}

textarea {
  overflow: auto;
  resize: vertical
}

[type=checkbox],
[type=radio] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit
}

select {
  display: block
}

table {
  background-color: transparent;
  width: 100%;
  margin-bottom: 15px;
  font-size: .9em;
  border-spacing: 0;
  border-collapse: collapse
}

table td,
table th {
  padding: 15px;
  line-height: 1.5;
  vertical-align: top;
  border: 1px solid hsla(0, 0%, 50.2%, .5019607843137255)
}

table th {
  font-weight: 700
}

table tbody>tr:nth-child(odd)>td,
table tbody>tr:nth-child(odd)>th {
  background-color: hsla(0, 0%, 50.2%, .07058823529411765)
}

table tbody tr:hover>td,
table tbody tr:hover>th {
  background-color: hsla(0, 0%, 50.2%, .10196078431372549)
}

table tbody+tbody {
  border-top: 2px solid hsla(0, 0%, 50.2%, .5019607843137255)
}

dd,
dl,
dt,
li,
ol,
ul {
  margin-top: 0;
  margin-bottom: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: 0 0
}

.comments-area a,
.page-content a {
  text-decoration: underline
}

.wp-caption {
  margin-bottom: 1.25rem;
  max-width: 100%
}

.wp-caption img {
  display: block;
  margin-left: auto;
  margin-right: auto
}

.wp-caption-text {
  margin: 0
}

.pagination {
  margin: 20px auto
}

.sticky {
  position: relative;
  display: block
}

.hide {
  display: none !important
}

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important
}

.screen-reader-text:focus {
  background-color: #eee;
  clip: auto !important;
  -webkit-clip-path: none;
  clip-path: none;
  color: #333;
  display: block;
  font-size: 1rem;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000
}

#comments .comment {
  position: relative
}

#comments .comment .avatar {
  position: absolute;
  left: 0;
  border-radius: 50%;
  margin-right: 10px
}

body.rtl #comments .comment .avatar {
  left: auto;
  right: 0;
  margin-right: 0;
  margin-left: 10px
}

#comments .reply {
  font-size: 11px;
  line-height: 1
}

#comments .children {
  position: relative;
  list-style: none;
  margin: 0;
  padding-left: 30px
}

body.rtl #comments .children {
  padding-left: 0;
  padding-right: 30px
}

#comments .children li:last-child {
  padding-bottom: 0
}

body:not([class*=elementor-page-]) .site-main {
  display: initial;
  /* margin-right: auto;
  margin-left: auto;
  width: 100% */
}

@media (max-width:575px) {
  body:not([class*=elementor-page-]) .site-main {
    padding-right: 10px;
    padding-left: 10px
  }
}

@media (min-width:768px) {
  body:not([class*=elementor-page-]) .site-main {
    max-width: 600px
  }
}

@media (min-width:992px) {
  body:not([class*=elementor-page-]) .site-main {
    max-width: 800px
  }
}

@media (min-width:1200px) {
  body:not([class*=elementor-page-]) .site-main {
    max-width: 1140px
  }
}

.dialog-widget-content {
  background-color: var(--e-a-bg-default);
  position: absolute;
  border-radius: 3px;
  box-shadow: 2px 8px 23px 3px rgba(0, 0, 0, .2);
  overflow: hidden
}

.dialog-message {
  line-height: 1.5;
  box-sizing: border-box
}

.dialog-close-button {
  cursor: pointer;
  position: absolute;
  margin-top: 15px;
  right: 15px;
  color: var(--e-a-color-txt);
  font-size: 15px;
  line-height: 1;
  transition: var(--e-a-transition-hover)
}

.dialog-close-button:hover {
  color: var(--e-a-color-txt-hover)
}

.dialog-prevent-scroll {
  overflow: hidden;
  max-height: 100vh
}

.dialog-type-lightbox {
  position: fixed;
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .8);
  z-index: 9999;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none
}

.flatpickr-calendar {
  width: 280px
}

.elementor-screen-only,
.screen-reader-text,
.screen-reader-text span {
  position: absolute;
  top: -10000em;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0
}

.elementor-aspect-ratio-219 .elementor-fit-aspect-ratio {
  padding-bottom: 42.8571%
}

.elementor-aspect-ratio-169 .elementor-fit-aspect-ratio {
  padding-bottom: 56.25%
}

.elementor-aspect-ratio-43 .elementor-fit-aspect-ratio {
  padding-bottom: 75%
}

.elementor-aspect-ratio-32 .elementor-fit-aspect-ratio {
  padding-bottom: 66.6666%
}

.elementor-aspect-ratio-11 .elementor-fit-aspect-ratio {
  padding-bottom: 100%
}

.elementor-aspect-ratio-916 .elementor-fit-aspect-ratio {
  padding-bottom: 177.8%
}

.elementor *,
.elementor :after,
.elementor :before {
  box-sizing: border-box
}

.elementor a {
  box-shadow: none;
  text-decoration: none
}

.elementor hr {
  margin: 0;
  background-color: transparent
}

.elementor img {
  height: auto;
  max-width: 100%;
  border: none;
  border-radius: 0;
  box-shadow: none
}

.elementor .elementor-widget:not(.elementor-widget-text-editor):not(.elementor-widget-theme-post-content) figure {
  margin: 0
}

.elementor embed,
.elementor iframe,
.elementor object,
.elementor video {
  max-width: 100%;
  width: 100%;
  margin: 0;
  line-height: 1;
  border: none
}

.elementor .elementor-background-video-container {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  overflow: hidden;
  z-index: 0;
  direction: ltr
}

.elementor .elementor-background-video-container {
  transition: opacity 1s;
  pointer-events: none
}

.elementor .elementor-background-video-container.elementor-loading {
  opacity: 0
}

.elementor .elementor-background-video-embed {
  max-width: none
}

.elementor .elementor-background-video-embed,
.elementor .elementor-background-video-hosted {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)
}

.elementor .elementor-background-overlay,
.elementor .elementor-background-slideshow {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute
}

.elementor .elementor-background-slideshow {
  z-index: 0
}

.elementor .elementor-background-slideshow__slide__image {
  width: 100%;
  height: 100%;
  background-position: 50%;
  background-size: cover
}

.e-con-inner>.elementor-element.elementor-absolute,
.e-con>.elementor-element.elementor-absolute,
.elementor-widget-wrap>.elementor-element.elementor-absolute {
  position: absolute
}

.elementor-widget-wrap .elementor-element.elementor-widget__width-initial {
  max-width: 100%
}

.elementor-element {
  --flex-direction: initial;
  --flex-wrap: initial;
  --justify-content: initial;
  --align-items: initial;
  --align-content: initial;
  --gap: initial;
  --flex-basis: initial;
  --flex-grow: initial;
  --flex-shrink: initial;
  --order: initial;
  --align-self: initial;
  flex-basis: var(--flex-basis);
  flex-grow: var(--flex-grow);
  flex-shrink: var(--flex-shrink);
  order: var(--order);
  align-self: var(--align-self)
}

.elementor-element.elementor-absolute {
  z-index: 1
}

.elementor-element:where(.e-con-full, .elementor-widget) {
  flex-direction: var(--flex-direction);
  flex-wrap: var(--flex-wrap);
  justify-content: var(--justify-content);
  align-items: var(--align-items);
  align-content: var(--align-content);
  gap: var(--gap)
}

.elementor-invisible {
  visibility: hidden
}

.elementor-align-center {
  text-align: center
}

.elementor-align-center .elementor-button {
  width: auto
}

.elementor-align-right {
  text-align: right
}

.elementor-align-right .elementor-button {
  width: auto
}

.elementor-align-left {
  text-align: left
}

.elementor-align-left .elementor-button {
  width: auto
}

.elementor-align-justify .elementor-button {
  width: 100%
}

.elementor-ken-burns {
  transition-property: transform;
  transition-duration: 10s;
  transition-timing-function: linear
}

.elementor-ken-burns--out {
  transform: scale(1.3)
}

.elementor-ken-burns--active {
  transition-duration: 20s
}

.elementor-ken-burns--active.elementor-ken-burns--out {
  transform: scale(1)
}

.elementor-ken-burns--active.elementor-ken-burns--in {
  transform: scale(1.3)
}

@media (max-width:1024px) {
  .elementor-tablet-align-center {
    text-align: center
  }

  .elementor-tablet-align-center .elementor-button {
    width: auto
  }

  .elementor-section .elementor-container {
    flex-wrap: wrap
  }
}

@media (max-width:767px) {
  table table {
    font-size: .8em
  }

  table table td,
  table table th {
    padding: 7px;
    line-height: 1.3
  }

  table table th {
    font-weight: 400
  }

  #comments .children {
    padding-left: 20px
  }

  #comments .comment .avatar {
    position: inherit;
    float: left
  }

  body.rtl #comments .comment .avatar {
    float: right
  }

  .elementor-mobile-align-center {
    text-align: center
  }

  .elementor-mobile-align-center .elementor-button {
    width: auto
  }

  .elementor-mobile-align-justify .elementor-button {
    width: 100%
  }
}

:root {
  --page-title-display: block
}

.elementor-section {
  position: relative
}

.elementor-section .elementor-container {
  display: flex;
  margin-right: auto;
  margin-left: auto;
  position: relative
}

.elementor-section.elementor-section-stretched {
  position: relative;
  width: 100%
}

.elementor-widget-wrap {
  position: relative;
  width: 100%;
  flex-wrap: wrap;
  align-content: flex-start
}

.elementor:not(.elementor-bc-flex-widget) .elementor-widget-wrap {
  display: flex
}

.elementor-widget-wrap>.elementor-element {
  width: 100%
}

.elementor-widget-wrap.e-swiper-container {
  width: calc(100% - (var(--e-column-margin-left, 0px) + var(--e-column-margin-right, 0px)))
}

.elementor-widget {
  position: relative
}

.elementor-widget:not(:last-child).elementor-absolute,
.elementor-widget:not(:last-child).elementor-widget__width-initial {
  margin-bottom: 0
}

.elementor-column {
  position: relative;
  min-height: 1px;
  display: flex
}

.elementor-column-wrap {
  width: 100%;
  position: relative;
  display: flex
}

.elementor-column-gap-narrow>.elementor-column>.elementor-element-populated {
  padding: 5px
}

.elementor-column-gap-default>.elementor-column>.elementor-element-populated {
  padding: 10px
}

.elementor-column-gap-extended>.elementor-column>.elementor-element-populated {
  padding: 15px
}

.elementor-inner-section .elementor-column-gap-no .elementor-element-populated {
  padding: 0
}

@media (min-width:768px) {
  .elementor-column.elementor-col-10 {
    width: 10%
  }

  .elementor-column.elementor-col-12 {
    width: 12.5%
  }

  .elementor-column.elementor-col-16 {
    width: 16.666%
  }

  .elementor-column.elementor-col-20 {
    width: 20%
  }

  .elementor-column.elementor-col-33 {
    width: 33.333%
  }

  .elementor-column.elementor-col-50 {
    width: 50%
  }

  .elementor-column.elementor-col-100 {
    width: 100%
  }
}

@media (min-width:768px) and (max-width:1024px) {
  .elementor-reverse-tablet>.elementor-container>:first-child {
    order: 10
  }

  .elementor-reverse-tablet>.elementor-container>:nth-child(2) {
    order: 9
  }

  .elementor-reverse-tablet>.elementor-container>:nth-child(3) {
    order: 8
  }

  .elementor-reverse-tablet>.elementor-container>:nth-child(4) {
    order: 7
  }

  .elementor-reverse-tablet>.elementor-container>:nth-child(5) {
    order: 6
  }

  .elementor-reverse-tablet>.elementor-container>:nth-child(6) {
    order: 5
  }

  .elementor-reverse-tablet>.elementor-container>:nth-child(7) {
    order: 4
  }

  .elementor-reverse-tablet>.elementor-container>:nth-child(8) {
    order: 3
  }

  .elementor-reverse-tablet>.elementor-container>:nth-child(9) {
    order: 2
  }

  .elementor-reverse-tablet>.elementor-container>:nth-child(10) {
    order: 1
  }
}

@media (min-width:1) and (max-width:1024px) {
  .elementor-reverse-tablet>.elementor-container>:first-child {
    order: 10
  }

  .elementor-reverse-tablet>.elementor-container>:nth-child(2) {
    order: 9
  }

  .elementor-reverse-tablet>.elementor-container>:nth-child(3) {
    order: 8
  }

  .elementor-reverse-tablet>.elementor-container>:nth-child(4) {
    order: 7
  }

  .elementor-reverse-tablet>.elementor-container>:nth-child(5) {
    order: 6
  }

  .elementor-reverse-tablet>.elementor-container>:nth-child(6) {
    order: 5
  }

  .elementor-reverse-tablet>.elementor-container>:nth-child(7) {
    order: 4
  }

  .elementor-reverse-tablet>.elementor-container>:nth-child(8) {
    order: 3
  }

  .elementor-reverse-tablet>.elementor-container>:nth-child(9) {
    order: 2
  }

  .elementor-reverse-tablet>.elementor-container>:nth-child(10) {
    order: 1
  }
}

@media (min-width:768px) and (max-width:1) {

  .elementor-reverse-tablet>.elementor-container>:first-child,
  .elementor-reverse-tablet>.elementor-container>:nth-child(10),
  .elementor-reverse-tablet>.elementor-container>:nth-child(2),
  .elementor-reverse-tablet>.elementor-container>:nth-child(3),
  .elementor-reverse-tablet>.elementor-container>:nth-child(4),
  .elementor-reverse-tablet>.elementor-container>:nth-child(5),
  .elementor-reverse-tablet>.elementor-container>:nth-child(6),
  .elementor-reverse-tablet>.elementor-container>:nth-child(7),
  .elementor-reverse-tablet>.elementor-container>:nth-child(8),
  .elementor-reverse-tablet>.elementor-container>:nth-child(9) {
    order: 0
  }
}

@media (min-width:1025px) {
  #elementor-device-mode:after {
    content: "desktop"
  }
}

@media (min-width:1) {
  #elementor-device-mode:after {
    content: "widescreen"
  }

  .elementor-widget:not(.elementor-widescreen-align-right) .elementor-icon-list-item:after {
    left: 0
  }

  .elementor-widget:not(.elementor-widescreen-align-left) .elementor-icon-list-item:after {
    right: 0
  }
}

@media (max-width:1) {
  #elementor-device-mode:after {
    content: "laptop";
    content: "tablet_extra"
  }
}

@media (max-width:1024px) {
  #elementor-device-mode:after {
    content: "tablet"
  }
}

@media (max-width:1) {
  #elementor-device-mode:after {
    content: "mobile_extra"
  }

  .elementor-widget:not(.elementor-laptop-align-right) .elementor-icon-list-item:after {
    left: 0
  }

  .elementor-widget:not(.elementor-laptop-align-left) .elementor-icon-list-item:after {
    right: 0
  }
}

@media (max-width:767px) {
  .elementor-reverse-mobile>.elementor-container>:first-child {
    order: 10
  }

  .elementor-reverse-mobile>.elementor-container>:nth-child(2) {
    order: 9
  }

  .elementor-reverse-mobile>.elementor-container>:nth-child(3) {
    order: 8
  }

  .elementor-reverse-mobile>.elementor-container>:nth-child(4) {
    order: 7
  }

  .elementor-reverse-mobile>.elementor-container>:nth-child(5) {
    order: 6
  }

  .elementor-reverse-mobile>.elementor-container>:nth-child(6) {
    order: 5
  }

  .elementor-reverse-mobile>.elementor-container>:nth-child(7) {
    order: 4
  }

  .elementor-reverse-mobile>.elementor-container>:nth-child(8) {
    order: 3
  }

  .elementor-reverse-mobile>.elementor-container>:nth-child(9) {
    order: 2
  }

  .elementor-reverse-mobile>.elementor-container>:nth-child(10) {
    order: 1
  }

  .elementor-column {
    width: 100%
  }

  #elementor-device-mode:after {
    content: "mobile"
  }
}

.e-con {
  --border-radius: 0;
  --display: flex;
  --flex-direction: column;
  --flex-basis: auto;
  --flex-grow: 0;
  --flex-shrink: 1;
  --container-widget-width: 100%;
  --container-widget-height: initial;
  --container-widget-flex-grow: 0;
  --container-widget-align-self: initial;
  --content-width: Min(100%, var(--container-max-width, 1140px));
  --width: 100%;
  --min-height: initial;
  --height: auto;
  --text-align: initial;
  --margin-top: 0;
  --margin-right: 0;
  --margin-bottom: 0;
  --margin-left: 0;
  --padding-top: var(--container-default-padding-top, 10px);
  --padding-right: var(--container-default-padding-right, 10px);
  --padding-bottom: var(--container-default-padding-bottom, 10px);
  --padding-left: var(--container-default-padding-left, 10px);
  --position: relative;
  --z-index: revert;
  --overflow: visible;
  --gap: var(--widgets-spacing, 20px);
  --overlay-mix-blend-mode: initial;
  --overlay-opacity: 1;
  --overlay-transition: 0.3s;
  position: var(--position);
  flex: var(--flex-grow) var(--flex-shrink) var(--flex-basis);
  width: var(--width);
  min-width: 0;
  min-height: var(--min-height);
  height: var(--height);
  border-radius: var(--border-radius);
  margin: var(--margin-top) var(--margin-right) var(--margin-bottom) var(--margin-left);
  padding-left: var(--padding-left);
  padding-right: var(--padding-right);
  z-index: var(--z-index);
  overflow: var(--overflow);
  transition: background var(--background-transition, .3s), border var(--border-transition, .3s), box-shadow var(--border-transition, .3s), transform var(--e-con-transform-transition-duration, .4s)
}

.e-con>.e-con-inner {
  flex-direction: var(--flex-direction);
  text-align: var(--text-align);
  padding-top: var(--padding-top);
  padding-bottom: var(--padding-bottom)
}

.e-con,
.e-con>.e-con-inner {
  display: var(--display)
}

.e-con>.e-con-inner {
  flex-wrap: var(--flex-wrap);
  justify-content: var(--justify-content);
  align-items: var(--align-items);
  align-content: var(--align-content);
  gap: var(--gap);
  width: 100%;
  max-width: var(--content-width);
  margin: 0 auto;
  padding-inline: 0;
  height: 100%;
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
  align-self: auto
}

:is(.elementor-section-wrap, [data-elementor-id])>.e-con {
  --margin-right: auto;
  --margin-left: auto;
  max-width: min(100%, var(--width))
}

.e-con .elementor-widget.elementor-widget {
  margin-bottom: 0
}

.e-con:before,
.e-con>.e-con-inner>.elementor-background-slideshow:before,
.e-con>.e-con-inner>.elementor-background-video-container:before,
.e-con>.elementor-background-slideshow:before,
.e-con>.elementor-background-video-container:before,
.e-con>.elementor-motion-effects-container>.elementor-motion-effects-layer:before {
  content: var(--background-overlay);
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  mix-blend-mode: var(--overlay-mix-blend-mode);
  opacity: var(--overlay-opacity);
  transition: var(--overlay-transition, .3s);
  border-radius: var(--border-radius)
}

.e-con:before {
  transition: background var(--overlay-transition, .3s), border-radius var(--border-transition, .3s), opacity var(--overlay-transition, .3s)
}

.e-con>.e-con-inner>.elementor-background-video-container:before,
.e-con>.elementor-background-video-container:before {
  z-index: 1
}

.e-con>.e-con-inner>.elementor-background-slideshow:before,
.e-con>.elementor-background-slideshow:before {
  z-index: 2
}

.e-con .elementor-widget {
  min-width: 0
}

.e-con .elementor-widget.e-widget-swiper {
  width: 100%
}

.e-con>.e-con-inner>.elementor-widget>.elementor-widget-container,
.e-con>.elementor-widget>.elementor-widget-container {
  height: 100%
}

.e-con.e-con>.e-con-inner>.elementor-widget,
.elementor.elementor .e-con>.elementor-widget {
  max-width: 100%
}

@media (max-width:767px) {
  .e-con {
    --width: 100%;
    --flex-wrap: wrap
  }
}

.elementor-element .elementor-widget-container {
  transition: background .3s, border .3s, border-radius .3s, box-shadow .3s, transform var(--e-transform-transition-duration, .4s)
}

.elementor-button {
  display: inline-block;
  line-height: 1;
  background-color: #69727d;
  font-size: 15px;
  padding: 12px 24px;
  border-radius: 3px;
  color: #fff;
  fill: #fff;
  text-align: center;
  transition: all .3s
}

.elementor-button:focus,
.elementor-button:hover,
.elementor-button:visited {
  color: #fff
}

.elementor-button-content-wrapper {
  display: flex;
  justify-content: center
}

.elementor-button-icon {
  flex-grow: 0;
  order: 5
}

.elementor-button-icon svg {
  width: 1em;
  height: auto
}

.elementor-button-icon .e-font-icon-svg {
  height: 1em
}

.elementor-button-text {
  flex-grow: 1;
  order: 10;
  display: inline-block
}

.elementor-button.elementor-size-lg {
  font-size: 18px;
  padding: 20px 40px;
  border-radius: 5px
}

.elementor-button.elementor-size-xl {
  font-size: 20px;
  padding: 25px 50px;
  border-radius: 6px
}

.elementor-button .elementor-align-icon-right {
  margin-left: 5px;
  order: 15
}

.elementor-button span {
  text-decoration: inherit
}

.elementor-view-stacked .elementor-icon {
  padding: .5em;
  background-color: #69727d;
  color: #fff;
  fill: #fff
}

.elementor-icon {
  display: inline-block;
  line-height: 1;
  transition: all .3s;
  color: #69727d;
  font-size: 50px;
  text-align: center
}

.elementor-icon:hover {
  color: #69727d
}

.elementor-icon i,
.elementor-icon svg {
  width: 1em;
  height: 1em;
  position: relative;
  display: block
}

.elementor-icon i:before,
.elementor-icon svg:before {
  position: absolute;
  left: 50%;
  transform: translateX(-50%)
}

.elementor-shape-circle .elementor-icon {
  border-radius: 50%
}

.elementor-element {
  --swiper-theme-color: #000;
  --swiper-navigation-size: 44px;
  --swiper-pagination-bullet-size: 6px;
  --swiper-pagination-bullet-horizontal-gap: 6px
}

.elementor-element .swiper .swiper-slide figure {
  line-height: 0
}

.elementor-lightbox .dialog-header {
  display: none
}

.elementor-lightbox .dialog-widget-content {
  background: 0 0;
  box-shadow: none;
  width: 100%;
  height: 100%
}

.elementor-lightbox .dialog-message {
  animation-duration: .3s
}

.elementor-lightbox .dialog-message:not(.elementor-fit-aspect-ratio) {
  height: 100%
}

.elementor-lightbox .dialog-message.dialog-lightbox-message {
  padding: 0
}

.elementor-lightbox .dialog-lightbox-close-button {
  cursor: pointer;
  position: absolute;
  font-size: var(--lightbox-header-icons-size);
  right: .75em;
  margin-top: 13px;
  padding: .25em;
  z-index: 2;
  line-height: 1;
  display: flex
}

.elementor-lightbox .dialog-lightbox-close-button svg {
  height: 1em;
  width: 1em
}

.elementor-lightbox .dialog-lightbox-close-button {
  color: var(--lightbox-ui-color);
  transition: all .3s;
  opacity: 1
}

.elementor-lightbox .dialog-lightbox-close-button svg {
  fill: var(--lightbox-ui-color)
}

.elementor-lightbox .dialog-lightbox-close-button:hover {
  color: var(--lightbox-ui-color-hover)
}

.elementor-lightbox .dialog-lightbox-close-button:hover svg {
  fill: var(--lightbox-ui-color-hover)
}

.animated {
  animation-duration: 1.25s
}

.animated.animated-slow {
  animation-duration: 2s
}

.animated.infinite {
  animation-iteration-count: infinite
}

.animated.reverse {
  animation-direction: reverse;
  animation-fill-mode: forwards
}

@media (prefers-reduced-motion:reduce) {
  .animated {
    animation: none
  }
}

.elementor-shape {
  overflow: hidden;
  position: absolute;
  left: 0;
  width: 100%;
  line-height: 0;
  direction: ltr
}

.elementor-shape-top {
  top: -1px
}

.elementor-shape-top:not([data-negative=false]) svg {
  z-index: -1
}

.elementor-shape-bottom {
  bottom: -1px
}

.elementor-shape-bottom:not([data-negative=true]) svg {
  z-index: -1
}

.elementor-shape[data-negative=false].elementor-shape-bottom,
.elementor-shape[data-negative=true].elementor-shape-top {
  transform: rotate(180deg)
}

.elementor-shape svg {
  display: block;
  width: calc(100% + 1.3px);
  position: relative;
  left: 50%;
  transform: translateX(-50%)
}

.elementor-shape .elementor-shape-fill {
  fill: #fff;
  transform-origin: center;
  transform: rotateY(0)
}

#wpadminbar * {
  font-style: normal
}

.elementor-post__thumbnail__link {
  transition: none
}

.elementor .elementor-element ul.elementor-icon-list-items,
.elementor-edit-area .elementor-element ul.elementor-icon-list-items {
  padding: 0
}

@media (max-width:767px) {

  .elementor .elementor-hidden-mobile,
  .elementor .elementor-hidden-phone {
    display: none
  }
}

@media (min-width:768px) and (max-width:1024px) {
  .elementor-lightbox .elementor-aspect-ratio-916 .elementor-video-container {
    width: 70%
  }

  .elementor .elementor-hidden-tablet {
    display: none
  }
}

@media (min-width:1025px) and (max-width:99999px) {
  .elementor .elementor-hidden-desktop {
    display: none
  }
}

:is(.elementor .elementor-element.elementor-widget-n-tabs>.elementor-widget-container>.e-n-tabs>.e-n-tabs-heading .e-n-tab-title, .elementor .elementor-element.elementor-widget-n-tabs>.elementor-widget-container>.e-n-tabs>.e-n-tabs-content .e-n-tab-title).e-active {
  background-color: var(--n-tabs-title-background-color-active);
  background-image: none
}

.clearfix::after,
.clearfix::before {
  content: " ";
  display: table;
  clear: both
}

.elementor-lightbox .dialog-widget-content {
  width: 100%;
  height: 100%
}

.elementor-widget-eael-testimonial-slider .eael-testimonial-slider {
  position: relative
}

.elementor-widget-eael-testimonial-slider .eael-testimonial-slider .swiper-container {
  width: 100%
}

.elementor-widget-eael-testimonial-slider .eael-testimonial-slider .swiper-container .swiper-button-next:after,
.elementor-widget-eael-testimonial-slider .eael-testimonial-slider .swiper-container .swiper-button-prev:after {
  content: none
}

.elementor-widget-eael-testimonial-slider .testimonial-star-rating {
  padding: 0;
  margin: 0
}

.elementor-widget-eael-testimonial-slider .testimonial-star-rating li {
  list-style: none;
  display: inline-block
}

.elementor-widget-eael-testimonial-slider .testimonial-star-rating li i {
  color: #d8d8d8
}

.elementor-widget-eael-testimonial-slider .rating-five .testimonial-star-rating li i {
  color: #f2b01e
}

.elementor-widget-eael-testimonial-slider .eael-testimonial-text-full-text {
  display: none
}

.elementor-widget-eael-testimonial-slider .eael-testimonial-text-excerpt .eael-testimonial-read-more-btn {
  border: none;
  background: 0 0;
  color: #333dcc;
  padding: 0;
  margin: 0
}

.elementor-widget-eael-testimonial-slider .eael-testimonial-text-excerpt .eael-testimonial-read-more-btn:hover {
  color: #333dcc
}

.elementor-widget-eael-testimonial-slider .eael-testimonial-image img {
  max-width: 100%
}

.elementor-widget-eael-testimonial-slider .eael-testimonial-image {
  padding: 10px 0 0
}

.elementor-widget-eael-testimonial-slider .eael-testimonial-image>figure {
  display: block;
  margin: 0
}

.elementor-widget-eael-testimonial-slider .testimonial-avatar-rounded figure img {
  border-radius: 50%;
  height: auto
}

.elementor-widget-eael-testimonial-slider .eael-testimonial-content {
  margin: 0;
  padding: 10px
}

.elementor-widget-eael-testimonial-slider .eael-testimonial-item>figure {
  margin: 0 auto;
  padding: 0 20px
}

.elementor-widget-eael-testimonial-slider .eael-testimonial-content .eael-testimonial-text {
  font-size: 100%;
  font-style: italic;
  line-height: 1.5;
  margin-top: 0;
  margin-bottom: 10px;
  word-wrap: break-word
}

.elementor-widget-eael-testimonial-slider .eael-testimonial-content .eael-testimonial-user {
  display: inline-block;
  font-family: inherit;
  font-size: 105%;
  font-style: italic;
  font-weight: 400;
  line-height: 1.5;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 5px
}

.elementor-widget-eael-testimonial-slider .eael-testimonial-content .eael-testimonial-user-company {
  display: inline-block;
  font-family: inherit;
  font-size: 90%;
  font-style: italic;
  font-weight: lighter;
  line-height: 1.75;
  margin-bottom: 0;
  margin-top: 1px
}

.elementor-widget-eael-testimonial-slider .eael-testimonial-image {
  position: relative
}

.elementor-widget-eael-testimonial-slider .eael-testimonial-item .eael-testimonial-quote::after {
  color: inherit !important;
  content: "";
  font-family: "Font Awesome 5 Free";
  font-size: 200%;
  font-weight: 900
}

.elementor-widget-eael-testimonial-slider .eael-testimonial-slider.default-style .eael-testimonial-align-center .default-style-testimonial-content {
  text-align: center
}

.elementor-widget-eael-testimonial-slider .eael-testimonial-align-center .eael-testimonial-user,
.elementor-widget-eael-testimonial-slider .eael-testimonial-align-center .eael-testimonial-user-company {
  float: none;
  text-align: center
}

.elementor-widget-eael-testimonial-slider .default-style .eael-testimonial-item.eael-testimonial-align-center {
  text-align: center
}

.elementor-widget-eael-testimonial-slider .eael-testimonial-align-center .eael-testimonial-image,
.elementor-widget-eael-testimonial-slider .eael-testimonial-image {
  margin: 0 auto
}

.elementor-widget-eael-testimonial-slider .eael-testimonial-align-center .eael-testimonial-image {
  display: block
}

.elementor-widget-eael-testimonial-slider .eael-testimonial-align-center .eael-testimonial-image {
  margin: 0 auto
}

.elementor-widget-eael-testimonial-slider .eael-testimonial-item {
  position: relative
}

.elementor-widget-eael-testimonial-slider span.eael-testimonial-quote {
  position: absolute;
  right: 10px;
  top: 10px
}

.elementor-widget-eael-testimonial-slider .eael-testimonial-item.left .eael-testimonial-image {
  text-align: left
}

.elementor-widget-eael-testimonial-slider .eael-testimonial-item.center .eael-testimonial-image {
  text-align: center
}

.elementor-widget-eael-testimonial-slider .eael-testimonial-item.right .eael-testimonial-image {
  text-align: right
}

.elementor-widget-eael-testimonial-slider .eael-testimonial-slider-main.swiper-container .swiper-button-next,
.elementor-widget-eael-testimonial-slider .eael-testimonial-slider-main.swiper-container .swiper-button-prev {
  background-image: none;
  outline: 0
}

.elementor-widget-eael-testimonial-slider .swiper-container-wrap .swiper-button-next,
.elementor-widget-eael-testimonial-slider .swiper-container-wrap .swiper-button-prev {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.elementor-widget-eael-testimonial-slider .eael-testimonial-gallary-pagination {
  width: 350px !important;
  height: 100px !important;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important
}

.elementor-widget-eael-testimonial-slider .eael-testimonial-gallary-pagination .swiper-slide {
  width: 100px !important;
  opacity: .4;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.elementor-widget-eael-testimonial-slider .eael-testimonial-gallary-pagination .swiper-slide-active {
  opacity: 1
}

.elementor-widget-eael-testimonial-slider .swiper-container-wrap-dots-outside .swiper-pagination {
  position: static
}

.jet-elements select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%
}

.jet-elements-icon {
  display: inline-block;
  line-height: 1;
  text-align: center
}

.jet-elements-icon svg {
  display: block;
  width: 1em;
  height: 1em;
  fill: currentColor
}

.jet-elements .tippy-box[data-animation=scale][data-placement*=top] {
  -webkit-transform-origin: bottom;
  -ms-transform-origin: bottom;
  transform-origin: bottom
}

.jet-elements .tippy-box[data-animation=scale][data-placement*=bottom] {
  -webkit-transform-origin: top;
  -ms-transform-origin: top;
  transform-origin: top
}

.jet-elements .tippy-box[data-animation=scale][data-placement*=left] {
  -webkit-transform-origin: right;
  -ms-transform-origin: right;
  transform-origin: right
}

.jet-elements .tippy-box[data-animation=scale][data-placement*=right] {
  -webkit-transform-origin: left;
  -ms-transform-origin: left;
  transform-origin: left
}

.jet-elements .tippy-box[data-animation=scale][data-state=hidden] {
  -webkit-transform: scale(.5);
  -ms-transform: scale(.5);
  transform: scale(.5);
  opacity: 0
}

.jet-elements .tippy-box[data-animation=perspective][data-placement^=top] {
  -webkit-transform-origin: bottom;
  -ms-transform-origin: bottom;
  transform-origin: bottom
}

.jet-elements .tippy-box[data-animation=perspective][data-placement^=top][data-state=visible] {
  -webkit-transform: perspective(700px);
  transform: perspective(700px)
}

.jet-elements .tippy-box[data-animation=perspective][data-placement^=top][data-state=hidden] {
  -webkit-transform: perspective(700px) translateY(8px) rotateX(60deg);
  transform: perspective(700px) translateY(8px) rotateX(60deg)
}

.jet-elements .tippy-box[data-animation=perspective][data-placement^=bottom] {
  -webkit-transform-origin: top;
  -ms-transform-origin: top;
  transform-origin: top
}

.jet-elements .tippy-box[data-animation=perspective][data-placement^=bottom][data-state=visible] {
  -webkit-transform: perspective(700px);
  transform: perspective(700px)
}

.jet-elements .tippy-box[data-animation=perspective][data-placement^=bottom][data-state=hidden] {
  -webkit-transform: perspective(700px) translateY(-8px) rotateX(-60deg);
  transform: perspective(700px) translateY(-8px) rotateX(-60deg)
}

.jet-elements .tippy-box[data-animation=perspective][data-placement^=left] {
  -webkit-transform-origin: right;
  -ms-transform-origin: right;
  transform-origin: right
}

.jet-elements .tippy-box[data-animation=perspective][data-placement^=left][data-state=visible] {
  -webkit-transform: perspective(700px);
  transform: perspective(700px)
}

.jet-elements .tippy-box[data-animation=perspective][data-placement^=left][data-state=hidden] {
  -webkit-transform: perspective(700px) translateX(8px) rotateY(-60deg);
  transform: perspective(700px) translateX(8px) rotateY(-60deg)
}

.jet-elements .tippy-box[data-animation=perspective][data-placement^=right] {
  -webkit-transform-origin: left;
  -ms-transform-origin: left;
  transform-origin: left
}

.jet-elements .tippy-box[data-animation=perspective][data-placement^=right][data-state=visible] {
  -webkit-transform: perspective(700px);
  transform: perspective(700px)
}

.jet-elements .tippy-box[data-animation=perspective][data-placement^=right][data-state=hidden] {
  -webkit-transform: perspective(700px) translateX(-8px) rotateY(60deg);
  transform: perspective(700px) translateX(-8px) rotateY(60deg)
}

.jet-elements .tippy-box[data-animation=perspective][data-state=hidden] {
  opacity: 0
}

.jet-elements .tippy-box[data-animation=shift-away][data-state=hidden] {
  opacity: 0
}

.jet-elements .tippy-box[data-animation=shift-away][data-state=hidden][data-placement^=top] {
  -webkit-transform: translateY(10px);
  -ms-transform: translateY(10px);
  transform: translateY(10px)
}

.jet-elements .tippy-box[data-animation=shift-away][data-state=hidden][data-placement^=bottom] {
  -webkit-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  transform: translateY(-10px)
}

.jet-elements .tippy-box[data-animation=shift-away][data-state=hidden][data-placement^=left] {
  -webkit-transform: translateX(10px);
  -ms-transform: translateX(10px);
  transform: translateX(10px)
}

.jet-elements .tippy-box[data-animation=shift-away][data-state=hidden][data-placement^=right] {
  -webkit-transform: translateX(-10px);
  -ms-transform: translateX(-10px);
  transform: translateX(-10px)
}

.slick-list {
  position: relative;
  display: block
}

.slick-list {
  overflow: hidden;
  margin: 0;
  padding: 0
}

.slick-list:focus {
  outline: 0
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block
}

.slick-track:after,
.slick-track:before {
  content: "";
  display: table
}

.slick-track:after {
  clear: both
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none
}

.slick-slide img {
  display: block;
  margin: auto
}

.jet-animated-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.jet-animated-text__animated-text {
  position: relative;
  -ms-flex-item-align: auto;
  align-self: auto;
  -webkit-transition: width .3s;
  -o-transition: width .3s;
  transition: width .3s ease
}

.jet-animated-text__animated-text-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  visibility: hidden;
  z-index: 1
}

.jet-animated-text__animated-text-item.active {
  position: relative;
  z-index: 2
}

.elementor-widget:not(.elementor-invisible) .jet-animated-text__animated-text-item.visible {
  visibility: visible
}

.elementor-sticky.elementor-sticky__spacer .jet-animated-text__animated-text-item.visible {
  visibility: hidden
}

.jet-animated-text__animated-text-item span {
  position: relative;
  -ms-flex-item-align: auto;
  align-self: auto
}

.jet-animated-box {
  position: relative
}

.jet-animated-box__toggle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  z-index: 3;
  top: 10px;
  right: 10px;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: all .3s ease
}

.jet-animated-box__back,
.jet-animated-box__front {
  position: absolute;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch
}

.jet-animated-box__front {
  background-color: #6ec1e4
}

.jet-animated-box__back {
  background-color: #54595f
}

.jet-carousel {
  position: relative;
  margin-left: -10px;
  margin-right: -10px
}

.jet-carousel .jet-arrow {
  position: absolute;
  top: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  z-index: 999;
  -webkit-transition: .2s linear;
  -o-transition: .2s linear;
  transition: all .2s linear
}

.jet-carousel .jet-arrow svg {
  width: 1em;
  height: 1em
}

.jet-carousel .jet-arrow:before {
  display: block;
  width: 100%;
  text-align: center;
  line-height: 0;
  -webkit-transition: .2s linear;
  -o-transition: .2s linear;
  transition: all .2s linear
}

.jet-carousel .jet-slick-dots {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  z-index: 99;
  padding: 0;
  margin-left: 0
}

.jet-carousel .jet-slick-dots li {
  padding: 5px;
  margin: 0;
  list-style: none
}

.jet-carousel .jet-slick-dots li span {
  -webkit-transition: .2s linear;
  -o-transition: .2s linear;
  transition: all .2s linear;
  display: block;
  cursor: pointer;
  width: 10px;
  height: 10px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  padding: 0;
  text-indent: 10em;
  overflow: hidden
}

.jet-carousel .jet-slick-dots li span:before {
  display: none
}

.jet-carousel__fraction-navigation {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  padding: 0 10px
}

.circle-progress {
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg)
}

.circle-progress-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  position: relative
}

.circle-progress__meter,
.circle-progress__value {
  -webkit-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-timing-function: cubic-bezier(0.28, 0.77, 0.39, 0.95);
  -o-transition-timing-function: cubic-bezier(0.28, 0.77, 0.39, 0.95);
  transition-timing-function: cubic-bezier(0.28, 0.77, 0.39, 0.95);
  fill: none;
  stroke-linecap: butt
}

.jet-countdown-timer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.jet-countdown-timer__digit {
  display: inline-block
}

.jet-countdown-timer-message {
  display: none;
  text-align: center
}

.jet-posts__item {
  position: relative;
  min-height: 1px;
  padding: 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  outline: 0
}

.jet-posts__item.slick-slide {
  width: 100%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%
}

.jet-posts .slick-list {
  width: 100%
}

.pricing-table {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap
}

.pricing-table>* {
  width: 100%
}

.pricing-table__fold-mask {
  overflow: hidden
}

.pricing-table__fold-mask:not(.pricing-table-unfold-state) {
  max-height: 0
}

.pricing-table__fold-button {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.pricing-table__fold-button-icon,
.pricing-table__fold-button-text {
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: all .3s
}

.pricing-feature:not(:first-child):before {
  content: "";
  display: block;
  height: 0;
  margin-left: auto;
  margin-right: auto
}

.pricing-feature:last-child {
  margin-bottom: 0 !important
}

.jet-images-layout {
  position: relative
}

.jet-images-layout__item {
  overflow: hidden;
  position: relative;
  -webkit-transform: translateZ(0);
  transform: translateZ(0)
}

.jet-images-layout__image {
  position: relative;
  line-height: 0
}

.jet-images-layout__image-instance {
  width: 100%;
  min-width: 100px;
  min-height: 100px
}

.jet-images-layout__image-loader {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, .3);
  z-index: 1
}

.jet-images-layout__image-loader span {
  width: 30px;
  height: 30px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  -webkit-box-shadow: 2px 2px 1px #fff;
  box-shadow: 2px 2px 1px #fff;
  -webkit-animation: 690ms linear infinite jet-images-layout-load-spin;
  animation: 690ms linear infinite jet-images-layout-load-spin
}

@-webkit-keyframes jet-images-layout-load-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@keyframes jet-images-layout-load-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

.jet-slider .slider-pro {
  visibility: hidden
}

.elementor-section.elementor-invisible .jet-slider .slider-pro {
  visibility: hidden
}

.jet-slider .slider-pro.slider-loaded {
  visibility: visible
}

.jet-slider__item {
  overflow: hidden
}

.jet-slider__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.jet-slider .sp-full-screen-button {
  color: #fff
}

.jet-slider .sp-full-screen-button svg {
  width: 1em;
  height: 1em
}

.jet-slider .sp-full-screen-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
  -webkit-transform-origin: 50%;
  -ms-transform-origin: 50%;
  transform-origin: 50%;
  top: 10px;
  right: 10px
}

.jet-slider .sp-full-screen-button:before {
  display: none
}

.jet-slider__fraction-pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  padding: 0
}

.jet-testimonials__instance {
  width: 100%;
  position: relative
}

.jet-testimonials__instance:not(.slick-initialized) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  visibility: hidden
}

.jet-testimonials__item {
  outline: 0
}

.jet-testimonials__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.jet-testimonials__figure {
  position: relative;
  overflow: hidden
}

.jet-testimonials__rating {
  color: #ffe435
}

.jet-testimonials__rating i {
  color: #c1c1c1
}

.jet-image-comparison__instance {
  width: 100%;
  position: relative;
  overflow: hidden
}

.jet-image-comparison__instance:not(.slick-initialized) {
  display: none
}

.jet-scroll-navigation {
  position: fixed;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 999;
  overflow: visible !important
}

.jet-scroll-navigation__item {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: visible !important
}

.jet-subscribe-form {
  max-width: 100%
}

.jet-subscribe-form__fields {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch
}

.jet-subscribe-form__submit {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  position: relative;
  max-width: 100%
}

.jet-subscribe-form__submit i {
  visibility: visible;
  -webkit-transition: inherit;
  -o-transition: inherit;
  transition: inherit
}

.jet-subscribe-form__submit span {
  visibility: visible
}

.jet-subscribe-form__submit:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  visibility: hidden;
  top: 50%;
  left: 50%;
  width: 26px;
  height: 26px;
  margin-top: -13px;
  margin-left: -13px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border: 3px solid #fff;
  border-top-color: #000;
  content: '';
  -webkit-animation: .6s linear infinite jet-spinner;
  animation: .6s linear infinite jet-spinner
}

.jet-subscribe-form__submit.loading i {
  visibility: hidden
}

.jet-subscribe-form__submit.loading span {
  visibility: hidden
}

.jet-subscribe-form__submit.loading:before {
  visibility: visible
}

.jet-subscribe-form__message {
  position: absolute;
  top: 120%;
  width: 100%;
  visibility: hidden
}

@-webkit-keyframes jet-spinner {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@keyframes jet-spinner {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

.jet-parallax-section__layout {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  pointer-events: none;
  -webkit-perspective: 1200px;
  perspective: 1200px
}

.jet-parallax-section__image {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  -webkit-background-size: auto auto;
  background-size: auto;
  background-position-x: 50%;
  background-position-y: 50%;
  will-change: transform
}

.jet-progress-bar__status-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  will-change: width, height;
  -webkit-transition: width 1s cubic-bezier(.6, .17, .37, .86), height 1s cubic-bezier(.6, .17, .37, .86);
  -o-transition: width 1s cubic-bezier(.6, .17, .37, .86), height 1s cubic-bezier(.6, .17, .37, .86);
  transition: width 1s cubic-bezier(.6, .17, .37, .86), height 1s cubic-bezier(.6, .17, .37, .86);
  -webkit-transform: translateZ(0);
  transform: translateZ(0)
}

.jet-progress-bar__percent-value {
  line-height: 1
}

.jet-portfolio {
  position: relative
}

.jet-portfolio__filter-item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer
}

.jet-portfolio__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition: height .5s cubic-bezier(.33, .79, .37, .97);
  -o-transition: height .5s cubic-bezier(.33, .79, .37, .97);
  transition: height .5s cubic-bezier(.33, .79, .37, .97)
}

.jet-portfolio__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  position: relative;
  -webkit-transform: translateZ(0);
  transform: translateZ(0)
}

.jet-portfolio__item.hidden-status {
  height: 0;
  visibility: hidden
}

.jet-portfolio__item.hidden-status .jet-portfolio__inner {
  opacity: 0;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0)
}

.jet-portfolio__item.visible-status {
  height: auto;
  visibility: visible
}

.jet-portfolio__item.visible-status .jet-portfolio__inner {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1)
}

.jet-portfolio__inner {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 100%;
  flex: 0 1 100%;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  position: relative;
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%
}

.jet-portfolio__link {
  display: block
}

.jet-portfolio__image {
  position: relative;
  line-height: 0
}

.jet-portfolio__image-loader {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(84, 89, 95, .75);
  z-index: 1
}

.jet-portfolio__image-loader span {
  width: 30px;
  height: 30px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  -webkit-box-shadow: 2px 2px 1px #fff;
  box-shadow: 2px 2px 1px #fff;
  -webkit-animation: 690ms linear infinite jet-portfolio-load-spin;
  animation: 690ms linear infinite jet-portfolio-load-spin
}

.jet-portfolio__view-more {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

.jet-portfolio__view-more.hidden-status {
  display: none
}

.jet-portfolio__view-more-button {
  cursor: pointer
}

.jet-portfolio .grid-sizer {
  width: 25%;
  height: 0
}

@-webkit-keyframes jet-portfolio-load-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@keyframes jet-portfolio-load-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

.jet-timeline {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 100%;
  margin: 0 auto
}

.jet-timeline-item {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap
}

.jet-timeline__line {
  position: absolute;
  width: 2px;
  bottom: 0;
  top: 0;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  left: 50%;
  overflow: hidden
}

.jet-timeline__line-progress {
  width: 100%;
  height: 20%
}

.jet-inline-svg {
  display: inline-block
}

.jet-inline-svg svg {
  min-width: 20px
}

.jet-inline-svg svg>circle:not([stroke]),
.jet-inline-svg svg>path:not([stroke]),
.jet-inline-svg svg>rect:not([stroke]) {
  stroke: none
}

.jet-inline-svg svg>circle:not([stroke-width]),
.jet-inline-svg svg>path:not([stroke-width]),
.jet-inline-svg svg>rect:not([stroke-width]) {
  stroke-width: 2px
}

.jet-inline-svg svg>circle:not([stroke-linecap]),
.jet-inline-svg svg>path:not([stroke-linecap]),
.jet-inline-svg svg>rect:not([stroke-linecap]) {
  stroke-linecap: butt
}

.jet-inline-svg svg>circle:not([stroke-dasharray]),
.jet-inline-svg svg>path:not([stroke-dasharray]),
.jet-inline-svg svg>rect:not([stroke-dasharray]) {
  stroke-dasharray: none
}

.jet-inline-svg svg>circle:not([stroke-miterlimit]),
.jet-inline-svg svg>path:not([stroke-miterlimit]),
.jet-inline-svg svg>rect:not([stroke-miterlimit]) {
  stroke-miterlimit: 4
}

.jet-inline-svg--custom-width {
  width: 100%
}

.jet-inline-svg--custom-width svg {
  width: 100%;
  height: auto;
  display: block
}

.jet-table {
  width: 100%;
  max-width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  background-color: transparent;
  border: none
}

.jet-table .jet-table__cell {
  padding: 0;
  border: 1px
}

.jet-table__cell {
  -webkit-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
  overflow: hidden
}

.jet-table-header-sort {
  cursor: pointer
}

.jet-dropbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

.jet-dropbar__inner {
  position: relative
}

.jet-dropbar__button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  max-width: 100%;
  white-space: normal;
  border: 1px;
  overflow: hidden
}

.jet-dropbar__button img {
  margin: 0 auto
}

.jet-dropbar__content {
  position: relative;
  padding: 10px;
  background-color: #f7f7fb
}

.jet-video {
  position: relative;
  z-index: 1;
  overflow: hidden;
  background-color: #000
}

.jet-video__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center
}

.jet-video__overlay:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4
}

.jet-video__overlay--custom-bg {
  z-index: 3
}

.jet-video__play-button {
  z-index: 5;
  font-size: 80px;
  line-height: 1;
  text-align: center;
  color: #fff;
  cursor: pointer;
  -webkit-transition: .2s;
  -o-transition: .2s;
  transition: .2s
}

.jet-video__play-button-icon {
  width: 1em;
  vertical-align: top
}

.jet-video.jet-video-aspect-ratio--4-3 {
  padding-bottom: 75%
}

.jet-video.jet-video-aspect-ratio iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  border: none
}

.jet-video>video.jet-video-mejs-player {
  opacity: 0
}

.jet-audio>audio {
  display: none
}

.jet-hor-timeline {
  position: relative
}

.jet-hor-timeline--arrows-nav .jet-hor-timeline-track {
  -webkit-transition: -webkit-transform .5s;
  transition: -webkit-transform .5s ease;
  -o-transition: transform .5s;
  transition: transform .5s ease;
  transition: transform .5s ease, -webkit-transform .5s ease
}

.jet-hor-timeline-list--middle {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end
}

.jet-hor-timeline-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding-left: 15px;
  padding-right: 15px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.33%;
  flex: 0 0 33.33%;
  max-width: 33.33%;
  -webkit-transition: .2s;
  -o-transition: .2s;
  transition: .2s
}

.jet-hor-timeline__line {
  position: absolute;
  top: 50%;
  height: 2px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  overflow: hidden;
  background-color: #efeff1
}

.jet-hor-timeline .jet-arrow {
  position: absolute;
  top: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  z-index: 999;
  -webkit-transition: .2s linear;
  -o-transition: .2s linear;
  transition: all .2s linear;
  width: 36px;
  height: 36px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  font-size: 30px;
  line-height: 1;
  background-color: #55cdff;
  color: #fff
}

.jet-hor-timeline .jet-arrow:before {
  line-height: 0
}

.jet-hor-timeline .jet-arrow.jet-prev-arrow {
  left: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%)
}

.jet-hor-timeline .jet-arrow.jet-next-arrow {
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%)
}

.jet-hor-timeline .jet-arrow.jet-arrow-disabled {
  opacity: .5;
  cursor: default;
  pointer-events: none
}

.jet-bar-chart-container,
.jet-line-chart-container,
.jet-pie-chart-container {
  position: relative;
  height: 400px;
  max-width: 100%
}

.jet-line-chart-container {
  width: 100%
}

.jet-line-chart-tooltip-title {
  color: #fff;
  font-size: 12px
}

.jet-line-chart-tooltip-title+.jet-line-chart-tooltip-body {
  display: block
}

.jet-line-chart-tooltip-body {
  color: #fff;
  font-size: 10px;
  line-height: 1
}

.jet-line-chart-tooltip-body+.jet-line-chart-tooltip-body {
  margin-top: 5px
}

.jet-line-chart-tooltip-body,
.jet-line-chart-tooltip-compare-current,
.jet-line-chart-tooltip-compare-previous,
.jet-line-chart-tooltip-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.jet-lottie {
  display: inline-block
}

.pricing-table {
  text-align: center;
  padding: 30px
}

.jet-animated-box {
  height: 250px
}

.jet-animated-box .jet-animated-box__toggle {
  cursor: pointer;
  width: 30px;
  height: 30px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  color: #fff;
  background-color: #848484;
  -webkit-box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, .2);
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, .2)
}

.jet-animated-text__animated-text {
  color: #6ec1e4;
  font-size: 36px
}

.jet-carousel .jet-arrow {
  width: 36px;
  height: 36px;
  -webkit-border-radius: 18px;
  border-radius: 18px;
  margin-top: -18px;
  color: #fff;
  font-size: 23px
}

.jet-carousel .jet-slick-dots li:hover span {
  background-color: #6ec1e4
}

.jet-carousel .jet-slick-dots li span {
  background-color: #7a7a7a
}

.jet-slider {
  position: relative
}

.jet-slider .sp-full-screen-button {
  font-size: 16px;
  color: #fff
}

.jet-scroll-navigation__item {
  margin: 10px 0
}

.jet-subscribe-form .jet-subscribe-form__submit {
  background-color: #6ec1e4
}

.jet-subscribe-form--response-error .jet-subscribe-form__message {
  color: #d9534f
}

.jet-progress-bar__status-bar {
  height: 100%;
  background-color: #6ec1e4;
  -webkit-border-radius: 2px;
  border-radius: 2px
}

.jet-portfolio .jet-portfolio__filter-item {
  padding: 0 10px;
  margin: 0 5px;
  color: #54595f
}

.jet-portfolio .jet-portfolio__filter-item.active {
  color: #fff;
  background-color: #6ec1e4
}

.jet-portfolio .jet-portfolio__view-more {
  margin-top: 30px
}

.jet-portfolio .jet-portfolio__view-more-button {
  background-color: #6ec1e4;
  color: #fff
}

.jet-timeline__line {
  background-color: #e8e8f6
}

.jet-timeline__line-progress {
  background-color: #55cdff
}

.swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  z-index: 1
}

.swiper-container .swiper-slide figure {
  line-height: 0
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box
}

.swiper-wrapper {
  transform: translateZ(0)
}

.swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative
}

.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
  height: auto
}

.swiper-container-autoheight .swiper-wrapper {
  align-items: flex-start;
  transition-property: transform, height
}

.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000
}

.swiper-button-next,
.swiper-button-prev {
  position: absolute;
  top: 50%;
  width: 27px;
  height: 44px;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
  background-size: 27px 44px;
  background: 50% no-repeat
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  opacity: .35;
  cursor: auto;
  pointer-events: none
}

.swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M0 22L22 0l2.1 2.1L4.2 22l19.9 19.9L22 44 0 22z' fill='%23007aff'/%3E%3C/svg%3E");
  left: 10px;
  right: auto
}

.swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M27 22L5 44l-2.1-2.1L22.8 22 2.9 2.1 5 0l22 22z' fill='%23007aff'/%3E%3C/svg%3E");
  right: 10px;
  left: auto
}

.swiper-pagination {
  position: absolute;
  text-align: center;
  transition: .3s;
  transform: translateZ(0);
  z-index: 10
}

.swiper-pagination.swiper-pagination-hidden {
  opacity: 0
}

.swiper-container-horizontal>.swiper-pagination-bullets {
  bottom: 5px;
  left: 0;
  width: 100%
}

.swiper-pagination-bullet {
  width: 6px;
  height: 6px;
  display: inline-block;
  border-radius: 50%;
  background: #000;
  opacity: .2
}

button.swiper-pagination-bullet {
  border: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none
}

.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer
}

.swiper-pagination-bullet-active {
  opacity: 1
}

.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 6px
}

.swiper-zoom-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center
}

.swiper-zoom-container>canvas,
.swiper-zoom-container>img,
.swiper-zoom-container>svg {
  max-width: 100%;
  max-height: 100%;
  -o-object-fit: contain;
  object-fit: contain
}

.swiper-scrollbar-drag {
  height: 100%;
  width: 100%;
  position: relative;
  background: rgba(0, 0, 0, .5);
  border-radius: 10px;
  left: 0;
  top: 0
}

.swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  transform-origin: 50%;
  animation: 1s steps(12) infinite swiper-preloader-spin
}

.swiper-lazy-preloader:after {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  background-size: 100%;
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 120 120' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' stroke='%236c6c6c' stroke-width='11' stroke-linecap='round' d='M60 7v20'/%3E%3C/defs%3E%3Cuse xlink:href='%23a' opacity='.27'/%3E%3Cuse xlink:href='%23a' opacity='.27' transform='rotate(30 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.27' transform='rotate(60 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.27' transform='rotate(90 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.27' transform='rotate(120 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.27' transform='rotate(150 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.37' transform='rotate(180 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.46' transform='rotate(210 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.56' transform='rotate(240 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.66' transform='rotate(270 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.75' transform='rotate(300 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.85' transform='rotate(330 60 60)'/%3E%3C/svg%3E") 50% no-repeat
}

@keyframes swiper-preloader-spin {
  to {
    transform: rotate(1turn)
  }
}

.elementor-kit-6677 {
  --e-global-color-primary: #000000;
  --e-global-color-secondary: #F8D546;
  --e-global-color-text: #000000;
  --e-global-color-accent: #1877F2;
  --e-global-color-5f56f174: #0ABF3C;
  --e-global-color-6c3ab248: #FFF;
  --e-global-color-d9377ef: #F8D546;
  --e-global-color-ddda476: #333333;
  --e-global-color-b23ebb9: #F4F4F4;
  --e-global-color-41f27a1: #FF3C3C;
  --e-global-color-42cc58c: #7E7E7E;
  --e-global-typography-primary-font-family: "Roboto";
  --e-global-typography-primary-font-size: 60px;
  --e-global-typography-primary-font-weight: bold;
  --e-global-typography-secondary-font-family: "Roboto";
  --e-global-typography-secondary-font-size: 24px;
  --e-global-typography-secondary-font-weight: 400;
  --e-global-typography-secondary-line-height: 1.4em;
  --e-global-typography-text-font-family: "Roboto";
  --e-global-typography-text-font-size: 16px;
  --e-global-typography-text-font-weight: normal;
  --e-global-typography-text-line-height: 1.8em;
  --e-global-typography-accent-font-family: "Roboto";
  --e-global-typography-accent-font-weight: 400;
  --e-global-typography-1d40c76-font-family: "Roboto";
  --e-global-typography-1d40c76-font-size: 16px;
  --e-global-typography-1d40c76-font-weight: normal;
  --e-global-typography-a62ecd8-font-size: 40px;
  --e-global-typography-a62ecd8-font-weight: bold;
  --e-global-typography-a62ecd8-line-height: 1.2em;
  color: #000
}

.elementor-kit-6677 a {
  color: #007aff
}

.elementor-kit-6677 h1 {
  color: #000;
  font-size: 45px
}

.elementor-kit-6677 h2 {
  color: #000;
  font-size: 40px;
  line-height: 1.2em
}

.elementor-kit-6677 h3 {
  color: #000;
  font-size: 35px
}

.elementor-kit-6677 h4 {
  color: #000;
  font-size: 30px
}

.elementor-kit-6677 .elementor-button,
.elementor-kit-6677 button,
.elementor-kit-6677 input[type=button],
.elementor-kit-6677 input[type=submit] {
  color: #323232;
  background-color: #F8D546;
  /*background-color: #0abf3c*/
}

.elementor-section.elementor-section-boxed>.elementor-container {
  max-width: 1140px
}

.e-con {
  --container-max-width: 1140px
}

.elementor-widget:not(:last-child) {
  margin-bottom: 20px
}

.elementor-element {
  --widgets-spacing: 20px
}

@media(max-width:1024px) {
  .elementor-kit-6677 {
    --e-global-typography-primary-font-size: 50px;
    --e-global-typography-secondary-font-size: 22px;
    --e-global-typography-secondary-line-height: 1.4em;
    --e-global-typography-text-font-size: 18px;
    --e-global-typography-1d40c76-font-size: 16px;
    --e-global-typography-a62ecd8-font-size: 26px
  }

  .elementor-section.elementor-section-boxed>.elementor-container {
    max-width: 1024px
  }

  .e-con {
    --container-max-width: 1024px
  }
}

@media(max-width:767px) {
  .elementor-kit-6677 {
    --e-global-typography-primary-font-size: 30px;
    --e-global-typography-secondary-font-size: 18px;
    --e-global-typography-secondary-line-height: 1.4em;
    --e-global-typography-text-font-size: 16px;
    --e-global-typography-1d40c76-font-size: 16px
  }

  .elementor-section.elementor-section-boxed>.elementor-container {
    max-width: 767px
  }

  .e-con {
    --container-max-width: 767px
  }
}

div[class*=' pp-'] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

.clearfix:after {
  clear: both;
  content: "";
  font-size: 0;
  height: 0;
  display: block;
  visibility: hidden
}

.mfp-content .pp-modal-popup-window {
  display: block
}

.pp-modal-popup-fullscreen .mfp-container {
  padding: 0
}

.pp-modal-popup-fullscreen .mfp-container .mfp-content,
.pp-modal-popup-fullscreen .mfp-container .pp-modal-popup-window {
  max-width: 100%;
  width: 100%;
  height: 100%
}

.win-top-left .mfp-content,
.win-top-right .mfp-content {
  position: static
}

.mfp-move-from-top .mfp-content {
  vertical-align: middle
}

.mfp-3d-unfold .mfp-content {
  -webkit-perspective: 2000px;
  perspective: 2000px
}

.slick-list {
  position: relative;
  overflow: hidden
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto
}

.slick-slide {
  float: left;
  display: none
}

.slick-initialized .slick-slide {
  display: block
}

.swiper-container .swiper-slide,
.swiper-container-wrap .swiper-slide {
  text-align: center
}

.swiper-container .swiper-button-next,
.swiper-container .swiper-button-prev,
.swiper-container-wrap .swiper-button-next,
.swiper-container-wrap .swiper-button-prev {
  background-image: none;
  font-size: 20px;
  height: 1.8182em;
  line-height: 1;
  margin: 0;
  text-align: center;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 1.8182em
}

.swiper-container .swiper-button-next .fa,
.swiper-container .swiper-button-prev .fa,
.swiper-container-wrap .swiper-button-next .fa,
.swiper-container-wrap .swiper-button-prev .fa {
  vertical-align: top
}

.swiper-button-next:focus,
.swiper-button-prev:focus {
  outline: 0
}

.swiper-container-wrap {
  position: relative
}

.swiper-container-wrap .swiper-pagination {
  bottom: 10px;
  left: 0;
  width: 100%
}

.swiper-container-wrap-dots-outside .swiper-pagination {
  position: static
}

.swiper-container-wrap .swiper-pagination-bullet {
  background: #ccc;
  margin: 0 4px;
  opacity: 1;
  height: 8px;
  width: 8px
}

.swiper-container-wrap .swiper-pagination-bullet-active {
  background: #000
}

.swiper-pagination-bullet,
.swiper-pagination-bullet:focus {
  outline: 0
}

.elementor-sticky--active {
  z-index: 99
}

.e-con.elementor-sticky--active,
.e-container.elementor-sticky--active {
  z-index: var(--z-index, 99)
}

.e-con .e-con.elementor-sticky--active,
.e-con .e-container.elementor-sticky--active,
.e-container .e-con.elementor-sticky--active,
.e-container .e-container.elementor-sticky--active {
  padding-left: var(--padding-left);
  padding-right: var(--padding-right)
}

[data-elementor-type=popup]:not(.elementor-edit-area) {
  display: none
}

.elementor-popup-modal {
  display: flex;
  pointer-events: none;
  background-color: transparent;
  -webkit-user-select: auto;
  -moz-user-select: auto;
  user-select: auto
}

.elementor-popup-modal .dialog-buttons-wrapper,
.elementor-popup-modal .dialog-header {
  display: none
}

.elementor-popup-modal .dialog-close-button {
  display: none;
  top: 20px;
  margin-top: 0;
  right: 20px;
  opacity: 1;
  z-index: 9999;
  pointer-events: all
}

.elementor-popup-modal .dialog-close-button svg {
  fill: #373a3c;
  height: 1em;
  width: 1em
}

.elementor-popup-modal .dialog-widget-content {
  width: auto;
  overflow: visible;
  max-width: 100%;
  max-height: 100%;
  border-radius: 0;
  box-shadow: none;
  pointer-events: all
}

.elementor-popup-modal .dialog-message {
  width: 640px;
  max-width: 100vw;
  max-height: 100vh;
  padding: 0;
  overflow: auto;
  display: flex
}

.elementor-popup-modal .elementor {
  width: 100%
}

.elementor-motion-effects-element,
.elementor-motion-effects-layer {
  transition-property: transform, opacity;
  transition-timing-function: cubic-bezier(0, .33, .07, 1.03);
  transition-duration: 1s
}

.elementor-motion-effects-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transform-origin: var(--e-transform-origin-y) var(--e-transform-origin-x)
}

.elementor-motion-effects-layer {
  position: absolute;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: cover
}

.elementor-motion-effects-perspective {
  perspective: 1200px
}

.elementor-motion-effects-element {
  transform-origin: var(--e-transform-origin-y) var(--e-transform-origin-x)
}

.fa,
.fab,
.far,
.fas {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1
}

.fa-lg {
  font-size: 1.33333em;
  line-height: .75em;
  vertical-align: -.0667em
}

.fa-xs {
  font-size: .75em
}

.fa-sm {
  font-size: .875em
}

.fa-1x {
  font-size: 1em
}

.fa-2x {
  font-size: 2em
}

.fa-3x {
  font-size: 3em
}

.fa-4x {
  font-size: 4em
}

.fa-5x {
  font-size: 5em
}

.fa-6x {
  font-size: 6em
}

.fa-7x {
  font-size: 7em
}

.fa-8x {
  font-size: 8em
}

.fa-9x {
  font-size: 9em
}

.fa-10x {
  font-size: 10em
}

.fa-fw {
  text-align: center;
  width: 1.25em
}

.fa-ul {
  list-style-type: none;
  margin-left: 2.5em;
  padding-left: 0
}

.fa-ul>li {
  position: relative
}

.fa-li {
  left: -2em;
  position: absolute;
  text-align: center;
  width: 2em;
  line-height: inherit
}

.fa-border {
  border: .08em solid #eee;
  border-radius: .1em;
  padding: .2em .25em .15em
}

.fa-pull-left {
  float: left
}

.fa-pull-right {
  float: right
}

.fa-spin {
  -webkit-animation: 2s linear infinite fa-spin;
  animation: 2s linear infinite fa-spin
}

.fa-pulse {
  -webkit-animation: 1s steps(8) infinite fa-spin;
  animation: 1s steps(8) infinite fa-spin
}

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn)
  }
}

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn)
  }
}

.fa-rotate-90 {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg)
}

.fa-rotate-180 {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg)
}

.fa-rotate-270 {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg)
}

.fa-flip-horizontal {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1)
}

.fa-flip-vertical {
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1)
}

.fa-flip-both,
.fa-flip-horizontal.fa-flip-vertical {
  -webkit-transform: scale(-1);
  transform: scale(-1)
}

.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2.5em
}

.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%
}

.fa-stack-1x {
  line-height: inherit
}

.fa-stack-2x {
  font-size: 2em
}

.fa-inverse {
  color: #fff
}

.fa-500px:before {
  content: "\f26e"
}

.fa-accessible-icon:before {
  content: "\f368"
}

.fa-accusoft:before {
  content: "\f369"
}

.fa-acquisitions-incorporated:before {
  content: "\f6af"
}

.fa-ad:before {
  content: "\f641"
}

.fa-address-book:before {
  content: "\f2b9"
}

.fa-address-card:before {
  content: "\f2bb"
}

.fa-adjust:before {
  content: "\f042"
}

.fa-adn:before {
  content: "\f170"
}

.fa-adversal:before {
  content: "\f36a"
}

.fa-affiliatetheme:before {
  content: "\f36b"
}

.fa-air-freshener:before {
  content: "\f5d0"
}

.fa-airbnb:before {
  content: "\f834"
}

.fa-algolia:before {
  content: "\f36c"
}

.fa-align-center:before {
  content: "\f037"
}

.fa-align-justify:before {
  content: "\f039"
}

.fa-align-left:before {
  content: "\f036"
}

.fa-align-right:before {
  content: "\f038"
}

.fa-alipay:before {
  content: "\f642"
}

.fa-allergies:before {
  content: "\f461"
}

.fa-amazon:before {
  content: "\f270"
}

.fa-amazon-pay:before {
  content: "\f42c"
}

.fa-ambulance:before {
  content: "\f0f9"
}

.fa-american-sign-language-interpreting:before {
  content: "\f2a3"
}

.fa-amilia:before {
  content: "\f36d"
}

.fa-anchor:before {
  content: "\f13d"
}

.fa-android:before {
  content: "\f17b"
}

.fa-angellist:before {
  content: "\f209"
}

.fa-angle-double-down:before {
  content: "\f103"
}

.fa-angle-double-left:before {
  content: "\f100"
}

.fa-angle-double-right:before {
  content: "\f101"
}

.fa-angle-double-up:before {
  content: "\f102"
}

.fa-angle-down:before {
  content: "\f107"
}

.fa-angle-left:before {
  content: "\f104"
}

.fa-angle-right:before {
  content: "\f105"
}

.fa-angle-up:before {
  content: "\f106"
}

.fa-angry:before {
  content: "\f556"
}

.fa-angrycreative:before {
  content: "\f36e"
}

.fa-angular:before {
  content: "\f420"
}

.fa-ankh:before {
  content: "\f644"
}

.fa-app-store:before {
  content: "\f36f"
}

.fa-app-store-ios:before {
  content: "\f370"
}

.fa-apper:before {
  content: "\f371"
}

.fa-apple:before {
  content: "\f179"
}

.fa-apple-alt:before {
  content: "\f5d1"
}

.fa-apple-pay:before {
  content: "\f415"
}

.fa-archive:before {
  content: "\f187"
}

.fa-archway:before {
  content: "\f557"
}

.fa-arrow-alt-circle-down:before {
  content: "\f358"
}

.fa-arrow-alt-circle-left:before {
  content: "\f359"
}

.fa-arrow-alt-circle-right:before {
  content: "\f35a"
}

.fa-arrow-alt-circle-up:before {
  content: "\f35b"
}

.fa-arrow-circle-down:before {
  content: "\f0ab"
}

.fa-arrow-circle-left:before {
  content: "\f0a8"
}

.fa-arrow-circle-right:before {
  content: "\f0a9"
}

.fa-arrow-circle-up:before {
  content: "\f0aa"
}

.fa-arrow-down:before {
  content: "\f063"
}

.fa-arrow-left:before {
  content: "\f060"
}

.fa-arrow-right:before {
  content: "\f061"
}

.fa-arrow-up:before {
  content: "\f062"
}

.fa-arrows-alt:before {
  content: "\f0b2"
}

.fa-arrows-alt-h:before {
  content: "\f337"
}

.fa-arrows-alt-v:before {
  content: "\f338"
}

.fa-artstation:before {
  content: "\f77a"
}

.fa-assistive-listening-systems:before {
  content: "\f2a2"
}

.fa-asterisk:before {
  content: "\f069"
}

.fa-asymmetrik:before {
  content: "\f372"
}

.fa-at:before {
  content: "\f1fa"
}

.fa-atlas:before {
  content: "\f558"
}

.fa-atlassian:before {
  content: "\f77b"
}

.fa-atom:before {
  content: "\f5d2"
}

.fa-audible:before {
  content: "\f373"
}

.fa-audio-description:before {
  content: "\f29e"
}

.fa-autoprefixer:before {
  content: "\f41c"
}

.fa-avianex:before {
  content: "\f374"
}

.fa-aviato:before {
  content: "\f421"
}

.fa-award:before {
  content: "\f559"
}

.fa-aws:before {
  content: "\f375"
}

.fa-baby:before {
  content: "\f77c"
}

.fa-baby-carriage:before {
  content: "\f77d"
}

.fa-backspace:before {
  content: "\f55a"
}

.fa-backward:before {
  content: "\f04a"
}

.fa-bacon:before {
  content: "\f7e5"
}

.fa-bacteria:before {
  content: "\e059"
}

.fa-bacterium:before {
  content: "\e05a"
}

.fa-bahai:before {
  content: "\f666"
}

.fa-balance-scale:before {
  content: "\f24e"
}

.fa-balance-scale-left:before {
  content: "\f515"
}

.fa-balance-scale-right:before {
  content: "\f516"
}

.fa-ban:before {
  content: "\f05e"
}

.fa-band-aid:before {
  content: "\f462"
}

.fa-bandcamp:before {
  content: "\f2d5"
}

.fa-barcode:before {
  content: "\f02a"
}

.fa-bars:before {
  content: "\f0c9"
}

.fa-baseball-ball:before {
  content: "\f433"
}

.fa-basketball-ball:before {
  content: "\f434"
}

.fa-bath:before {
  content: "\f2cd"
}

.fa-battery-empty:before {
  content: "\f244"
}

.fa-battery-full:before {
  content: "\f240"
}

.fa-battery-half:before {
  content: "\f242"
}

.fa-battery-quarter:before {
  content: "\f243"
}

.fa-battery-three-quarters:before {
  content: "\f241"
}

.fa-battle-net:before {
  content: "\f835"
}

.fa-bed:before {
  content: "\f236"
}

.fa-beer:before {
  content: "\f0fc"
}

.fa-behance:before {
  content: "\f1b4"
}

.fa-behance-square:before {
  content: "\f1b5"
}

.fa-bell:before {
  content: "\f0f3"
}

.fa-bell-slash:before {
  content: "\f1f6"
}

.fa-bezier-curve:before {
  content: "\f55b"
}

.fa-bible:before {
  content: "\f647"
}

.fa-bicycle:before {
  content: "\f206"
}

.fa-biking:before {
  content: "\f84a"
}

.fa-bimobject:before {
  content: "\f378"
}

.fa-binoculars:before {
  content: "\f1e5"
}

.fa-biohazard:before {
  content: "\f780"
}

.fa-birthday-cake:before {
  content: "\f1fd"
}

.fa-bitbucket:before {
  content: "\f171"
}

.fa-bitcoin:before {
  content: "\f379"
}

.fa-bity:before {
  content: "\f37a"
}

.fa-black-tie:before {
  content: "\f27e"
}

.fa-blackberry:before {
  content: "\f37b"
}

.fa-blender:before {
  content: "\f517"
}

.fa-blender-phone:before {
  content: "\f6b6"
}

.fa-blind:before {
  content: "\f29d"
}

.fa-blog:before {
  content: "\f781"
}

.fa-blogger:before {
  content: "\f37c"
}

.fa-blogger-b:before {
  content: "\f37d"
}

.fa-bluetooth:before {
  content: "\f293"
}

.fa-bluetooth-b:before {
  content: "\f294"
}

.fa-bold:before {
  content: "\f032"
}

.fa-bolt:before {
  content: "\f0e7"
}

.fa-bomb:before {
  content: "\f1e2"
}

.fa-bone:before {
  content: "\f5d7"
}

.fa-bong:before {
  content: "\f55c"
}

.fa-book:before {
  content: "\f02d"
}

.fa-book-dead:before {
  content: "\f6b7"
}

.fa-book-medical:before {
  content: "\f7e6"
}

.fa-book-open:before {
  content: "\f518"
}

.fa-book-reader:before {
  content: "\f5da"
}

.fa-bookmark:before {
  content: "\f02e"
}

.fa-bootstrap:before {
  content: "\f836"
}

.fa-border-all:before {
  content: "\f84c"
}

.fa-border-none:before {
  content: "\f850"
}

.fa-border-style:before {
  content: "\f853"
}

.fa-bowling-ball:before {
  content: "\f436"
}

.fa-box:before {
  content: "\f466"
}

.fa-box-open:before {
  content: "\f49e"
}

.fa-box-tissue:before {
  content: "\e05b"
}

.fa-boxes:before {
  content: "\f468"
}

.fa-braille:before {
  content: "\f2a1"
}

.fa-brain:before {
  content: "\f5dc"
}

.fa-bread-slice:before {
  content: "\f7ec"
}

.fa-briefcase:before {
  content: "\f0b1"
}

.fa-briefcase-medical:before {
  content: "\f469"
}

.fa-broadcast-tower:before {
  content: "\f519"
}

.fa-broom:before {
  content: "\f51a"
}

.fa-brush:before {
  content: "\f55d"
}

.fa-btc:before {
  content: "\f15a"
}

.fa-buffer:before {
  content: "\f837"
}

.fa-bug:before {
  content: "\f188"
}

.fa-building:before {
  content: "\f1ad"
}

.fa-bullhorn:before {
  content: "\f0a1"
}

.fa-bullseye:before {
  content: "\f140"
}

.fa-burn:before {
  content: "\f46a"
}

.fa-buromobelexperte:before {
  content: "\f37f"
}

.fa-bus:before {
  content: "\f207"
}

.fa-bus-alt:before {
  content: "\f55e"
}

.fa-business-time:before {
  content: "\f64a"
}

.fa-buy-n-large:before {
  content: "\f8a6"
}

.fa-buysellads:before {
  content: "\f20d"
}

.fa-calculator:before {
  content: "\f1ec"
}

.fa-calendar:before {
  content: "\f133"
}

.fa-calendar-alt:before {
  content: "\f073"
}

.fa-calendar-check:before {
  content: "\f274"
}

.fa-calendar-day:before {
  content: "\f783"
}

.fa-calendar-minus:before {
  content: "\f272"
}

.fa-calendar-plus:before {
  content: "\f271"
}

.fa-calendar-times:before {
  content: "\f273"
}

.fa-calendar-week:before {
  content: "\f784"
}

.fa-camera:before {
  content: "\f030"
}

.fa-camera-retro:before {
  content: "\f083"
}

.fa-campground:before {
  content: "\f6bb"
}

.fa-canadian-maple-leaf:before {
  content: "\f785"
}

.fa-candy-cane:before {
  content: "\f786"
}

.fa-cannabis:before {
  content: "\f55f"
}

.fa-capsules:before {
  content: "\f46b"
}

.fa-car:before {
  content: "\f1b9"
}

.fa-car-alt:before {
  content: "\f5de"
}

.fa-car-battery:before {
  content: "\f5df"
}

.fa-car-crash:before {
  content: "\f5e1"
}

.fa-car-side:before {
  content: "\f5e4"
}

.fa-caravan:before {
  content: "\f8ff"
}

.fa-caret-down:before {
  content: "\f0d7"
}

.fa-caret-left:before {
  content: "\f0d9"
}

.fa-caret-right:before {
  content: "\f0da"
}

.fa-caret-square-down:before {
  content: "\f150"
}

.fa-caret-square-left:before {
  content: "\f191"
}

.fa-caret-square-right:before {
  content: "\f152"
}

.fa-caret-square-up:before {
  content: "\f151"
}

.fa-caret-up:before {
  content: "\f0d8"
}

.fa-carrot:before {
  content: "\f787"
}

.fa-cart-arrow-down:before {
  content: "\f218"
}

.fa-cart-plus:before {
  content: "\f217"
}

.fa-cash-register:before {
  content: "\f788"
}

.fa-cat:before {
  content: "\f6be"
}

.fa-cc-amazon-pay:before {
  content: "\f42d"
}

.fa-cc-amex:before {
  content: "\f1f3"
}

.fa-cc-apple-pay:before {
  content: "\f416"
}

.fa-cc-diners-club:before {
  content: "\f24c"
}

.fa-cc-discover:before {
  content: "\f1f2"
}

.fa-cc-jcb:before {
  content: "\f24b"
}

.fa-cc-mastercard:before {
  content: "\f1f1"
}

.fa-cc-paypal:before {
  content: "\f1f4"
}

.fa-cc-stripe:before {
  content: "\f1f5"
}

.fa-cc-visa:before {
  content: "\f1f0"
}

.fa-centercode:before {
  content: "\f380"
}

.fa-centos:before {
  content: "\f789"
}

.fa-certificate:before {
  content: "\f0a3"
}

.fa-chair:before {
  content: "\f6c0"
}

.fa-chalkboard:before {
  content: "\f51b"
}

.fa-chalkboard-teacher:before {
  content: "\f51c"
}

.fa-charging-station:before {
  content: "\f5e7"
}

.fa-chart-area:before {
  content: "\f1fe"
}

.fa-chart-bar:before {
  content: "\f080"
}

.fa-chart-line:before {
  content: "\f201"
}

.fa-chart-pie:before {
  content: "\f200"
}

.fa-check:before {
  content: "\f00c"
}

.fa-check-circle:before {
  content: "\f058"
}

.fa-check-double:before {
  content: "\f560"
}

.fa-check-square:before {
  content: "\f14a"
}

.fa-cheese:before {
  content: "\f7ef"
}

.fa-chess:before {
  content: "\f439"
}

.fa-chess-bishop:before {
  content: "\f43a"
}

.fa-chess-board:before {
  content: "\f43c"
}

.fa-chess-king:before {
  content: "\f43f"
}

.fa-chess-knight:before {
  content: "\f441"
}

.fa-chess-pawn:before {
  content: "\f443"
}

.fa-chess-queen:before {
  content: "\f445"
}

.fa-chess-rook:before {
  content: "\f447"
}

.fa-chevron-circle-down:before {
  content: "\f13a"
}

.fa-chevron-circle-left:before {
  content: "\f137"
}

.fa-chevron-circle-right:before {
  content: "\f138"
}

.fa-chevron-circle-up:before {
  content: "\f139"
}

.fa-chevron-down:before {
  content: "\f078"
}

.fa-chevron-left:before {
  content: "\f053"
}

.fa-chevron-right:before {
  content: "\f054"
}

.fa-chevron-up:before {
  content: "\f077"
}

.fa-child:before {
  content: "\f1ae"
}

.fa-chrome:before {
  content: "\f268"
}

.fa-chromecast:before {
  content: "\f838"
}

.fa-church:before {
  content: "\f51d"
}

.fa-circle:before {
  content: "\f111"
}

.fa-circle-notch:before {
  content: "\f1ce"
}

.fa-city:before {
  content: "\f64f"
}

.fa-clinic-medical:before {
  content: "\f7f2"
}

.fa-clipboard:before {
  content: "\f328"
}

.fa-clipboard-check:before {
  content: "\f46c"
}

.fa-clipboard-list:before {
  content: "\f46d"
}

.fa-clock:before {
  content: "\f017"
}

.fa-clone:before {
  content: "\f24d"
}

.fa-closed-captioning:before {
  content: "\f20a"
}

.fa-cloud:before {
  content: "\f0c2"
}

.fa-cloud-download-alt:before {
  content: "\f381"
}

.fa-cloud-meatball:before {
  content: "\f73b"
}

.fa-cloud-moon:before {
  content: "\f6c3"
}

.fa-cloud-moon-rain:before {
  content: "\f73c"
}

.fa-cloud-rain:before {
  content: "\f73d"
}

.fa-cloud-showers-heavy:before {
  content: "\f740"
}

.fa-cloud-sun:before {
  content: "\f6c4"
}

.fa-cloud-sun-rain:before {
  content: "\f743"
}

.fa-cloud-upload-alt:before {
  content: "\f382"
}

.fa-cloudflare:before {
  content: "\e07d"
}

.fa-cloudscale:before {
  content: "\f383"
}

.fa-cloudsmith:before {
  content: "\f384"
}

.fa-cloudversify:before {
  content: "\f385"
}

.fa-cocktail:before {
  content: "\f561"
}

.fa-code:before {
  content: "\f121"
}

.fa-code-branch:before {
  content: "\f126"
}

.fa-codepen:before {
  content: "\f1cb"
}

.fa-codiepie:before {
  content: "\f284"
}

.fa-coffee:before {
  content: "\f0f4"
}

.fa-cog:before {
  content: "\f013"
}

.fa-cogs:before {
  content: "\f085"
}

.fa-coins:before {
  content: "\f51e"
}

.fa-columns:before {
  content: "\f0db"
}

.fa-comment:before {
  content: "\f075"
}

.fa-comment-alt:before {
  content: "\f27a"
}

.fa-comment-dollar:before {
  content: "\f651"
}

.fa-comment-dots:before {
  content: "\f4ad"
}

.fa-comment-medical:before {
  content: "\f7f5"
}

.fa-comment-slash:before {
  content: "\f4b3"
}

.fa-comments:before {
  content: "\f086"
}

.fa-comments-dollar:before {
  content: "\f653"
}

.fa-compact-disc:before {
  content: "\f51f"
}

.fa-compass:before {
  content: "\f14e"
}

.fa-compress:before {
  content: "\f066"
}

.fa-compress-alt:before {
  content: "\f422"
}

.fa-compress-arrows-alt:before {
  content: "\f78c"
}

.fa-concierge-bell:before {
  content: "\f562"
}

.fa-confluence:before {
  content: "\f78d"
}

.fa-connectdevelop:before {
  content: "\f20e"
}

.fa-contao:before {
  content: "\f26d"
}

.fa-cookie:before {
  content: "\f563"
}

.fa-cookie-bite:before {
  content: "\f564"
}

.fa-copy:before {
  content: "\f0c5"
}

.fa-copyright:before {
  content: "\f1f9"
}

.fa-cotton-bureau:before {
  content: "\f89e"
}

.fa-couch:before {
  content: "\f4b8"
}

.fa-cpanel:before {
  content: "\f388"
}

.fa-creative-commons:before {
  content: "\f25e"
}

.fa-creative-commons-by:before {
  content: "\f4e7"
}

.fa-creative-commons-nc:before {
  content: "\f4e8"
}

.fa-creative-commons-nc-eu:before {
  content: "\f4e9"
}

.fa-creative-commons-nc-jp:before {
  content: "\f4ea"
}

.fa-creative-commons-nd:before {
  content: "\f4eb"
}

.fa-creative-commons-pd:before {
  content: "\f4ec"
}

.fa-creative-commons-pd-alt:before {
  content: "\f4ed"
}

.fa-creative-commons-remix:before {
  content: "\f4ee"
}

.fa-creative-commons-sa:before {
  content: "\f4ef"
}

.fa-creative-commons-sampling:before {
  content: "\f4f0"
}

.fa-creative-commons-sampling-plus:before {
  content: "\f4f1"
}

.fa-creative-commons-share:before {
  content: "\f4f2"
}

.fa-creative-commons-zero:before {
  content: "\f4f3"
}

.fa-credit-card:before {
  content: "\f09d"
}

.fa-critical-role:before {
  content: "\f6c9"
}

.fa-crop:before {
  content: "\f125"
}

.fa-crop-alt:before {
  content: "\f565"
}

.fa-cross:before {
  content: "\f654"
}

.fa-crosshairs:before {
  content: "\f05b"
}

.fa-crow:before {
  content: "\f520"
}

.fa-crown:before {
  content: "\f521"
}

.fa-crutch:before {
  content: "\f7f7"
}

.fa-css3:before {
  content: "\f13c"
}

.fa-css3-alt:before {
  content: "\f38b"
}

.fa-cube:before {
  content: "\f1b2"
}

.fa-cubes:before {
  content: "\f1b3"
}

.fa-cut:before {
  content: "\f0c4"
}

.fa-cuttlefish:before {
  content: "\f38c"
}

.fa-d-and-d:before {
  content: "\f38d"
}

.fa-d-and-d-beyond:before {
  content: "\f6ca"
}

.fa-dailymotion:before {
  content: "\e052"
}

.fa-dashcube:before {
  content: "\f210"
}

.fa-database:before {
  content: "\f1c0"
}

.fa-deaf:before {
  content: "\f2a4"
}

.fa-deezer:before {
  content: "\e077"
}

.fa-delicious:before {
  content: "\f1a5"
}

.fa-democrat:before {
  content: "\f747"
}

.fa-deploydog:before {
  content: "\f38e"
}

.fa-deskpro:before {
  content: "\f38f"
}

.fa-desktop:before {
  content: "\f108"
}

.fa-dev:before {
  content: "\f6cc"
}

.fa-deviantart:before {
  content: "\f1bd"
}

.fa-dharmachakra:before {
  content: "\f655"
}

.fa-dhl:before {
  content: "\f790"
}

.fa-diagnoses:before {
  content: "\f470"
}

.fa-diaspora:before {
  content: "\f791"
}

.fa-dice:before {
  content: "\f522"
}

.fa-dice-d20:before {
  content: "\f6cf"
}

.fa-dice-d6:before {
  content: "\f6d1"
}

.fa-dice-five:before {
  content: "\f523"
}

.fa-dice-four:before {
  content: "\f524"
}

.fa-dice-one:before {
  content: "\f525"
}

.fa-dice-six:before {
  content: "\f526"
}

.fa-dice-three:before {
  content: "\f527"
}

.fa-dice-two:before {
  content: "\f528"
}

.fa-digg:before {
  content: "\f1a6"
}

.fa-digital-ocean:before {
  content: "\f391"
}

.fa-digital-tachograph:before {
  content: "\f566"
}

.fa-directions:before {
  content: "\f5eb"
}

.fa-discord:before {
  content: "\f392"
}

.fa-discourse:before {
  content: "\f393"
}

.fa-disease:before {
  content: "\f7fa"
}

.fa-divide:before {
  content: "\f529"
}

.fa-dizzy:before {
  content: "\f567"
}

.fa-dna:before {
  content: "\f471"
}

.fa-dochub:before {
  content: "\f394"
}

.fa-docker:before {
  content: "\f395"
}

.fa-dog:before {
  content: "\f6d3"
}

.fa-dollar-sign:before {
  content: "\f155"
}

.fa-dolly:before {
  content: "\f472"
}

.fa-dolly-flatbed:before {
  content: "\f474"
}

.fa-donate:before {
  content: "\f4b9"
}

.fa-door-closed:before {
  content: "\f52a"
}

.fa-door-open:before {
  content: "\f52b"
}

.fa-dot-circle:before {
  content: "\f192"
}

.fa-dove:before {
  content: "\f4ba"
}

.fa-download:before {
  content: "\f019"
}

.fa-draft2digital:before {
  content: "\f396"
}

.fa-drafting-compass:before {
  content: "\f568"
}

.fa-dragon:before {
  content: "\f6d5"
}

.fa-draw-polygon:before {
  content: "\f5ee"
}

.fa-dribbble:before {
  content: "\f17d"
}

.fa-dribbble-square:before {
  content: "\f397"
}

.fa-dropbox:before {
  content: "\f16b"
}

.fa-drum:before {
  content: "\f569"
}

.fa-drum-steelpan:before {
  content: "\f56a"
}

.fa-drumstick-bite:before {
  content: "\f6d7"
}

.fa-drupal:before {
  content: "\f1a9"
}

.fa-dumbbell:before {
  content: "\f44b"
}

.fa-dumpster:before {
  content: "\f793"
}

.fa-dumpster-fire:before {
  content: "\f794"
}

.fa-dungeon:before {
  content: "\f6d9"
}

.fa-dyalog:before {
  content: "\f399"
}

.fa-earlybirds:before {
  content: "\f39a"
}

.fa-ebay:before {
  content: "\f4f4"
}

.fa-edge:before {
  content: "\f282"
}

.fa-edge-legacy:before {
  content: "\e078"
}

.fa-edit:before {
  content: "\f044"
}

.fa-egg:before {
  content: "\f7fb"
}

.fa-eject:before {
  content: "\f052"
}

.fa-elementor:before {
  content: "\f430"
}

.fa-ellipsis-h:before {
  content: "\f141"
}

.fa-ellipsis-v:before {
  content: "\f142"
}

.fa-ello:before {
  content: "\f5f1"
}

.fa-ember:before {
  content: "\f423"
}

.fa-empire:before {
  content: "\f1d1"
}

.fa-envelope:before {
  content: "\f0e0"
}

.fa-envelope-open:before {
  content: "\f2b6"
}

.fa-envelope-open-text:before {
  content: "\f658"
}

.fa-envelope-square:before {
  content: "\f199"
}

.fa-envira:before {
  content: "\f299"
}

.fa-equals:before {
  content: "\f52c"
}

.fa-eraser:before {
  content: "\f12d"
}

.fa-erlang:before {
  content: "\f39d"
}

.fa-ethereum:before {
  content: "\f42e"
}

.fa-ethernet:before {
  content: "\f796"
}

.fa-etsy:before {
  content: "\f2d7"
}

.fa-euro-sign:before {
  content: "\f153"
}

.fa-evernote:before {
  content: "\f839"
}

.fa-exchange-alt:before {
  content: "\f362"
}

.fa-exclamation:before {
  content: "\f12a"
}

.fa-exclamation-circle:before {
  content: "\f06a"
}

.fa-exclamation-triangle:before {
  content: "\f071"
}

.fa-expand:before {
  content: "\f065"
}

.fa-expand-alt:before {
  content: "\f424"
}

.fa-expand-arrows-alt:before {
  content: "\f31e"
}

.fa-expeditedssl:before {
  content: "\f23e"
}

.fa-external-link-alt:before {
  content: "\f35d"
}

.fa-external-link-square-alt:before {
  content: "\f360"
}

.fa-eye:before {
  content: "\f06e"
}

.fa-eye-dropper:before {
  content: "\f1fb"
}

.fa-eye-slash:before {
  content: "\f070"
}

.fa-facebook:before {
  content: "\f09a"
}

.fa-facebook-f:before {
  content: "\f39e"
}

.fa-facebook-messenger:before {
  content: "\f39f"
}

.fa-facebook-square:before {
  content: "\f082"
}

.fa-fan:before {
  content: "\f863"
}

.fa-fantasy-flight-games:before {
  content: "\f6dc"
}

.fa-fast-backward:before {
  content: "\f049"
}

.fa-fast-forward:before {
  content: "\f050"
}

.fa-faucet:before {
  content: "\e005"
}

.fa-fax:before {
  content: "\f1ac"
}

.fa-feather:before {
  content: "\f52d"
}

.fa-feather-alt:before {
  content: "\f56b"
}

.fa-fedex:before {
  content: "\f797"
}

.fa-fedora:before {
  content: "\f798"
}

.fa-female:before {
  content: "\f182"
}

.fa-fighter-jet:before {
  content: "\f0fb"
}

.fa-figma:before {
  content: "\f799"
}

.fa-file:before {
  content: "\f15b"
}

.fa-file-alt:before {
  content: "\f15c"
}

.fa-file-archive:before {
  content: "\f1c6"
}

.fa-file-audio:before {
  content: "\f1c7"
}

.fa-file-code:before {
  content: "\f1c9"
}

.fa-file-contract:before {
  content: "\f56c"
}

.fa-file-csv:before {
  content: "\f6dd"
}

.fa-file-download:before {
  content: "\f56d"
}

.fa-file-excel:before {
  content: "\f1c3"
}

.fa-file-export:before {
  content: "\f56e"
}

.fa-file-image:before {
  content: "\f1c5"
}

.fa-file-import:before {
  content: "\f56f"
}

.fa-file-invoice:before {
  content: "\f570"
}

.fa-file-invoice-dollar:before {
  content: "\f571"
}

.fa-file-medical:before {
  content: "\f477"
}

.fa-file-medical-alt:before {
  content: "\f478"
}

.fa-file-pdf:before {
  content: "\f1c1"
}

.fa-file-powerpoint:before {
  content: "\f1c4"
}

.fa-file-prescription:before {
  content: "\f572"
}

.fa-file-signature:before {
  content: "\f573"
}

.fa-file-upload:before {
  content: "\f574"
}

.fa-file-video:before {
  content: "\f1c8"
}

.fa-file-word:before {
  content: "\f1c2"
}

.fa-fill:before {
  content: "\f575"
}

.fa-fill-drip:before {
  content: "\f576"
}

.fa-film:before {
  content: "\f008"
}

.fa-filter:before {
  content: "\f0b0"
}

.fa-fingerprint:before {
  content: "\f577"
}

.fa-fire:before {
  content: "\f06d"
}

.fa-fire-alt:before {
  content: "\f7e4"
}

.fa-fire-extinguisher:before {
  content: "\f134"
}

.fa-firefox:before {
  content: "\f269"
}

.fa-firefox-browser:before {
  content: "\e007"
}

.fa-first-aid:before {
  content: "\f479"
}

.fa-first-order:before {
  content: "\f2b0"
}

.fa-first-order-alt:before {
  content: "\f50a"
}

.fa-firstdraft:before {
  content: "\f3a1"
}

.fa-fish:before {
  content: "\f578"
}

.fa-fist-raised:before {
  content: "\f6de"
}

.fa-flag:before {
  content: "\f024"
}

.fa-flag-checkered:before {
  content: "\f11e"
}

.fa-flag-usa:before {
  content: "\f74d"
}

.fa-flask:before {
  content: "\f0c3"
}

.fa-flickr:before {
  content: "\f16e"
}

.fa-flipboard:before {
  content: "\f44d"
}

.fa-flushed:before {
  content: "\f579"
}

.fa-fly:before {
  content: "\f417"
}

.fa-folder:before {
  content: "\f07b"
}

.fa-folder-minus:before {
  content: "\f65d"
}

.fa-folder-open:before {
  content: "\f07c"
}

.fa-folder-plus:before {
  content: "\f65e"
}

.fa-font:before {
  content: "\f031"
}

.fa-font-awesome:before {
  content: "\f2b4"
}

.fa-font-awesome-alt:before {
  content: "\f35c"
}

.fa-font-awesome-flag:before {
  content: "\f425"
}

.fa-font-awesome-logo-full:before {
  content: "\f4e6"
}

.fa-fonticons:before {
  content: "\f280"
}

.fa-fonticons-fi:before {
  content: "\f3a2"
}

.fa-football-ball:before {
  content: "\f44e"
}

.fa-fort-awesome:before {
  content: "\f286"
}

.fa-fort-awesome-alt:before {
  content: "\f3a3"
}

.fa-forumbee:before {
  content: "\f211"
}

.fa-forward:before {
  content: "\f04e"
}

.fa-foursquare:before {
  content: "\f180"
}

.fa-free-code-camp:before {
  content: "\f2c5"
}

.fa-freebsd:before {
  content: "\f3a4"
}

.fa-frog:before {
  content: "\f52e"
}

.fa-frown:before {
  content: "\f119"
}

.fa-frown-open:before {
  content: "\f57a"
}

.fa-fulcrum:before {
  content: "\f50b"
}

.fa-funnel-dollar:before {
  content: "\f662"
}

.fa-futbol:before {
  content: "\f1e3"
}

.fa-galactic-republic:before {
  content: "\f50c"
}

.fa-galactic-senate:before {
  content: "\f50d"
}

.fa-gamepad:before {
  content: "\f11b"
}

.fa-gas-pump:before {
  content: "\f52f"
}

.fa-gavel:before {
  content: "\f0e3"
}

.fa-gem:before {
  content: "\f3a5"
}

.fa-genderless:before {
  content: "\f22d"
}

.fa-get-pocket:before {
  content: "\f265"
}

.fa-gg:before {
  content: "\f260"
}

.fa-gg-circle:before {
  content: "\f261"
}

.fa-ghost:before {
  content: "\f6e2"
}

.fa-gift:before {
  content: "\f06b"
}

.fa-gifts:before {
  content: "\f79c"
}

.fa-git:before {
  content: "\f1d3"
}

.fa-git-alt:before {
  content: "\f841"
}

.fa-git-square:before {
  content: "\f1d2"
}

.fa-github:before {
  content: "\f09b"
}

.fa-github-alt:before {
  content: "\f113"
}

.fa-github-square:before {
  content: "\f092"
}

.fa-gitkraken:before {
  content: "\f3a6"
}

.fa-gitlab:before {
  content: "\f296"
}

.fa-gitter:before {
  content: "\f426"
}

.fa-glass-cheers:before {
  content: "\f79f"
}

.fa-glass-martini:before {
  content: "\f000"
}

.fa-glass-martini-alt:before {
  content: "\f57b"
}

.fa-glass-whiskey:before {
  content: "\f7a0"
}

.fa-glasses:before {
  content: "\f530"
}

.fa-glide:before {
  content: "\f2a5"
}

.fa-glide-g:before {
  content: "\f2a6"
}

.fa-globe:before {
  content: "\f0ac"
}

.fa-globe-africa:before {
  content: "\f57c"
}

.fa-globe-americas:before {
  content: "\f57d"
}

.fa-globe-asia:before {
  content: "\f57e"
}

.fa-globe-europe:before {
  content: "\f7a2"
}

.fa-gofore:before {
  content: "\f3a7"
}

.fa-golf-ball:before {
  content: "\f450"
}

.fa-goodreads:before {
  content: "\f3a8"
}

.fa-goodreads-g:before {
  content: "\f3a9"
}

.fa-google:before {
  content: "\f1a0"
}

.fa-google-drive:before {
  content: "\f3aa"
}

.fa-google-pay:before {
  content: "\e079"
}

.fa-google-play:before {
  content: "\f3ab"
}

.fa-google-plus:before {
  content: "\f2b3"
}

.fa-google-plus-g:before {
  content: "\f0d5"
}

.fa-google-plus-square:before {
  content: "\f0d4"
}

.fa-google-wallet:before {
  content: "\f1ee"
}

.fa-gopuram:before {
  content: "\f664"
}

.fa-graduation-cap:before {
  content: "\f19d"
}

.fa-gratipay:before {
  content: "\f184"
}

.fa-grav:before {
  content: "\f2d6"
}

.fa-greater-than:before {
  content: "\f531"
}

.fa-greater-than-equal:before {
  content: "\f532"
}

.fa-grimace:before {
  content: "\f57f"
}

.fa-grin:before {
  content: "\f580"
}

.fa-grin-alt:before {
  content: "\f581"
}

.fa-grin-beam:before {
  content: "\f582"
}

.fa-grin-beam-sweat:before {
  content: "\f583"
}

.fa-grin-hearts:before {
  content: "\f584"
}

.fa-grin-squint:before {
  content: "\f585"
}

.fa-grin-squint-tears:before {
  content: "\f586"
}

.fa-grin-stars:before {
  content: "\f587"
}

.fa-grin-tears:before {
  content: "\f588"
}

.fa-grin-tongue:before {
  content: "\f589"
}

.fa-grin-tongue-squint:before {
  content: "\f58a"
}

.fa-grin-tongue-wink:before {
  content: "\f58b"
}

.fa-grin-wink:before {
  content: "\f58c"
}

.fa-grip-horizontal:before {
  content: "\f58d"
}

.fa-grip-lines:before {
  content: "\f7a4"
}

.fa-grip-lines-vertical:before {
  content: "\f7a5"
}

.fa-grip-vertical:before {
  content: "\f58e"
}

.fa-gripfire:before {
  content: "\f3ac"
}

.fa-grunt:before {
  content: "\f3ad"
}

.fa-guilded:before {
  content: "\e07e"
}

.fa-guitar:before {
  content: "\f7a6"
}

.fa-gulp:before {
  content: "\f3ae"
}

.fa-h-square:before {
  content: "\f0fd"
}

.fa-hacker-news:before {
  content: "\f1d4"
}

.fa-hacker-news-square:before {
  content: "\f3af"
}

.fa-hackerrank:before {
  content: "\f5f7"
}

.fa-hamburger:before {
  content: "\f805"
}

.fa-hammer:before {
  content: "\f6e3"
}

.fa-hamsa:before {
  content: "\f665"
}

.fa-hand-holding:before {
  content: "\f4bd"
}

.fa-hand-holding-heart:before {
  content: "\f4be"
}

.fa-hand-holding-medical:before {
  content: "\e05c"
}

.fa-hand-holding-usd:before {
  content: "\f4c0"
}

.fa-hand-holding-water:before {
  content: "\f4c1"
}

.fa-hand-lizard:before {
  content: "\f258"
}

.fa-hand-middle-finger:before {
  content: "\f806"
}

.fa-hand-paper:before {
  content: "\f256"
}

.fa-hand-peace:before {
  content: "\f25b"
}

.fa-hand-point-down:before {
  content: "\f0a7"
}

.fa-hand-point-left:before {
  content: "\f0a5"
}

.fa-hand-point-right:before {
  content: "\f0a4"
}

.fa-hand-point-up:before {
  content: "\f0a6"
}

.fa-hand-pointer:before {
  content: "\f25a"
}

.fa-hand-rock:before {
  content: "\f255"
}

.fa-hand-scissors:before {
  content: "\f257"
}

.fa-hand-sparkles:before {
  content: "\e05d"
}

.fa-hand-spock:before {
  content: "\f259"
}

.fa-hands:before {
  content: "\f4c2"
}

.fa-hands-helping:before {
  content: "\f4c4"
}

.fa-hands-wash:before {
  content: "\e05e"
}

.fa-handshake:before {
  content: "\f2b5"
}

.fa-handshake-alt-slash:before {
  content: "\e05f"
}

.fa-handshake-slash:before {
  content: "\e060"
}

.fa-hanukiah:before {
  content: "\f6e6"
}

.fa-hard-hat:before {
  content: "\f807"
}

.fa-hashtag:before {
  content: "\f292"
}

.fa-hat-cowboy:before {
  content: "\f8c0"
}

.fa-hat-cowboy-side:before {
  content: "\f8c1"
}

.fa-hat-wizard:before {
  content: "\f6e8"
}

.fa-hdd:before {
  content: "\f0a0"
}

.fa-head-side-cough:before {
  content: "\e061"
}

.fa-head-side-cough-slash:before {
  content: "\e062"
}

.fa-head-side-mask:before {
  content: "\e063"
}

.fa-head-side-virus:before {
  content: "\e064"
}

.fa-heading:before {
  content: "\f1dc"
}

.fa-headphones:before {
  content: "\f025"
}

.fa-headphones-alt:before {
  content: "\f58f"
}

.fa-headset:before {
  content: "\f590"
}

.fa-heart:before {
  content: "\f004"
}

.fa-heart-broken:before {
  content: "\f7a9"
}

.fa-heartbeat:before {
  content: "\f21e"
}

.fa-helicopter:before {
  content: "\f533"
}

.fa-highlighter:before {
  content: "\f591"
}

.fa-hiking:before {
  content: "\f6ec"
}

.fa-hippo:before {
  content: "\f6ed"
}

.fa-hips:before {
  content: "\f452"
}

.fa-hire-a-helper:before {
  content: "\f3b0"
}

.fa-history:before {
  content: "\f1da"
}

.fa-hive:before {
  content: "\e07f"
}

.fa-hockey-puck:before {
  content: "\f453"
}

.fa-holly-berry:before {
  content: "\f7aa"
}

.fa-home:before {
  content: "\f015"
}

.fa-hooli:before {
  content: "\f427"
}

.fa-hornbill:before {
  content: "\f592"
}

.fa-horse:before {
  content: "\f6f0"
}

.fa-horse-head:before {
  content: "\f7ab"
}

.fa-hospital:before {
  content: "\f0f8"
}

.fa-hospital-alt:before {
  content: "\f47d"
}

.fa-hospital-symbol:before {
  content: "\f47e"
}

.fa-hospital-user:before {
  content: "\f80d"
}

.fa-hot-tub:before {
  content: "\f593"
}

.fa-hotdog:before {
  content: "\f80f"
}

.fa-hotel:before {
  content: "\f594"
}

.fa-hotjar:before {
  content: "\f3b1"
}

.fa-hourglass:before {
  content: "\f254"
}

.fa-hourglass-end:before {
  content: "\f253"
}

.fa-hourglass-half:before {
  content: "\f252"
}

.fa-hourglass-start:before {
  content: "\f251"
}

.fa-house-damage:before {
  content: "\f6f1"
}

.fa-house-user:before {
  content: "\e065"
}

.fa-houzz:before {
  content: "\f27c"
}

.fa-hryvnia:before {
  content: "\f6f2"
}

.fa-html5:before {
  content: "\f13b"
}

.fa-hubspot:before {
  content: "\f3b2"
}

.fa-i-cursor:before {
  content: "\f246"
}

.fa-ice-cream:before {
  content: "\f810"
}

.fa-icicles:before {
  content: "\f7ad"
}

.fa-icons:before {
  content: "\f86d"
}

.fa-id-badge:before {
  content: "\f2c1"
}

.fa-id-card:before {
  content: "\f2c2"
}

.fa-id-card-alt:before {
  content: "\f47f"
}

.fa-ideal:before {
  content: "\e013"
}

.fa-igloo:before {
  content: "\f7ae"
}

.fa-image:before {
  content: "\f03e"
}

.fa-images:before {
  content: "\f302"
}

.fa-imdb:before {
  content: "\f2d8"
}

.fa-inbox:before {
  content: "\f01c"
}

.fa-indent:before {
  content: "\f03c"
}

.fa-industry:before {
  content: "\f275"
}

.fa-infinity:before {
  content: "\f534"
}

.fa-info:before {
  content: "\f129"
}

.fa-info-circle:before {
  content: "\f05a"
}

.fa-innosoft:before {
  content: "\e080"
}

.fa-instagram:before {
  content: "\f16d"
}

.fa-instagram-square:before {
  content: "\e055"
}

.fa-instalod:before {
  content: "\e081"
}

.fa-intercom:before {
  content: "\f7af"
}

.fa-internet-explorer:before {
  content: "\f26b"
}

.fa-invision:before {
  content: "\f7b0"
}

.fa-ioxhost:before {
  content: "\f208"
}

.fa-italic:before {
  content: "\f033"
}

.fa-itch-io:before {
  content: "\f83a"
}

.fa-itunes:before {
  content: "\f3b4"
}

.fa-itunes-note:before {
  content: "\f3b5"
}

.fa-java:before {
  content: "\f4e4"
}

.fa-jedi:before {
  content: "\f669"
}

.fa-jedi-order:before {
  content: "\f50e"
}

.fa-jenkins:before {
  content: "\f3b6"
}

.fa-jira:before {
  content: "\f7b1"
}

.fa-joget:before {
  content: "\f3b7"
}

.fa-joint:before {
  content: "\f595"
}

.fa-joomla:before {
  content: "\f1aa"
}

.fa-journal-whills:before {
  content: "\f66a"
}

.fa-js:before {
  content: "\f3b8"
}

.fa-js-square:before {
  content: "\f3b9"
}

.fa-jsfiddle:before {
  content: "\f1cc"
}

.fa-kaaba:before {
  content: "\f66b"
}

.fa-kaggle:before {
  content: "\f5fa"
}

.fa-key:before {
  content: "\f084"
}

.fa-keybase:before {
  content: "\f4f5"
}

.fa-keyboard:before {
  content: "\f11c"
}

.fa-keycdn:before {
  content: "\f3ba"
}

.fa-khanda:before {
  content: "\f66d"
}

.fa-kickstarter:before {
  content: "\f3bb"
}

.fa-kickstarter-k:before {
  content: "\f3bc"
}

.fa-kiss:before {
  content: "\f596"
}

.fa-kiss-beam:before {
  content: "\f597"
}

.fa-kiss-wink-heart:before {
  content: "\f598"
}

.fa-kiwi-bird:before {
  content: "\f535"
}

.fa-korvue:before {
  content: "\f42f"
}

.fa-landmark:before {
  content: "\f66f"
}

.fa-language:before {
  content: "\f1ab"
}

.fa-laptop:before {
  content: "\f109"
}

.fa-laptop-code:before {
  content: "\f5fc"
}

.fa-laptop-house:before {
  content: "\e066"
}

.fa-laptop-medical:before {
  content: "\f812"
}

.fa-laravel:before {
  content: "\f3bd"
}

.fa-lastfm:before {
  content: "\f202"
}

.fa-lastfm-square:before {
  content: "\f203"
}

.fa-laugh:before {
  content: "\f599"
}

.fa-laugh-beam:before {
  content: "\f59a"
}

.fa-laugh-squint:before {
  content: "\f59b"
}

.fa-laugh-wink:before {
  content: "\f59c"
}

.fa-layer-group:before {
  content: "\f5fd"
}

.fa-leaf:before {
  content: "\f06c"
}

.fa-leanpub:before {
  content: "\f212"
}

.fa-lemon:before {
  content: "\f094"
}

.fa-less:before {
  content: "\f41d"
}

.fa-less-than:before {
  content: "\f536"
}

.fa-less-than-equal:before {
  content: "\f537"
}

.fa-level-down-alt:before {
  content: "\f3be"
}

.fa-level-up-alt:before {
  content: "\f3bf"
}

.fa-life-ring:before {
  content: "\f1cd"
}

.fa-lightbulb:before {
  content: "\f0eb"
}

.fa-line:before {
  content: "\f3c0"
}

.fa-link:before {
  content: "\f0c1"
}

.fa-linkedin:before {
  content: "\f08c"
}

.fa-linkedin-in:before {
  content: "\f0e1"
}

.fa-linode:before {
  content: "\f2b8"
}

.fa-linux:before {
  content: "\f17c"
}

.fa-lira-sign:before {
  content: "\f195"
}

.fa-list:before {
  content: "\f03a"
}

.fa-list-alt:before {
  content: "\f022"
}

.fa-list-ol:before {
  content: "\f0cb"
}

.fa-list-ul:before {
  content: "\f0ca"
}

.fa-location-arrow:before {
  content: "\f124"
}

.fa-lock:before {
  content: "\f023"
}

.fa-lock-open:before {
  content: "\f3c1"
}

.fa-long-arrow-alt-down:before {
  content: "\f309"
}

.fa-long-arrow-alt-left:before {
  content: "\f30a"
}

.fa-long-arrow-alt-right:before {
  content: "\f30b"
}

.fa-long-arrow-alt-up:before {
  content: "\f30c"
}

.fa-low-vision:before {
  content: "\f2a8"
}

.fa-luggage-cart:before {
  content: "\f59d"
}

.fa-lungs:before {
  content: "\f604"
}

.fa-lungs-virus:before {
  content: "\e067"
}

.fa-lyft:before {
  content: "\f3c3"
}

.fa-magento:before {
  content: "\f3c4"
}

.fa-magic:before {
  content: "\f0d0"
}

.fa-magnet:before {
  content: "\f076"
}

.fa-mail-bulk:before {
  content: "\f674"
}

.fa-mailchimp:before {
  content: "\f59e"
}

.fa-male:before {
  content: "\f183"
}

.fa-mandalorian:before {
  content: "\f50f"
}

.fa-map:before {
  content: "\f279"
}

.fa-map-marked:before {
  content: "\f59f"
}

.fa-map-marked-alt:before {
  content: "\f5a0"
}

.fa-map-marker:before {
  content: "\f041"
}

.fa-map-marker-alt:before {
  content: "\f3c5"
}

.fa-map-pin:before {
  content: "\f276"
}

.fa-map-signs:before {
  content: "\f277"
}

.fa-markdown:before {
  content: "\f60f"
}

.fa-marker:before {
  content: "\f5a1"
}

.fa-mars:before {
  content: "\f222"
}

.fa-mars-double:before {
  content: "\f227"
}

.fa-mars-stroke:before {
  content: "\f229"
}

.fa-mars-stroke-h:before {
  content: "\f22b"
}

.fa-mars-stroke-v:before {
  content: "\f22a"
}

.fa-mask:before {
  content: "\f6fa"
}

.fa-mastodon:before {
  content: "\f4f6"
}

.fa-maxcdn:before {
  content: "\f136"
}

.fa-mdb:before {
  content: "\f8ca"
}

.fa-medal:before {
  content: "\f5a2"
}

.fa-medapps:before {
  content: "\f3c6"
}

.fa-medium:before {
  content: "\f23a"
}

.fa-medium-m:before {
  content: "\f3c7"
}

.fa-medkit:before {
  content: "\f0fa"
}

.fa-medrt:before {
  content: "\f3c8"
}

.fa-meetup:before {
  content: "\f2e0"
}

.fa-megaport:before {
  content: "\f5a3"
}

.fa-meh:before {
  content: "\f11a"
}

.fa-meh-blank:before {
  content: "\f5a4"
}

.fa-meh-rolling-eyes:before {
  content: "\f5a5"
}

.fa-memory:before {
  content: "\f538"
}

.fa-mendeley:before {
  content: "\f7b3"
}

.fa-menorah:before {
  content: "\f676"
}

.fa-mercury:before {
  content: "\f223"
}

.fa-meteor:before {
  content: "\f753"
}

.fa-microblog:before {
  content: "\e01a"
}

.fa-microchip:before {
  content: "\f2db"
}

.fa-microphone:before {
  content: "\f130"
}

.fa-microphone-alt:before {
  content: "\f3c9"
}

.fa-microphone-alt-slash:before {
  content: "\f539"
}

.fa-microphone-slash:before {
  content: "\f131"
}

.fa-microscope:before {
  content: "\f610"
}

.fa-microsoft:before {
  content: "\f3ca"
}

.fa-minus:before {
  content: "\f068"
}

.fa-minus-circle:before {
  content: "\f056"
}

.fa-minus-square:before {
  content: "\f146"
}

.fa-mitten:before {
  content: "\f7b5"
}

.fa-mix:before {
  content: "\f3cb"
}

.fa-mixcloud:before {
  content: "\f289"
}

.fa-mixer:before {
  content: "\e056"
}

.fa-mizuni:before {
  content: "\f3cc"
}

.fa-mobile:before {
  content: "\f10b"
}

.fa-mobile-alt:before {
  content: "\f3cd"
}

.fa-modx:before {
  content: "\f285"
}

.fa-monero:before {
  content: "\f3d0"
}

.fa-money-bill:before {
  content: "\f0d6"
}

.fa-money-bill-alt:before {
  content: "\f3d1"
}

.fa-money-bill-wave:before {
  content: "\f53a"
}

.fa-money-bill-wave-alt:before {
  content: "\f53b"
}

.fa-money-check:before {
  content: "\f53c"
}

.fa-money-check-alt:before {
  content: "\f53d"
}

.fa-monument:before {
  content: "\f5a6"
}

.fa-moon:before {
  content: "\f186"
}

.fa-mortar-pestle:before {
  content: "\f5a7"
}

.fa-mosque:before {
  content: "\f678"
}

.fa-motorcycle:before {
  content: "\f21c"
}

.fa-mountain:before {
  content: "\f6fc"
}

.fa-mouse:before {
  content: "\f8cc"
}

.fa-mouse-pointer:before {
  content: "\f245"
}

.fa-mug-hot:before {
  content: "\f7b6"
}

.fa-music:before {
  content: "\f001"
}

.fa-napster:before {
  content: "\f3d2"
}

.fa-neos:before {
  content: "\f612"
}

.fa-network-wired:before {
  content: "\f6ff"
}

.fa-neuter:before {
  content: "\f22c"
}

.fa-newspaper:before {
  content: "\f1ea"
}

.fa-nimblr:before {
  content: "\f5a8"
}

.fa-node:before {
  content: "\f419"
}

.fa-node-js:before {
  content: "\f3d3"
}

.fa-not-equal:before {
  content: "\f53e"
}

.fa-notes-medical:before {
  content: "\f481"
}

.fa-npm:before {
  content: "\f3d4"
}

.fa-ns8:before {
  content: "\f3d5"
}

.fa-nutritionix:before {
  content: "\f3d6"
}

.fa-object-group:before {
  content: "\f247"
}

.fa-object-ungroup:before {
  content: "\f248"
}

.fa-octopus-deploy:before {
  content: "\e082"
}

.fa-odnoklassniki:before {
  content: "\f263"
}

.fa-odnoklassniki-square:before {
  content: "\f264"
}

.fa-oil-can:before {
  content: "\f613"
}

.fa-old-republic:before {
  content: "\f510"
}

.fa-om:before {
  content: "\f679"
}

.fa-opencart:before {
  content: "\f23d"
}

.fa-openid:before {
  content: "\f19b"
}

.fa-opera:before {
  content: "\f26a"
}

.fa-optin-monster:before {
  content: "\f23c"
}

.fa-orcid:before {
  content: "\f8d2"
}

.fa-osi:before {
  content: "\f41a"
}

.fa-otter:before {
  content: "\f700"
}

.fa-outdent:before {
  content: "\f03b"
}

.fa-page4:before {
  content: "\f3d7"
}

.fa-pagelines:before {
  content: "\f18c"
}

.fa-pager:before {
  content: "\f815"
}

.fa-paint-brush:before {
  content: "\f1fc"
}

.fa-paint-roller:before {
  content: "\f5aa"
}

.fa-palette:before {
  content: "\f53f"
}

.fa-palfed:before {
  content: "\f3d8"
}

.fa-pallet:before {
  content: "\f482"
}

.fa-paper-plane:before {
  content: "\f1d8"
}

.fa-paperclip:before {
  content: "\f0c6"
}

.fa-parachute-box:before {
  content: "\f4cd"
}

.fa-paragraph:before {
  content: "\f1dd"
}

.fa-parking:before {
  content: "\f540"
}

.fa-passport:before {
  content: "\f5ab"
}

.fa-pastafarianism:before {
  content: "\f67b"
}

.fa-paste:before {
  content: "\f0ea"
}

.fa-patreon:before {
  content: "\f3d9"
}

.fa-pause:before {
  content: "\f04c"
}

.fa-pause-circle:before {
  content: "\f28b"
}

.fa-paw:before {
  content: "\f1b0"
}

.fa-paypal:before {
  content: "\f1ed"
}

.fa-peace:before {
  content: "\f67c"
}

.fa-pen:before {
  content: "\f304"
}

.fa-pen-alt:before {
  content: "\f305"
}

.fa-pen-fancy:before {
  content: "\f5ac"
}

.fa-pen-nib:before {
  content: "\f5ad"
}

.fa-pen-square:before {
  content: "\f14b"
}

.fa-pencil-alt:before {
  content: "\f303"
}

.fa-pencil-ruler:before {
  content: "\f5ae"
}

.fa-penny-arcade:before {
  content: "\f704"
}

.fa-people-arrows:before {
  content: "\e068"
}

.fa-people-carry:before {
  content: "\f4ce"
}

.fa-pepper-hot:before {
  content: "\f816"
}

.fa-perbyte:before {
  content: "\e083"
}

.fa-percent:before {
  content: "\f295"
}

.fa-percentage:before {
  content: "\f541"
}

.fa-periscope:before {
  content: "\f3da"
}

.fa-person-booth:before {
  content: "\f756"
}

.fa-phabricator:before {
  content: "\f3db"
}

.fa-phoenix-framework:before {
  content: "\f3dc"
}

.fa-phoenix-squadron:before {
  content: "\f511"
}

.fa-phone:before {
  content: "\f095"
}

.fa-phone-alt:before {
  content: "\f879"
}

.fa-phone-slash:before {
  content: "\f3dd"
}

.fa-phone-square:before {
  content: "\f098"
}

.fa-phone-square-alt:before {
  content: "\f87b"
}

.fa-phone-volume:before {
  content: "\f2a0"
}

.fa-photo-video:before {
  content: "\f87c"
}

.fa-php:before {
  content: "\f457"
}

.fa-pied-piper:before {
  content: "\f2ae"
}

.fa-pied-piper-alt:before {
  content: "\f1a8"
}

.fa-pied-piper-hat:before {
  content: "\f4e5"
}

.fa-pied-piper-pp:before {
  content: "\f1a7"
}

.fa-pied-piper-square:before {
  content: "\e01e"
}

.fa-piggy-bank:before {
  content: "\f4d3"
}

.fa-pills:before {
  content: "\f484"
}

.fa-pinterest:before {
  content: "\f0d2"
}

.fa-pinterest-p:before {
  content: "\f231"
}

.fa-pinterest-square:before {
  content: "\f0d3"
}

.fa-pizza-slice:before {
  content: "\f818"
}

.fa-place-of-worship:before {
  content: "\f67f"
}

.fa-plane:before {
  content: "\f072"
}

.fa-plane-arrival:before {
  content: "\f5af"
}

.fa-plane-departure:before {
  content: "\f5b0"
}

.fa-plane-slash:before {
  content: "\e069"
}

.fa-play:before {
  content: "\f04b"
}

.fa-play-circle:before {
  content: "\f144"
}

.fa-playstation:before {
  content: "\f3df"
}

.fa-plug:before {
  content: "\f1e6"
}

.fa-plus:before {
  content: "\f067"
}

.fa-plus-circle:before {
  content: "\f055"
}

.fa-plus-square:before {
  content: "\f0fe"
}

.fa-podcast:before {
  content: "\f2ce"
}

.fa-poll:before {
  content: "\f681"
}

.fa-poll-h:before {
  content: "\f682"
}

.fa-poo:before {
  content: "\f2fe"
}

.fa-poo-storm:before {
  content: "\f75a"
}

.fa-poop:before {
  content: "\f619"
}

.fa-portrait:before {
  content: "\f3e0"
}

.fa-pound-sign:before {
  content: "\f154"
}

.fa-power-off:before {
  content: "\f011"
}

.fa-pray:before {
  content: "\f683"
}

.fa-praying-hands:before {
  content: "\f684"
}

.fa-prescription:before {
  content: "\f5b1"
}

.fa-prescription-bottle:before {
  content: "\f485"
}

.fa-prescription-bottle-alt:before {
  content: "\f486"
}

.fa-print:before {
  content: "\f02f"
}

.fa-procedures:before {
  content: "\f487"
}

.fa-product-hunt:before {
  content: "\f288"
}

.fa-project-diagram:before {
  content: "\f542"
}

.fa-pump-medical:before {
  content: "\e06a"
}

.fa-pump-soap:before {
  content: "\e06b"
}

.fa-pushed:before {
  content: "\f3e1"
}

.fa-puzzle-piece:before {
  content: "\f12e"
}

.fa-python:before {
  content: "\f3e2"
}

.fa-qq:before {
  content: "\f1d6"
}

.fa-qrcode:before {
  content: "\f029"
}

.fa-question:before {
  content: "\f128"
}

.fa-question-circle:before {
  content: "\f059"
}

.fa-quidditch:before {
  content: "\f458"
}

.fa-quinscape:before {
  content: "\f459"
}

.fa-quora:before {
  content: "\f2c4"
}

.fa-quote-left:before {
  content: "\f10d"
}

.fa-quote-right:before {
  content: "\f10e"
}

.fa-quran:before {
  content: "\f687"
}

.fa-r-project:before {
  content: "\f4f7"
}

.fa-radiation:before {
  content: "\f7b9"
}

.fa-radiation-alt:before {
  content: "\f7ba"
}

.fa-rainbow:before {
  content: "\f75b"
}

.fa-random:before {
  content: "\f074"
}

.fa-raspberry-pi:before {
  content: "\f7bb"
}

.fa-ravelry:before {
  content: "\f2d9"
}

.fa-react:before {
  content: "\f41b"
}

.fa-reacteurope:before {
  content: "\f75d"
}

.fa-readme:before {
  content: "\f4d5"
}

.fa-rebel:before {
  content: "\f1d0"
}

.fa-receipt:before {
  content: "\f543"
}

.fa-record-vinyl:before {
  content: "\f8d9"
}

.fa-recycle:before {
  content: "\f1b8"
}

.fa-red-river:before {
  content: "\f3e3"
}

.fa-reddit:before {
  content: "\f1a1"
}

.fa-reddit-alien:before {
  content: "\f281"
}

.fa-reddit-square:before {
  content: "\f1a2"
}

.fa-redhat:before {
  content: "\f7bc"
}

.fa-redo:before {
  content: "\f01e"
}

.fa-redo-alt:before {
  content: "\f2f9"
}

.fa-registered:before {
  content: "\f25d"
}

.fa-remove-format:before {
  content: "\f87d"
}

.fa-renren:before {
  content: "\f18b"
}

.fa-reply:before {
  content: "\f3e5"
}

.fa-reply-all:before {
  content: "\f122"
}

.fa-replyd:before {
  content: "\f3e6"
}

.fa-republican:before {
  content: "\f75e"
}

.fa-researchgate:before {
  content: "\f4f8"
}

.fa-resolving:before {
  content: "\f3e7"
}

.fa-restroom:before {
  content: "\f7bd"
}

.fa-retweet:before {
  content: "\f079"
}

.fa-rev:before {
  content: "\f5b2"
}

.fa-ribbon:before {
  content: "\f4d6"
}

.fa-ring:before {
  content: "\f70b"
}

.fa-road:before {
  content: "\f018"
}

.fa-robot:before {
  content: "\f544"
}

.fa-rocket:before {
  content: "\f135"
}

.fa-rocketchat:before {
  content: "\f3e8"
}

.fa-rockrms:before {
  content: "\f3e9"
}

.fa-route:before {
  content: "\f4d7"
}

.fa-rss:before {
  content: "\f09e"
}

.fa-rss-square:before {
  content: "\f143"
}

.fa-ruble-sign:before {
  content: "\f158"
}

.fa-ruler:before {
  content: "\f545"
}

.fa-ruler-combined:before {
  content: "\f546"
}

.fa-ruler-horizontal:before {
  content: "\f547"
}

.fa-ruler-vertical:before {
  content: "\f548"
}

.fa-running:before {
  content: "\f70c"
}

.fa-rupee-sign:before {
  content: "\f156"
}

.fa-rust:before {
  content: "\e07a"
}

.fa-sad-cry:before {
  content: "\f5b3"
}

.fa-sad-tear:before {
  content: "\f5b4"
}

.fa-safari:before {
  content: "\f267"
}

.fa-salesforce:before {
  content: "\f83b"
}

.fa-sass:before {
  content: "\f41e"
}

.fa-satellite:before {
  content: "\f7bf"
}

.fa-satellite-dish:before {
  content: "\f7c0"
}

.fa-save:before {
  content: "\f0c7"
}

.fa-schlix:before {
  content: "\f3ea"
}

.fa-school:before {
  content: "\f549"
}

.fa-screwdriver:before {
  content: "\f54a"
}

.fa-scribd:before {
  content: "\f28a"
}

.fa-scroll:before {
  content: "\f70e"
}

.fa-sd-card:before {
  content: "\f7c2"
}

.fa-search:before {
  content: "\f002"
}

.fa-search-dollar:before {
  content: "\f688"
}

.fa-search-location:before {
  content: "\f689"
}

.fa-search-minus:before {
  content: "\f010"
}

.fa-search-plus:before {
  content: "\f00e"
}

.fa-searchengin:before {
  content: "\f3eb"
}

.fa-seedling:before {
  content: "\f4d8"
}

.fa-sellcast:before {
  content: "\f2da"
}

.fa-sellsy:before {
  content: "\f213"
}

.fa-server:before {
  content: "\f233"
}

.fa-servicestack:before {
  content: "\f3ec"
}

.fa-shapes:before {
  content: "\f61f"
}

.fa-share:before {
  content: "\f064"
}

.fa-share-alt:before {
  content: "\f1e0"
}

.fa-share-alt-square:before {
  content: "\f1e1"
}

.fa-share-square:before {
  content: "\f14d"
}

.fa-shekel-sign:before {
  content: "\f20b"
}

.fa-shield-alt:before {
  content: "\f3ed"
}

.fa-shield-virus:before {
  content: "\e06c"
}

.fa-ship:before {
  content: "\f21a"
}

.fa-shipping-fast:before {
  content: "\f48b"
}

.fa-shirtsinbulk:before {
  content: "\f214"
}

.fa-shoe-prints:before {
  content: "\f54b"
}

.fa-shopify:before {
  content: "\e057"
}

.fa-shopping-bag:before {
  content: "\f290"
}

.fa-shopping-basket:before {
  content: "\f291"
}

.fa-shopping-cart:before {
  content: "\f07a"
}

.fa-shopware:before {
  content: "\f5b5"
}

.fa-shower:before {
  content: "\f2cc"
}

.fa-shuttle-van:before {
  content: "\f5b6"
}

.fa-sign:before {
  content: "\f4d9"
}

.fa-sign-in-alt:before {
  content: "\f2f6"
}

.fa-sign-language:before {
  content: "\f2a7"
}

.fa-sign-out-alt:before {
  content: "\f2f5"
}

.fa-signal:before {
  content: "\f012"
}

.fa-signature:before {
  content: "\f5b7"
}

.fa-sim-card:before {
  content: "\f7c4"
}

.fa-simplybuilt:before {
  content: "\f215"
}

.fa-sink:before {
  content: "\e06d"
}

.fa-sistrix:before {
  content: "\f3ee"
}

.fa-sitemap:before {
  content: "\f0e8"
}

.fa-sith:before {
  content: "\f512"
}

.fa-skating:before {
  content: "\f7c5"
}

.fa-sketch:before {
  content: "\f7c6"
}

.fa-skiing:before {
  content: "\f7c9"
}

.fa-skiing-nordic:before {
  content: "\f7ca"
}

.fa-skull:before {
  content: "\f54c"
}

.fa-skull-crossbones:before {
  content: "\f714"
}

.fa-skyatlas:before {
  content: "\f216"
}

.fa-skype:before {
  content: "\f17e"
}

.fa-slack:before {
  content: "\f198"
}

.fa-slack-hash:before {
  content: "\f3ef"
}

.fa-slash:before {
  content: "\f715"
}

.fa-sleigh:before {
  content: "\f7cc"
}

.fa-sliders-h:before {
  content: "\f1de"
}

.fa-slideshare:before {
  content: "\f1e7"
}

.fa-smile:before {
  content: "\f118"
}

.fa-smile-beam:before {
  content: "\f5b8"
}

.fa-smile-wink:before {
  content: "\f4da"
}

.fa-smog:before {
  content: "\f75f"
}

.fa-smoking:before {
  content: "\f48d"
}

.fa-smoking-ban:before {
  content: "\f54d"
}

.fa-sms:before {
  content: "\f7cd"
}

.fa-snapchat:before {
  content: "\f2ab"
}

.fa-snapchat-ghost:before {
  content: "\f2ac"
}

.fa-snapchat-square:before {
  content: "\f2ad"
}

.fa-snowboarding:before {
  content: "\f7ce"
}

.fa-snowflake:before {
  content: "\f2dc"
}

.fa-snowman:before {
  content: "\f7d0"
}

.fa-snowplow:before {
  content: "\f7d2"
}

.fa-soap:before {
  content: "\e06e"
}

.fa-socks:before {
  content: "\f696"
}

.fa-solar-panel:before {
  content: "\f5ba"
}

.fa-sort:before {
  content: "\f0dc"
}

.fa-sort-alpha-down:before {
  content: "\f15d"
}

.fa-sort-alpha-down-alt:before {
  content: "\f881"
}

.fa-sort-alpha-up:before {
  content: "\f15e"
}

.fa-sort-alpha-up-alt:before {
  content: "\f882"
}

.fa-sort-amount-down:before {
  content: "\f160"
}

.fa-sort-amount-down-alt:before {
  content: "\f884"
}

.fa-sort-amount-up:before {
  content: "\f161"
}

.fa-sort-amount-up-alt:before {
  content: "\f885"
}

.fa-sort-down:before {
  content: "\f0dd"
}

.fa-sort-numeric-down:before {
  content: "\f162"
}

.fa-sort-numeric-down-alt:before {
  content: "\f886"
}

.fa-sort-numeric-up:before {
  content: "\f163"
}

.fa-sort-numeric-up-alt:before {
  content: "\f887"
}

.fa-sort-up:before {
  content: "\f0de"
}

.fa-soundcloud:before {
  content: "\f1be"
}

.fa-sourcetree:before {
  content: "\f7d3"
}

.fa-spa:before {
  content: "\f5bb"
}

.fa-space-shuttle:before {
  content: "\f197"
}

.fa-speakap:before {
  content: "\f3f3"
}

.fa-speaker-deck:before {
  content: "\f83c"
}

.fa-spell-check:before {
  content: "\f891"
}

.fa-spider:before {
  content: "\f717"
}

.fa-spinner:before {
  content: "\f110"
}

.fa-splotch:before {
  content: "\f5bc"
}

.fa-spotify:before {
  content: "\f1bc"
}

.fa-spray-can:before {
  content: "\f5bd"
}

.fa-square:before {
  content: "\f0c8"
}

.fa-square-full:before {
  content: "\f45c"
}

.fa-square-root-alt:before {
  content: "\f698"
}

.fa-squarespace:before {
  content: "\f5be"
}

.fa-stack-exchange:before {
  content: "\f18d"
}

.fa-stack-overflow:before {
  content: "\f16c"
}

.fa-stackpath:before {
  content: "\f842"
}

.fa-stamp:before {
  content: "\f5bf"
}

.fa-star:before {
  content: "\f005"
}

.fa-star-and-crescent:before {
  content: "\f699"
}

.fa-star-half:before {
  content: "\f089"
}

.fa-star-half-alt:before {
  content: "\f5c0"
}

.fa-star-of-david:before {
  content: "\f69a"
}

.fa-star-of-life:before {
  content: "\f621"
}

.fa-staylinked:before {
  content: "\f3f5"
}

.fa-steam:before {
  content: "\f1b6"
}

.fa-steam-square:before {
  content: "\f1b7"
}

.fa-steam-symbol:before {
  content: "\f3f6"
}

.fa-step-backward:before {
  content: "\f048"
}

.fa-step-forward:before {
  content: "\f051"
}

.fa-stethoscope:before {
  content: "\f0f1"
}

.fa-sticker-mule:before {
  content: "\f3f7"
}

.fa-sticky-note:before {
  content: "\f249"
}

.fa-stop:before {
  content: "\f04d"
}

.fa-stop-circle:before {
  content: "\f28d"
}

.fa-stopwatch:before {
  content: "\f2f2"
}

.fa-stopwatch-20:before {
  content: "\e06f"
}

.fa-store:before {
  content: "\f54e"
}

.fa-store-alt:before {
  content: "\f54f"
}

.fa-store-alt-slash:before {
  content: "\e070"
}

.fa-store-slash:before {
  content: "\e071"
}

.fa-strava:before {
  content: "\f428"
}

.fa-stream:before {
  content: "\f550"
}

.fa-street-view:before {
  content: "\f21d"
}

.fa-strikethrough:before {
  content: "\f0cc"
}

.fa-stripe:before {
  content: "\f429"
}

.fa-stripe-s:before {
  content: "\f42a"
}

.fa-stroopwafel:before {
  content: "\f551"
}

.fa-studiovinari:before {
  content: "\f3f8"
}

.fa-stumbleupon:before {
  content: "\f1a4"
}

.fa-stumbleupon-circle:before {
  content: "\f1a3"
}

.fa-subscript:before {
  content: "\f12c"
}

.fa-subway:before {
  content: "\f239"
}

.fa-suitcase:before {
  content: "\f0f2"
}

.fa-suitcase-rolling:before {
  content: "\f5c1"
}

.fa-sun:before {
  content: "\f185"
}

.fa-superpowers:before {
  content: "\f2dd"
}

.fa-superscript:before {
  content: "\f12b"
}

.fa-supple:before {
  content: "\f3f9"
}

.fa-surprise:before {
  content: "\f5c2"
}

.fa-suse:before {
  content: "\f7d6"
}

.fa-swatchbook:before {
  content: "\f5c3"
}

.fa-swift:before {
  content: "\f8e1"
}

.fa-swimmer:before {
  content: "\f5c4"
}

.fa-swimming-pool:before {
  content: "\f5c5"
}

.fa-symfony:before {
  content: "\f83d"
}

.fa-synagogue:before {
  content: "\f69b"
}

.fa-sync:before {
  content: "\f021"
}

.fa-sync-alt:before {
  content: "\f2f1"
}

.fa-syringe:before {
  content: "\f48e"
}

.fa-table:before {
  content: "\f0ce"
}

.fa-table-tennis:before {
  content: "\f45d"
}

.fa-tablet:before {
  content: "\f10a"
}

.fa-tablet-alt:before {
  content: "\f3fa"
}

.fa-tablets:before {
  content: "\f490"
}

.fa-tachometer-alt:before {
  content: "\f3fd"
}

.fa-tag:before {
  content: "\f02b"
}

.fa-tags:before {
  content: "\f02c"
}

.fa-tape:before {
  content: "\f4db"
}

.fa-tasks:before {
  content: "\f0ae"
}

.fa-taxi:before {
  content: "\f1ba"
}

.fa-teamspeak:before {
  content: "\f4f9"
}

.fa-teeth:before {
  content: "\f62e"
}

.fa-teeth-open:before {
  content: "\f62f"
}

.fa-telegram:before {
  content: "\f2c6"
}

.fa-telegram-plane:before {
  content: "\f3fe"
}

.fa-temperature-high:before {
  content: "\f769"
}

.fa-temperature-low:before {
  content: "\f76b"
}

.fa-tencent-weibo:before {
  content: "\f1d5"
}

.fa-tenge:before {
  content: "\f7d7"
}

.fa-terminal:before {
  content: "\f120"
}

.fa-text-height:before {
  content: "\f034"
}

.fa-text-width:before {
  content: "\f035"
}

.fa-th:before {
  content: "\f00a"
}

.fa-th-large:before {
  content: "\f009"
}

.fa-th-list:before {
  content: "\f00b"
}

.fa-the-red-yeti:before {
  content: "\f69d"
}

.fa-theater-masks:before {
  content: "\f630"
}

.fa-themeco:before {
  content: "\f5c6"
}

.fa-themeisle:before {
  content: "\f2b2"
}

.fa-thermometer:before {
  content: "\f491"
}

.fa-thermometer-empty:before {
  content: "\f2cb"
}

.fa-thermometer-full:before {
  content: "\f2c7"
}

.fa-thermometer-half:before {
  content: "\f2c9"
}

.fa-thermometer-quarter:before {
  content: "\f2ca"
}

.fa-thermometer-three-quarters:before {
  content: "\f2c8"
}

.fa-think-peaks:before {
  content: "\f731"
}

.fa-thumbs-down:before {
  content: "\f165"
}

.fa-thumbs-up:before {
  content: "\f164"
}

.fa-thumbtack:before {
  content: "\f08d"
}

.fa-ticket-alt:before {
  content: "\f3ff"
}

.fa-tiktok:before {
  content: "\e07b"
}

.fa-times:before {
  content: "\f00d"
}

.fa-times-circle:before {
  content: "\f057"
}

.fa-tint:before {
  content: "\f043"
}

.fa-tint-slash:before {
  content: "\f5c7"
}

.fa-tired:before {
  content: "\f5c8"
}

.fa-toggle-off:before {
  content: "\f204"
}

.fa-toggle-on:before {
  content: "\f205"
}

.fa-toilet:before {
  content: "\f7d8"
}

.fa-toilet-paper:before {
  content: "\f71e"
}

.fa-toilet-paper-slash:before {
  content: "\e072"
}

.fa-toolbox:before {
  content: "\f552"
}

.fa-tools:before {
  content: "\f7d9"
}

.fa-tooth:before {
  content: "\f5c9"
}

.fa-torah:before {
  content: "\f6a0"
}

.fa-torii-gate:before {
  content: "\f6a1"
}

.fa-tractor:before {
  content: "\f722"
}

.fa-trade-federation:before {
  content: "\f513"
}

.fa-trademark:before {
  content: "\f25c"
}

.fa-traffic-light:before {
  content: "\f637"
}

.fa-trailer:before {
  content: "\e041"
}

.fa-train:before {
  content: "\f238"
}

.fa-tram:before {
  content: "\f7da"
}

.fa-transgender:before {
  content: "\f224"
}

.fa-transgender-alt:before {
  content: "\f225"
}

.fa-trash:before {
  content: "\f1f8"
}

.fa-trash-alt:before {
  content: "\f2ed"
}

.fa-trash-restore:before {
  content: "\f829"
}

.fa-trash-restore-alt:before {
  content: "\f82a"
}

.fa-tree:before {
  content: "\f1bb"
}

.fa-trello:before {
  content: "\f181"
}

.fa-tripadvisor:before {
  content: "\f262"
}

.fa-trophy:before {
  content: "\f091"
}

.fa-truck:before {
  content: "\f0d1"
}

.fa-truck-loading:before {
  content: "\f4de"
}

.fa-truck-monster:before {
  content: "\f63b"
}

.fa-truck-moving:before {
  content: "\f4df"
}

.fa-truck-pickup:before {
  content: "\f63c"
}

.fa-tshirt:before {
  content: "\f553"
}

.fa-tty:before {
  content: "\f1e4"
}

.fa-tumblr:before {
  content: "\f173"
}

.fa-tumblr-square:before {
  content: "\f174"
}

.fa-tv:before {
  content: "\f26c"
}

.fa-twitch:before {
  content: "\f1e8"
}

.fa-twitter:before {
  content: "\f099"
}

.fa-twitter-square:before {
  content: "\f081"
}

.fa-typo3:before {
  content: "\f42b"
}

.fa-uber:before {
  content: "\f402"
}

.fa-ubuntu:before {
  content: "\f7df"
}

.fa-uikit:before {
  content: "\f403"
}

.fa-umbraco:before {
  content: "\f8e8"
}

.fa-umbrella:before {
  content: "\f0e9"
}

.fa-umbrella-beach:before {
  content: "\f5ca"
}

.fa-uncharted:before {
  content: "\e084"
}

.fa-underline:before {
  content: "\f0cd"
}

.fa-undo:before {
  content: "\f0e2"
}

.fa-undo-alt:before {
  content: "\f2ea"
}

.fa-uniregistry:before {
  content: "\f404"
}

.fa-unity:before {
  content: "\e049"
}

.fa-universal-access:before {
  content: "\f29a"
}

.fa-university:before {
  content: "\f19c"
}

.fa-unlink:before {
  content: "\f127"
}

.fa-unlock:before {
  content: "\f09c"
}

.fa-unlock-alt:before {
  content: "\f13e"
}

.fa-unsplash:before {
  content: "\e07c"
}

.fa-untappd:before {
  content: "\f405"
}

.fa-upload:before {
  content: "\f093"
}

.fa-ups:before {
  content: "\f7e0"
}

.fa-usb:before {
  content: "\f287"
}

.fa-user:before {
  content: "\f007"
}

.fa-user-alt:before {
  content: "\f406"
}

.fa-user-alt-slash:before {
  content: "\f4fa"
}

.fa-user-astronaut:before {
  content: "\f4fb"
}

.fa-user-check:before {
  content: "\f4fc"
}

.fa-user-circle:before {
  content: "\f2bd"
}

.fa-user-clock:before {
  content: "\f4fd"
}

.fa-user-cog:before {
  content: "\f4fe"
}

.fa-user-edit:before {
  content: "\f4ff"
}

.fa-user-friends:before {
  content: "\f500"
}

.fa-user-graduate:before {
  content: "\f501"
}

.fa-user-injured:before {
  content: "\f728"
}

.fa-user-lock:before {
  content: "\f502"
}

.fa-user-md:before {
  content: "\f0f0"
}

.fa-user-minus:before {
  content: "\f503"
}

.fa-user-ninja:before {
  content: "\f504"
}

.fa-user-nurse:before {
  content: "\f82f"
}

.fa-user-plus:before {
  content: "\f234"
}

.fa-user-secret:before {
  content: "\f21b"
}

.fa-user-shield:before {
  content: "\f505"
}

.fa-user-slash:before {
  content: "\f506"
}

.fa-user-tag:before {
  content: "\f507"
}

.fa-user-tie:before {
  content: "\f508"
}

.fa-user-times:before {
  content: "\f235"
}

.fa-users:before {
  content: "\f0c0"
}

.fa-users-cog:before {
  content: "\f509"
}

.fa-users-slash:before {
  content: "\e073"
}

.fa-usps:before {
  content: "\f7e1"
}

.fa-ussunnah:before {
  content: "\f407"
}

.fa-utensil-spoon:before {
  content: "\f2e5"
}

.fa-utensils:before {
  content: "\f2e7"
}

.fa-vaadin:before {
  content: "\f408"
}

.fa-vector-square:before {
  content: "\f5cb"
}

.fa-venus:before {
  content: "\f221"
}

.fa-venus-double:before {
  content: "\f226"
}

.fa-venus-mars:before {
  content: "\f228"
}

.fa-vest:before {
  content: "\e085"
}

.fa-vest-patches:before {
  content: "\e086"
}

.fa-viacoin:before {
  content: "\f237"
}

.fa-viadeo:before {
  content: "\f2a9"
}

.fa-viadeo-square:before {
  content: "\f2aa"
}

.fa-vial:before {
  content: "\f492"
}

.fa-vials:before {
  content: "\f493"
}

.fa-viber:before {
  content: "\f409"
}

.fa-video:before {
  content: "\f03d"
}

.fa-video-slash:before {
  content: "\f4e2"
}

.fa-vihara:before {
  content: "\f6a7"
}

.fa-vimeo:before {
  content: "\f40a"
}

.fa-vimeo-square:before {
  content: "\f194"
}

.fa-vimeo-v:before {
  content: "\f27d"
}

.fa-vine:before {
  content: "\f1ca"
}

.fa-virus:before {
  content: "\e074"
}

.fa-virus-slash:before {
  content: "\e075"
}

.fa-viruses:before {
  content: "\e076"
}

.fa-vk:before {
  content: "\f189"
}

.fa-vnv:before {
  content: "\f40b"
}

.fa-voicemail:before {
  content: "\f897"
}

.fa-volleyball-ball:before {
  content: "\f45f"
}

.fa-volume-down:before {
  content: "\f027"
}

.fa-volume-mute:before {
  content: "\f6a9"
}

.fa-volume-off:before {
  content: "\f026"
}

.fa-volume-up:before {
  content: "\f028"
}

.fa-vote-yea:before {
  content: "\f772"
}

.fa-vr-cardboard:before {
  content: "\f729"
}

.fa-vuejs:before {
  content: "\f41f"
}

.fa-walking:before {
  content: "\f554"
}

.fa-wallet:before {
  content: "\f555"
}

.fa-warehouse:before {
  content: "\f494"
}

.fa-watchman-monitoring:before {
  content: "\e087"
}

.fa-water:before {
  content: "\f773"
}

.fa-wave-square:before {
  content: "\f83e"
}

.fa-waze:before {
  content: "\f83f"
}

.fa-weebly:before {
  content: "\f5cc"
}

.fa-weibo:before {
  content: "\f18a"
}

.fa-weight:before {
  content: "\f496"
}

.fa-weight-hanging:before {
  content: "\f5cd"
}

.fa-weixin:before {
  content: "\f1d7"
}

.fa-whatsapp:before {
  content: "\f232"
}

.fa-whatsapp-square:before {
  content: "\f40c"
}

.fa-wheelchair:before {
  content: "\f193"
}

.fa-whmcs:before {
  content: "\f40d"
}

.fa-wifi:before {
  content: "\f1eb"
}

.fa-wikipedia-w:before {
  content: "\f266"
}

.fa-wind:before {
  content: "\f72e"
}

.fa-window-close:before {
  content: "\f410"
}

.fa-window-maximize:before {
  content: "\f2d0"
}

.fa-window-minimize:before {
  content: "\f2d1"
}

.fa-window-restore:before {
  content: "\f2d2"
}

.fa-windows:before {
  content: "\f17a"
}

.fa-wine-bottle:before {
  content: "\f72f"
}

.fa-wine-glass:before {
  content: "\f4e3"
}

.fa-wine-glass-alt:before {
  content: "\f5ce"
}

.fa-wix:before {
  content: "\f5cf"
}

.fa-wizards-of-the-coast:before {
  content: "\f730"
}

.fa-wodu:before {
  content: "\e088"
}

.fa-wolf-pack-battalion:before {
  content: "\f514"
}

.fa-won-sign:before {
  content: "\f159"
}

.fa-wordpress:before {
  content: "\f19a"
}

.fa-wordpress-simple:before {
  content: "\f411"
}

.fa-wpbeginner:before {
  content: "\f297"
}

.fa-wpexplorer:before {
  content: "\f2de"
}

.fa-wpforms:before {
  content: "\f298"
}

.fa-wpressr:before {
  content: "\f3e4"
}

.fa-wrench:before {
  content: "\f0ad"
}

.fa-x-ray:before {
  content: "\f497"
}

.fa-xbox:before {
  content: "\f412"
}

.fa-xing:before {
  content: "\f168"
}

.fa-xing-square:before {
  content: "\f169"
}

.fa-y-combinator:before {
  content: "\f23b"
}

.fa-yahoo:before {
  content: "\f19e"
}

.fa-yammer:before {
  content: "\f840"
}

.fa-yandex:before {
  content: "\f413"
}

.fa-yandex-international:before {
  content: "\f414"
}

.fa-yarn:before {
  content: "\f7e3"
}

.fa-yelp:before {
  content: "\f1e9"
}

.fa-yen-sign:before {
  content: "\f157"
}

.fa-yin-yang:before {
  content: "\f6ad"
}

.fa-yoast:before {
  content: "\f2b1"
}

.fa-youtube:before {
  content: "\f167"
}

.fa-youtube-square:before {
  content: "\f431"
}

.fa-zhihu:before {
  content: "\f63f"
}

@font-face {
  font-family: "Font Awesome 5 Brands";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://socialbee.com/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-brands-400.eot);
  src: url(https://socialbee.com/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-brands-400.eot?#iefix) format("embedded-opentype"), url(https://socialbee.com/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-brands-400.woff2) format("woff2"), url(https://socialbee.com/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-brands-400.woff) format("woff"), url(https://socialbee.com/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-brands-400.ttf) format("truetype"), url(https://socialbee.com/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-brands-400.svg#fontawesome) format("svg")
}

.fab {
  font-family: "Font Awesome 5 Brands"
}

.fab,
.far {
  font-weight: 400
}

@font-face {
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://socialbee.com/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-solid-900.eot);
  src: url(https://socialbee.com/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-solid-900.eot?#iefix) format("embedded-opentype"), url(https://socialbee.com/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-solid-900.woff2) format("woff2"), url(https://socialbee.com/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-solid-900.woff) format("woff"), url(https://socialbee.com/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-solid-900.ttf) format("truetype"), url(https://socialbee.com/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-solid-900.svg#fontawesome) format("svg")
}

.fa,
.far,
.fas {
  font-family: "Font Awesome 5 Free"
}

.fa,
.fas {
  font-weight: 900
}

.fa.fa-facebook,
.fa.fa-twitter {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400
}

.fa.fa-facebook:before {
  content: "\f39e"
}

.fa.fa-facebook-f {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400
}

.fa.fa-facebook-f:before {
  content: "\f39e"
}

.fa.fa-linkedin {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400
}

.fa.fa-linkedin:before {
  content: "\f0e1"
}

.fa.fa-youtube {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400
}

.fa.fa-instagram {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400
}

.fa.fa-pinterest-p {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400
}

.elementor-widget-heading .elementor-heading-title {
  color: var(--e-global-color-primary);
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-size: var(--e-global-typography-primary-font-size);
  font-weight: var(--e-global-typography-primary-font-weight)
}

.elementor-widget-image .widget-image-caption {
  color: var(--e-global-color-text);
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-size: var(--e-global-typography-text-font-size);
  font-weight: var(--e-global-typography-text-font-weight);
  line-height: var(--e-global-typography-text-line-height)
}

.elementor-widget-text-editor {
  color: var(--e-global-color-text);
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-size: var(--e-global-typography-text-font-size);
  font-weight: var(--e-global-typography-text-font-weight);
  line-height: var(--e-global-typography-text-line-height)
}

.elementor-widget-button .elementor-button {
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
  background-color: var(--e-global-color-accent)
}

.elementor-widget-icon-box.elementor-view-stacked .elementor-icon {
  background-color: var(--e-global-color-primary)
}

.elementor-widget-icon-box.elementor-view-default .elementor-icon {
  fill: var(--e-global-color-primary);
  color: var(--e-global-color-primary);
  border-color: var(--e-global-color-primary)
}

.elementor-widget-icon-box .elementor-icon-box-title {
  color: var(--e-global-color-primary)
}

.elementor-widget-icon-box .elementor-icon-box-title,
.elementor-widget-icon-box .elementor-icon-box-title a {
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-size: var(--e-global-typography-primary-font-size);
  font-weight: var(--e-global-typography-primary-font-weight)
}

.elementor-widget-icon-box .elementor-icon-box-description {
  color: var(--e-global-color-text);
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-size: var(--e-global-typography-text-font-size);
  font-weight: var(--e-global-typography-text-font-weight);
  line-height: var(--e-global-typography-text-line-height)
}

.elementor-widget-icon-list .elementor-icon-list-item:not(:last-child):after {
  border-color: var(--e-global-color-text)
}

.elementor-widget-icon-list .elementor-icon-list-icon i {
  color: var(--e-global-color-primary)
}

.elementor-widget-icon-list .elementor-icon-list-icon svg {
  fill: var(--e-global-color-primary)
}

.elementor-widget-icon-list .elementor-icon-list-item>.elementor-icon-list-text,
.elementor-widget-icon-list .elementor-icon-list-item>a {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-size: var(--e-global-typography-text-font-size);
  font-weight: var(--e-global-typography-text-font-weight);
  line-height: var(--e-global-typography-text-line-height)
}

.elementor-widget-icon-list .elementor-icon-list-text {
  color: var(--e-global-color-secondary)
}

.elementor-widget-testimonial .elementor-testimonial-content {
  color: var(--e-global-color-text);
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-size: var(--e-global-typography-text-font-size);
  font-weight: var(--e-global-typography-text-font-weight);
  line-height: var(--e-global-typography-text-line-height)
}

.elementor-widget-testimonial .elementor-testimonial-name {
  color: var(--e-global-color-primary);
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-size: var(--e-global-typography-primary-font-size);
  font-weight: var(--e-global-typography-primary-font-weight)
}

.elementor-widget-testimonial .elementor-testimonial-job {
  color: var(--e-global-color-secondary);
  font-family: var(--e-global-typography-secondary-font-family), Sans-serif;
  font-size: var(--e-global-typography-secondary-font-size);
  font-weight: var(--e-global-typography-secondary-font-weight);
  line-height: var(--e-global-typography-secondary-line-height)
}

.elementor-widget-search-form .elementor-lightbox .dialog-lightbox-close-button,
.elementor-widget-search-form .elementor-lightbox .dialog-lightbox-close-button:hover {
  color: var(--e-global-color-text);
  fill: var(--e-global-color-text)
}

@media(max-width:1024px) {
  .elementor-widget-heading .elementor-heading-title {
    font-size: var(--e-global-typography-primary-font-size)
  }

  .elementor-widget-image .widget-image-caption {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height)
  }

  .elementor-widget-text-editor {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height)
  }

  .elementor-widget-icon-box .elementor-icon-box-title,
  .elementor-widget-icon-box .elementor-icon-box-title a {
    font-size: var(--e-global-typography-primary-font-size)
  }

  .elementor-widget-icon-box .elementor-icon-box-description {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height)
  }

  .elementor-widget-icon-list .elementor-icon-list-item>.elementor-icon-list-text,
  .elementor-widget-icon-list .elementor-icon-list-item>a {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height)
  }

  .elementor-widget-testimonial .elementor-testimonial-content {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height)
  }

  .elementor-widget-testimonial .elementor-testimonial-name {
    font-size: var(--e-global-typography-primary-font-size)
  }

  .elementor-widget-testimonial .elementor-testimonial-job {
    font-size: var(--e-global-typography-secondary-font-size);
    line-height: var(--e-global-typography-secondary-line-height)
  }
}

@media(max-width:767px) {
  .elementor-widget-heading .elementor-heading-title {
    font-size: var(--e-global-typography-primary-font-size)
  }

  .elementor-widget-image .widget-image-caption {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height)
  }

  .elementor-widget-text-editor {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height)
  }

  .elementor-widget-icon-box .elementor-icon-box-title,
  .elementor-widget-icon-box .elementor-icon-box-title a {
    font-size: var(--e-global-typography-primary-font-size)
  }

  .elementor-widget-icon-box .elementor-icon-box-description {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height)
  }

  .elementor-widget-icon-list .elementor-icon-list-item>.elementor-icon-list-text,
  .elementor-widget-icon-list .elementor-icon-list-item>a {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height)
  }

  .elementor-widget-testimonial .elementor-testimonial-content {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height)
  }

  .elementor-widget-testimonial .elementor-testimonial-name {
    font-size: var(--e-global-typography-primary-font-size)
  }

  .elementor-widget-testimonial .elementor-testimonial-job {
    font-size: var(--e-global-typography-secondary-font-size);
    line-height: var(--e-global-typography-secondary-line-height)
  }
}

.elementor-4722 .elementor-element.elementor-element-a363456>.elementor-container {
  max-width: 940px
    /* max-width: 1200px; */
}

.elementor-4722 .elementor-element.elementor-element-a363456:not(.elementor-motion-effects-element-type-background),
.elementor-4722 .elementor-element.elementor-element-a363456>.elementor-motion-effects-container>.elementor-motion-effects-layer {
  /* background-color: var(--e-global-color-d9377ef) */
  background-color: #F8D546;
}

.elementor-4722 .elementor-element.elementor-element-a363456>.elementor-background-overlay {
  background-image: url("../images/homepage10BG.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  /* opacity: 0.1; */
  /* mix-blend-mode: multiply; */
  transition: background .3s, border-radius .3s, opacity .3s
}

.elementor-4722 .elementor-element.elementor-element-a363456 {
  transition: background .3s, border .3s, border-radius .3s, box-shadow .3s;
  /* margin-top: 60px;  */
  margin-bottom: 0;
  padding: 60px 0 120px;
  z-index: 1
}

.elementor-4722 .elementor-element.elementor-element-a363456>.elementor-shape-bottom .elementor-shape-fill {
  fill: var(--e-global-color-ddda476)
}

.elementor-4722 .elementor-element.elementor-element-a363456>.elementor-shape-bottom svg {
  height: 140px;
  transform: translateX(-50%) rotateY(180deg)
}

.elementor-4722 .elementor-element.elementor-element-8a859f6>.elementor-widget-wrap>.elementor-widget:not(.elementor-widget__width-auto):not(.elementor-widget__width-initial):not(:last-child):not(.elementor-absolute) {
  margin-bottom: 5px
}

.elementor-4722 .elementor-element.elementor-element-8a859f6>.elementor-element-populated {
  transition: background .3s, border .3s, border-radius .3s, box-shadow .3s
}

.elementor-4722 .elementor-element.elementor-element-8a859f6>.elementor-element-populated>.elementor-background-overlay {
  transition: background .3s, border-radius .3s, opacity .3s
}

.elementor-4722 .elementor-element.elementor-element-3afab82 {
  text-align: center
}

.elementor-4722 .elementor-element.elementor-element-3afab82 .elementor-heading-title {
  color: var(--e-global-color-primary);
  font-family: Roboto, Sans-serif;
  font-size: 50px;
  font-weight: 700
}

.elementor-4722 .elementor-element.elementor-element-6a28035 {
  text-align: center;
  color: var(--e-global-color-primary);
  font-family: Roboto, Sans-serif;
  font-size: 23px;
  font-weight: 400;
  line-height: 1.7em
}

.elementor-4722 .elementor-element.elementor-global-43819 img {
  width: 65%;
  max-width: 100%
}

.elementor-4722 .elementor-element.elementor-global-43819 .widget-image-caption {
  font-family: Roboto, Sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.8em
}

.elementor-4722 .elementor-element.elementor-global-43819>.elementor-widget-container {
  margin: 5px 0 0
}

.elementor-4722 .elementor-element.elementor-element-28e0577 {
  margin-top: 10px;
  margin-bottom: 0;
  padding: 0 0 10px
}

.elementor-4722 .elementor-element.elementor-element-2c213f9 .elementor-button {
  font-size: 20px;
  font-weight: 500;
  text-decoration: none;
  fill: #FFFFFF;
  color: #fff;
  background-color: #0abf3c;
  border-style: solid;
  border-width: 1px;
  border-color: #000
}

.elementor-4722 .elementor-element.elementor-element-ac5a923.elementor-column.elementor-element[data-element_type=column]>.elementor-widget-wrap.elementor-element-populated {
  align-content: center;
  align-items: center
}

.elementor-4722 .elementor-element.elementor-element-b220546 .elementor-button {
  font-size: 20px;
  font-weight: 500;
  text-decoration: none;
  fill: #000000;
  color: #000;
  background-color: var(--e-global-color-6c3ab248);
  border-style: solid;
  border-width: 1px;
  border-color: #000
}

.elementor-4722 .elementor-element.elementor-element-f06e043 {
  text-align: center;
  color: var(--e-global-color-primary);
  font-family: Roboto, Sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.7em
}

.elementor-4722 .elementor-element.elementor-element-f06e043>.elementor-widget-container {
  margin: 0
}

.elementor-4722 .elementor-element.elementor-element-ff2748a .elementor-jet-video {
  max-width: 875px;
  margin-left: auto;
  margin-right: auto
}

.elementor-4722 .elementor-element.elementor-element-ff2748a .jet-video {
  /* background-color: var(--e-global-color-d9377ef) */
  background-color: #F8D546;
}

.elementor-4722 .elementor-element.elementor-element-ff2748a .jet-video__play-button {
  font-size: 200px;
  color: var(--e-global-color-ddda476)
}

.elementor-4722 .elementor-element.elementor-element-ff2748a .jet-video__overlay:hover .jet-video__play-button {
  color: var(--e-global-color-d9377ef)
}

.elementor-4722 .elementor-element.elementor-element-ff2748a>.elementor-widget-container {
  margin: 30px 0
}

.elementor-4722 .elementor-element.elementor-element-1570105>.elementor-container {
  max-width: 1300px
}

.elementor-4722 .elementor-element.elementor-element-1570105:not(.elementor-motion-effects-element-type-background),
.elementor-4722 .elementor-element.elementor-element-1570105>.elementor-motion-effects-container>.elementor-motion-effects-layer {
  background-color: var(--e-global-color-ddda476)
}

.elementor-4722 .elementor-element.elementor-element-1570105 {
  transition: background .3s, border .3s, border-radius .3s, box-shadow .3s;
  padding: 0 0px 20px 10px;
  /* padding: 0 10px */
}

.elementor-4722 .elementor-element.elementor-element-1570105>.elementor-background-overlay {
  transition: background .3s, border-radius .3s, opacity .3s
}

.elementor-4722 .elementor-element.elementor-element-fe04227.elementor-column.elementor-element[data-element_type=column]>.elementor-widget-wrap.elementor-element-populated {
  align-content: center;
  align-items: center
}

.elementor-4722 .elementor-element.elementor-element-fe04227>.elementor-widget-wrap>.elementor-widget:not(.elementor-widget__width-auto):not(.elementor-widget__width-initial):not(:last-child):not(.elementor-absolute) {
  margin-bottom: 1px
}

.elementor-4722 .elementor-element.elementor-element-fe04227>.elementor-element-populated {
  padding: 0 20px 0 10px
}

.elementor-4722 .elementor-element.elementor-element-77e4ac0 {
  text-align: left
}

.elementor-4722 .elementor-element.elementor-element-77e4ac0 .elementor-heading-title {
  color: var(--e-global-color-6c3ab248);
  font-family: Roboto, Sans-serif;
  font-size: 40px;
  font-weight: 700
}

.elementor-4722 .elementor-element.elementor-element-0335699 {
  text-align: left;
  color: var(--e-global-color-6c3ab248);
  font-family: Roboto, Sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 1.7em
}

.elementor-4722 .elementor-element.elementor-element-4d401f5>.elementor-element-populated {
  margin: 0;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0
}

.elementor-4722 .elementor-element.elementor-element-1dac062 {
  width: var(--container-widget-width, 101.982%);
  max-width: 101.982%;
  --container-widget-width: 101.982%;
  --container-widget-flex-grow: 0
}

.elementor-4722 .elementor-element.elementor-element-a92c563>.elementor-container {
  max-width: 1390px
}

.elementor-4722 .elementor-element.elementor-element-a92c563:not(.elementor-motion-effects-element-type-background),
.elementor-4722 .elementor-element.elementor-element-a92c563>.elementor-motion-effects-container>.elementor-motion-effects-layer {
  background-color: var(--e-global-color-ddda476)
}

.elementor-4722 .elementor-element.elementor-element-a92c563 {
  transition: background .3s, border .3s, border-radius .3s, box-shadow .3s;
  margin-top: 0;
  margin-bottom: 0;
  padding: 80px 0 30px
}

.elementor-4722 .elementor-element.elementor-element-a92c563>.elementor-background-overlay {
  transition: background .3s, border-radius .3s, opacity .3s
}

.elementor-4722 .elementor-element.elementor-element-a92c563>.elementor-shape-bottom svg {
  height: 140px;
  transform: translateX(-50%) rotateY(180deg)
}

.elementor-4722 .elementor-element.elementor-element-c422c1d {
  --icon-box-icon-margin: 15px
}

.elementor-4722 .elementor-element.elementor-element-c422c1d .elementor-icon {
  font-size: 85px
}

.elementor-4722 .elementor-element.elementor-element-c422c1d .elementor-icon-box-title,
.elementor-4722 .elementor-element.elementor-element-c422c1d .elementor-icon-box-title a {
  font-family: Roboto, Sans-serif;
  font-size: 25px;
  font-weight: 700
}

.elementor-4722 .elementor-element.elementor-element-c422c1d .elementor-icon-box-description {
  font-family: Roboto, Sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3em
}

.elementor-4722 .elementor-element.elementor-element-c422c1d>.elementor-widget-container {
  margin: 0;
  padding: 20px;
  background-color: #fafafa;
  border-style: solid;
  border-width: 1px;
  border-radius: 15px 15px 15px 15px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .5)
}

.elementor-4722 .elementor-element.elementor-element-c422c1d:hover .elementor-widget-container {
  background-color: #ededed
}

.elementor-4722 .elementor-element.elementor-element-353f4ad.elementor-view-stacked .elementor-icon {
  background-color: #F8D546;
}

.elementor-4722 .elementor-element.elementor-element-353f4ad.elementor-view-default .elementor-icon {
  fill: var(--e-global-color-d9377ef);
  color: var(--e-global-color-d9377ef);
  border-color: var(--e-global-color-d9377ef)
}

.elementor-4722 .elementor-element.elementor-element-353f4ad {
  --icon-box-icon-margin: 15px
}

.elementor-4722 .elementor-element.elementor-element-353f4ad .elementor-icon {
  font-size: 85px
}

.elementor-4722 .elementor-element.elementor-element-353f4ad .elementor-icon-box-title,
.elementor-4722 .elementor-element.elementor-element-353f4ad .elementor-icon-box-title a {
  font-family: Roboto, Sans-serif;
  font-size: 25px;
  font-weight: 700
}

.elementor-4722 .elementor-element.elementor-element-353f4ad .elementor-icon-box-description {
  font-family: Roboto, Sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 1.8em
}

.elementor-4722 .elementor-element.elementor-element-353f4ad>.elementor-widget-container {
  margin: 0;
  padding: 20px;
  background-color: #fafafa;
  border-style: solid;
  border-width: 1px;
  border-radius: 15px 15px 15px 15px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .5)
}

.elementor-4722 .elementor-element.elementor-element-353f4ad:hover .elementor-widget-container {
  background-color: #ededed
}

.elementor-4722 .elementor-element.elementor-element-3163703.elementor-view-stacked .elementor-icon {
  background-color: #F8D546;
}

.elementor-4722 .elementor-element.elementor-element-3163703.elementor-view-default .elementor-icon {
  fill: var(--e-global-color-d9377ef);
  color: var(--e-global-color-d9377ef);
  border-color: var(--e-global-color-d9377ef)
}

.elementor-4722 .elementor-element.elementor-element-3163703 {
  --icon-box-icon-margin: 15px
}

.elementor-4722 .elementor-element.elementor-element-3163703 .elementor-icon {
  font-size: 85px
}

.elementor-4722 .elementor-element.elementor-element-3163703 .elementor-icon-box-title,
.elementor-4722 .elementor-element.elementor-element-3163703 .elementor-icon-box-title a {
  font-family: Roboto, Sans-serif;
  font-size: 25px;
  font-weight: 700
}

.elementor-4722 .elementor-element.elementor-element-3163703 .elementor-icon-box-description {
  font-family: Roboto, Sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 1.8em
}

.elementor-4722 .elementor-element.elementor-element-3163703>.elementor-widget-container {
  margin: 0;
  padding: 20px;
  background-color: #fafafa;
  border-style: solid;
  border-width: 1px;
  border-radius: 15px 15px 15px 15px
}

.elementor-4722 .elementor-element.elementor-element-3163703:hover .elementor-widget-container {
  background-color: #ededed
}

.elementor-4722 .elementor-element.elementor-element-53e3870.elementor-view-stacked .elementor-icon {
  background-color: #F8D546;
}

.elementor-4722 .elementor-element.elementor-element-53e3870.elementor-view-default .elementor-icon {
  fill: var(--e-global-color-d9377ef);
  color: var(--e-global-color-d9377ef);
  border-color: var(--e-global-color-d9377ef)
}

.elementor-4722 .elementor-element.elementor-element-53e3870 {
  --icon-box-icon-margin: 15px
}

.elementor-4722 .elementor-element.elementor-element-53e3870 .elementor-icon {
  font-size: 85px
}

.elementor-4722 .elementor-element.elementor-element-53e3870 .elementor-icon-box-title,
.elementor-4722 .elementor-element.elementor-element-53e3870 .elementor-icon-box-title a {
  font-family: Roboto, Sans-serif;
  font-size: 25px;
  font-weight: 700
}

.elementor-4722 .elementor-element.elementor-element-53e3870 .elementor-icon-box-description {
  font-family: Roboto, Sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 1.8em
}

.elementor-4722 .elementor-element.elementor-element-53e3870>.elementor-widget-container {
  margin: 0;
  padding: 20px;
  background-color: #fafafa;
  border-style: solid;
  border-width: 1px;
  border-radius: 15px 15px 15px 15px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .5)
}

.elementor-4722 .elementor-element.elementor-element-53e3870:hover .elementor-widget-container {
  background-color: #ededed
}

.elementor-4722 .elementor-element.elementor-element-9045622.elementor-view-stacked .elementor-icon {
  background-color: #F8D546;
}

.elementor-4722 .elementor-element.elementor-element-9045622.elementor-view-default .elementor-icon {
  fill: var(--e-global-color-d9377ef);
  color: var(--e-global-color-d9377ef);
  border-color: var(--e-global-color-d9377ef)
}

.elementor-4722 .elementor-element.elementor-element-9045622 {
  --icon-box-icon-margin: 15px
}

.elementor-4722 .elementor-element.elementor-element-9045622 .elementor-icon {
  font-size: 85px
}

.elementor-4722 .elementor-element.elementor-element-9045622 .elementor-icon-box-title,
.elementor-4722 .elementor-element.elementor-element-9045622 .elementor-icon-box-title a {
  font-family: Roboto, Sans-serif;
  font-size: 25px;
  font-weight: 700
}

.elementor-4722 .elementor-element.elementor-element-9045622 .elementor-icon-box-description {
  font-family: Roboto, Sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 1.8em
}

.elementor-4722 .elementor-element.elementor-element-9045622>.elementor-widget-container {
  margin: 0;
  padding: 20px;
  background-color: #fafafa;
  border-style: solid;
  border-width: 1px;
  border-radius: 15px 15px 15px 15px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .5)
}

.elementor-4722 .elementor-element.elementor-element-9045622:hover .elementor-widget-container {
  background-color: #ededed
}

.elementor-4722 .elementor-element.elementor-element-412ed8d>.elementor-container {
  max-width: 1600px
}

.elementor-4722 .elementor-element.elementor-element-412ed8d:not(.elementor-motion-effects-element-type-background),
.elementor-4722 .elementor-element.elementor-element-412ed8d>.elementor-motion-effects-container>.elementor-motion-effects-layer {
  background-color: #F8D546;
  /* background-image: url("../images/homepage10BG.jpg"); */
  background-size: cover
}

.elementor-4722 .elementor-element.elementor-element-412ed8d>.elementor-background-overlay {
  background-color: transparent;
  /* background-image: linear-gradient(180deg, #fff 0, #efefef 100%); */
  opacity: 1;
  mix-blend-mode: multiply;
  transition: background .3s, border-radius .3s, opacity .3s
}

.elementor-4722 .elementor-element.elementor-element-412ed8d {
  transition: background .3s, border .3s, border-radius .3s, box-shadow .3s;
  margin-top: 0;
  margin-bottom: 0;
  padding: 60px 0 180px
}

.elementor-4722 .elementor-element.elementor-element-412ed8d>.elementor-shape-bottom svg {
  height: 140px;
  transform: translateX(-50%) rotateY(180deg)
}

.elementor-4722 .elementor-element.elementor-element-a06d42d {
  text-align: center
}

.elementor-4722 .elementor-element.elementor-element-a06d42d .elementor-heading-title {
  color: #000;
  font-size: 25px;
  letter-spacing: 3.3px;
  word-spacing: 0.2em
}

.elementor-4722 .elementor-element.elementor-element-a06d42d>.elementor-widget-container {
  margin: 0
}

.elementor-4722 .elementor-element.elementor-element-d0eb175>.elementor-container {
  max-width: 981px
}

.elementor-4722 .elementor-element.elementor-element-d0eb175 {
  margin-top: 30px;
  margin-bottom: 0
}

.elementor-4722 .elementor-element.elementor-element-6b469e9.elementor-column.elementor-element[data-element_type=column]>.elementor-widget-wrap.elementor-element-populated {
  align-content: flex-start;
  align-items: flex-start
}

.elementor-4722 .elementor-element.elementor-element-6b469e9>.elementor-widget-wrap>.elementor-widget:not(.elementor-widget__width-auto):not(.elementor-widget__width-initial):not(:last-child):not(.elementor-absolute) {
  margin-bottom: 10px
}

.elementor-4722 .elementor-element.elementor-element-6b469e9>.elementor-element-populated {
  transition: background .3s, border .3s, border-radius .3s, box-shadow .3s;
  margin: 0 10px;
  --e-column-margin-right: 10px;
  --e-column-margin-left: 10px
}

.elementor-4722 .elementor-element.elementor-element-6b469e9>.elementor-element-populated>.elementor-background-overlay {
  transition: background .3s, border-radius .3s, opacity .3s
}

.elementor-4722 .elementor-element.elementor-element-b637324 {
  text-align: center
}

.elementor-4722 .elementor-element.elementor-element-b637324 .elementor-heading-title {
  color: #1877f2;
  font-size: 20px;
  font-weight: 700
}

.elementor-4722 .elementor-element.elementor-element-b637324>.elementor-widget-container {
  margin: 0
}

.elementor-4722 .elementor-element.elementor-element-1861337.elementor-column.elementor-element[data-element_type=column]>.elementor-widget-wrap.elementor-element-populated {
  align-content: flex-start;
  align-items: flex-start
}

.elementor-4722 .elementor-element.elementor-element-1861337>.elementor-widget-wrap>.elementor-widget:not(.elementor-widget__width-auto):not(.elementor-widget__width-initial):not(:last-child):not(.elementor-absolute) {
  margin-bottom: 10px
}

.elementor-4722 .elementor-element.elementor-element-1861337>.elementor-element-populated {
  transition: background .3s, border .3s, border-radius .3s, box-shadow .3s;
  margin: 0 10px;
  --e-column-margin-right: 10px;
  --e-column-margin-left: 10px
}

.elementor-4722 .elementor-element.elementor-element-1861337>.elementor-element-populated>.elementor-background-overlay {
  transition: background .3s, border-radius .3s, opacity .3s
}

.elementor-4722 .elementor-element.elementor-element-dc7d32a {
  text-align: center
}

.elementor-4722 .elementor-element.elementor-element-dc7d32a .elementor-heading-title {
  color: #1877f2;
  font-size: 20px;
  font-weight: 700
}

.elementor-4722 .elementor-element.elementor-element-dc7d32a>.elementor-widget-container {
  margin: 0
}

.elementor-4722 .elementor-element.elementor-element-e22fe07.elementor-column.elementor-element[data-element_type=column]>.elementor-widget-wrap.elementor-element-populated {
  align-content: flex-start;
  align-items: flex-start
}

.elementor-4722 .elementor-element.elementor-element-e22fe07>.elementor-widget-wrap>.elementor-widget:not(.elementor-widget__width-auto):not(.elementor-widget__width-initial):not(:last-child):not(.elementor-absolute) {
  margin-bottom: 10px
}

.elementor-4722 .elementor-element.elementor-element-e22fe07>.elementor-element-populated {
  transition: background .3s, border .3s, border-radius .3s, box-shadow .3s;
  margin: 0 10px;
  --e-column-margin-right: 10px;
  --e-column-margin-left: 10px
}

.elementor-4722 .elementor-element.elementor-element-e22fe07>.elementor-element-populated>.elementor-background-overlay {
  transition: background .3s, border-radius .3s, opacity .3s
}

.elementor-4722 .elementor-element.elementor-element-edc5926 {
  text-align: center
}

.elementor-4722 .elementor-element.elementor-element-edc5926 .elementor-heading-title {
  color: #1877f2;
  font-size: 20px;
  font-weight: 700
}

.elementor-4722 .elementor-element.elementor-element-edc5926>.elementor-widget-container {
  margin: 0
}

.elementor-4722 .elementor-element.elementor-element-45b4327.elementor-column.elementor-element[data-element_type=column]>.elementor-widget-wrap.elementor-element-populated {
  align-content: flex-start;
  align-items: flex-start
}

.elementor-4722 .elementor-element.elementor-element-45b4327>.elementor-widget-wrap>.elementor-widget:not(.elementor-widget__width-auto):not(.elementor-widget__width-initial):not(:last-child):not(.elementor-absolute) {
  margin-bottom: 10px
}

.elementor-4722 .elementor-element.elementor-element-45b4327>.elementor-element-populated {
  transition: background .3s, border .3s, border-radius .3s, box-shadow .3s;
  margin: 0 10px;
  --e-column-margin-right: 10px;
  --e-column-margin-left: 10px
}

.elementor-4722 .elementor-element.elementor-element-45b4327>.elementor-element-populated>.elementor-background-overlay {
  transition: background .3s, border-radius .3s, opacity .3s
}

.elementor-4722 .elementor-element.elementor-element-a4800fb {
  text-align: center
}

.elementor-4722 .elementor-element.elementor-element-a4800fb .elementor-heading-title {
  color: #1877f2;
  font-size: 20px;
  font-weight: 700
}

.elementor-4722 .elementor-element.elementor-element-a4800fb>.elementor-widget-container {
  margin: 0
}

.elementor-4722 .elementor-element.elementor-element-19d368b.elementor-column.elementor-element[data-element_type=column]>.elementor-widget-wrap.elementor-element-populated {
  align-content: flex-start;
  align-items: flex-start
}

.elementor-4722 .elementor-element.elementor-element-19d368b>.elementor-widget-wrap>.elementor-widget:not(.elementor-widget__width-auto):not(.elementor-widget__width-initial):not(:last-child):not(.elementor-absolute) {
  margin-bottom: 10px
}

.elementor-4722 .elementor-element.elementor-element-19d368b>.elementor-element-populated {
  transition: background .3s, border .3s, border-radius .3s, box-shadow .3s;
  margin: 0 10px;
  --e-column-margin-right: 10px;
  --e-column-margin-left: 10px
}

.elementor-4722 .elementor-element.elementor-element-19d368b>.elementor-element-populated>.elementor-background-overlay {
  transition: background .3s, border-radius .3s, opacity .3s
}

.elementor-4722 .elementor-element.elementor-element-3024c51 {
  text-align: center
}

.elementor-4722 .elementor-element.elementor-element-3024c51 .elementor-heading-title {
  color: #1877f2;
  font-size: 20px;
  font-weight: 700
}

.elementor-4722 .elementor-element.elementor-element-3024c51>.elementor-widget-container {
  margin: 0
}

.elementor-4722 .elementor-element.elementor-element-ba990d4.elementor-column.elementor-element[data-element_type=column]>.elementor-widget-wrap.elementor-element-populated {
  align-content: flex-start;
  align-items: flex-start
}

.elementor-4722 .elementor-element.elementor-element-ba990d4>.elementor-widget-wrap>.elementor-widget:not(.elementor-widget__width-auto):not(.elementor-widget__width-initial):not(:last-child):not(.elementor-absolute) {
  margin-bottom: 10px
}

.elementor-4722 .elementor-element.elementor-element-ba990d4>.elementor-element-populated {
  transition: background .3s, border .3s, border-radius .3s, box-shadow .3s;
  margin: 0 10px;
  --e-column-margin-right: 10px;
  --e-column-margin-left: 10px
}

.elementor-4722 .elementor-element.elementor-element-ba990d4>.elementor-element-populated>.elementor-background-overlay {
  transition: background .3s, border-radius .3s, opacity .3s
}

.elementor-4722 .elementor-element.elementor-element-60efb4d {
  text-align: center
}

.elementor-4722 .elementor-element.elementor-element-60efb4d .elementor-heading-title {
  color: #1877f2;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2em
}

.elementor-4722 .elementor-element.elementor-element-60efb4d>.elementor-widget-container {
  margin: 0
}

.elementor-4722 .elementor-element.elementor-element-6d87474.elementor-column.elementor-element[data-element_type=column]>.elementor-widget-wrap.elementor-element-populated {
  align-content: flex-start;
  align-items: flex-start
}

.elementor-4722 .elementor-element.elementor-element-6d87474>.elementor-widget-wrap>.elementor-widget:not(.elementor-widget__width-auto):not(.elementor-widget__width-initial):not(:last-child):not(.elementor-absolute) {
  margin-bottom: 10px
}

.elementor-4722 .elementor-element.elementor-element-6d87474>.elementor-element-populated {
  transition: background .3s, border .3s, border-radius .3s, box-shadow .3s;
  margin: 0 10px;
  --e-column-margin-right: 10px;
  --e-column-margin-left: 10px
}

.elementor-4722 .elementor-element.elementor-element-6d87474>.elementor-element-populated>.elementor-background-overlay {
  transition: background .3s, border-radius .3s, opacity .3s
}

.elementor-4722 .elementor-element.elementor-element-36cb23a {
  text-align: center
}

.elementor-4722 .elementor-element.elementor-element-36cb23a .elementor-heading-title {
  color: #1877f2;
  font-size: 20px;
  font-weight: 700
}

.elementor-4722 .elementor-element.elementor-element-36cb23a>.elementor-widget-container {
  margin: 0
}

.elementor-4722 .elementor-element.elementor-element-2619592.elementor-column.elementor-element[data-element_type=column]>.elementor-widget-wrap.elementor-element-populated {
  align-content: flex-start;
  align-items: flex-start
}

.elementor-4722 .elementor-element.elementor-element-2619592>.elementor-widget-wrap>.elementor-widget:not(.elementor-widget__width-auto):not(.elementor-widget__width-initial):not(:last-child):not(.elementor-absolute) {
  margin-bottom: 10px
}

.elementor-4722 .elementor-element.elementor-element-2619592>.elementor-element-populated {
  transition: background .3s, border .3s, border-radius .3s, box-shadow .3s;
  margin: 0 10px;
  --e-column-margin-right: 10px;
  --e-column-margin-left: 10px
}

.elementor-4722 .elementor-element.elementor-element-2619592>.elementor-element-populated>.elementor-background-overlay {
  transition: background .3s, border-radius .3s, opacity .3s
}

.elementor-4722 .elementor-element.elementor-element-50d0a5d {
  text-align: center
}

.elementor-4722 .elementor-element.elementor-element-50d0a5d img {
  width: 60%
}

.elementor-4722 .elementor-element.elementor-element-66ed496 {
  text-align: center
}

.elementor-4722 .elementor-element.elementor-element-66ed496 .elementor-heading-title {
  color: #1877f2;
  font-size: 20px;
  font-weight: 700
}

.elementor-4722 .elementor-element.elementor-element-66ed496>.elementor-widget-container {
  margin: 0
}

.elementor-4722 .elementor-element.elementor-element-859a3b2:not(.elementor-motion-effects-element-type-background),
.elementor-4722 .elementor-element.elementor-element-859a3b2>.elementor-motion-effects-container>.elementor-motion-effects-layer {
  background-color: var(--e-global-color-6c3ab248)
}

.elementor-4722 .elementor-element.elementor-element-859a3b2 {
  transition: background .3s, border .3s, border-radius .3s, box-shadow .3s;
  padding: 0px 0px 100px 0px;
}

.elementor-4722 .elementor-element.elementor-element-859a3b2>.elementor-background-overlay {
  transition: background .3s, border-radius .3s, opacity .3s
}

.elementor-4722 .elementor-element.elementor-element-0bea599 {
  padding: 0 0 40px
}

.elementor-4722 .elementor-element.elementor-element-648d0e6 {
  text-align: left
}

.elementor-4722 .elementor-element.elementor-element-648d0e6 .elementor-heading-title {
  color: #000;
  font-size: 50px;
  font-weight: 700
}

.elementor-4722 .elementor-element.elementor-element-648d0e6>.elementor-widget-container {
  margin: 0;
  padding: 0
}

.elementor-4722 .elementor-element.elementor-element-1f549de.elementor-column.elementor-element[data-element_type=column]>.elementor-widget-wrap.elementor-element-populated {
  align-content: center;
  align-items: center
}

.elementor-4722 .elementor-element.elementor-element-1f549de.elementor-column>.elementor-widget-wrap {
  justify-content: center
}

.elementor-4722 .elementor-element.elementor-element-f30c5f8 {
  text-align: center;
  color: var(--e-global-color-primary);
  font-family: Roboto, Sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 1.4em
}

.elementor-4722 .elementor-element.elementor-element-f30c5f8>.elementor-widget-container {
  margin: 0
}

.elementor-4722 .elementor-element.elementor-element-5ae988a.elementor-view-stacked .elementor-icon {
  background-color: #F8D546;
  ;
  fill: var(--e-global-color-ddda476);
  color: var(--e-global-color-ddda476)
}

.elementor-4722 .elementor-element.elementor-element-5ae988a.elementor-view-default .elementor-icon {
  fill: var(--e-global-color-d9377ef);
  color: var(--e-global-color-d9377ef);
  border-color: var(--e-global-color-d9377ef)
}

.elementor-4722 .elementor-element.elementor-element-5ae988a {
  --icon-box-icon-margin: 10px
}

.elementor-4722 .elementor-element.elementor-element-5ae988a .elementor-icon {
  font-size: 15px
}

.elementor-4722 .elementor-element.elementor-element-5ae988a .elementor-icon-box-title {
  color: var(--e-global-color-6c3ab248)
}

.elementor-4722 .elementor-element.elementor-element-5ae988a .elementor-icon-box-title,
.elementor-4722 .elementor-element.elementor-element-5ae988a .elementor-icon-box-title a {
  font-family: Roboto, Sans-serif;
  font-size: 60px;
  font-weight: 700
}

.elementor-4722 .elementor-element.elementor-element-5ae988a .elementor-icon-box-description {
  color: var(--e-global-color-6c3ab248);
  font-family: Roboto, Sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.8em
}

.elementor-4722 .elementor-element.elementor-element-5ae988a>.elementor-widget-container {
  margin: 0;
  padding: 0 10px 10px;
  background-color: var(--e-global-color-ddda476);
  border-radius: 7px 7px 7px 7px
}

.elementor-4722 .elementor-element.elementor-element-70bb3db.elementor-view-stacked .elementor-icon {
  background-color: #F8D546;
  ;
  fill: var(--e-global-color-primary);
  color: var(--e-global-color-primary)
}

.elementor-4722 .elementor-element.elementor-element-70bb3db.elementor-view-default .elementor-icon {
  fill: var(--e-global-color-d9377ef);
  color: var(--e-global-color-d9377ef);
  border-color: var(--e-global-color-d9377ef)
}

.elementor-4722 .elementor-element.elementor-element-70bb3db {
  --icon-box-icon-margin: 10px
}

.elementor-4722 .elementor-element.elementor-element-70bb3db .elementor-icon {
  font-size: 15px
}

.elementor-4722 .elementor-element.elementor-element-70bb3db .elementor-icon-box-title {
  color: var(--e-global-color-6c3ab248)
}

.elementor-4722 .elementor-element.elementor-element-70bb3db .elementor-icon-box-title,
.elementor-4722 .elementor-element.elementor-element-70bb3db .elementor-icon-box-title a {
  font-family: Roboto, Sans-serif;
  font-size: 60px;
  font-weight: 700
}

.elementor-4722 .elementor-element.elementor-element-70bb3db .elementor-icon-box-description {
  color: var(--e-global-color-6c3ab248);
  font-family: Roboto, Sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.8em
}

.elementor-4722 .elementor-element.elementor-element-70bb3db>.elementor-widget-container {
  margin: 0;
  padding: 0 10px 10px;
  background-color: var(--e-global-color-ddda476);
  border-radius: 7px 7px 7px 7px
}

.elementor-4722 .elementor-element.elementor-element-9fcfa73.elementor-view-stacked .elementor-icon {
  background-color: #F8D546;
  ;
  fill: var(--e-global-color-ddda476);
  color: var(--e-global-color-ddda476)
}

.elementor-4722 .elementor-element.elementor-element-9fcfa73.elementor-view-default .elementor-icon {
  fill: var(--e-global-color-d9377ef);
  color: var(--e-global-color-d9377ef);
  border-color: var(--e-global-color-d9377ef)
}

.elementor-4722 .elementor-element.elementor-element-9fcfa73 {
  --icon-box-icon-margin: 10px
}

.elementor-4722 .elementor-element.elementor-element-9fcfa73 .elementor-icon {
  font-size: 15px
}

.elementor-4722 .elementor-element.elementor-element-9fcfa73 .elementor-icon-box-title {
  color: var(--e-global-color-6c3ab248)
}

.elementor-4722 .elementor-element.elementor-element-9fcfa73 .elementor-icon-box-title,
.elementor-4722 .elementor-element.elementor-element-9fcfa73 .elementor-icon-box-title a {
  font-family: Roboto, Sans-serif;
  font-size: 60px;
  font-weight: 700
}

.elementor-4722 .elementor-element.elementor-element-9fcfa73 .elementor-icon-box-description {
  color: var(--e-global-color-6c3ab248);
  font-family: Roboto, Sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.8em
}

.elementor-4722 .elementor-element.elementor-element-9fcfa73>.elementor-widget-container {
  margin: 0;
  padding: 0 10px 10px;
  background-color: var(--e-global-color-ddda476);
  border-radius: 7px 7px 7px 7px
}

.elementor-4722 .elementor-element.elementor-element-8600f58.elementor-view-stacked .elementor-icon {
  background-color: #F8D546;
  ;
  fill: var(--e-global-color-ddda476);
  color: var(--e-global-color-ddda476)
}

.elementor-4722 .elementor-element.elementor-element-8600f58.elementor-view-default .elementor-icon {
  fill: var(--e-global-color-d9377ef);
  color: var(--e-global-color-d9377ef);
  border-color: var(--e-global-color-d9377ef)
}

.elementor-4722 .elementor-element.elementor-element-8600f58 {
  --icon-box-icon-margin: 10px
}

.elementor-4722 .elementor-element.elementor-element-8600f58 .elementor-icon {
  font-size: 15px
}

.elementor-4722 .elementor-element.elementor-element-8600f58 .elementor-icon-box-title {
  color: var(--e-global-color-6c3ab248)
}

.elementor-4722 .elementor-element.elementor-element-8600f58 .elementor-icon-box-title,
.elementor-4722 .elementor-element.elementor-element-8600f58 .elementor-icon-box-title a {
  font-family: Roboto, Sans-serif;
  font-size: 60px;
  font-weight: 700
}

.elementor-4722 .elementor-element.elementor-element-8600f58 .elementor-icon-box-description {
  color: var(--e-global-color-6c3ab248);
  font-family: Roboto, Sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.8em
}

.elementor-4722 .elementor-element.elementor-element-8600f58>.elementor-widget-container {
  margin: 0;
  padding: 0 10px 10px;
  background-color: var(--e-global-color-ddda476);
  border-radius: 7px 7px 7px 7px
}

.elementor-4722 .elementor-element.elementor-element-2778a97.elementor-view-stacked .elementor-icon {
  background-color: #F8D546;
  ;
  fill: var(--e-global-color-ddda476);
  color: var(--e-global-color-ddda476)
}

.elementor-4722 .elementor-element.elementor-element-2778a97.elementor-view-default .elementor-icon {
  fill: var(--e-global-color-d9377ef);
  color: var(--e-global-color-d9377ef);
  border-color: var(--e-global-color-d9377ef)
}

.elementor-4722 .elementor-element.elementor-element-2778a97 {
  --icon-box-icon-margin: 10px
}

.elementor-4722 .elementor-element.elementor-element-2778a97 .elementor-icon {
  font-size: 15px
}

.elementor-4722 .elementor-element.elementor-element-2778a97 .elementor-icon-box-title {
  color: var(--e-global-color-6c3ab248)
}

.elementor-4722 .elementor-element.elementor-element-2778a97 .elementor-icon-box-title,
.elementor-4722 .elementor-element.elementor-element-2778a97 .elementor-icon-box-title a {
  font-family: Roboto, Sans-serif;
  font-size: 60px;
  font-weight: 700
}

.elementor-4722 .elementor-element.elementor-element-2778a97 .elementor-icon-box-description {
  color: var(--e-global-color-6c3ab248);
  font-family: Roboto, Sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.8em
}

.elementor-4722 .elementor-element.elementor-element-2778a97>.elementor-widget-container {
  margin: 0;
  padding: 0 10px 10px;
  background-color: var(--e-global-color-ddda476);
  border-radius: 7px 7px 7px 7px
}

.elementor-4722 .elementor-element.elementor-element-ff18bd1.elementor-view-stacked .elementor-icon {
  background-color: #F8D546;
  ;
  fill: var(--e-global-color-ddda476);
  color: var(--e-global-color-ddda476)
}

.elementor-4722 .elementor-element.elementor-element-ff18bd1.elementor-view-default .elementor-icon {
  fill: var(--e-global-color-d9377ef);
  color: var(--e-global-color-d9377ef);
  border-color: var(--e-global-color-d9377ef)
}

.elementor-4722 .elementor-element.elementor-element-ff18bd1 {
  --icon-box-icon-margin: 10px
}

.elementor-4722 .elementor-element.elementor-element-ff18bd1 .elementor-icon {
  font-size: 15px
}

.elementor-4722 .elementor-element.elementor-element-ff18bd1 .elementor-icon-box-title {
  color: var(--e-global-color-6c3ab248)
}

.elementor-4722 .elementor-element.elementor-element-ff18bd1 .elementor-icon-box-title,
.elementor-4722 .elementor-element.elementor-element-ff18bd1 .elementor-icon-box-title a {
  font-family: Roboto, Sans-serif;
  font-size: 60px;
  font-weight: 700
}

.elementor-4722 .elementor-element.elementor-element-ff18bd1 .elementor-icon-box-description {
  color: var(--e-global-color-6c3ab248);
  font-family: Roboto, Sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.8em
}

.elementor-4722 .elementor-element.elementor-element-ff18bd1>.elementor-widget-container {
  margin: 0;
  padding: 0 10px 10px;
  background-color: var(--e-global-color-ddda476);
  border-radius: 7px 7px 7px 7px
}

.elementor-4722 .elementor-element.elementor-element-160f72e.elementor-view-stacked .elementor-icon {
  background-color: #F8D546;
  ;
  fill: var(--e-global-color-ddda476);
  color: var(--e-global-color-ddda476)
}

.elementor-4722 .elementor-element.elementor-element-160f72e.elementor-view-default .elementor-icon {
  fill: var(--e-global-color-d9377ef);
  color: var(--e-global-color-d9377ef);
  border-color: var(--e-global-color-d9377ef)
}

.elementor-4722 .elementor-element.elementor-element-160f72e {
  --icon-box-icon-margin: 10px
}

.elementor-4722 .elementor-element.elementor-element-160f72e .elementor-icon {
  font-size: 15px
}

.elementor-4722 .elementor-element.elementor-element-160f72e .elementor-icon-box-title {
  color: var(--e-global-color-6c3ab248)
}

.elementor-4722 .elementor-element.elementor-element-160f72e .elementor-icon-box-title,
.elementor-4722 .elementor-element.elementor-element-160f72e .elementor-icon-box-title a {
  font-family: Roboto, Sans-serif;
  font-size: 60px;
  font-weight: 700
}

.elementor-4722 .elementor-element.elementor-element-160f72e .elementor-icon-box-description {
  color: var(--e-global-color-6c3ab248);
  font-family: Roboto, Sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.8em
}

.elementor-4722 .elementor-element.elementor-element-160f72e>.elementor-widget-container {
  margin: 0;
  padding: 0 10px 10px;
  background-color: var(--e-global-color-ddda476);
  border-radius: 7px 7px 7px 7px
}

.elementor-4722 .elementor-element.elementor-element-5559292.elementor-view-stacked .elementor-icon {
  background-color: #F8D546;
  ;
  fill: var(--e-global-color-primary);
  color: var(--e-global-color-primary)
}

.elementor-4722 .elementor-element.elementor-element-5559292.elementor-view-default .elementor-icon {
  fill: var(--e-global-color-d9377ef);
  color: var(--e-global-color-d9377ef);
  border-color: var(--e-global-color-d9377ef)
}

.elementor-4722 .elementor-element.elementor-element-5559292 {
  --icon-box-icon-margin: 10px
}

.elementor-4722 .elementor-element.elementor-element-5559292 .elementor-icon {
  font-size: 15px
}

.elementor-4722 .elementor-element.elementor-element-5559292 .elementor-icon-box-title {
  color: var(--e-global-color-6c3ab248)
}

.elementor-4722 .elementor-element.elementor-element-5559292 .elementor-icon-box-title,
.elementor-4722 .elementor-element.elementor-element-5559292 .elementor-icon-box-title a {
  font-family: Roboto, Sans-serif;
  font-size: 60px;
  font-weight: 700
}

.elementor-4722 .elementor-element.elementor-element-5559292 .elementor-icon-box-description {
  color: var(--e-global-color-6c3ab248);
  font-family: Roboto, Sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.8em
}

.elementor-4722 .elementor-element.elementor-element-5559292>.elementor-widget-container {
  margin: 0;
  padding: 0 10px 10px;
  background-color: var(--e-global-color-ddda476);
  border-radius: 7px 7px 7px 7px
}

.elementor-4722 .elementor-element.elementor-element-816dd97.elementor-view-stacked .elementor-icon {
  background-color: #F8D546;
  ;
  fill: var(--e-global-color-ddda476);
  color: var(--e-global-color-ddda476)
}

.elementor-4722 .elementor-element.elementor-element-816dd97.elementor-view-default .elementor-icon {
  fill: var(--e-global-color-d9377ef);
  color: var(--e-global-color-d9377ef);
  border-color: var(--e-global-color-d9377ef)
}

.elementor-4722 .elementor-element.elementor-element-816dd97 {
  --icon-box-icon-margin: 10px
}

.elementor-4722 .elementor-element.elementor-element-816dd97 .elementor-icon {
  font-size: 15px
}

.elementor-4722 .elementor-element.elementor-element-816dd97 .elementor-icon-box-title {
  color: var(--e-global-color-6c3ab248)
}

.elementor-4722 .elementor-element.elementor-element-816dd97 .elementor-icon-box-title,
.elementor-4722 .elementor-element.elementor-element-816dd97 .elementor-icon-box-title a {
  font-family: Roboto, Sans-serif;
  font-size: 60px;
  font-weight: 700
}

.elementor-4722 .elementor-element.elementor-element-816dd97 .elementor-icon-box-description {
  color: var(--e-global-color-6c3ab248);
  font-family: Roboto, Sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.8em
}

.elementor-4722 .elementor-element.elementor-element-816dd97>.elementor-widget-container {
  margin: 0;
  padding: 0 10px 10px;
  background-color: var(--e-global-color-ddda476);
  border-radius: 7px 7px 7px 7px
}

.elementor-4722 .elementor-element.elementor-element-d8605a8.elementor-view-stacked .elementor-icon {
  background-color: #F8D546;
  ;
  fill: var(--e-global-color-ddda476);
  color: var(--e-global-color-ddda476)
}

.elementor-4722 .elementor-element.elementor-element-d8605a8.elementor-view-default .elementor-icon {
  fill: var(--e-global-color-d9377ef);
  color: var(--e-global-color-d9377ef);
  border-color: var(--e-global-color-d9377ef)
}

.elementor-4722 .elementor-element.elementor-element-d8605a8 {
  --icon-box-icon-margin: 10px
}

.elementor-4722 .elementor-element.elementor-element-d8605a8 .elementor-icon {
  font-size: 15px
}

.elementor-4722 .elementor-element.elementor-element-d8605a8 .elementor-icon-box-title {
  color: var(--e-global-color-6c3ab248)
}

.elementor-4722 .elementor-element.elementor-element-d8605a8 .elementor-icon-box-title,
.elementor-4722 .elementor-element.elementor-element-d8605a8 .elementor-icon-box-title a {
  font-family: Roboto, Sans-serif;
  font-size: 60px;
  font-weight: 700
}

.elementor-4722 .elementor-element.elementor-element-d8605a8 .elementor-icon-box-description {
  color: var(--e-global-color-6c3ab248);
  font-family: Roboto, Sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.8em
}

.elementor-4722 .elementor-element.elementor-element-d8605a8>.elementor-widget-container {
  margin: 0;
  padding: 0 10px 10px;
  background-color: var(--e-global-color-ddda476);
  border-radius: 7px 7px 7px 7px
}

.elementor-4722 .elementor-element.elementor-element-1cf236c.elementor-view-stacked .elementor-icon {
  background-color: #F8D546;
  ;
  fill: var(--e-global-color-ddda476);
  color: var(--e-global-color-ddda476)
}

.elementor-4722 .elementor-element.elementor-element-1cf236c.elementor-view-default .elementor-icon {
  fill: var(--e-global-color-d9377ef);
  color: var(--e-global-color-d9377ef);
  border-color: var(--e-global-color-d9377ef)
}

.elementor-4722 .elementor-element.elementor-element-1cf236c {
  --icon-box-icon-margin: 10px
}

.elementor-4722 .elementor-element.elementor-element-1cf236c .elementor-icon {
  font-size: 15px
}

.elementor-4722 .elementor-element.elementor-element-1cf236c .elementor-icon-box-title {
  color: var(--e-global-color-6c3ab248)
}

.elementor-4722 .elementor-element.elementor-element-1cf236c .elementor-icon-box-title,
.elementor-4722 .elementor-element.elementor-element-1cf236c .elementor-icon-box-title a {
  font-family: Roboto, Sans-serif;
  font-size: 60px;
  font-weight: 700
}

.elementor-4722 .elementor-element.elementor-element-1cf236c .elementor-icon-box-description {
  color: var(--e-global-color-6c3ab248);
  font-family: Roboto, Sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.8em
}

.elementor-4722 .elementor-element.elementor-element-1cf236c>.elementor-widget-container {
  margin: 0;
  padding: 0 10px 10px;
  background-color: var(--e-global-color-ddda476);
  border-radius: 7px 7px 7px 7px
}

.elementor-4722 .elementor-element.elementor-element-39d753b.elementor-view-stacked .elementor-icon {
  background-color: #F8D546;
  ;
  fill: var(--e-global-color-ddda476);
  color: var(--e-global-color-ddda476)
}

.elementor-4722 .elementor-element.elementor-element-39d753b.elementor-view-default .elementor-icon {
  fill: var(--e-global-color-d9377ef);
  color: var(--e-global-color-d9377ef);
  border-color: var(--e-global-color-d9377ef)
}

.elementor-4722 .elementor-element.elementor-element-39d753b {
  --icon-box-icon-margin: 10px
}

.elementor-4722 .elementor-element.elementor-element-39d753b .elementor-icon {
  font-size: 15px
}

.elementor-4722 .elementor-element.elementor-element-39d753b .elementor-icon-box-title {
  color: var(--e-global-color-6c3ab248)
}

.elementor-4722 .elementor-element.elementor-element-39d753b .elementor-icon-box-title,
.elementor-4722 .elementor-element.elementor-element-39d753b .elementor-icon-box-title a {
  font-family: Roboto, Sans-serif;
  font-size: 60px;
  font-weight: 700
}

.elementor-4722 .elementor-element.elementor-element-39d753b .elementor-icon-box-description {
  color: var(--e-global-color-6c3ab248);
  font-family: Roboto, Sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.8em
}

.elementor-4722 .elementor-element.elementor-element-39d753b>.elementor-widget-container {
  margin: 0;
  padding: 0 10px 10px;
  background-color: var(--e-global-color-ddda476);
  border-radius: 7px 7px 7px 7px
}

.elementor-4722 .elementor-element.elementor-element-102661b.elementor-view-stacked .elementor-icon {
  background-color: #F8D546;
  ;
  fill: var(--e-global-color-ddda476);
  color: var(--e-global-color-ddda476)
}

.elementor-4722 .elementor-element.elementor-element-102661b.elementor-view-default .elementor-icon {
  fill: var(--e-global-color-d9377ef);
  color: var(--e-global-color-d9377ef);
  border-color: var(--e-global-color-d9377ef)
}

.elementor-4722 .elementor-element.elementor-element-102661b {
  --icon-box-icon-margin: 10px
}

.elementor-4722 .elementor-element.elementor-element-102661b .elementor-icon {
  font-size: 15px
}

.elementor-4722 .elementor-element.elementor-element-102661b .elementor-icon-box-title {
  color: var(--e-global-color-6c3ab248)
}

.elementor-4722 .elementor-element.elementor-element-102661b .elementor-icon-box-title,
.elementor-4722 .elementor-element.elementor-element-102661b .elementor-icon-box-title a {
  font-family: Roboto, Sans-serif;
  font-size: 60px;
  font-weight: 700
}

.elementor-4722 .elementor-element.elementor-element-102661b .elementor-icon-box-description {
  color: var(--e-global-color-6c3ab248);
  font-family: Roboto, Sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.8em
}

.elementor-4722 .elementor-element.elementor-element-102661b>.elementor-widget-container {
  margin: 0;
  padding: 0 10px 10px;
  background-color: var(--e-global-color-ddda476);
  border-radius: 7px 7px 7px 7px
}

.elementor-4722 .elementor-element.elementor-element-e0af2f3.elementor-view-stacked .elementor-icon {
  background-color: #F8D546;
  ;
  fill: var(--e-global-color-ddda476);
  color: var(--e-global-color-ddda476)
}

.elementor-4722 .elementor-element.elementor-element-e0af2f3.elementor-view-default .elementor-icon {
  fill: var(--e-global-color-d9377ef);
  color: var(--e-global-color-d9377ef);
  border-color: var(--e-global-color-d9377ef)
}

.elementor-4722 .elementor-element.elementor-element-e0af2f3 {
  --icon-box-icon-margin: 10px
}

.elementor-4722 .elementor-element.elementor-element-e0af2f3 .elementor-icon {
  font-size: 15px
}

.elementor-4722 .elementor-element.elementor-element-e0af2f3 .elementor-icon-box-title {
  color: var(--e-global-color-6c3ab248)
}

.elementor-4722 .elementor-element.elementor-element-e0af2f3 .elementor-icon-box-title,
.elementor-4722 .elementor-element.elementor-element-e0af2f3 .elementor-icon-box-title a {
  font-family: Roboto, Sans-serif;
  font-size: 60px;
  font-weight: 700
}

.elementor-4722 .elementor-element.elementor-element-e0af2f3 .elementor-icon-box-description {
  color: var(--e-global-color-6c3ab248);
  font-family: Roboto, Sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.8em
}

.elementor-4722 .elementor-element.elementor-element-e0af2f3>.elementor-widget-container {
  margin: 0;
  padding: 0 10px 10px;
  background-color: var(--e-global-color-ddda476);
  border-radius: 7px 7px 7px 7px
}

.elementor-4722 .elementor-element.elementor-element-8b50494.elementor-view-stacked .elementor-icon {
  background-color: #F8D546;
  ;
  fill: var(--e-global-color-primary);
  color: var(--e-global-color-primary)
}

.elementor-4722 .elementor-element.elementor-element-8b50494.elementor-view-default .elementor-icon {
  fill: var(--e-global-color-d9377ef);
  color: var(--e-global-color-d9377ef);
  border-color: var(--e-global-color-d9377ef)
}

.elementor-4722 .elementor-element.elementor-element-8b50494 {
  --icon-box-icon-margin: 10px
}

.elementor-4722 .elementor-element.elementor-element-8b50494 .elementor-icon {
  font-size: 15px
}

.elementor-4722 .elementor-element.elementor-element-8b50494 .elementor-icon-box-title {
  color: var(--e-global-color-6c3ab248)
}

.elementor-4722 .elementor-element.elementor-element-8b50494 .elementor-icon-box-title,
.elementor-4722 .elementor-element.elementor-element-8b50494 .elementor-icon-box-title a {
  font-family: Roboto, Sans-serif;
  font-size: 60px;
  font-weight: 700
}

.elementor-4722 .elementor-element.elementor-element-8b50494 .elementor-icon-box-description {
  color: var(--e-global-color-6c3ab248);
  font-family: Roboto, Sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.8em
}

.elementor-4722 .elementor-element.elementor-element-8b50494>.elementor-widget-container {
  margin: 0;
  padding: 0 10px 10px;
  background-color: var(--e-global-color-ddda476);
  border-radius: 7px 7px 7px 7px
}

.elementor-4722 .elementor-element.elementor-element-5a0d0e0.elementor-view-stacked .elementor-icon {
  background-color: #F8D546;
  ;
  fill: var(--e-global-color-ddda476);
  color: var(--e-global-color-ddda476)
}

.elementor-4722 .elementor-element.elementor-element-5a0d0e0.elementor-view-default .elementor-icon {
  fill: var(--e-global-color-d9377ef);
  color: var(--e-global-color-d9377ef);
  border-color: var(--e-global-color-d9377ef)
}

.elementor-4722 .elementor-element.elementor-element-5a0d0e0 {
  --icon-box-icon-margin: 10px
}

.elementor-4722 .elementor-element.elementor-element-5a0d0e0 .elementor-icon {
  font-size: 15px
}

.elementor-4722 .elementor-element.elementor-element-5a0d0e0 .elementor-icon-box-title {
  color: var(--e-global-color-6c3ab248)
}

.elementor-4722 .elementor-element.elementor-element-5a0d0e0 .elementor-icon-box-title,
.elementor-4722 .elementor-element.elementor-element-5a0d0e0 .elementor-icon-box-title a {
  font-family: Roboto, Sans-serif;
  font-size: 60px;
  font-weight: 700
}

.elementor-4722 .elementor-element.elementor-element-5a0d0e0 .elementor-icon-box-description {
  color: var(--e-global-color-6c3ab248);
  font-family: Roboto, Sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.8em
}

.elementor-4722 .elementor-element.elementor-element-5a0d0e0>.elementor-widget-container {
  margin: 0;
  padding: 0 10px 10px;
  background-color: var(--e-global-color-ddda476);
  border-radius: 7px 7px 7px 7px
}

.elementor-4722 .elementor-element.elementor-element-1744713.elementor-view-stacked .elementor-icon {
  background-color: #F8D546;
  ;
  fill: var(--e-global-color-ddda476);
  color: var(--e-global-color-ddda476)
}

.elementor-4722 .elementor-element.elementor-element-1744713.elementor-view-default .elementor-icon {
  fill: var(--e-global-color-d9377ef);
  color: var(--e-global-color-d9377ef);
  border-color: var(--e-global-color-d9377ef)
}

.elementor-4722 .elementor-element.elementor-element-1744713 {
  --icon-box-icon-margin: 10px
}

.elementor-4722 .elementor-element.elementor-element-1744713 .elementor-icon {
  font-size: 15px
}

.elementor-4722 .elementor-element.elementor-element-1744713 .elementor-icon-box-title {
  color: var(--e-global-color-6c3ab248)
}

.elementor-4722 .elementor-element.elementor-element-1744713 .elementor-icon-box-title,
.elementor-4722 .elementor-element.elementor-element-1744713 .elementor-icon-box-title a {
  font-family: Roboto, Sans-serif;
  font-size: 60px;
  font-weight: 700
}

.elementor-4722 .elementor-element.elementor-element-1744713 .elementor-icon-box-description {
  color: var(--e-global-color-6c3ab248);
  font-family: Roboto, Sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.8em
}

.elementor-4722 .elementor-element.elementor-element-1744713>.elementor-widget-container {
  margin: 0;
  padding: 0 10px 10px;
  background-color: var(--e-global-color-ddda476);
  border-radius: 7px 7px 7px 7px
}

.elementor-4722 .elementor-element.elementor-element-076f54d.elementor-view-stacked .elementor-icon {
  background-color: #F8D546;
  ;
  fill: var(--e-global-color-primary);
  color: var(--e-global-color-primary)
}

.elementor-4722 .elementor-element.elementor-element-076f54d.elementor-view-default .elementor-icon {
  fill: var(--e-global-color-d9377ef);
  color: var(--e-global-color-d9377ef);
  border-color: var(--e-global-color-d9377ef)
}

.elementor-4722 .elementor-element.elementor-element-076f54d {
  --icon-box-icon-margin: 10px
}

.elementor-4722 .elementor-element.elementor-element-076f54d .elementor-icon {
  font-size: 15px
}

.elementor-4722 .elementor-element.elementor-element-076f54d .elementor-icon-box-title {
  color: var(--e-global-color-6c3ab248)
}

.elementor-4722 .elementor-element.elementor-element-076f54d .elementor-icon-box-title,
.elementor-4722 .elementor-element.elementor-element-076f54d .elementor-icon-box-title a {
  font-family: Roboto, Sans-serif;
  font-size: 60px;
  font-weight: 700
}

.elementor-4722 .elementor-element.elementor-element-076f54d .elementor-icon-box-description {
  color: var(--e-global-color-6c3ab248);
  font-family: Roboto, Sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.8em
}

.elementor-4722 .elementor-element.elementor-element-076f54d>.elementor-widget-container {
  margin: 0;
  padding: 0 10px 10px;
  background-color: var(--e-global-color-ddda476);
  border-radius: 7px 7px 7px 7px
}

.elementor-4722 .elementor-element.elementor-element-290aeeb.elementor-view-stacked .elementor-icon {
  background-color: #F8D546;
  ;
  fill: var(--e-global-color-ddda476);
  color: var(--e-global-color-ddda476)
}

.elementor-4722 .elementor-element.elementor-element-290aeeb.elementor-view-default .elementor-icon {
  fill: var(--e-global-color-d9377ef);
  color: var(--e-global-color-d9377ef);
  border-color: var(--e-global-color-d9377ef)
}

.elementor-4722 .elementor-element.elementor-element-290aeeb {
  --icon-box-icon-margin: 10px
}

.elementor-4722 .elementor-element.elementor-element-290aeeb .elementor-icon {
  font-size: 15px
}

.elementor-4722 .elementor-element.elementor-element-290aeeb .elementor-icon-box-title {
  color: var(--e-global-color-6c3ab248)
}

.elementor-4722 .elementor-element.elementor-element-290aeeb .elementor-icon-box-title,
.elementor-4722 .elementor-element.elementor-element-290aeeb .elementor-icon-box-title a {
  font-family: Roboto, Sans-serif;
  font-size: 60px;
  font-weight: 700
}

.elementor-4722 .elementor-element.elementor-element-290aeeb .elementor-icon-box-description {
  color: var(--e-global-color-6c3ab248);
  font-family: Roboto, Sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.8em
}

.elementor-4722 .elementor-element.elementor-element-290aeeb>.elementor-widget-container {
  margin: 0;
  padding: 0 10px 10px;
  background-color: var(--e-global-color-ddda476);
  border-radius: 7px 7px 7px 7px
}

.elementor-4722 .elementor-element.elementor-element-8d77b7b.elementor-view-stacked .elementor-icon {
  background-color: #F8D546;
  ;
  fill: var(--e-global-color-primary);
  color: var(--e-global-color-primary)
}

.elementor-4722 .elementor-element.elementor-element-8d77b7b.elementor-view-default .elementor-icon {
  fill: var(--e-global-color-d9377ef);
  color: var(--e-global-color-d9377ef);
  border-color: var(--e-global-color-d9377ef)
}

.elementor-4722 .elementor-element.elementor-element-8d77b7b {
  --icon-box-icon-margin: 10px
}

.elementor-4722 .elementor-element.elementor-element-8d77b7b .elementor-icon {
  font-size: 15px
}

.elementor-4722 .elementor-element.elementor-element-8d77b7b .elementor-icon-box-title {
  color: var(--e-global-color-6c3ab248)
}

.elementor-4722 .elementor-element.elementor-element-8d77b7b .elementor-icon-box-title,
.elementor-4722 .elementor-element.elementor-element-8d77b7b .elementor-icon-box-title a {
  font-family: Roboto, Sans-serif;
  font-size: 60px;
  font-weight: 700
}

.elementor-4722 .elementor-element.elementor-element-8d77b7b .elementor-icon-box-description {
  color: var(--e-global-color-6c3ab248);
  font-family: Roboto, Sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.8em
}

.elementor-4722 .elementor-element.elementor-element-8d77b7b>.elementor-widget-container {
  margin: 0;
  padding: 0 10px 10px;
  background-color: var(--e-global-color-ddda476);
  border-radius: 7px 7px 7px 7px
}

.elementor-4722 .elementor-element.elementor-element-0da761a.elementor-view-stacked .elementor-icon {
  background-color: #F8D546;
  ;
  fill: var(--e-global-color-ddda476);
  color: var(--e-global-color-ddda476)
}

.elementor-4722 .elementor-element.elementor-element-0da761a.elementor-view-default .elementor-icon {
  fill: var(--e-global-color-d9377ef);
  color: var(--e-global-color-d9377ef);
  border-color: var(--e-global-color-d9377ef)
}

.elementor-4722 .elementor-element.elementor-element-0da761a {
  --icon-box-icon-margin: 10px
}

.elementor-4722 .elementor-element.elementor-element-0da761a .elementor-icon {
  font-size: 15px
}

.elementor-4722 .elementor-element.elementor-element-0da761a .elementor-icon-box-title {
  color: var(--e-global-color-6c3ab248)
}

.elementor-4722 .elementor-element.elementor-element-0da761a .elementor-icon-box-title,
.elementor-4722 .elementor-element.elementor-element-0da761a .elementor-icon-box-title a {
  font-family: Roboto, Sans-serif;
  font-size: 60px;
  font-weight: 700
}

.elementor-4722 .elementor-element.elementor-element-0da761a .elementor-icon-box-description {
  color: var(--e-global-color-6c3ab248);
  font-family: Roboto, Sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.8em
}

.elementor-4722 .elementor-element.elementor-element-0da761a>.elementor-widget-container {
  margin: 0;
  padding: 0 10px 10px;
  background-color: var(--e-global-color-ddda476);
  border-radius: 7px 7px 7px 7px
}

.elementor-4722 .elementor-element.elementor-element-dbad877.elementor-view-stacked .elementor-icon {
  background-color: #F8D546;
  ;
  fill: var(--e-global-color-ddda476);
  color: var(--e-global-color-ddda476)
}

.elementor-4722 .elementor-element.elementor-element-dbad877.elementor-view-default .elementor-icon {
  fill: var(--e-global-color-d9377ef);
  color: var(--e-global-color-d9377ef);
  border-color: var(--e-global-color-d9377ef)
}

.elementor-4722 .elementor-element.elementor-element-dbad877 {
  --icon-box-icon-margin: 10px
}

.elementor-4722 .elementor-element.elementor-element-dbad877 .elementor-icon {
  font-size: 15px
}

.elementor-4722 .elementor-element.elementor-element-dbad877 .elementor-icon-box-title {
  color: var(--e-global-color-6c3ab248)
}

.elementor-4722 .elementor-element.elementor-element-dbad877 .elementor-icon-box-title,
.elementor-4722 .elementor-element.elementor-element-dbad877 .elementor-icon-box-title a {
  font-family: Roboto, Sans-serif;
  font-size: 60px;
  font-weight: 700
}

.elementor-4722 .elementor-element.elementor-element-dbad877 .elementor-icon-box-description {
  color: var(--e-global-color-6c3ab248);
  font-family: Roboto, Sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.8em
}

.elementor-4722 .elementor-element.elementor-element-dbad877>.elementor-widget-container {
  margin: 0;
  padding: 0 10px 10px;
  background-color: var(--e-global-color-ddda476);
  border-radius: 7px 7px 7px 7px
}

.elementor-4722 .elementor-element.elementor-element-182e273.elementor-view-stacked .elementor-icon {
  background-color: #F8D546;
  ;
  fill: var(--e-global-color-ddda476);
  color: var(--e-global-color-ddda476)
}

.elementor-4722 .elementor-element.elementor-element-182e273.elementor-view-default .elementor-icon {
  fill: var(--e-global-color-d9377ef);
  color: var(--e-global-color-d9377ef);
  border-color: var(--e-global-color-d9377ef)
}

.elementor-4722 .elementor-element.elementor-element-182e273 {
  --icon-box-icon-margin: 10px
}

.elementor-4722 .elementor-element.elementor-element-182e273 .elementor-icon {
  font-size: 15px
}

.elementor-4722 .elementor-element.elementor-element-182e273 .elementor-icon-box-title {
  color: var(--e-global-color-6c3ab248)
}

.elementor-4722 .elementor-element.elementor-element-182e273 .elementor-icon-box-title,
.elementor-4722 .elementor-element.elementor-element-182e273 .elementor-icon-box-title a {
  font-family: Roboto, Sans-serif;
  font-size: 60px;
  font-weight: 700
}

.elementor-4722 .elementor-element.elementor-element-182e273 .elementor-icon-box-description {
  color: var(--e-global-color-6c3ab248);
  font-family: Roboto, Sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.8em
}

.elementor-4722 .elementor-element.elementor-element-182e273>.elementor-widget-container {
  margin: 0;
  padding: 0 10px 10px;
  background-color: var(--e-global-color-ddda476);
  border-radius: 7px 7px 7px 7px
}

.elementor-4722 .elementor-element.elementor-element-f862cf5.elementor-view-stacked .elementor-icon {
  background-color: #F8D546;
  ;
  fill: var(--e-global-color-ddda476);
  color: var(--e-global-color-ddda476)
}

.elementor-4722 .elementor-element.elementor-element-f862cf5.elementor-view-default .elementor-icon {
  fill: var(--e-global-color-d9377ef);
  color: var(--e-global-color-d9377ef);
  border-color: var(--e-global-color-d9377ef)
}

.elementor-4722 .elementor-element.elementor-element-f862cf5 {
  --icon-box-icon-margin: 10px
}

.elementor-4722 .elementor-element.elementor-element-f862cf5 .elementor-icon {
  font-size: 15px
}

.elementor-4722 .elementor-element.elementor-element-f862cf5 .elementor-icon-box-title {
  color: var(--e-global-color-6c3ab248)
}

.elementor-4722 .elementor-element.elementor-element-f862cf5 .elementor-icon-box-title,
.elementor-4722 .elementor-element.elementor-element-f862cf5 .elementor-icon-box-title a {
  font-family: Roboto, Sans-serif;
  font-size: 60px;
  font-weight: 700
}

.elementor-4722 .elementor-element.elementor-element-f862cf5 .elementor-icon-box-description {
  color: var(--e-global-color-6c3ab248);
  font-family: Roboto, Sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.8em
}

.elementor-4722 .elementor-element.elementor-element-f862cf5>.elementor-widget-container {
  margin: 0;
  padding: 0 10px 10px;
  background-color: var(--e-global-color-ddda476);
  border-radius: 7px 7px 7px 7px
}

.elementor-4722 .elementor-element.elementor-element-978e40b>.elementor-container {
  max-width: 920px
}

.elementor-4722 .elementor-element.elementor-element-978e40b:not(.elementor-motion-effects-element-type-background),
.elementor-4722 .elementor-element.elementor-element-978e40b>.elementor-motion-effects-container>.elementor-motion-effects-layer {
  background-color: #fff
}

.elementor-4722 .elementor-element.elementor-element-978e40b {
  transition: background .3s, border .3s, border-radius .3s, box-shadow .3s;
  margin-top: 0;
  margin-bottom: 0;
  padding: 20px 0 0
}

.elementor-4722 .elementor-element.elementor-element-978e40b>.elementor-background-overlay {
  transition: background .3s, border-radius .3s, opacity .3s
}

.elementor-4722 .elementor-element.elementor-element-dc6d183.elementor-column.elementor-element[data-element_type=column]>.elementor-widget-wrap.elementor-element-populated {
  align-content: center;
  align-items: center
}

.elementor-4722 .elementor-element.elementor-element-137ec6b {
  text-align: center
}

.elementor-4722 .elementor-element.elementor-element-137ec6b img {
  width: 90px;
  filter: brightness(123%) contrast(89%) saturate(0%) blur(0px) hue-rotate(0deg)
}

.elementor-4722 .elementor-element.elementor-element-738b0a6.elementor-column.elementor-element[data-element_type=column]>.elementor-widget-wrap.elementor-element-populated {
  align-content: center;
  align-items: center
}

.elementor-4722 .elementor-element.elementor-element-7be206e {
  text-align: center
}

.elementor-4722 .elementor-element.elementor-element-7be206e img {
  width: 100px;
  filter: brightness(100%) contrast(100%) saturate(0%) blur(0px) hue-rotate(0deg)
}

.elementor-4722 .elementor-element.elementor-element-e0d529d.elementor-column.elementor-element[data-element_type=column]>.elementor-widget-wrap.elementor-element-populated {
  align-content: center;
  align-items: center
}

.elementor-4722 .elementor-element.elementor-element-498caba {
  text-align: center
}

.elementor-4722 .elementor-element.elementor-element-498caba img {
  width: 155px;
  filter: brightness(100%) contrast(100%) saturate(0%) blur(0px) hue-rotate(0deg)
}

.elementor-4722 .elementor-element.elementor-element-498caba>.elementor-widget-container {
  margin: 0 10px
}

.elementor-4722 .elementor-element.elementor-element-c5b6424.elementor-column.elementor-element[data-element_type=column]>.elementor-widget-wrap.elementor-element-populated {
  align-content: center;
  align-items: center
}

.elementor-4722 .elementor-element.elementor-element-3dfc9f0 {
  text-align: center
}

.elementor-4722 .elementor-element.elementor-element-3dfc9f0 img {
  width: 1000px;
  filter: brightness(100%) contrast(100%) saturate(0%) blur(0px) hue-rotate(0deg)
}

.elementor-4722 .elementor-element.elementor-element-01f2195.elementor-column.elementor-element[data-element_type=column]>.elementor-widget-wrap.elementor-element-populated {
  align-content: center;
  align-items: center
}

.elementor-4722 .elementor-element.elementor-element-8fe6618 {
  text-align: center
}

.elementor-4722 .elementor-element.elementor-element-8fe6618 img {
  width: 57px;
  filter: brightness(100%) contrast(100%) saturate(0%) blur(0px) hue-rotate(0deg)
}

.elementor-4722 .elementor-element.elementor-element-8fe6618>.elementor-widget-container {
  margin: -10px
}

.elementor-4722 .elementor-element.elementor-element-414e461.elementor-column.elementor-element[data-element_type=column]>.elementor-widget-wrap.elementor-element-populated {
  align-content: center;
  align-items: center
}

.elementor-4722 .elementor-element.elementor-element-414e461>.elementor-element-populated {
  margin: 0;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px
}

.elementor-4722 .elementor-element.elementor-element-c1f0357 {
  text-align: center
}

.elementor-4722 .elementor-element.elementor-element-c1f0357 img {
  width: 1000px;
  filter: brightness(100%) contrast(100%) saturate(0%) blur(0px) hue-rotate(0deg)
}

.elementor-4722 .elementor-element.elementor-element-f2de76f.elementor-column.elementor-element[data-element_type=column]>.elementor-widget-wrap.elementor-element-populated {
  align-content: center;
  align-items: center
}

.elementor-4722 .elementor-element.elementor-element-1c7a6cc {
  text-align: center
}

.elementor-4722 .elementor-element.elementor-element-1c7a6cc img {
  width: 65px;
  filter: brightness(100%) contrast(100%) saturate(0%) blur(0px) hue-rotate(0deg)
}

.elementor-4722 .elementor-element.elementor-element-74784d1.elementor-column.elementor-element[data-element_type=column]>.elementor-widget-wrap.elementor-element-populated {
  align-content: center;
  align-items: center
}

.elementor-4722 .elementor-element.elementor-element-386c42e {
  text-align: center
}

.elementor-4722 .elementor-element.elementor-element-386c42e img {
  width: 1000px;
  filter: brightness(100%) contrast(100%) saturate(0%) blur(0px) hue-rotate(0deg)
}

.elementor-4722 .elementor-element.elementor-element-393f69e.elementor-column.elementor-element[data-element_type=column]>.elementor-widget-wrap.elementor-element-populated {
  align-content: center;
  align-items: center
}

.elementor-4722 .elementor-element.elementor-element-7bbf60a {
  text-align: center
}

.elementor-4722 .elementor-element.elementor-element-7bbf60a img {
  width: 65px;
  filter: brightness(100%) contrast(100%) saturate(0%) blur(0px) hue-rotate(0deg)
}

.elementor-4722 .elementor-element.elementor-element-ad77565.elementor-column.elementor-element[data-element_type=column]>.elementor-widget-wrap.elementor-element-populated {
  align-content: center;
  align-items: center
}

.elementor-4722 .elementor-element.elementor-element-db5ec76 {
  text-align: right
}

.elementor-4722 .elementor-element.elementor-element-db5ec76 img {
  width: 1000px;
  filter: brightness(100%) contrast(100%) saturate(0%) blur(0px) hue-rotate(0deg)
}

.elementor-4722 .elementor-element.elementor-element-db5ec76>.elementor-widget-container {
  margin: 0;
  padding: 0
}

.elementor-4722 .elementor-element.elementor-element-d138a1e:not(.elementor-motion-effects-element-type-background),
.elementor-4722 .elementor-element.elementor-element-d138a1e>.elementor-motion-effects-container>.elementor-motion-effects-layer {
  background-color: #fff
}

.elementor-4722 .elementor-element.elementor-element-d138a1e {
  transition: background .3s, border .3s, border-radius .3s, box-shadow .3s;
  margin-top: 0;
  margin-bottom: 0;
  padding: 100px 0 50px
}

.elementor-4722 .elementor-element.elementor-element-d138a1e>.elementor-background-overlay {
  transition: background .3s, border-radius .3s, opacity .3s
}

.elementor-4722 .elementor-element.elementor-element-d138a1e>.elementor-shape-top svg {
  height: 140px;
  transform: translateX(-50%) rotateY(180deg)
}

.elementor-4722 .elementor-element.elementor-element-4f15c9a {
  text-align: center
}

.elementor-4722 .elementor-element.elementor-element-4f15c9a .elementor-heading-title {
  color: #000;
  font-size: 50px;
  font-weight: 700
}

.elementor-4722 .elementor-element.elementor-element-faa521f {
  text-align: center;
  color: #000;
  font-size: 18px;
  line-height: 1.9em
}

.elementor-4722 .elementor-element.elementor-element-7375290>.elementor-container {
  max-width: 1200px
}

.elementor-4722 .elementor-element.elementor-element-7375290:not(.elementor-motion-effects-element-type-background),
.elementor-4722 .elementor-element.elementor-element-7375290>.elementor-motion-effects-container>.elementor-motion-effects-layer {
  background-color: #fff
}

.elementor-4722 .elementor-element.elementor-element-7375290 {
  transition: background .3s, border .3s, border-radius .3s, box-shadow .3s;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0
}

.elementor-4722 .elementor-element.elementor-element-7375290>.elementor-background-overlay {
  transition: background .3s, border-radius .3s, opacity .3s
}

.elementor-4722 .elementor-element.elementor-element-099f1c4>.elementor-element-populated {
  margin: 0;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 30px 50px 0 10px
}

.elementor-4722 .elementor-element.elementor-element-de49654 .elementor-heading-title {
  color: var(--e-global-color-text);
  font-family: Roboto, Sans-serif;
  font-size: 20px;
  font-weight: 700;
  text-decoration: none;
  letter-spacing: 2.5px
}

.elementor-4722 .elementor-element.elementor-element-812170a .elementor-heading-title {
  font-family: Roboto, Sans-serif;
  font-size: 35px;
  font-weight: 700
}

.elementor-4722 .elementor-element.elementor-element-f52dc75 {
  font-family: Roboto, Sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.8em
}

.elementor-4722 .elementor-element.elementor-element-06a29ae>.elementor-widget-wrap>.elementor-widget:not(.elementor-widget__width-auto):not(.elementor-widget__width-initial):not(:last-child):not(.elementor-absolute) {
  margin-bottom: 1px
}

.elementor-4722 .elementor-element.elementor-element-06a29ae>.elementor-element-populated {
  margin: 0;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0
}

.elementor-4722 .elementor-element.elementor-element-9a2440d {
  text-align: left
}

.elementor-4722 .elementor-element.elementor-element-9a2440d img {
  width: 50%
}

.elementor-4722 .elementor-element.elementor-element-00ab7b3 {
  text-align: center;
  z-index: 0
}

.elementor-4722 .elementor-element.elementor-element-00ab7b3 img {
  width: 100%
}

.elementor-4722 .elementor-element.elementor-element-88a8294>.elementor-container {
  max-width: 1200px
}

.elementor-4722 .elementor-element.elementor-element-88a8294:not(.elementor-motion-effects-element-type-background),
.elementor-4722 .elementor-element.elementor-element-88a8294>.elementor-motion-effects-container>.elementor-motion-effects-layer {
  background-color: #fff
}

.elementor-4722 .elementor-element.elementor-element-88a8294 {
  transition: background .3s, border .3s, border-radius .3s, box-shadow .3s;
  margin-top: 0;
  margin-bottom: 0;
  padding: 100px 0
}

.elementor-4722 .elementor-element.elementor-element-88a8294>.elementor-background-overlay {
  transition: background .3s, border-radius .3s, opacity .3s
}

.elementor-4722 .elementor-element.elementor-element-74d3b98 img {
  width: 100%
}

.elementor-4722 .elementor-element.elementor-element-402b2e1 {
  text-align: right;
  top: 40px
}

.elementor-4722 .elementor-element.elementor-element-402b2e1 img {
  width: 40%
}

body:not(.rtl) .elementor-4722 .elementor-element.elementor-element-402b2e1 {
  left: -6.997px
}

body.rtl .elementor-4722 .elementor-element.elementor-element-402b2e1 {
  right: -6.997px
}

.elementor-4722 .elementor-element.elementor-element-3eb0ee1>.elementor-element-populated {
  margin: 0;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 40px 10px 0 50px
}

.elementor-4722 .elementor-element.elementor-element-2004a0b .elementor-heading-title {
  color: var(--e-global-color-text);
  font-family: Roboto, Sans-serif;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 2.5px
}

.elementor-4722 .elementor-element.elementor-element-9272d71 .elementor-heading-title {
  font-family: Roboto, Sans-serif;
  font-size: 35px;
  font-weight: 700
}

.elementor-4722 .elementor-element.elementor-element-77b6d38 {
  font-family: Roboto, Sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.8em
}

.elementor-4722 .elementor-element.elementor-element-c08db32>.elementor-container {
  max-width: 1200px
}

.elementor-4722 .elementor-element.elementor-element-c08db32:not(.elementor-motion-effects-element-type-background),
.elementor-4722 .elementor-element.elementor-element-c08db32>.elementor-motion-effects-container>.elementor-motion-effects-layer {
  background-color: #fff
}

.elementor-4722 .elementor-element.elementor-element-c08db32 {
  transition: background .3s, border .3s, border-radius .3s, box-shadow .3s;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 50px;
  /*padding: 0 0 100px*/
}

.elementor-4722 .elementor-element.elementor-element-c08db32>.elementor-background-overlay {
  transition: background .3s, border-radius .3s, opacity .3s
}

.elementor-4722 .elementor-element.elementor-element-1117567.elementor-column.elementor-element[data-element_type=column]>.elementor-widget-wrap.elementor-element-populated {
  align-content: center;
  align-items: center
}

.elementor-4722 .elementor-element.elementor-element-1117567>.elementor-element-populated {
  margin: 0;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0 70px 0 0
}

.elementor-4722 .elementor-element.elementor-element-ea223a7 .elementor-heading-title {
  color: var(--e-global-color-text);
  font-family: Roboto, Sans-serif;
  font-size: 20px;
  font-weight: 700;
  text-decoration: none;
  letter-spacing: 2.5px
}

.elementor-4722 .elementor-element.elementor-element-284613a .elementor-heading-title {
  font-family: Roboto, Sans-serif;
  font-size: 35px;
  font-weight: 700
}

.elementor-4722 .elementor-element.elementor-element-4bfb451 {
  font-family: Roboto, Sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.8em
}

.elementor-4722 .elementor-element.elementor-element-9ff998a>.elementor-widget-wrap>.elementor-widget:not(.elementor-widget__width-auto):not(.elementor-widget__width-initial):not(:last-child):not(.elementor-absolute) {
  margin-bottom: 1px
}

.elementor-4722 .elementor-element.elementor-element-9ff998a>.elementor-element-populated {
  margin: 0;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0
}

.elementor-4722 .elementor-element.elementor-element-3a9ae52 {
  text-align: left;
  z-index: 1
}

.elementor-4722 .elementor-element.elementor-element-3a9ae52 img {
  width: 40%
}

.elementor-4722 .elementor-element.elementor-element-c8045ad img {
  width: 100%
}

.elementor-4722 .elementor-element.elementor-element-c8045ad {
  z-index: 0
}

.elementor-4722 .elementor-element.elementor-element-62715f3:not(.elementor-motion-effects-element-type-background),
.elementor-4722 .elementor-element.elementor-element-62715f3>.elementor-motion-effects-container>.elementor-motion-effects-layer {
  background-color: #fff
}

.elementor-4722 .elementor-element.elementor-element-62715f3 {
  transition: background .3s, border .3s, border-radius .3s, box-shadow .3s;
  margin-top: 0;
  margin-bottom: 0;
  padding: 50px 0 80px
}

.elementor-4722 .elementor-element.elementor-element-62715f3>.elementor-background-overlay {
  transition: background .3s, border-radius .3s, opacity .3s
}

.elementor-4722 .elementor-element.elementor-element-62715f3>.elementor-shape-top svg {
  transform: translateX(-50%) rotateY(180deg)
}

.elementor-4722 .elementor-element.elementor-element-62715f3>.elementor-shape-bottom svg {
  transform: translateX(-50%) rotateY(180deg)
}

.elementor-4722 .elementor-element.elementor-element-559df52d:not(.elementor-motion-effects-element-type-background),
.elementor-4722 .elementor-element.elementor-element-559df52d>.elementor-motion-effects-container>.elementor-motion-effects-layer {
  background-color: #F8D546;
}

.elementor-4722 .elementor-element.elementor-element-559df52d,
.elementor-4722 .elementor-element.elementor-element-559df52d>.elementor-background-overlay {
  border-radius: 20px 20px 20px 20px
}

.elementor-4722 .elementor-element.elementor-element-559df52d {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .5);
  transition: background .3s, border .3s, border-radius .3s, box-shadow .3s;
  padding: 20px 20px 20px 30px
}

.elementor-4722 .elementor-element.elementor-element-559df52d>.elementor-background-overlay {
  transition: background .3s, border-radius .3s, opacity .3s
}

.elementor-4722 .elementor-element.elementor-element-131df838>.elementor-widget-wrap>.elementor-widget:not(.elementor-widget__width-auto):not(.elementor-widget__width-initial):not(:last-child):not(.elementor-absolute) {
  margin-bottom: 8px
}

.elementor-4722 .elementor-element.elementor-global-32498 {
  text-align: left
}

.elementor-4722 .elementor-element.elementor-global-32498 .elementor-heading-title {
  font-family: Roboto, Sans-serif;
  font-size: 35px;
  font-weight: 700
}

.elementor-4722 .elementor-element.elementor-global-32501 {
  font-family: Roboto, Sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.8em
}

.elementor-4722 .elementor-element.elementor-global-32504 .elementor-button .elementor-align-icon-right {
  margin-left: 12px
}

.elementor-4722 .elementor-element.elementor-global-32504 .elementor-button {
  font-size: 20px;
  font-weight: 500;
  fill: var(--e-global-color-text);
  color: var(--e-global-color-text);
  background-color: #0abf3c;
  border-style: solid;
  border-width: 1px;
  border-color: #000
}

.elementor-4722 .elementor-element.elementor-element-2943a640>.elementor-element-populated {
  margin: 0;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0
}

.elementor-4722 .elementor-element.elementor-global-32507 .elementor-icon-list-items:not(.elementor-inline-items) .elementor-icon-list-item:not(:last-child) {
  padding-bottom: calc(10px/2)
}

.elementor-4722 .elementor-element.elementor-global-32507 .elementor-icon-list-items:not(.elementor-inline-items) .elementor-icon-list-item:not(:first-child) {
  margin-top: calc(10px/2)
}

.elementor-4722 .elementor-element.elementor-global-32507 .elementor-icon-list-icon i {
  transition: color .3s
}

.elementor-4722 .elementor-element.elementor-global-32507 .elementor-icon-list-icon svg {
  transition: fill .3s
}

.elementor-4722 .elementor-element.elementor-global-32507 {
  --e-icon-list-icon-size: 28px;
  --e-icon-list-icon-align: left;
  --e-icon-list-icon-margin: 0 calc(var(--e-icon-list-icon-size, 1em) * 0.25) 0 0;
  --icon-vertical-offset: 0px
}

.elementor-4722 .elementor-element.elementor-global-32507 .elementor-icon-list-item>.elementor-icon-list-text,
.elementor-4722 .elementor-element.elementor-global-32507 .elementor-icon-list-item>a {
  font-family: Roboto, Sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 1.8em
}

.elementor-4722 .elementor-element.elementor-global-32507 .elementor-icon-list-text {
  color: #000;
  transition: color .3s
}

.elementor-4722 .elementor-element.elementor-global-32507>.elementor-widget-container {
  padding: 15px 0 0
}

.elementor-4722 .elementor-element.elementor-element-e136a19>.elementor-container {
  max-width: 689px
}

.elementor-4722 .elementor-element.elementor-element-e136a19 {
  padding: 30px 0 0
}

.elementor-4722 .elementor-element.elementor-global-45546 img {
  width: 85%
}

.elementor-4722 .elementor-element.elementor-global-45547 img {
  width: 85%
}

.elementor-4722 .elementor-element.elementor-global-45548 img {
  width: 85%
}

.elementor-4722 .elementor-element.elementor-global-45549 img {
  width: 85%
}

.elementor-4722 .elementor-element.elementor-global-45550 img {
  width: 85%
}

.elementor-4722 .elementor-element.elementor-element-9bec390>.elementor-container {
  max-width: 1200px
}

.elementor-4722 .elementor-element.elementor-element-9bec390:not(.elementor-motion-effects-element-type-background),
.elementor-4722 .elementor-element.elementor-element-9bec390>.elementor-motion-effects-container>.elementor-motion-effects-layer {
  background-color: #fff
}

.elementor-4722 .elementor-element.elementor-element-9bec390 {
  transition: background .3s, border .3s, border-radius .3s, box-shadow .3s;
  margin-top: 0;
  margin-bottom: 0;
  padding: 40px 0 0
}

.elementor-4722 .elementor-element.elementor-element-9bec390>.elementor-background-overlay {
  transition: background .3s, border-radius .3s, opacity .3s
}

.elementor-4722 .elementor-element.elementor-element-8b039fe>.elementor-element-populated {
  margin: 0;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 80px 50px 0 10px
}

.elementor-4722 .elementor-element.elementor-element-3218d64 .elementor-heading-title {
  color: var(--e-global-color-text);
  font-family: Roboto, Sans-serif;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 2.5px
}

.elementor-4722 .elementor-element.elementor-element-2f9d6d2 .elementor-heading-title {
  font-family: Roboto, Sans-serif;
  font-size: 35px;
  font-weight: 700
}

.elementor-4722 .elementor-element.elementor-element-5cfcb08 {
  font-family: Roboto, Sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.8em
}

.elementor-4722 .elementor-element.elementor-element-42ff95e img {
  width: 100%
}

.elementor-4722 .elementor-element.elementor-element-cc326ac {
  text-align: right;
  bottom: 55px
}

.elementor-4722 .elementor-element.elementor-element-cc326ac img {
  width: 50%
}

body:not(.rtl) .elementor-4722 .elementor-element.elementor-element-cc326ac {
  left: -185px
}

body.rtl .elementor-4722 .elementor-element.elementor-element-cc326ac {
  right: -185px
}

.elementor-4722 .elementor-element.elementor-element-8d01f70>.elementor-container {
  max-width: 1200px
}

.elementor-4722 .elementor-element.elementor-element-8d01f70:not(.elementor-motion-effects-element-type-background),
.elementor-4722 .elementor-element.elementor-element-8d01f70>.elementor-motion-effects-container>.elementor-motion-effects-layer {
  background-color: #fff
}

.elementor-4722 .elementor-element.elementor-element-8d01f70 {
  transition: background .3s, border .3s, border-radius .3s, box-shadow .3s;
  margin-top: 0;
  margin-bottom: 0;
  padding: 100px 0 0
}

.elementor-4722 .elementor-element.elementor-element-8d01f70>.elementor-background-overlay {
  transition: background .3s, border-radius .3s, opacity .3s
}

.elementor-4722 .elementor-element.elementor-element-162728e {
  text-align: left;
  top: 45px;
  z-index: 0
}

.elementor-4722 .elementor-element.elementor-element-162728e img {
  width: 40%
}

body:not(.rtl) .elementor-4722 .elementor-element.elementor-element-162728e {
  left: -5px
}

body.rtl .elementor-4722 .elementor-element.elementor-element-162728e {
  right: -5px
}

.elementor-4722 .elementor-element.elementor-element-cb69a26 img {
  width: 100%
}

.elementor-4722 .elementor-element.elementor-element-bb6f8ec>.elementor-element-populated {
  margin: 0;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 60px 10px 0 50px
}

.elementor-4722 .elementor-element.elementor-element-ba55bb9 .elementor-heading-title {
  color: var(--e-global-color-text);
  font-family: Roboto, Sans-serif;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 2.5px
}

.elementor-4722 .elementor-element.elementor-element-c59cbd8 .elementor-heading-title {
  font-family: Roboto, Sans-serif;
  font-size: 35px;
  font-weight: 700
}

.elementor-4722 .elementor-element.elementor-element-fff0bb0 {
  font-family: Roboto, Sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.8em
}

.elementor-4722 .elementor-element.elementor-element-38cb92e>.elementor-container {
  max-width: 1200px
}

.elementor-4722 .elementor-element.elementor-element-38cb92e:not(.elementor-motion-effects-element-type-background),
.elementor-4722 .elementor-element.elementor-element-38cb92e>.elementor-motion-effects-container>.elementor-motion-effects-layer {
  background-color: #fff
}

.elementor-4722 .elementor-element.elementor-element-38cb92e {
  transition: background .3s, border .3s, border-radius .3s, box-shadow .3s;
  margin-top: 0;
  margin-bottom: 0;
  padding: 100px 0 0
}

.elementor-4722 .elementor-element.elementor-element-38cb92e>.elementor-background-overlay {
  transition: background .3s, border-radius .3s, opacity .3s
}

.elementor-4722 .elementor-element.elementor-element-94c2651>.elementor-element-populated {
  margin: 0;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 70px 50px 0 10px
}

.elementor-4722 .elementor-element.elementor-element-02a3635 .elementor-heading-title {
  color: var(--e-global-color-text);
  font-family: Roboto, Sans-serif;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 2.5px
}

.elementor-4722 .elementor-element.elementor-element-b5cbd04 .elementor-heading-title {
  font-family: Roboto, Sans-serif;
  font-size: 35px;
  font-weight: 700
}

.elementor-4722 .elementor-element.elementor-element-4bf4adf {
  font-family: Roboto, Sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.8em
}

.elementor-4722 .elementor-element.elementor-element-3ceba49 {
  text-align: left
}

.elementor-4722 .elementor-element.elementor-element-3ceba49 img {
  width: 100%
}

.elementor-4722 .elementor-element.elementor-element-5e618ab {
  text-align: right;
  top: 35px
}

.elementor-4722 .elementor-element.elementor-element-5e618ab img {
  width: 30%
}

body:not(.rtl) .elementor-4722 .elementor-element.elementor-element-5e618ab {
  left: -3px
}

body.rtl .elementor-4722 .elementor-element.elementor-element-5e618ab {
  right: -3px
}

.elementor-4722 .elementor-element.elementor-element-b9bbbe0:not(.elementor-motion-effects-element-type-background),
.elementor-4722 .elementor-element.elementor-element-b9bbbe0>.elementor-motion-effects-container>.elementor-motion-effects-layer {
  background-color: #fff
}

.elementor-4722 .elementor-element.elementor-element-b9bbbe0 {
  transition: background .3s, border .3s, border-radius .3s, box-shadow .3s;
  padding: 80px 0 100px
}

.elementor-4722 .elementor-element.elementor-element-b9bbbe0>.elementor-background-overlay {
  transition: background .3s, border-radius .3s, opacity .3s
}

.elementor-4722 .elementor-element.elementor-global-19772 .elementor-button {
  font-size: 20px;
  font-weight: 700;
  text-decoration: none;
  fill: #000000;
  color: #000;
  background-color: #f5c400;
  border-style: solid;
  border-width: 2px
}

.elementor-4722 .elementor-element.elementor-global-19772>.elementor-widget-container {
  margin: 0 380px;
  padding: 0
}

.elementor-4722 .elementor-element.elementor-element-32a7154:not(.elementor-motion-effects-element-type-background),
.elementor-4722 .elementor-element.elementor-element-32a7154>.elementor-motion-effects-container>.elementor-motion-effects-layer {
  background-color: #fff
}

.elementor-4722 .elementor-element.elementor-element-32a7154 {
  transition: background .3s, border .3s, border-radius .3s, box-shadow .3s
}

.elementor-4722 .elementor-element.elementor-element-32a7154>.elementor-background-overlay {
  transition: background .3s, border-radius .3s, opacity .3s
}

.elementor-4722 .elementor-element.elementor-element-f544fdd>.elementor-element-populated {
  transition: background .3s, border .3s, border-radius .3s, box-shadow .3s
}

.elementor-4722 .elementor-element.elementor-element-f544fdd>.elementor-element-populated>.elementor-background-overlay {
  transition: background .3s, border-radius .3s, opacity .3s
}

.elementor-4722 .elementor-element.elementor-element-3d064a8 .eael-testimonial-item .eael-testimonial-item-inner {
  background-color: #fff
}

.elementor-4722 .elementor-element.elementor-element-3d064a8 .eael-testimonial-item .eael-testimonial-item-inner {
  padding: 0 30px
}

.elementor-4722 .elementor-element.elementor-element-3d064a8 .eael-testimonial-image {
  max-width: 14%
}

.elementor-4722 .elementor-element.elementor-element-3d064a8 .eael-testimonial-image img {
  width: 143px
}

.elementor-4722 .elementor-element.elementor-element-3d064a8 .eael-testimonial-content .eael-testimonial-user {
  color: var(--e-global-color-text)
}

.elementor-4722 .elementor-element.elementor-element-3d064a8 .eael-testimonial-content .eael-testimonial-user-company {
  color: var(--e-global-color-text)
}

.elementor-4722 .elementor-element.elementor-element-3d064a8 .eael-testimonial-content .eael-testimonial-text {
  color: var(--e-global-color-text)
}

.elementor-4722 .elementor-element.elementor-element-3d064a8 .eael-testimonial-quote {
  color: var(--e-global-color-primary);
  position: absolute
}

.elementor-4722 .elementor-element.elementor-element-3d064a8 .swiper-container-wrap .swiper-button-next,
.elementor-4722 .elementor-element.elementor-element-3d064a8 .swiper-container-wrap .swiper-button-prev {
  width: 27px;
  height: 44px;
  font-size: 22px
}

.elementor-4722 .elementor-element.elementor-element-3d064a8 .swiper-container-wrap .swiper-pagination-bullet {
  height: 9px;
  width: 9px;
  margin-left: 10px;
  margin-right: 10px
}

body:not(.rtl) .elementor-4722 .elementor-element.elementor-element-3d064a8 .eael-testimonial-quote {
  right: -1000px
}

body.rtl .elementor-4722 .elementor-element.elementor-element-3d064a8 .eael-testimonial-quote {
  left: -1000px
}

.elementor-4722 .elementor-element.elementor-element-3d064a8>.elementor-widget-container {
  margin: 50px 0 0
}

.elementor-4722 .elementor-element.elementor-element-618931e:not(.elementor-motion-effects-element-type-background),
.elementor-4722 .elementor-element.elementor-element-618931e>.elementor-motion-effects-container>.elementor-motion-effects-layer {
  background-color: #F8D546;
}

.elementor-4722 .elementor-element.elementor-element-618931e {
  transition: background .3s, border .3s, border-radius .3s, box-shadow .3s;
  margin-top: 0;
  margin-bottom: 0;
  padding: 60px 0 100px
}

.elementor-4722 .elementor-element.elementor-element-618931e>.elementor-background-overlay {
  transition: background .3s, border-radius .3s, opacity .3s
}

.elementor-4722 .elementor-element.elementor-element-618931e>.elementor-shape-top .elementor-shape-fill {
  fill: #FFFFFF
}

.elementor-4722 .elementor-element.elementor-element-618931e>.elementor-shape-top svg {
  height: 230px;
  transform: translateX(-50%) rotateY(180deg)
}

.elementor-4722 .elementor-element.elementor-element-6c28692>.elementor-element-populated {
  margin: 40px 0 0;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px
}

.elementor-4722 .elementor-element.elementor-element-47b7bdb>.elementor-container {
  max-width: 800px
}

.elementor-4722 .elementor-element.elementor-element-47b7bdb {
  transition: background .3s, border .3s, border-radius .3s, box-shadow .3s;
  /* margin-top: 70px; */
  margin-bottom: 0
}

.elementor-4722 .elementor-element.elementor-element-47b7bdb>.elementor-background-overlay {
  transition: background .3s, border-radius .3s, opacity .3s
}

.elementor-4722 .elementor-element.elementor-element-bfc6493:not(.elementor-motion-effects-element-type-background)>.elementor-widget-wrap,
.elementor-4722 .elementor-element.elementor-element-bfc6493>.elementor-widget-wrap>.elementor-motion-effects-container>.elementor-motion-effects-layer {
  background-color: #fff
}

.elementor-4722 .elementor-element.elementor-element-bfc6493>.elementor-element-populated {
  border-style: solid;
  border-width: 1px;
  border-color: #dfdfdf;
  transition: background .3s, border .3s, border-radius .3s, box-shadow .3s;
  /* margin: -100px 100px 0; */
  --e-column-margin-right: 100px;
  --e-column-margin-left: 100px;
  padding: 0 50px 40px
}

.elementor-4722 .elementor-element.elementor-element-bfc6493>.elementor-background-slideshow,
.elementor-4722 .elementor-element.elementor-element-bfc6493>.elementor-element-populated,
.elementor-4722 .elementor-element.elementor-element-bfc6493>.elementor-element-populated>.elementor-background-overlay {
  border-radius: 8px 8px 8px 8px
}

.elementor-4722 .elementor-element.elementor-element-bfc6493>.elementor-element-populated>.elementor-background-overlay {
  transition: background .3s, border-radius .3s, opacity .3s
}

.elementor-4722 .elementor-element.elementor-element-f96a860 .jet-inline-svg {
  max-width: 100px
}

.elementor-4722 .elementor-element.elementor-element-f96a860 .jet-inline-svg__wrapper {
  text-align: center
}

.elementor-4722 .elementor-element.elementor-element-f96a860>.elementor-widget-container {
  margin: -50px 0 0
}

.elementor-4722 .elementor-element.elementor-element-28590ad .elementor-testimonial-wrapper {
  text-align: center
}

.elementor-4722 .elementor-element.elementor-element-28590ad .elementor-testimonial-content {
  color: #000;
  font-size: 24px;
  line-height: 1.5em
}

.elementor-4722 .elementor-element.elementor-element-28590ad .elementor-testimonial-name {
  color: #000;
  font-size: 18px;
  text-decoration: none
}

.elementor-4722 .elementor-element.elementor-element-28590ad .elementor-testimonial-job {
  color: #000;
  font-size: 16px;
  text-decoration: none
}

.elementor-4722 .elementor-element.elementor-element-355a156:not(.elementor-motion-effects-element-type-background),
.elementor-4722 .elementor-element.elementor-element-355a156>.elementor-motion-effects-container>.elementor-motion-effects-layer {
  background-color: #F8D546;
}

.elementor-4722 .elementor-element.elementor-element-355a156 {
  transition: background .3s, border .3s, border-radius .3s, box-shadow .3s;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0 0 180px
}

.elementor-4722 .elementor-element.elementor-element-355a156>.elementor-background-overlay {
  transition: background .3s, border-radius .3s, opacity .3s
}

.elementor-4722 .elementor-element.elementor-element-355a156>.elementor-shape-bottom .elementor-shape-fill {
  fill: #FFFFFF
}

.elementor-4722 .elementor-element.elementor-element-355a156>.elementor-shape-bottom svg {
  transform: translateX(-50%) rotateY(180deg)
}

.elementor-4722 .elementor-element.elementor-element-0281c07>.elementor-widget-wrap>.elementor-widget:not(.elementor-widget__width-auto):not(.elementor-widget__width-initial):not(:last-child):not(.elementor-absolute) {
  margin-bottom: 1px
}

.elementor-4722 .elementor-element.elementor-element-0281c07>.elementor-element-populated {
  margin: 0;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0
}

.elementor-4722 .elementor-element.elementor-element-dfa0687 {
  text-align: center
}

.elementor-4722 .elementor-element.elementor-element-dfa0687 .elementor-heading-title {
  color: var(--e-global-color-text);
  font-size: 50px
}

.elementor-4722 .elementor-element.elementor-element-b272fbe {
  text-align: center;
  color: var(--e-global-color-text);
  font-size: 20px;
  line-height: 1.9em
}

.elementor-4722 .elementor-element.elementor-element-b272fbe>.elementor-widget-container {
  margin: 0;
  padding: 0
}

.elementor-4722 .elementor-element.elementor-element-651d0f7 .elementor-button {
  font-size: 20px;
  font-weight: 700;
  text-decoration: none;
  fill: var(--e-global-color-text);
  color: var(--e-global-color-text);
  background-color: var(--e-global-color-5f56f174);
  border-style: solid;
  border-width: 1px;
  border-color: var(--e-global-color-primary)
}

.elementor-4722 .elementor-element.elementor-element-5986987 img {
  width: 100%
}

.elementor-4722 .elementor-element.elementor-element-5986987>.elementor-widget-container {
  margin: 0;
  padding: 0
}

.elementor-4722 .elementor-element.elementor-element-bcab5db>.elementor-container {
  max-width: 1370px
}

.elementor-4722 .elementor-element.elementor-element-bcab5db:not(.elementor-motion-effects-element-type-background),
.elementor-4722 .elementor-element.elementor-element-bcab5db>.elementor-motion-effects-container>.elementor-motion-effects-layer {
  background-color: #fff
}

.elementor-4722 .elementor-element.elementor-element-bcab5db {
  transition: background .3s, border .3s, border-radius .3s, box-shadow .3s;
  margin-top: 0;
  margin-bottom: 0;
  padding: 50px 0 40px
}

.elementor-4722 .elementor-element.elementor-element-bcab5db>.elementor-background-overlay {
  transition: background .3s, border-radius .3s, opacity .3s
}

.elementor-4722 .elementor-element.elementor-element-96a1d50.elementor-column.elementor-element[data-element_type=column]>.elementor-widget-wrap.elementor-element-populated {
  align-content: center;
  align-items: center
}

.elementor-4722 .elementor-element.elementor-element-96a1d50>.elementor-element-populated {
  margin: 0;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0
}

.elementor-4722 .elementor-element.elementor-global-12796 {
  text-align: center
}

.elementor-4722 .elementor-element.elementor-global-12796 .elementor-heading-title {
  color: #000;
  font-size: 50px
}

.elementor-4722 .elementor-element.elementor-element-9c9d904>.elementor-container {
  max-width: 1370px
}

.elementor-4722 .elementor-element.elementor-element-9c9d904:not(.elementor-motion-effects-element-type-background),
.elementor-4722 .elementor-element.elementor-element-9c9d904>.elementor-motion-effects-container>.elementor-motion-effects-layer {
  background-color: #fff
}

.elementor-4722 .elementor-element.elementor-element-9c9d904 {
  transition: background .3s, border .3s, border-radius .3s, box-shadow .3s;
  margin-top: 0;
  margin-bottom: 0;
  /*padding: 0 0 40px*/
}

.elementor-4722 .elementor-element.elementor-element-9c9d904>.elementor-background-overlay {
  transition: background .3s, border-radius .3s, opacity .3s
}

.elementor-4722 .elementor-element.elementor-element-0902596.elementor-column.elementor-element[data-element_type=column]>.elementor-widget-wrap.elementor-element-populated {
  align-content: center;
  align-items: center
}

.elementor-4722 .elementor-element.elementor-element-0902596>.elementor-element-populated {
  margin: 0;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0
}

.elementor-4722 .elementor-element.elementor-element-8a78fa2>.elementor-container {
  max-width: 1230px
}

.elementor-4722 .elementor-element.elementor-element-8a78fa2 {
  margin-top: 0;
  margin-bottom: 0;
  padding: 0 0 40px
}

.elementor-4722 .elementor-element.elementor-element-c50aa5b:not(.elementor-motion-effects-element-type-background)>.elementor-widget-wrap,
.elementor-4722 .elementor-element.elementor-element-c50aa5b>.elementor-widget-wrap>.elementor-motion-effects-container>.elementor-motion-effects-layer {
  background-color: var(--e-global-color-primary)
}

.elementor-4722 .elementor-element.elementor-element-c50aa5b>.elementor-element-populated {
  transition: background .3s, border .3s, border-radius .3s, box-shadow .3s;
  margin: 20px 20px 0;
  --e-column-margin-right: 20px;
  --e-column-margin-left: 20px;
  padding: 0 0 20px
}

.elementor-4722 .elementor-element.elementor-element-c50aa5b>.elementor-element-populated>.elementor-background-overlay {
  transition: background .3s, border-radius .3s, opacity .3s
}

.elementor-4722 .elementor-element.elementor-element-a3e395d .widget-image-caption {
  text-align: center;
  color: #fff;
  /* background-color: #000; */
  font-family: Roboto, Sans-serif;
  font-size: 35px;
  line-height: 1em;
  margin-top: 0;
  padding: 20px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
  min-height: 145px;
}

.elementor-4722 .elementor-element.elementor-element-7327e02 {
  text-align: center
}

.elementor-4722 .elementor-element.elementor-element-7327e02 .elementor-heading-title {
  color: #fff;
  font-size: 30px;
  font-weight: 700;
  line-height: 1.2em
}

.elementor-4722 .elementor-element.elementor-element-7327e02>.elementor-widget-container {
  margin: 0 10px
}

.elementor-4722 .elementor-element.elementor-element-f1bacc4 {
  text-align: center
}

.elementor-4722 .elementor-element.elementor-element-f1bacc4 .elementor-heading-title {
  color: #fff;
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-size: var(--e-global-typography-text-font-size);
  font-weight: var(--e-global-typography-text-font-weight);
  line-height: var(--e-global-typography-text-line-height)
}

.elementor-4722 .elementor-element.elementor-element-f1bacc4>.elementor-widget-container {
  margin: 0;
  padding: 0
}

.elementor-4722 .elementor-element.elementor-element-d55e40a:not(.elementor-motion-effects-element-type-background)>.elementor-widget-wrap,
.elementor-4722 .elementor-element.elementor-element-d55e40a>.elementor-widget-wrap>.elementor-motion-effects-container>.elementor-motion-effects-layer {
  background-color: var(--e-global-color-primary)
}

.elementor-4722 .elementor-element.elementor-element-d55e40a>.elementor-element-populated {
  transition: background .3s, border .3s, border-radius .3s, box-shadow .3s;
  margin: 20px 20px 0;
  --e-column-margin-right: 20px;
  --e-column-margin-left: 20px;
  padding: 0 0 20px
}

.elementor-4722 .elementor-element.elementor-element-d55e40a>.elementor-element-populated>.elementor-background-overlay {
  transition: background .3s, border-radius .3s, opacity .3s
}

.elementor-4722 .elementor-element.elementor-element-0a17805 .widget-image-caption {
  text-align: center;
  color: #fff;
  background-color: #000;
  font-family: Roboto, Sans-serif;
  font-size: 35px;
  line-height: 1em;
  margin-top: 0
}

.elementor-4722 .elementor-element.elementor-element-5716f91 {
  text-align: center
}

.elementor-4722 .elementor-element.elementor-element-5716f91 .elementor-heading-title {
  color: #fff;
  font-size: 30px;
  font-weight: 700;
  line-height: 1.2em
}

.elementor-4722 .elementor-element.elementor-element-5716f91>.elementor-widget-container {
  margin: 0 10px
}

.elementor-4722 .elementor-element.elementor-element-7f4d764 {
  text-align: center
}

.elementor-4722 .elementor-element.elementor-element-7f4d764 .elementor-heading-title {
  color: #fff;
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-size: var(--e-global-typography-text-font-size);
  font-weight: var(--e-global-typography-text-font-weight);
  line-height: var(--e-global-typography-text-line-height)
}

.elementor-4722 .elementor-element.elementor-element-d41f1bf:not(.elementor-motion-effects-element-type-background)>.elementor-widget-wrap,
.elementor-4722 .elementor-element.elementor-element-d41f1bf>.elementor-widget-wrap>.elementor-motion-effects-container>.elementor-motion-effects-layer {
  background-color: var(--e-global-color-primary)
}

.elementor-4722 .elementor-element.elementor-element-d41f1bf>.elementor-element-populated {
  transition: background .3s, border .3s, border-radius .3s, box-shadow .3s;
  margin: 20px 20px 0;
  --e-column-margin-right: 20px;
  --e-column-margin-left: 20px;
  padding: 0 0 20px
}

.elementor-4722 .elementor-element.elementor-element-d41f1bf>.elementor-element-populated>.elementor-background-overlay {
  transition: background .3s, border-radius .3s, opacity .3s
}

.elementor-4722 .elementor-element.elementor-element-8482b25 .widget-image-caption {
  text-align: center;
  color: #fff;
  background-color: #000;
  font-family: Roboto, Sans-serif;
  font-size: 35px;
  line-height: 1em;
  margin-top: 0
}

.elementor-4722 .elementor-element.elementor-element-f8856f5 {
  text-align: center
}

.elementor-4722 .elementor-element.elementor-element-f8856f5 .elementor-heading-title {
  color: #fff;
  font-size: 30px;
  font-weight: 700;
  line-height: 1.2em
}

.elementor-4722 .elementor-element.elementor-element-f8856f5>.elementor-widget-container {
  margin: 0 10px;
  padding: 0 10px
}

.elementor-4722 .elementor-element.elementor-element-2b8ca6a {
  text-align: center
}

.elementor-4722 .elementor-element.elementor-element-2b8ca6a .elementor-heading-title {
  color: #fff;
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-size: var(--e-global-typography-text-font-size);
  font-weight: var(--e-global-typography-text-font-weight);
  line-height: var(--e-global-typography-text-line-height)
}

.elementor-4722 .elementor-element.elementor-element-2d2c189:not(.elementor-motion-effects-element-type-background),
.elementor-4722 .elementor-element.elementor-element-2d2c189>.elementor-motion-effects-container>.elementor-motion-effects-layer {
  background-image: url("../images/background_bg.jpg");
  background-position: top center;
  background-size: cover
}

.elementor-4722 .elementor-element.elementor-element-2d2c189>.elementor-background-overlay {
  background-color: #eaeaea;
  opacity: 1;
  mix-blend-mode: multiply;
  transition: background .3s, border-radius .3s, opacity .3s
}

.elementor-4722 .elementor-element.elementor-element-2d2c189 {
  transition: background .3s, border .3s, border-radius .3s, box-shadow .3s;
  padding: 150px 0 100px
}

.elementor-4722 .elementor-element.elementor-element-2d2c189>.elementor-shape-top svg {
  height: 160px;
  transform: translateX(-50%) rotateY(180deg)
}

.elementor-4722 .elementor-element.elementor-element-a7c29b8>.elementor-widget-wrap>.elementor-widget:not(.elementor-widget__width-auto):not(.elementor-widget__width-initial):not(:last-child):not(.elementor-absolute) {
  margin-bottom: 1px
}

.elementor-4722 .elementor-element.elementor-element-c3bff08 .jet-inline-svg {
  max-width: 110px
}

.elementor-4722 .elementor-element.elementor-element-c3bff08 .jet-inline-svg__wrapper {
  text-align: center
}

.elementor-4722 .elementor-element.elementor-element-45eb4b9 {
  text-align: center
}

.elementor-4722 .elementor-element.elementor-element-45eb4b9 .elementor-heading-title {
  color: #000;
  font-size: 50px
}

.elementor-4722 .elementor-element.elementor-element-cd8a53c {
  margin-top: 20px;
  margin-bottom: 0
}

.elementor-4722 .elementor-element.elementor-element-04da5c5 .elementor-button {
  font-size: 20px;
  font-weight: 500;
  text-decoration: none;
  fill: var(--e-global-color-text);
  color: var(--e-global-color-text);
  background-color: #0abf3c;
  border-style: solid;
  border-width: 1px;
  border-color: #000
}

.elementor-4722 .elementor-element.elementor-element-029fa62 .elementor-button {
  font-size: 20px;
  font-weight: 500;
  text-decoration: none;
  fill: #000000;
  color: #000;
  background-color: #f5c400;
  border-style: solid;
  border-width: 1px
}

.elementor-4722 .elementor-element.elementor-element-4af1fab {
  text-align: center;
  color: #000;
  font-size: 20px
}

.elementor-4722 .elementor-element.elementor-element-4af1fab>.elementor-widget-container {
  margin: 10px 0 0
}

:root {
  --page-title-display: none
}

body.elementor-page-4722 {
  padding: 0
}

body.elementor-page-4722:not(.elementor-motion-effects-element-type-background),
body.elementor-page-4722>.elementor-motion-effects-container>.elementor-motion-effects-layer {
  background-color: #F8D546;
}

@media(min-width:768px) {
  .elementor-4722 .elementor-element.elementor-element-fe04227 {
    width: 40%
  }

  .elementor-4722 .elementor-element.elementor-element-4d401f5 {
    width: 60%
  }

  .elementor-4722 .elementor-element.elementor-element-9abc3bb {
    width: 20.649%
  }

  .elementor-4722 .elementor-element.elementor-element-c008481 {
    width: 19.333%
  }

  .elementor-4722 .elementor-element.elementor-element-3348801 {
    width: 61.578%
  }

  .elementor-4722 .elementor-element.elementor-element-1f549de {
    width: 38.379%
  }

  .elementor-4722 .elementor-element.elementor-element-d6acd25 {
    width: 4.121%
  }

  .elementor-4722 .elementor-element.elementor-element-6695ed1 {
    width: 15.999%
  }

  .elementor-4722 .elementor-element.elementor-element-dd3548b {
    width: 32.632%
  }

  .elementor-4722 .elementor-element.elementor-element-ae0c1bf {
    width: 25.526%
  }

  .elementor-4722 .elementor-element.elementor-element-e6b9bc5 {
    width: 16.088%
  }

  .elementor-4722 .elementor-element.elementor-element-94d04f0 {
    width: 3.618%
  }

  .elementor-4722 .elementor-element.elementor-element-5ffca4e {
    width: 2%
  }

  .elementor-4722 .elementor-element.elementor-element-594df85 {
    width: 17.764%
  }

  .elementor-4722 .elementor-element.elementor-element-298149d {
    width: 26.748%
  }

  .elementor-4722 .elementor-element.elementor-element-c31a822 {
    width: 27.169%
  }

  .elementor-4722 .elementor-element.elementor-element-44d3f60 {
    width: 22.317%
  }

  .elementor-4722 .elementor-element.elementor-element-22eef73 {
    width: 2%
  }

  .elementor-4722 .elementor-element.elementor-element-f817983 {
    width: 18.771%
  }

  .elementor-4722 .elementor-element.elementor-element-4455a7d {
    width: 17.442%
  }

  .elementor-4722 .elementor-element.elementor-element-08f2ae7 {
    width: 20.347%
  }

  .elementor-4722 .elementor-element.elementor-element-4dad8eb {
    width: 24.039%
  }

  .elementor-4722 .elementor-element.elementor-element-0c32459 {
    width: 19.369%
  }

  .elementor-4722 .elementor-element.elementor-element-0afa244 {
    width: 2%
  }

  .elementor-4722 .elementor-element.elementor-element-707c4e1 {
    width: 30.529%
  }

  .elementor-4722 .elementor-element.elementor-element-8746662 {
    width: 23.656%
  }

  .elementor-4722 .elementor-element.elementor-element-bcd762d {
    width: 21.058%
  }

  .elementor-4722 .elementor-element.elementor-element-a62bff0 {
    width: 22.722%
  }

  .elementor-4722 .elementor-element.elementor-element-c1652b7 {
    width: 2.982%
  }

  .elementor-4722 .elementor-element.elementor-element-5ee1089 {
    width: 26.087%
  }

  .elementor-4722 .elementor-element.elementor-element-fc3cc50 {
    width: 29.035%
  }

  .elementor-4722 .elementor-element.elementor-element-6040ef4 {
    width: 24.297%
  }

  .elementor-4722 .elementor-element.elementor-element-f677479 {
    width: 13.583%
  }

  .elementor-4722 .elementor-element.elementor-element-726f72d {
    width: 2%
  }

  .elementor-4722 .elementor-element.elementor-element-4e571a9 {
    width: 6%
  }

  .elementor-4722 .elementor-element.elementor-element-2fe01a7 {
    width: 29.437%
  }

  .elementor-4722 .elementor-element.elementor-element-92e1de8 {
    width: 31.843%
  }

  .elementor-4722 .elementor-element.elementor-element-406d818 {
    width: 27.65%
  }

  .elementor-4722 .elementor-element.elementor-element-b46d567 {
    width: 5%
  }

  .elementor-4722 .elementor-element.elementor-element-dc6d183 {
    width: 11.808%
  }

  .elementor-4722 .elementor-element.elementor-element-738b0a6 {
    width: 10.526%
  }

  .elementor-4722 .elementor-element.elementor-element-c5b6424 {
    width: 9.474%
  }

  .elementor-4722 .elementor-element.elementor-element-01f2195 {
    width: 9.134%
  }

  .elementor-4722 .elementor-element.elementor-element-414e461 {
    width: 9.719%
  }

  .elementor-4722 .elementor-element.elementor-element-f2de76f {
    width: 7%
  }

  .elementor-4722 .elementor-element.elementor-element-74784d1 {
    width: 9.999%
  }

  .elementor-4722 .elementor-element.elementor-element-393f69e {
    width: 9.474%
  }

  .elementor-4722 .elementor-element.elementor-element-ad77565 {
    width: 12.859%
  }

  .elementor-4722 .elementor-element.elementor-element-099f1c4 {
    width: 56.285%
  }

  .elementor-4722 .elementor-element.elementor-element-06a29ae {
    width: 43.628%
  }

  .elementor-4722 .elementor-element.elementor-element-3ecc43f {
    width: 45.461%
  }

  .elementor-4722 .elementor-element.elementor-element-3eb0ee1 {
    width: 54.452%
  }

  .elementor-4722 .elementor-element.elementor-element-1117567 {
    width: 52.917%
  }

  .elementor-4722 .elementor-element.elementor-element-9ff998a {
    width: 46.958%
  }

  .elementor-4722 .elementor-element.elementor-element-131df838 {
    width: 70.959%
  }

  .elementor-4722 .elementor-element.elementor-element-2943a640 {
    width: 28.961%
  }

  .elementor-4722 .elementor-element.elementor-element-8b039fe {
    width: 58%
  }

  .elementor-4722 .elementor-element.elementor-element-eb1c0d3 {
    width: 41.913%
  }

  .elementor-4722 .elementor-element.elementor-element-e852634 {
    width: 42.877%
  }

  .elementor-4722 .elementor-element.elementor-element-bb6f8ec {
    width: 57.036%
  }

  .elementor-4722 .elementor-element.elementor-element-94c2651 {
    width: 58.287%
  }

  .elementor-4722 .elementor-element.elementor-element-3a9a238 {
    width: 41.216%
  }

  .elementor-4722 .elementor-element.elementor-element-e47c273 {
    width: 49.375%
  }

  .elementor-4722 .elementor-element.elementor-element-146bf7e {
    width: 50.625%
  }
}

@media(max-width:1024px) {
  .elementor-4722 .elementor-element.elementor-element-a363456>.elementor-shape-bottom svg {
    height: 70px
  }

  .elementor-4722 .elementor-element.elementor-element-a363456 {
    margin-top: 30px;
    margin-bottom: 0;
    padding: 30px 20px 100px
  }

  .elementor-4722 .elementor-element.elementor-element-3afab82 .elementor-heading-title {
    font-size: 60px
  }

  .elementor-4722 .elementor-element.elementor-element-6a28035 {
    font-size: 22px;
    line-height: 1.4em
  }

  .elementor-4722 .elementor-element.elementor-element-6a28035>.elementor-widget-container {
    margin: 0
  }

  .elementor-4722 .elementor-element.elementor-global-43819 .widget-image-caption {
    font-size: 18px
  }

  .elementor-4722 .elementor-element.elementor-element-28e0577 {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0
  }

  .elementor-4722 .elementor-element.elementor-element-f06e043 {
    font-size: 22px;
    line-height: 1.4em
  }

  .elementor-4722 .elementor-element.elementor-element-f06e043>.elementor-widget-container {
    margin: 0
  }

  .elementor-4722 .elementor-element.elementor-element-ff2748a .jet-video__play-button {
    font-size: 195px
  }

  .elementor-4722 .elementor-element.elementor-element-ff2748a>.elementor-widget-container {
    margin: 20px 0 50px
  }

  .elementor-4722 .elementor-element.elementor-element-1570105 {
    padding: 50px 20px 0
  }

  .elementor-4722 .elementor-element.elementor-element-fe04227>.elementor-element-populated {
    padding: 0
  }

  .elementor-4722 .elementor-element.elementor-element-77e4ac0 {
    text-align: center
  }

  .elementor-4722 .elementor-element.elementor-element-77e4ac0 .elementor-heading-title {
    font-size: 45px
  }

  .elementor-4722 .elementor-element.elementor-element-0335699 {
    text-align: center;
    font-size: 28px;
    line-height: 1.4em
  }

  .elementor-4722 .elementor-element.elementor-element-0335699>.elementor-widget-container {
    margin: 0
  }

  .elementor-4722 .elementor-element.elementor-element-a92c563 {
    padding: 50px 10px 100px
  }

  .elementor-4722 .elementor-element.elementor-element-c422c1d .elementor-icon-box-title,
  .elementor-4722 .elementor-element.elementor-element-c422c1d .elementor-icon-box-title a {
    font-size: 23px
  }

  .elementor-4722 .elementor-element.elementor-element-c422c1d .elementor-icon-box-description {
    font-size: 18px
  }

  .elementor-4722 .elementor-element.elementor-element-353f4ad .elementor-icon-box-title,
  .elementor-4722 .elementor-element.elementor-element-353f4ad .elementor-icon-box-title a {
    font-size: 23px
  }

  .elementor-4722 .elementor-element.elementor-element-353f4ad .elementor-icon-box-description {
    font-size: 18px
  }

  .elementor-4722 .elementor-element.elementor-element-3163703 .elementor-icon-box-title,
  .elementor-4722 .elementor-element.elementor-element-3163703 .elementor-icon-box-title a {
    font-size: 23px
  }

  .elementor-4722 .elementor-element.elementor-element-3163703 .elementor-icon-box-description {
    font-size: 18px
  }

  .elementor-4722 .elementor-element.elementor-element-53e3870 .elementor-icon-box-title,
  .elementor-4722 .elementor-element.elementor-element-53e3870 .elementor-icon-box-title a {
    font-size: 23px
  }

  .elementor-4722 .elementor-element.elementor-element-53e3870 .elementor-icon-box-description {
    font-size: 18px
  }

  .elementor-4722 .elementor-element.elementor-element-9045622 .elementor-icon-box-title,
  .elementor-4722 .elementor-element.elementor-element-9045622 .elementor-icon-box-title a {
    font-size: 23px
  }

  .elementor-4722 .elementor-element.elementor-element-9045622 .elementor-icon-box-description {
    font-size: 18px
  }

  .elementor-4722 .elementor-element.elementor-element-412ed8d>.elementor-shape-bottom svg {
    height: 60px
  }

  .elementor-4722 .elementor-element.elementor-element-412ed8d {
    margin-top: 0;
    margin-bottom: 0;
    padding: 40px 0 80px
  }

  .elementor-4722 .elementor-element.elementor-element-a06d42d .elementor-heading-title {
    font-size: 19px
  }

  .elementor-4722 .elementor-element.elementor-element-a06d42d>.elementor-widget-container {
    margin: 0
  }

  .elementor-4722 .elementor-element.elementor-element-d0eb175 {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0
  }

  .elementor-4722 .elementor-element.elementor-element-6b469e9>.elementor-widget-wrap>.elementor-widget:not(.elementor-widget__width-auto):not(.elementor-widget__width-initial):not(:last-child):not(.elementor-absolute) {
    margin-bottom: 0
  }

  .elementor-4722 .elementor-element.elementor-element-6b469e9>.elementor-element-populated {
    margin: 0 11px;
    --e-column-margin-right: 11px;
    --e-column-margin-left: 11px;
    padding: 0
  }

  .elementor-4722 .elementor-element.elementor-element-b637324 .elementor-heading-title {
    font-size: 15px
  }

  .elementor-4722 .elementor-element.elementor-element-b637324>.elementor-widget-container {
    margin: 0
  }

  .elementor-4722 .elementor-element.elementor-element-1861337>.elementor-widget-wrap>.elementor-widget:not(.elementor-widget__width-auto):not(.elementor-widget__width-initial):not(:last-child):not(.elementor-absolute) {
    margin-bottom: 0
  }

  .elementor-4722 .elementor-element.elementor-element-1861337>.elementor-element-populated {
    margin: 0 10px;
    --e-column-margin-right: 10px;
    --e-column-margin-left: 10px;
    padding: 0
  }

  .elementor-4722 .elementor-element.elementor-element-dc7d32a .elementor-heading-title {
    font-size: 15px
  }

  .elementor-4722 .elementor-element.elementor-element-dc7d32a>.elementor-widget-container {
    margin: 0
  }

  .elementor-4722 .elementor-element.elementor-element-e22fe07>.elementor-widget-wrap>.elementor-widget:not(.elementor-widget__width-auto):not(.elementor-widget__width-initial):not(:last-child):not(.elementor-absolute) {
    margin-bottom: 0
  }

  .elementor-4722 .elementor-element.elementor-element-e22fe07>.elementor-element-populated {
    margin: 0 10px;
    --e-column-margin-right: 10px;
    --e-column-margin-left: 10px;
    padding: 0
  }

  .elementor-4722 .elementor-element.elementor-element-edc5926 .elementor-heading-title {
    font-size: 15px
  }

  .elementor-4722 .elementor-element.elementor-element-edc5926>.elementor-widget-container {
    margin: 0
  }

  .elementor-4722 .elementor-element.elementor-element-45b4327>.elementor-widget-wrap>.elementor-widget:not(.elementor-widget__width-auto):not(.elementor-widget__width-initial):not(:last-child):not(.elementor-absolute) {
    margin-bottom: 0
  }

  .elementor-4722 .elementor-element.elementor-element-45b4327>.elementor-element-populated {
    margin: 0 10px;
    --e-column-margin-right: 10px;
    --e-column-margin-left: 10px;
    padding: 0
  }

  .elementor-4722 .elementor-element.elementor-element-a4800fb .elementor-heading-title {
    font-size: 15px
  }

  .elementor-4722 .elementor-element.elementor-element-a4800fb>.elementor-widget-container {
    margin: 0
  }

  .elementor-4722 .elementor-element.elementor-element-19d368b>.elementor-widget-wrap>.elementor-widget:not(.elementor-widget__width-auto):not(.elementor-widget__width-initial):not(:last-child):not(.elementor-absolute) {
    margin-bottom: 0
  }

  .elementor-4722 .elementor-element.elementor-element-19d368b>.elementor-element-populated {
    margin: 0 10px;
    --e-column-margin-right: 10px;
    --e-column-margin-left: 10px;
    padding: 0
  }

  .elementor-4722 .elementor-element.elementor-element-3024c51 .elementor-heading-title {
    font-size: 15px
  }

  .elementor-4722 .elementor-element.elementor-element-3024c51>.elementor-widget-container {
    margin: 0
  }

  .elementor-4722 .elementor-element.elementor-element-ba990d4>.elementor-widget-wrap>.elementor-widget:not(.elementor-widget__width-auto):not(.elementor-widget__width-initial):not(:last-child):not(.elementor-absolute) {
    margin-bottom: 0
  }

  .elementor-4722 .elementor-element.elementor-element-ba990d4>.elementor-element-populated {
    margin: 0 10px;
    --e-column-margin-right: 10px;
    --e-column-margin-left: 10px;
    padding: 0
  }

  .elementor-4722 .elementor-element.elementor-element-60efb4d .elementor-heading-title {
    font-size: 15px
  }

  .elementor-4722 .elementor-element.elementor-element-60efb4d>.elementor-widget-container {
    margin: 0
  }

  .elementor-4722 .elementor-element.elementor-element-6d87474>.elementor-widget-wrap>.elementor-widget:not(.elementor-widget__width-auto):not(.elementor-widget__width-initial):not(:last-child):not(.elementor-absolute) {
    margin-bottom: 0
  }

  .elementor-4722 .elementor-element.elementor-element-6d87474>.elementor-element-populated {
    margin: 0 10px;
    --e-column-margin-right: 10px;
    --e-column-margin-left: 10px;
    padding: 0
  }

  .elementor-4722 .elementor-element.elementor-element-36cb23a .elementor-heading-title {
    font-size: 15px
  }

  .elementor-4722 .elementor-element.elementor-element-36cb23a>.elementor-widget-container {
    margin: 0
  }

  .elementor-4722 .elementor-element.elementor-element-2619592>.elementor-widget-wrap>.elementor-widget:not(.elementor-widget__width-auto):not(.elementor-widget__width-initial):not(:last-child):not(.elementor-absolute) {
    margin-bottom: 0
  }

  .elementor-4722 .elementor-element.elementor-element-2619592>.elementor-element-populated {
    margin: 0 10px;
    --e-column-margin-right: 10px;
    --e-column-margin-left: 10px;
    padding: 0
  }

  .elementor-4722 .elementor-element.elementor-element-66ed496 .elementor-heading-title {
    font-size: 15px
  }

  .elementor-4722 .elementor-element.elementor-element-66ed496>.elementor-widget-container {
    margin: 0
  }

  .elementor-4722 .elementor-element.elementor-element-648d0e6 .elementor-heading-title {
    font-size: 25px
  }

  .elementor-4722 .elementor-element.elementor-element-648d0e6>.elementor-widget-container {
    margin: 70px 0 0
  }

  .elementor-4722 .elementor-element.elementor-element-f30c5f8 {
    font-size: 22px;
    line-height: 1.4em
  }

  .elementor-4722 .elementor-element.elementor-element-f30c5f8>.elementor-widget-container {
    margin: 0
  }

  .elementor-4722 .elementor-element.elementor-element-5ae988a .elementor-icon-box-title,
  .elementor-4722 .elementor-element.elementor-element-5ae988a .elementor-icon-box-title a {
    font-size: 50px
  }

  .elementor-4722 .elementor-element.elementor-element-5ae988a .elementor-icon-box-description {
    font-size: 18px
  }

  .elementor-4722 .elementor-element.elementor-element-70bb3db .elementor-icon-box-title,
  .elementor-4722 .elementor-element.elementor-element-70bb3db .elementor-icon-box-title a {
    font-size: 50px
  }

  .elementor-4722 .elementor-element.elementor-element-70bb3db .elementor-icon-box-description {
    font-size: 18px
  }

  .elementor-4722 .elementor-element.elementor-element-9fcfa73 .elementor-icon-box-title,
  .elementor-4722 .elementor-element.elementor-element-9fcfa73 .elementor-icon-box-title a {
    font-size: 50px
  }

  .elementor-4722 .elementor-element.elementor-element-9fcfa73 .elementor-icon-box-description {
    font-size: 18px
  }

  .elementor-4722 .elementor-element.elementor-element-8600f58 .elementor-icon-box-title,
  .elementor-4722 .elementor-element.elementor-element-8600f58 .elementor-icon-box-title a {
    font-size: 50px
  }

  .elementor-4722 .elementor-element.elementor-element-8600f58 .elementor-icon-box-description {
    font-size: 18px
  }

  .elementor-4722 .elementor-element.elementor-element-2778a97 .elementor-icon-box-title,
  .elementor-4722 .elementor-element.elementor-element-2778a97 .elementor-icon-box-title a {
    font-size: 50px
  }

  .elementor-4722 .elementor-element.elementor-element-2778a97 .elementor-icon-box-description {
    font-size: 18px
  }

  .elementor-4722 .elementor-element.elementor-element-ff18bd1 .elementor-icon-box-title,
  .elementor-4722 .elementor-element.elementor-element-ff18bd1 .elementor-icon-box-title a {
    font-size: 50px
  }

  .elementor-4722 .elementor-element.elementor-element-ff18bd1 .elementor-icon-box-description {
    font-size: 18px
  }

  .elementor-4722 .elementor-element.elementor-element-160f72e .elementor-icon-box-title,
  .elementor-4722 .elementor-element.elementor-element-160f72e .elementor-icon-box-title a {
    font-size: 50px
  }

  .elementor-4722 .elementor-element.elementor-element-160f72e .elementor-icon-box-description {
    font-size: 18px
  }

  .elementor-4722 .elementor-element.elementor-element-5559292 .elementor-icon-box-title,
  .elementor-4722 .elementor-element.elementor-element-5559292 .elementor-icon-box-title a {
    font-size: 50px
  }

  .elementor-4722 .elementor-element.elementor-element-5559292 .elementor-icon-box-description {
    font-size: 18px
  }

  .elementor-4722 .elementor-element.elementor-element-816dd97 .elementor-icon-box-title,
  .elementor-4722 .elementor-element.elementor-element-816dd97 .elementor-icon-box-title a {
    font-size: 50px
  }

  .elementor-4722 .elementor-element.elementor-element-816dd97 .elementor-icon-box-description {
    font-size: 18px
  }

  .elementor-4722 .elementor-element.elementor-element-d8605a8 .elementor-icon-box-title,
  .elementor-4722 .elementor-element.elementor-element-d8605a8 .elementor-icon-box-title a {
    font-size: 50px
  }

  .elementor-4722 .elementor-element.elementor-element-d8605a8 .elementor-icon-box-description {
    font-size: 18px
  }

  .elementor-4722 .elementor-element.elementor-element-1cf236c .elementor-icon-box-title,
  .elementor-4722 .elementor-element.elementor-element-1cf236c .elementor-icon-box-title a {
    font-size: 50px
  }

  .elementor-4722 .elementor-element.elementor-element-1cf236c .elementor-icon-box-description {
    font-size: 18px
  }

  .elementor-4722 .elementor-element.elementor-element-39d753b .elementor-icon-box-title,
  .elementor-4722 .elementor-element.elementor-element-39d753b .elementor-icon-box-title a {
    font-size: 50px
  }

  .elementor-4722 .elementor-element.elementor-element-39d753b .elementor-icon-box-description {
    font-size: 18px
  }

  .elementor-4722 .elementor-element.elementor-element-102661b .elementor-icon-box-title,
  .elementor-4722 .elementor-element.elementor-element-102661b .elementor-icon-box-title a {
    font-size: 50px
  }

  .elementor-4722 .elementor-element.elementor-element-102661b .elementor-icon-box-description {
    font-size: 18px
  }

  .elementor-4722 .elementor-element.elementor-element-e0af2f3 .elementor-icon-box-title,
  .elementor-4722 .elementor-element.elementor-element-e0af2f3 .elementor-icon-box-title a {
    font-size: 50px
  }

  .elementor-4722 .elementor-element.elementor-element-e0af2f3 .elementor-icon-box-description {
    font-size: 18px
  }

  .elementor-4722 .elementor-element.elementor-element-8b50494 .elementor-icon-box-title,
  .elementor-4722 .elementor-element.elementor-element-8b50494 .elementor-icon-box-title a {
    font-size: 50px
  }

  .elementor-4722 .elementor-element.elementor-element-8b50494 .elementor-icon-box-description {
    font-size: 18px
  }

  .elementor-4722 .elementor-element.elementor-element-5a0d0e0 .elementor-icon-box-title,
  .elementor-4722 .elementor-element.elementor-element-5a0d0e0 .elementor-icon-box-title a {
    font-size: 50px
  }

  .elementor-4722 .elementor-element.elementor-element-5a0d0e0 .elementor-icon-box-description {
    font-size: 18px
  }

  .elementor-4722 .elementor-element.elementor-element-1744713 .elementor-icon-box-title,
  .elementor-4722 .elementor-element.elementor-element-1744713 .elementor-icon-box-title a {
    font-size: 50px
  }

  .elementor-4722 .elementor-element.elementor-element-1744713 .elementor-icon-box-description {
    font-size: 18px
  }

  .elementor-4722 .elementor-element.elementor-element-076f54d .elementor-icon-box-title,
  .elementor-4722 .elementor-element.elementor-element-076f54d .elementor-icon-box-title a {
    font-size: 50px
  }

  .elementor-4722 .elementor-element.elementor-element-076f54d .elementor-icon-box-description {
    font-size: 18px
  }

  .elementor-4722 .elementor-element.elementor-element-290aeeb .elementor-icon-box-title,
  .elementor-4722 .elementor-element.elementor-element-290aeeb .elementor-icon-box-title a {
    font-size: 50px
  }

  .elementor-4722 .elementor-element.elementor-element-290aeeb .elementor-icon-box-description {
    font-size: 18px
  }

  .elementor-4722 .elementor-element.elementor-element-8d77b7b .elementor-icon-box-title,
  .elementor-4722 .elementor-element.elementor-element-8d77b7b .elementor-icon-box-title a {
    font-size: 50px
  }

  .elementor-4722 .elementor-element.elementor-element-8d77b7b .elementor-icon-box-description {
    font-size: 18px
  }

  .elementor-4722 .elementor-element.elementor-element-0da761a .elementor-icon-box-title,
  .elementor-4722 .elementor-element.elementor-element-0da761a .elementor-icon-box-title a {
    font-size: 50px
  }

  .elementor-4722 .elementor-element.elementor-element-0da761a .elementor-icon-box-description {
    font-size: 18px
  }

  .elementor-4722 .elementor-element.elementor-element-dbad877 .elementor-icon-box-title,
  .elementor-4722 .elementor-element.elementor-element-dbad877 .elementor-icon-box-title a {
    font-size: 50px
  }

  .elementor-4722 .elementor-element.elementor-element-dbad877 .elementor-icon-box-description {
    font-size: 18px
  }

  .elementor-4722 .elementor-element.elementor-element-182e273 .elementor-icon-box-title,
  .elementor-4722 .elementor-element.elementor-element-182e273 .elementor-icon-box-title a {
    font-size: 50px
  }

  .elementor-4722 .elementor-element.elementor-element-182e273 .elementor-icon-box-description {
    font-size: 18px
  }

  .elementor-4722 .elementor-element.elementor-element-f862cf5 .elementor-icon-box-title,
  .elementor-4722 .elementor-element.elementor-element-f862cf5 .elementor-icon-box-title a {
    font-size: 50px
  }

  .elementor-4722 .elementor-element.elementor-element-f862cf5 .elementor-icon-box-description {
    font-size: 18px
  }

  .elementor-4722 .elementor-element.elementor-element-978e40b {
    padding: 0
  }

  .elementor-4722 .elementor-element.elementor-element-3dfc9f0 img {
    width: 70%
  }

  .elementor-4722 .elementor-element.elementor-element-8fe6618 img {
    width: 68%
  }

  .elementor-4722 .elementor-element.elementor-element-c1f0357 img {
    width: 70%
  }

  .elementor-4722 .elementor-element.elementor-element-1c7a6cc img {
    width: 70%
  }

  .elementor-4722 .elementor-element.elementor-element-7bbf60a img {
    width: 90%
  }

  .elementor-4722 .elementor-element.elementor-element-db5ec76 {
    text-align: left
  }

  .elementor-4722 .elementor-element.elementor-element-db5ec76 img {
    width: 100%
  }

  .elementor-4722 .elementor-element.elementor-element-db5ec76>.elementor-widget-container {
    margin: 0;
    padding: 0
  }

  .elementor-4722 .elementor-element.elementor-element-d138a1e {
    margin-top: 0;
    margin-bottom: 0;
    padding: 40px 0 60px
  }

  .elementor-4722 .elementor-element.elementor-element-faa521f {
    font-size: 18px
  }

  .elementor-4722 .elementor-element.elementor-element-7375290 {
    padding: 0 10px 0 20px
  }

  .elementor-4722 .elementor-element.elementor-element-099f1c4>.elementor-element-populated {
    padding: 0 30px 0 0
  }

  .elementor-4722 .elementor-element.elementor-element-de49654 .elementor-heading-title {
    font-size: 15px
  }

  .elementor-4722 .elementor-element.elementor-element-812170a .elementor-heading-title {
    font-size: 25px
  }

  .elementor-4722 .elementor-element.elementor-element-f52dc75 {
    font-size: 18px
  }

  .elementor-4722 .elementor-element.elementor-element-88a8294 {
    padding: 80px 10px 0
  }

  .elementor-4722 .elementor-element.elementor-element-3ecc43f.elementor-column.elementor-element[data-element_type=column]>.elementor-widget-wrap.elementor-element-populated {
    align-content: center;
    align-items: center
  }

  .elementor-4722 .elementor-element.elementor-element-3eb0ee1>.elementor-element-populated {
    padding: 0 0 0 30px
  }

  .elementor-4722 .elementor-element.elementor-element-2004a0b .elementor-heading-title {
    font-size: 15px
  }

  .elementor-4722 .elementor-element.elementor-element-9272d71 .elementor-heading-title {
    font-size: 25px
  }

  .elementor-4722 .elementor-element.elementor-element-77b6d38 {
    font-size: 18px
  }

  .elementor-4722 .elementor-element.elementor-element-c08db32 {
    padding: 50px 10px 0 20px
  }

  .elementor-4722 .elementor-element.elementor-element-1117567>.elementor-element-populated {
    padding: 0 30px 0 0
  }

  .elementor-4722 .elementor-element.elementor-element-ea223a7 .elementor-heading-title {
    font-size: 15px
  }

  .elementor-4722 .elementor-element.elementor-element-284613a .elementor-heading-title {
    font-size: 25px
  }

  .elementor-4722 .elementor-element.elementor-element-4bfb451 {
    font-size: 18px
  }

  .elementor-4722 .elementor-element.elementor-element-62715f3 {
    padding: 70px 0 20px
  }

  .elementor-4722 .elementor-element.elementor-element-559df52d {
    padding: 10px 5px 10px 15px
  }

  .elementor-4722 .elementor-element.elementor-global-32498 .elementor-heading-title {
    font-size: 23px
  }

  .elementor-4722 .elementor-element.elementor-global-32501 {
    font-size: 18px
  }

  .elementor-4722 .elementor-element.elementor-global-32504 .elementor-button {
    font-size: 18px
  }

  .elementor-4722 .elementor-element.elementor-global-32507 {
    --e-icon-list-icon-size: 20px
  }

  .elementor-4722 .elementor-element.elementor-global-32507 .elementor-icon-list-item>.elementor-icon-list-text,
  .elementor-4722 .elementor-element.elementor-global-32507 .elementor-icon-list-item>a {
    font-size: 16px
  }

  .elementor-4722 .elementor-element.elementor-global-32507>.elementor-widget-container {
    padding: 20px 0 0
  }

  .elementor-4722 .elementor-element.elementor-element-e136a19 {
    padding: 30px 0 0
  }

  .elementor-4722 .elementor-element.elementor-element-9bec390 {
    padding: 80px 10px 0
  }

  .elementor-4722 .elementor-element.elementor-element-8b039fe>.elementor-element-populated {
    padding: 0 0 0 30px
  }

  .elementor-4722 .elementor-element.elementor-element-3218d64 .elementor-heading-title {
    font-size: 15px
  }

  .elementor-4722 .elementor-element.elementor-element-2f9d6d2 .elementor-heading-title {
    font-size: 25px
  }

  .elementor-4722 .elementor-element.elementor-element-5cfcb08 {
    font-size: 18px
  }

  .elementor-4722 .elementor-element.elementor-element-eb1c0d3>.elementor-element-populated {
    padding: 60px 0 0
  }

  .elementor-4722 .elementor-element.elementor-element-cc326ac>.elementor-widget-container {
    margin: 0
  }

  .elementor-4722 .elementor-element.elementor-element-8d01f70 {
    padding: 80px 10px 0
  }

  .elementor-4722 .elementor-element.elementor-element-bb6f8ec>.elementor-element-populated {
    padding: 0 20px 0 30px
  }

  .elementor-4722 .elementor-element.elementor-element-ba55bb9 .elementor-heading-title {
    font-size: 15px
  }

  .elementor-4722 .elementor-element.elementor-element-c59cbd8 .elementor-heading-title {
    font-size: 25px
  }

  .elementor-4722 .elementor-element.elementor-element-fff0bb0 {
    font-size: 18px
  }

  .elementor-4722 .elementor-element.elementor-element-38cb92e {
    padding: 120px 10px 0
  }

  .elementor-4722 .elementor-element.elementor-element-94c2651>.elementor-element-populated {
    padding: 0 0 0 30px
  }

  .elementor-4722 .elementor-element.elementor-element-02a3635 .elementor-heading-title {
    font-size: 15px
  }

  .elementor-4722 .elementor-element.elementor-element-b5cbd04 .elementor-heading-title {
    font-size: 25px
  }

  .elementor-4722 .elementor-element.elementor-element-4bf4adf {
    font-size: 18px
  }

  .elementor-4722 .elementor-element.elementor-element-b9bbbe0 {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0
  }

  .elementor-4722 .elementor-element.elementor-global-19772>.elementor-widget-container {
    margin: 0;
    padding: 0
  }

  .elementor-4722 .elementor-element.elementor-element-618931e {
    padding: 0 0 60px
  }

  .elementor-4722 .elementor-element.elementor-element-6c28692>.elementor-element-populated {
    margin: 0;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0
  }

  .elementor-4722 .elementor-element.elementor-element-bfc6493>.elementor-element-populated {
    margin: 0 10px;
    --e-column-margin-right: 10px;
    --e-column-margin-left: 10px;
    padding: 15px
  }

  .elementor-4722 .elementor-element.elementor-element-f96a860 .jet-inline-svg {
    max-width: 86px
  }

  .elementor-4722 .elementor-element.elementor-element-28590ad .elementor-testimonial-job {
    line-height: 1.3em
  }

  .elementor-4722 .elementor-element.elementor-element-355a156 {
    margin-top: 0;
    margin-bottom: 0;
    padding: 20px 0 100px
  }

  .elementor-4722 .elementor-element.elementor-element-dfa0687 .elementor-heading-title {
    font-size: 30px
  }

  .elementor-4722 .elementor-element.elementor-element-b272fbe {
    font-size: 15px
  }

  .elementor-4722 .elementor-element.elementor-element-b272fbe>.elementor-widget-container {
    margin: 0;
    padding: 0
  }

  .elementor-4722 .elementor-element.elementor-global-12796 .elementor-heading-title {
    font-size: 35px
  }

  .elementor-4722 .elementor-element.elementor-element-8a78fa2>.elementor-container {
    max-width: 1600px
  }

  .elementor-4722 .elementor-element.elementor-element-8a78fa2 {
    padding: 0
  }

  .elementor-4722 .elementor-element.elementor-element-a3e395d .widget-image-caption {
    /* font-size: 18px */
  }

  .elementor-4722 .elementor-element.elementor-element-7327e02 .elementor-heading-title {
    font-size: 20px
  }

  .elementor-4722 .elementor-element.elementor-element-f1bacc4 .elementor-heading-title {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height)
  }

  .elementor-4722 .elementor-element.elementor-element-0a17805 .widget-image-caption {
    font-size: 18px
  }

  .elementor-4722 .elementor-element.elementor-element-5716f91 .elementor-heading-title {
    font-size: 20px
  }

  .elementor-4722 .elementor-element.elementor-element-7f4d764 .elementor-heading-title {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height)
  }

  .elementor-4722 .elementor-element.elementor-element-8482b25 .widget-image-caption {
    font-size: 18px
  }

  .elementor-4722 .elementor-element.elementor-element-f8856f5 .elementor-heading-title {
    font-size: 20px
  }

  .elementor-4722 .elementor-element.elementor-element-2b8ca6a .elementor-heading-title {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height)
  }

  .elementor-4722 .elementor-element.elementor-element-2d2c189>.elementor-shape-top svg {
    height: 60px
  }

  .elementor-4722 .elementor-element.elementor-element-2d2c189 {
    padding: 70px 0 50px
  }

  .elementor-4722 .elementor-element.elementor-element-a7c29b8>.elementor-widget-wrap>.elementor-widget:not(.elementor-widget__width-auto):not(.elementor-widget__width-initial):not(:last-child):not(.elementor-absolute) {
    margin-bottom: 1px
  }

  .elementor-4722 .elementor-element.elementor-element-45eb4b9 .elementor-heading-title {
    font-size: 50px
  }

  .elementor-4722 .elementor-element.elementor-element-cd8a53c {
    padding: 0 0 0 160px
  }
}

@media(max-width:767px) {
  .elementor-4722 .elementor-element.elementor-element-a363456>.elementor-shape-bottom svg {
    height: 70px
  }

  .elementor-4722 .elementor-element.elementor-element-a363456 {
    margin-top: 0px;
    margin-bottom: 0;
    padding: 0 0 100px
  }

  .elementor-4722 .elementor-element.elementor-element-3afab82 .elementor-heading-title {
    font-size: 30px
  }

  .elementor-4722 .elementor-element.elementor-element-6a28035 {
    font-size: 18px;
    line-height: 1.4em
  }

  .elementor-4722 .elementor-element.elementor-element-6a28035>.elementor-widget-container {
    margin: 0;
    padding: 0
  }

  .elementor-4722 .elementor-element.elementor-global-43819 .widget-image-caption {
    font-size: 18px
  }

  .elementor-4722 .elementor-element.elementor-element-28e0577 {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0
  }

  .elementor-4722 .elementor-element.elementor-element-5b63ea3>.elementor-widget-wrap>.elementor-widget:not(.elementor-widget__width-auto):not(.elementor-widget__width-initial):not(:last-child):not(.elementor-absolute) {
    margin-bottom: 0
  }

  .elementor-4722 .elementor-element.elementor-element-ac5a923 {
    width: 100%
  }

  .elementor-4722 .elementor-element.elementor-element-ac5a923>.elementor-widget-wrap>.elementor-widget:not(.elementor-widget__width-auto):not(.elementor-widget__width-initial):not(:last-child):not(.elementor-absolute) {
    margin-bottom: 0
  }

  .elementor-4722 .elementor-element.elementor-element-f06e043 {
    font-size: 18px;
    line-height: 1.4em
  }

  .elementor-4722 .elementor-element.elementor-element-f06e043>.elementor-widget-container {
    margin: 0;
    padding: 0
  }

  .elementor-4722 .elementor-element.elementor-element-ff2748a .jet-video__play-button {
    font-size: 120px
  }

  .elementor-4722 .elementor-element.elementor-element-ff2748a>.elementor-widget-container {
    margin: 10px 0 20px
  }

  .elementor-4722 .elementor-element.elementor-element-1570105 {
    padding: 30px 20px 0
  }

  .elementor-4722 .elementor-element.elementor-element-fe04227>.elementor-element-populated {
    padding: 0
  }

  .elementor-4722 .elementor-element.elementor-element-77e4ac0 .elementor-heading-title {
    font-size: 26px
  }

  .elementor-4722 .elementor-element.elementor-element-0335699 {
    font-size: 20px;
    line-height: 1.4em
  }

  .elementor-4722 .elementor-element.elementor-element-0335699>.elementor-widget-container {
    margin: 0;
    padding: 0
  }

  .elementor-4722 .elementor-element.elementor-element-a92c563 {
    padding: 30px 10px 100px
  }

  .elementor-4722 .elementor-element.elementor-element-c422c1d .elementor-icon-box-title,
  .elementor-4722 .elementor-element.elementor-element-c422c1d .elementor-icon-box-title a {
    font-size: 30px
  }

  .elementor-4722 .elementor-element.elementor-element-c422c1d .elementor-icon-box-description {
    font-size: 16px
  }

  .elementor-4722 .elementor-element.elementor-element-353f4ad .elementor-icon-box-title,
  .elementor-4722 .elementor-element.elementor-element-353f4ad .elementor-icon-box-title a {
    font-size: 30px
  }

  .elementor-4722 .elementor-element.elementor-element-353f4ad .elementor-icon-box-description {
    font-size: 16px
  }

  .elementor-4722 .elementor-element.elementor-element-3163703 .elementor-icon-box-title,
  .elementor-4722 .elementor-element.elementor-element-3163703 .elementor-icon-box-title a {
    font-size: 30px
  }

  .elementor-4722 .elementor-element.elementor-element-3163703 .elementor-icon-box-description {
    font-size: 16px
  }

  .elementor-4722 .elementor-element.elementor-element-53e3870 .elementor-icon-box-title,
  .elementor-4722 .elementor-element.elementor-element-53e3870 .elementor-icon-box-title a {
    font-size: 30px
  }

  .elementor-4722 .elementor-element.elementor-element-53e3870 .elementor-icon-box-description {
    font-size: 16px
  }

  .elementor-4722 .elementor-element.elementor-element-9045622 .elementor-icon-box-title,
  .elementor-4722 .elementor-element.elementor-element-9045622 .elementor-icon-box-title a {
    font-size: 30px
  }

  .elementor-4722 .elementor-element.elementor-element-9045622 .elementor-icon-box-description {
    font-size: 16px
  }

  .elementor-4722 .elementor-element.elementor-element-412ed8d {
    margin-top: 0;
    margin-bottom: 0;
    padding: 30px 0 70px
  }

  .elementor-4722 .elementor-element.elementor-element-a06d42d .elementor-heading-title {
    font-size: 17px;
    line-height: 1.4em
  }

  .elementor-4722 .elementor-element.elementor-element-a06d42d>.elementor-widget-container {
    margin: 0
  }

  .elementor-4722 .elementor-element.elementor-element-d0eb175 {
    margin-top: 60px;
    margin-bottom: 0;
    padding: 0 30px
  }

  .elementor-4722 .elementor-element.elementor-element-b637324 .elementor-heading-title {
    font-size: 22px
  }

  .elementor-4722 .elementor-element.elementor-element-b637324>.elementor-widget-container {
    margin: 10px
  }

  .elementor-4722 .elementor-element.elementor-element-1861337>.elementor-element-populated {
    margin: 20px 0 0;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px
  }

  .elementor-4722 .elementor-element.elementor-element-dc7d32a .elementor-heading-title {
    font-size: 22px
  }

  .elementor-4722 .elementor-element.elementor-element-dc7d32a>.elementor-widget-container {
    margin: 10px
  }

  .elementor-4722 .elementor-element.elementor-element-e22fe07>.elementor-element-populated {
    margin: 20px 0 0;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px
  }

  .elementor-4722 .elementor-element.elementor-element-edc5926 .elementor-heading-title {
    font-size: 22px
  }

  .elementor-4722 .elementor-element.elementor-element-edc5926>.elementor-widget-container {
    margin: 10px
  }

  .elementor-4722 .elementor-element.elementor-element-45b4327>.elementor-element-populated {
    margin: 20px 0 0;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px
  }

  .elementor-4722 .elementor-element.elementor-element-a4800fb .elementor-heading-title {
    font-size: 22px
  }

  .elementor-4722 .elementor-element.elementor-element-a4800fb>.elementor-widget-container {
    margin: 10px
  }

  .elementor-4722 .elementor-element.elementor-element-19d368b>.elementor-element-populated {
    margin: 20px 0 0;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px
  }

  .elementor-4722 .elementor-element.elementor-element-3024c51 .elementor-heading-title {
    font-size: 22px
  }

  .elementor-4722 .elementor-element.elementor-element-3024c51>.elementor-widget-container {
    margin: 10px
  }

  .elementor-4722 .elementor-element.elementor-element-ba990d4>.elementor-element-populated {
    margin: 20px 0 0;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px
  }

  .elementor-4722 .elementor-element.elementor-element-60efb4d .elementor-heading-title {
    font-size: 22px
  }

  .elementor-4722 .elementor-element.elementor-element-60efb4d>.elementor-widget-container {
    margin: 10px
  }

  .elementor-4722 .elementor-element.elementor-element-6d87474>.elementor-element-populated {
    margin: 20px 0 0;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px
  }

  .elementor-4722 .elementor-element.elementor-element-36cb23a .elementor-heading-title {
    font-size: 22px
  }

  .elementor-4722 .elementor-element.elementor-element-36cb23a>.elementor-widget-container {
    margin: 10px
  }

  .elementor-4722 .elementor-element.elementor-element-2619592>.elementor-element-populated {
    margin: 20px 0 0;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px
  }

  .elementor-4722 .elementor-element.elementor-element-50d0a5d img {
    width: 17%
  }

  .elementor-4722 .elementor-element.elementor-element-66ed496 .elementor-heading-title {
    font-size: 22px
  }

  .elementor-4722 .elementor-element.elementor-element-66ed496>.elementor-widget-container {
    margin: 10px
  }

  .elementor-4722 .elementor-element.elementor-element-648d0e6 .elementor-heading-title {
    font-size: 30px;
    line-height: 1.1em
  }

  .elementor-4722 .elementor-element.elementor-element-648d0e6>.elementor-widget-container {
    margin: 40px 0 0
  }

  .elementor-4722 .elementor-element.elementor-element-f30c5f8 {
    font-size: 18px;
    line-height: 1.4em
  }

  .elementor-4722 .elementor-element.elementor-element-f30c5f8>.elementor-widget-container {
    margin: 0;
    padding: 0
  }

  .elementor-4722 .elementor-element.elementor-element-5ae988a .elementor-icon-box-title,
  .elementor-4722 .elementor-element.elementor-element-5ae988a .elementor-icon-box-title a {
    font-size: 30px
  }

  .elementor-4722 .elementor-element.elementor-element-5ae988a .elementor-icon-box-description {
    font-size: 16px
  }

  .elementor-4722 .elementor-element.elementor-element-70bb3db .elementor-icon-box-title,
  .elementor-4722 .elementor-element.elementor-element-70bb3db .elementor-icon-box-title a {
    font-size: 30px
  }

  .elementor-4722 .elementor-element.elementor-element-70bb3db .elementor-icon-box-description {
    font-size: 16px
  }

  .elementor-4722 .elementor-element.elementor-element-9fcfa73 .elementor-icon-box-title,
  .elementor-4722 .elementor-element.elementor-element-9fcfa73 .elementor-icon-box-title a {
    font-size: 30px
  }

  .elementor-4722 .elementor-element.elementor-element-9fcfa73 .elementor-icon-box-description {
    font-size: 16px
  }

  .elementor-4722 .elementor-element.elementor-element-8600f58 .elementor-icon-box-title,
  .elementor-4722 .elementor-element.elementor-element-8600f58 .elementor-icon-box-title a {
    font-size: 30px
  }

  .elementor-4722 .elementor-element.elementor-element-8600f58 .elementor-icon-box-description {
    font-size: 16px
  }

  .elementor-4722 .elementor-element.elementor-element-2778a97 .elementor-icon-box-title,
  .elementor-4722 .elementor-element.elementor-element-2778a97 .elementor-icon-box-title a {
    font-size: 30px
  }

  .elementor-4722 .elementor-element.elementor-element-2778a97 .elementor-icon-box-description {
    font-size: 16px
  }

  .elementor-4722 .elementor-element.elementor-element-ff18bd1 .elementor-icon-box-title,
  .elementor-4722 .elementor-element.elementor-element-ff18bd1 .elementor-icon-box-title a {
    font-size: 30px
  }

  .elementor-4722 .elementor-element.elementor-element-ff18bd1 .elementor-icon-box-description {
    font-size: 16px
  }

  .elementor-4722 .elementor-element.elementor-element-160f72e .elementor-icon-box-title,
  .elementor-4722 .elementor-element.elementor-element-160f72e .elementor-icon-box-title a {
    font-size: 30px
  }

  .elementor-4722 .elementor-element.elementor-element-160f72e .elementor-icon-box-description {
    font-size: 16px
  }

  .elementor-4722 .elementor-element.elementor-element-5559292 .elementor-icon-box-title,
  .elementor-4722 .elementor-element.elementor-element-5559292 .elementor-icon-box-title a {
    font-size: 30px
  }

  .elementor-4722 .elementor-element.elementor-element-5559292 .elementor-icon-box-description {
    font-size: 16px
  }

  .elementor-4722 .elementor-element.elementor-element-816dd97 .elementor-icon-box-title,
  .elementor-4722 .elementor-element.elementor-element-816dd97 .elementor-icon-box-title a {
    font-size: 30px
  }

  .elementor-4722 .elementor-element.elementor-element-816dd97 .elementor-icon-box-description {
    font-size: 16px
  }

  .elementor-4722 .elementor-element.elementor-element-d8605a8 .elementor-icon-box-title,
  .elementor-4722 .elementor-element.elementor-element-d8605a8 .elementor-icon-box-title a {
    font-size: 30px
  }

  .elementor-4722 .elementor-element.elementor-element-d8605a8 .elementor-icon-box-description {
    font-size: 16px
  }

  .elementor-4722 .elementor-element.elementor-element-1cf236c .elementor-icon-box-title,
  .elementor-4722 .elementor-element.elementor-element-1cf236c .elementor-icon-box-title a {
    font-size: 30px
  }

  .elementor-4722 .elementor-element.elementor-element-1cf236c .elementor-icon-box-description {
    font-size: 16px
  }

  .elementor-4722 .elementor-element.elementor-element-39d753b .elementor-icon-box-title,
  .elementor-4722 .elementor-element.elementor-element-39d753b .elementor-icon-box-title a {
    font-size: 30px
  }

  .elementor-4722 .elementor-element.elementor-element-39d753b .elementor-icon-box-description {
    font-size: 16px
  }

  .elementor-4722 .elementor-element.elementor-element-102661b .elementor-icon-box-title,
  .elementor-4722 .elementor-element.elementor-element-102661b .elementor-icon-box-title a {
    font-size: 30px
  }

  .elementor-4722 .elementor-element.elementor-element-102661b .elementor-icon-box-description {
    font-size: 16px
  }

  .elementor-4722 .elementor-element.elementor-element-e0af2f3 .elementor-icon-box-title,
  .elementor-4722 .elementor-element.elementor-element-e0af2f3 .elementor-icon-box-title a {
    font-size: 30px
  }

  .elementor-4722 .elementor-element.elementor-element-e0af2f3 .elementor-icon-box-description {
    font-size: 16px
  }

  .elementor-4722 .elementor-element.elementor-element-8b50494 .elementor-icon-box-title,
  .elementor-4722 .elementor-element.elementor-element-8b50494 .elementor-icon-box-title a {
    font-size: 30px
  }

  .elementor-4722 .elementor-element.elementor-element-8b50494 .elementor-icon-box-description {
    font-size: 16px
  }

  .elementor-4722 .elementor-element.elementor-element-5a0d0e0 .elementor-icon-box-title,
  .elementor-4722 .elementor-element.elementor-element-5a0d0e0 .elementor-icon-box-title a {
    font-size: 30px
  }

  .elementor-4722 .elementor-element.elementor-element-5a0d0e0 .elementor-icon-box-description {
    font-size: 16px
  }

  .elementor-4722 .elementor-element.elementor-element-1744713 .elementor-icon-box-title,
  .elementor-4722 .elementor-element.elementor-element-1744713 .elementor-icon-box-title a {
    font-size: 30px
  }

  .elementor-4722 .elementor-element.elementor-element-1744713 .elementor-icon-box-description {
    font-size: 16px
  }

  .elementor-4722 .elementor-element.elementor-element-076f54d .elementor-icon-box-title,
  .elementor-4722 .elementor-element.elementor-element-076f54d .elementor-icon-box-title a {
    font-size: 30px
  }

  .elementor-4722 .elementor-element.elementor-element-076f54d .elementor-icon-box-description {
    font-size: 16px
  }

  .elementor-4722 .elementor-element.elementor-element-290aeeb .elementor-icon-box-title,
  .elementor-4722 .elementor-element.elementor-element-290aeeb .elementor-icon-box-title a {
    font-size: 30px
  }

  .elementor-4722 .elementor-element.elementor-element-290aeeb .elementor-icon-box-description {
    font-size: 16px
  }

  .elementor-4722 .elementor-element.elementor-element-8d77b7b .elementor-icon-box-title,
  .elementor-4722 .elementor-element.elementor-element-8d77b7b .elementor-icon-box-title a {
    font-size: 30px
  }

  .elementor-4722 .elementor-element.elementor-element-8d77b7b .elementor-icon-box-description {
    font-size: 16px
  }

  .elementor-4722 .elementor-element.elementor-element-0da761a .elementor-icon-box-title,
  .elementor-4722 .elementor-element.elementor-element-0da761a .elementor-icon-box-title a {
    font-size: 30px
  }

  .elementor-4722 .elementor-element.elementor-element-0da761a .elementor-icon-box-description {
    font-size: 16px
  }

  .elementor-4722 .elementor-element.elementor-element-dbad877 .elementor-icon-box-title,
  .elementor-4722 .elementor-element.elementor-element-dbad877 .elementor-icon-box-title a {
    font-size: 30px
  }

  .elementor-4722 .elementor-element.elementor-element-dbad877 .elementor-icon-box-description {
    font-size: 16px
  }

  .elementor-4722 .elementor-element.elementor-element-182e273 .elementor-icon-box-title,
  .elementor-4722 .elementor-element.elementor-element-182e273 .elementor-icon-box-title a {
    font-size: 30px
  }

  .elementor-4722 .elementor-element.elementor-element-182e273 .elementor-icon-box-description {
    font-size: 16px
  }

  .elementor-4722 .elementor-element.elementor-element-f862cf5 .elementor-icon-box-title,
  .elementor-4722 .elementor-element.elementor-element-f862cf5 .elementor-icon-box-title a {
    font-size: 30px
  }

  .elementor-4722 .elementor-element.elementor-element-f862cf5 .elementor-icon-box-description {
    font-size: 16px
  }

  .elementor-4722 .elementor-element.elementor-element-978e40b {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0 0 50px
  }

  .elementor-4722 .elementor-element.elementor-element-dc6d183 {
    width: 50%
  }

  .elementor-4722 .elementor-element.elementor-element-137ec6b img {
    width: 65%
  }

  .elementor-4722 .elementor-element.elementor-element-738b0a6 {
    width: 50%
  }

  .elementor-4722 .elementor-element.elementor-element-e0d529d {
    width: 50%
  }

  .elementor-4722 .elementor-element.elementor-element-498caba img {
    width: 80%
  }

  .elementor-4722 .elementor-element.elementor-element-c5b6424 {
    width: 50%
  }

  .elementor-4722 .elementor-element.elementor-element-3dfc9f0 {
    text-align: center
  }

  .elementor-4722 .elementor-element.elementor-element-3dfc9f0 img {
    width: 50%
  }

  .elementor-4722 .elementor-element.elementor-element-3dfc9f0>.elementor-widget-container {
    margin: 0;
    padding: 0
  }

  .elementor-4722 .elementor-element.elementor-element-01f2195 {
    width: 50%
  }

  .elementor-4722 .elementor-element.elementor-element-8fe6618 img {
    width: 45%
  }

  .elementor-4722 .elementor-element.elementor-element-414e461 {
    width: 50%
  }

  .elementor-4722 .elementor-element.elementor-element-c1f0357 img {
    width: 60px
  }

  .elementor-4722 .elementor-element.elementor-element-f2de76f {
    width: 50%
  }

  .elementor-4722 .elementor-element.elementor-element-1c7a6cc {
    text-align: center
  }

  .elementor-4722 .elementor-element.elementor-element-1c7a6cc img {
    width: 50%
  }

  .elementor-4722 .elementor-element.elementor-element-1c7a6cc>.elementor-widget-container {
    margin: 0;
    padding: 0
  }

  .elementor-4722 .elementor-element.elementor-element-74784d1 {
    width: 50%
  }

  .elementor-4722 .elementor-element.elementor-element-386c42e img {
    width: 80%
  }

  .elementor-4722 .elementor-element.elementor-element-393f69e {
    width: 50%
  }

  .elementor-4722 .elementor-element.elementor-element-7bbf60a {
    text-align: center
  }

  .elementor-4722 .elementor-element.elementor-element-7bbf60a img {
    width: 65%
  }

  .elementor-4722 .elementor-element.elementor-element-ad77565 {
    width: 50%
  }

  .elementor-4722 .elementor-element.elementor-element-db5ec76 img {
    width: 185px
  }

  .elementor-4722 .elementor-element.elementor-element-db5ec76>.elementor-widget-container {
    margin: 0
  }

  .elementor-4722 .elementor-element.elementor-element-d138a1e {
    margin-top: 0;
    margin-bottom: 0;
    padding: 100px 0 0
  }

  .elementor-4722 .elementor-element.elementor-element-4f15c9a .elementor-heading-title {
    font-size: 34px;
    line-height: 1.1em
  }

  .elementor-4722 .elementor-element.elementor-element-4f15c9a>.elementor-widget-container {
    margin: 40px 0 0
  }

  .elementor-4722 .elementor-element.elementor-element-7375290 {
    padding: 0 20px
  }

  .elementor-4722 .elementor-element.elementor-element-099f1c4>.elementor-element-populated {
    padding: 0
  }

  .elementor-4722 .elementor-element.elementor-element-de49654 .elementor-heading-title {
    font-size: 15px
  }

  .elementor-4722 .elementor-element.elementor-element-812170a .elementor-heading-title {
    font-size: 25px
  }

  .elementor-4722 .elementor-element.elementor-element-f52dc75 {
    font-size: 16px
  }

  .elementor-4722 .elementor-element.elementor-element-88a8294 {
    padding: 50px 20px 0
  }

  .elementor-4722 .elementor-element.elementor-element-3eb0ee1>.elementor-element-populated {
    margin: 0;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0
  }

  .elementor-4722 .elementor-element.elementor-element-2004a0b .elementor-heading-title {
    font-size: 15px
  }

  .elementor-4722 .elementor-element.elementor-element-9272d71 .elementor-heading-title {
    font-size: 25px
  }

  .elementor-4722 .elementor-element.elementor-element-9272d71>.elementor-widget-container {
    margin: 0;
    padding: 0
  }

  .elementor-4722 .elementor-element.elementor-element-77b6d38 {
    font-size: 16px
  }

  .elementor-4722 .elementor-element.elementor-element-c08db32 {
    padding: 50px 20px 0
  }

  .elementor-4722 .elementor-element.elementor-element-1117567>.elementor-element-populated {
    padding: 0
  }

  .elementor-4722 .elementor-element.elementor-element-ea223a7 .elementor-heading-title {
    font-size: 15px
  }

  .elementor-4722 .elementor-element.elementor-element-284613a .elementor-heading-title {
    font-size: 25px
  }

  .elementor-4722 .elementor-element.elementor-element-4bfb451 {
    font-size: 16px
  }

  .elementor-4722 .elementor-element.elementor-element-62715f3 {
    padding: 30px 0 0
  }

  .elementor-4722 .elementor-element.elementor-element-559df52d {
    padding: 20px
  }

  .elementor-4722 .elementor-element.elementor-element-131df838>.elementor-element-populated {
    margin: 0;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0 0 20px
  }

  .elementor-4722 .elementor-element.elementor-global-32498 .elementor-heading-title {
    font-size: 25px
  }

  .elementor-4722 .elementor-element.elementor-global-32498>.elementor-widget-container {
    padding: 0
  }

  .elementor-4722 .elementor-element.elementor-global-32501 {
    font-size: 16px
  }

  .elementor-4722 .elementor-element.elementor-global-32504 .elementor-button {
    font-size: 17px
  }

  .elementor-4722 .elementor-element.elementor-global-32504>.elementor-widget-container {
    margin: 0;
    padding: 0
  }

  .elementor-4722 .elementor-element.elementor-element-2943a640>.elementor-element-populated {
    padding: 0
  }

  .elementor-4722 .elementor-element.elementor-global-32507 .elementor-icon-list-item>.elementor-icon-list-text,
  .elementor-4722 .elementor-element.elementor-global-32507 .elementor-icon-list-item>a {
    font-size: 16px
  }

  .elementor-4722 .elementor-element.elementor-global-32507>.elementor-widget-container {
    padding: 0
  }

  .elementor-4722 .elementor-element.elementor-element-9bec390 {
    padding: 50px 20px 0
  }

  .elementor-4722 .elementor-element.elementor-element-8b039fe>.elementor-element-populated {
    padding: 0
  }

  .elementor-4722 .elementor-element.elementor-element-3218d64 .elementor-heading-title {
    font-size: 15px
  }

  .elementor-4722 .elementor-element.elementor-element-2f9d6d2 .elementor-heading-title {
    font-size: 25px
  }

  .elementor-4722 .elementor-element.elementor-element-5cfcb08 {
    font-size: 16px
  }

  .elementor-4722 .elementor-element.elementor-element-8d01f70 {
    padding: 50px 20px 0
  }

  .elementor-4722 .elementor-element.elementor-element-bb6f8ec>.elementor-element-populated {
    padding: 0
  }

  .elementor-4722 .elementor-element.elementor-element-ba55bb9 .elementor-heading-title {
    font-size: 15px
  }

  .elementor-4722 .elementor-element.elementor-element-c59cbd8 .elementor-heading-title {
    font-size: 25px
  }

  .elementor-4722 .elementor-element.elementor-element-fff0bb0 {
    font-size: 16px
  }

  .elementor-4722 .elementor-element.elementor-element-38cb92e {
    padding: 50px 20px 0
  }

  .elementor-4722 .elementor-element.elementor-element-94c2651>.elementor-element-populated {
    padding: 0
  }

  .elementor-4722 .elementor-element.elementor-element-02a3635 .elementor-heading-title {
    font-size: 15px
  }

  .elementor-4722 .elementor-element.elementor-element-b5cbd04 .elementor-heading-title {
    font-size: 25px
  }

  .elementor-4722 .elementor-element.elementor-element-4bf4adf {
    font-size: 16px
  }

  .elementor-4722 .elementor-element.elementor-element-618931e>.elementor-shape-top svg {
    height: 40px
  }

  .elementor-4722 .elementor-element.elementor-element-618931e {
    padding: 0
  }

  .elementor-4722 .elementor-element.elementor-element-47b7bdb {
    margin-top: 10px;
    margin-bottom: 0
  }

  .elementor-4722 .elementor-element.elementor-element-bfc6493>.elementor-element-populated {
    margin: 0;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px
  }

  .elementor-4722 .elementor-element.elementor-element-f96a860 .jet-inline-svg {
    max-width: 81px
  }

  .elementor-4722 .elementor-element.elementor-element-28590ad .elementor-testimonial-content {
    font-size: 20px
  }

  .elementor-4722 .elementor-element.elementor-element-28590ad .elementor-testimonial-job {
    font-size: 16px
  }

  .elementor-4722 .elementor-element.elementor-element-dfa0687 .elementor-heading-title {
    font-size: 32px;
    line-height: 1.1em
  }

  .elementor-4722 .elementor-element.elementor-element-dfa0687>.elementor-widget-container {
    margin: 40px 0 0
  }

  .elementor-4722 .elementor-element.elementor-element-b272fbe {
    font-size: 14px
  }

  .elementor-4722 .elementor-element.elementor-element-5986987>.elementor-widget-container {
    margin: 0;
    padding: 20px 0 0
  }

  .elementor-4722 .elementor-element.elementor-element-bcab5db {
    padding: 20px 0 0
  }

  .elementor-4722 .elementor-element.elementor-global-12796 .elementor-heading-title {
    font-size: 30px;
    line-height: 1.1em
  }

  .elementor-4722 .elementor-element.elementor-global-12796>.elementor-widget-container {
    margin: 0
  }

  .elementor-4722 .elementor-element.elementor-element-9c9d904 {
    padding: 0 0 40px
  }

  .elementor-4722 .elementor-element.elementor-element-8a78fa2 {
    margin-top: 30px;
    margin-bottom: 0;
    padding: 0 20px
  }

  .elementor-4722 .elementor-element.elementor-element-c50aa5b>.elementor-element-populated {
    margin: 0 0 20px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px
  }

  .elementor-4722 .elementor-element.elementor-element-a3e395d .widget-image-caption {
    /* font-size: 16px */
  }

  .elementor-4722 .elementor-element.elementor-element-7327e02 .elementor-heading-title {
    font-size: 25px
  }

  .elementor-4722 .elementor-element.elementor-element-f1bacc4 .elementor-heading-title {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height)
  }

  .elementor-4722 .elementor-element.elementor-element-d55e40a>.elementor-element-populated {
    margin: 0 0 20px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px
  }

  .elementor-4722 .elementor-element.elementor-element-0a17805 .widget-image-caption {
    font-size: 16px
  }

  .elementor-4722 .elementor-element.elementor-element-7f4d764 .elementor-heading-title {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height)
  }

  .elementor-4722 .elementor-element.elementor-element-d41f1bf>.elementor-element-populated {
    margin: 0;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0
  }

  .elementor-4722 .elementor-element.elementor-element-8482b25 .widget-image-caption {
    font-size: 16px
  }

  .elementor-4722 .elementor-element.elementor-element-2b8ca6a .elementor-heading-title {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height)
  }

  .elementor-4722 .elementor-element.elementor-element-45eb4b9 .elementor-heading-title {
    font-size: 30px;
    line-height: 1.1em
  }

  .elementor-4722 .elementor-element.elementor-element-45eb4b9>.elementor-widget-container {
    margin: 0
  }

  .elementor-4722 .elementor-element.elementor-element-cd8a53c {
    padding: 0
  }

  .elementor-4722 .elementor-element.elementor-element-4af1fab {
    font-size: 17px
  }

  .elementor-4722 .elementor-element.elementor-element-4af1fab>.elementor-widget-container {
    margin: 20px 0 0
  }

  .text-slider-container {
    height: 40px !important;
    padding-top: 10px;
  }

  .elementor-widget-container {
    padding-top: 20px;
  }

  body:not([class*=elementor-page-]) .site-main {
    display: contents !important;
  }

  .padding-top-20 {
    padding-top: 20px;
  }
}

@media(max-width:1024px) and (min-width:768px) {
  .elementor-4722 .elementor-element.elementor-element-5b63ea3 {
    width: 50%
  }

  .elementor-4722 .elementor-element.elementor-element-ac5a923 {
    width: 50%
  }

  .elementor-4722 .elementor-element.elementor-element-fe04227 {
    width: 100%
  }

  .elementor-4722 .elementor-element.elementor-element-4d401f5 {
    width: 100%
  }

  .elementor-4722 .elementor-element.elementor-element-839dad5 {
    width: 100%
  }

  .elementor-4722 .elementor-element.elementor-element-9abc3bb {
    width: 100%
  }

  .elementor-4722 .elementor-element.elementor-element-c008481 {
    width: 100%
  }

  .elementor-4722 .elementor-element.elementor-element-e0230c9 {
    width: 100%
  }

  .elementor-4722 .elementor-element.elementor-element-d34fbac {
    width: 100%
  }

  .elementor-4722 .elementor-element.elementor-element-131df838 {
    width: 67%
  }

  .elementor-4722 .elementor-element.elementor-element-2943a640 {
    width: 33%
  }

  .elementor-4722 .elementor-element.elementor-element-e47c273 {
    width: 37%
  }

  .elementor-4722 .elementor-element.elementor-element-146bf7e {
    width: 50%
  }
}

.elementor-25881 .elementor-element.elementor-element-30576ea7:not(.elementor-motion-effects-element-type-background),
.elementor-25881 .elementor-element.elementor-element-30576ea7>.elementor-motion-effects-container>.elementor-motion-effects-layer {
  background-color: #f4f4ff
}

.elementor-25881 .elementor-element.elementor-element-30576ea7>.elementor-background-overlay {
  background-image: url("https://socialbee.com/wp-content/uploads/2020/03/background-white-e1653912817568.png");
  opacity: 1;
  transition: background .3s, border-radius .3s, opacity .3s
}

.elementor-25881 .elementor-element.elementor-element-30576ea7 {
  transition: background .3s, border .3s, border-radius .3s, box-shadow .3s;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0 0 20px
}

.elementor-25881 .elementor-element.elementor-element-4ad548ef>.elementor-element-populated {
  margin: 0;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0
}

.elementor-25881 .elementor-element.elementor-element-a0cadf9 {
  margin-top: 0;
  margin-bottom: 0;
  padding: 0
}

.elementor-25881 .elementor-element.elementor-element-4db980d4>.elementor-element-populated {
  margin: 0;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0
}

.elementor-25881 .elementor-element.elementor-element-11dcd495 {
  text-align: right
}

.elementor-25881 .elementor-element.elementor-element-11dcd495 img {
  width: 100%
}

.elementor-25881 .elementor-element.elementor-element-11dcd495>.elementor-widget-container {
  margin: 0
}

.elementor-25881 .elementor-element.elementor-element-28c7f938 {
  text-align: center;
  color: #000;
  font-size: 35px;
  line-height: 1.4em
}

.elementor-25881 .elementor-element.elementor-element-28c7f938>.elementor-widget-container {
  margin: 0 0 -20px;
  padding: 0 30px
}

.elementor-25881 .elementor-element.elementor-element-d3e5979 {
  text-align: center;
  color: #000;
  font-size: 19px;
  line-height: 1.4em
}

.elementor-25881 .elementor-element.elementor-element-d3e5979>.elementor-widget-container {
  margin: 0 0 -20px;
  padding: 0 30px
}

.elementor-25881 .elementor-element.elementor-element-38e7e201>.elementor-widget-container {
  margin: 0;
  padding: 0 20px
}

#elementor-popup-modal-25881 .dialog-message {
  width: 640px;
  height: auto
}

#elementor-popup-modal-25881 {
  justify-content: center;
  align-items: center;
  pointer-events: all;
  background-color: #00000040
}

#elementor-popup-modal-25881 .dialog-close-button {
  display: flex
}

#elementor-popup-modal-25881 .dialog-widget-content {
  animation-duration: 1s;
  background-color: #f5c400
}

@media(max-width:767px) {
  .elementor-25881 .elementor-element.elementor-element-28c7f938 {
    font-size: 20px;
    line-height: 1.2em
  }

  .elementor-25881 .elementor-element.elementor-element-d3e5979 {
    font-size: 20px;
    line-height: 1.2em
  }

  .elementor-25881 .elementor-element.elementor-element-38e7e201>.elementor-widget-container {
    margin: 0;
    padding: 0
  }
}

.elementor-15095 .elementor-element.elementor-element-5157d742>.elementor-container {
  max-width: 1235px
}

.elementor-15095 .elementor-element.elementor-element-5157d742:not(.elementor-motion-effects-element-type-background),
.elementor-15095 .elementor-element.elementor-element-5157d742>.elementor-motion-effects-container>.elementor-motion-effects-layer {
  background-color: transparent;
  background-image: linear-gradient(180deg, #fff 0, #f3c500 88%)
}

.elementor-15095 .elementor-element.elementor-element-5157d742>.elementor-background-overlay {
  opacity: 1;
  transition: background .3s, border-radius .3s, opacity .3s
}

.elementor-15095 .elementor-element.elementor-element-5157d742 {
  transition: background .3s, border .3s, border-radius .3s, box-shadow .3s;
  padding: 20px 5px 12px
}

.elementor-15095 .elementor-element.elementor-element-46b9a373.elementor-column.elementor-element[data-element_type=column]>.elementor-widget-wrap.elementor-element-populated {
  align-content: center;
  align-items: center
}

.elementor-15095 .elementor-element.elementor-element-46b9a373>.elementor-widget-wrap>.elementor-widget:not(.elementor-widget__width-auto):not(.elementor-widget__width-initial):not(:last-child):not(.elementor-absolute) {
  margin-bottom: 7px
}

.elementor-15095 .elementor-element.elementor-element-46b9a373>.elementor-element-populated {
  padding: 0 20px 0 10px
}

.elementor-15095 .elementor-element.elementor-element-46b9a373 {
  z-index: 1
}

.elementor-15095 .elementor-element.elementor-element-7bb7f81 {
  color: var(--e-global-color-ddda476);
  font-family: Roboto, Sans-serif;
  font-size: 23px;
  font-weight: 400;
  line-height: 1.3em
}

.elementor-15095 .elementor-element.elementor-element-11815f8 {
  color: var(--e-global-color-ddda476);
  font-family: Roboto, Sans-serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 1.3em
}

.elementor-15095 .elementor-element.elementor-element-2b415e6d.elementor-column.elementor-element[data-element_type=column]>.elementor-widget-wrap.elementor-element-populated {
  align-content: center;
  align-items: center
}

.elementor-15095 .elementor-element.elementor-element-2b415e6d {
  z-index: 1
}

.elementor-15095 .elementor-element.elementor-element-ded4fb9 .elementor-button .elementor-align-icon-right {
  margin-left: 0
}

.elementor-15095 .elementor-element.elementor-element-ded4fb9 .elementor-button {
  font-size: 19px;
  font-weight: 700;
  background-color: var(--e-global-color-ddda476);
  border-style: solid;
  border-width: 1px
}

.elementor-15095 .elementor-element.elementor-element-ded4fb9>.elementor-widget-container {
  margin: 0
}

.elementor-15095 .elementor-element.elementor-element-ded4fb9 {
  z-index: 1
}

#elementor-popup-modal-15095 .dialog-message {
  width: 100vw;
  height: auto
}

#elementor-popup-modal-15095 {
  justify-content: center;
  align-items: flex-end
}

#elementor-popup-modal-15095 .dialog-close-button {
  display: flex;
  top: 5.9%;
  font-size: 20px
}

#elementor-popup-modal-15095 .dialog-widget-content {
  animation-duration: 1.2s;
  box-shadow: 2px 8px 23px 3px rgba(0, 0, 0, .2)
}

body:not(.rtl) #elementor-popup-modal-15095 .dialog-close-button {
  right: .3%
}

body.rtl #elementor-popup-modal-15095 .dialog-close-button {
  left: .3%
}

#elementor-popup-modal-15095 .dialog-close-button i {
  color: var(--e-global-color-text)
}

#elementor-popup-modal-15095 .dialog-close-button svg {
  fill: var(--e-global-color-text)
}

#elementor-popup-modal-15095 .dialog-close-button:hover i {
  color: #fff
}

@media(max-width:1024px) {
  .elementor-15095 .elementor-element.elementor-element-5157d742 {
    padding: 5px 30px
  }

  .elementor-15095 .elementor-element.elementor-element-46b9a373>.elementor-element-populated {
    padding: 10px
  }

  .elementor-15095 .elementor-element.elementor-element-7bb7f81 {
    font-size: 18px;
    line-height: 1.4em
  }

  .elementor-15095 .elementor-element.elementor-element-11815f8 {
    font-size: 18px;
    line-height: 1.4em
  }

  .elementor-15095 .elementor-element.elementor-element-2b415e6d>.elementor-element-populated {
    margin: 0;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0
  }

  .elementor-15095 .elementor-element.elementor-element-ded4fb9 .elementor-button {
    font-size: 11px
  }

  .elementor-15095 .elementor-element.elementor-element-ded4fb9>.elementor-widget-container {
    margin: 0;
    padding: 0
  }
}

@media(max-width:767px) {
  .elementor-15095 .elementor-element.elementor-element-5157d742 {
    padding: 10px 20px
  }

  .elementor-15095 .elementor-element.elementor-element-46b9a373 {
    width: 100%
  }

  .elementor-15095 .elementor-element.elementor-element-7bb7f81 {
    font-size: 20px;
    line-height: 1.3em
  }

  .elementor-15095 .elementor-element.elementor-element-11815f8 {
    font-size: 20px;
    line-height: 1.3em
  }

  .elementor-15095 .elementor-element.elementor-element-2b415e6d>.elementor-element-populated {
    padding: 0
  }
}

@media(min-width:768px) {
  .elementor-15095 .elementor-element.elementor-element-46b9a373 {
    width: 77.001%
  }

  .elementor-15095 .elementor-element.elementor-element-2b415e6d {
    width: 22.999%
  }
}

@media(max-width:1024px) and (min-width:768px) {
  .elementor-15095 .elementor-element.elementor-element-46b9a373 {
    width: 60%
  }

  .elementor-15095 .elementor-element.elementor-element-2b415e6d {
    width: 40%
  }
}

#cookie-notice {
  position: fixed;
  min-width: 100%;
  height: auto;
  z-index: 100000;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 20px;
  left: 0;
  text-align: center;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, Arial, Roboto, "Helvetica Neue", sans-serif
}

#cookie-notice,
#cookie-notice * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

#cookie-notice.cn-animated {
  -webkit-animation-duration: .5s !important;
  animation-duration: .5s !important;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both
}

#cookie-notice .cookie-notice-container {
  display: block
}

#cookie-notice.cookie-notice-hidden .cookie-notice-container {
  display: none
}

.cn-position-bottom {
  bottom: 0
}

.cookie-notice-container {
  padding: 15px 30px;
  text-align: center;
  width: 100%;
  z-index: 2
}

.cn-close-icon {
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: -10px;
  width: 15px;
  height: 15px;
  opacity: .5;
  padding: 10px;
  outline: 0;
  cursor: pointer
}

.cn-close-icon:hover {
  opacity: 1
}

.cn-close-icon:after,
.cn-close-icon:before {
  position: absolute;
  content: ' ';
  height: 15px;
  width: 2px;
  top: 3px;
  background-color: grey
}

.cn-close-icon:before {
  transform: rotate(45deg)
}

.cn-close-icon:after {
  transform: rotate(-45deg)
}

#cookie-notice .cn-revoke-cookie {
  margin: 0
}

#cookie-notice .cn-button {
  margin: 0 0 0 10px;
  display: inline-block
}

#cookie-notice .cn-button:not(.cn-button-custom) {
  font-family: -apple-system, BlinkMacSystemFont, Arial, Roboto, "Helvetica Neue", sans-serif;
  font-weight: 400;
  font-size: 13px;
  letter-spacing: .25px;
  line-height: 20px;
  margin: 0 0 0 10px;
  text-align: center;
  text-transform: none;
  display: inline-block;
  cursor: pointer;
  touch-action: manipulation;
  white-space: nowrap;
  outline: 0;
  box-shadow: none;
  text-shadow: none;
  border: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  text-decoration: none;
  padding: 8.5px 10px;
  line-height: 1;
  color: inherit
}

.cn-text-container {
  margin: 0 0 6px
}

.cn-buttons-container,
.cn-text-container {
  display: inline-block
}

#cookie-notice.cookie-notice-visible.cn-effect-fade,
#cookie-notice.cookie-revoke-visible.cn-effect-fade {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn
}

#cookie-notice.cn-effect-fade {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0
  }

  to {
    opacity: 1
  }
}

@-webkit-keyframes fadeOut {
  from {
    opacity: 1
  }

  to {
    opacity: 0
  }
}

@keyframes fadeOut {
  from {
    opacity: 1
  }

  to {
    opacity: 0
  }
}

@-webkit-keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }
}

@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }
}

@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }
}

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }
}

@media all and (max-width:900px) {
  .cookie-notice-container #cn-notice-text {
    display: block
  }

  .cookie-notice-container #cn-notice-buttons {
    display: block
  }

  #cookie-notice .cn-button {
    margin: 0 5px 5px
  }
}

@media all and (max-width:480px) {
  .cookie-notice-container {
    padding: 15px 25px
  }
}

@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOiCnqEu92Fr1Mu51QrEzAdLw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1Mu51xIIzI.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TzBic6CsQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu4mxK.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9fBBc4.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfBBc4.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmYUtfBBc4.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

div.elementor-element-3afab82>div>h1 {
  font-size: 65px !important
}

.elementor-heading-title {
  padding: 0;
  margin: 0;
  line-height: 1
}

.elementor-widget-heading .elementor-heading-title[class*=elementor-size-]>a {
  color: inherit;
  font-size: inherit;
  line-height: inherit
}

.elementor-widget-heading .elementor-heading-title.elementor-size-xl {
  font-size: 39px
}

.elementor-widget-image {
  text-align: center
}

.elementor-widget-image a {
  display: inline-block
}

.elementor-widget-image a img[src$=".svg"] {
  width: 48px
}

.elementor-widget-image img {
  vertical-align: middle;
  display: inline-block
}

.elementor-widget-icon-box .elementor-icon-box-wrapper {
  display: block;
  text-align: center
}

.elementor-widget-icon-box .elementor-icon-box-icon {
  margin-bottom: var(--icon-box-icon-margin, 15px);
  margin-right: auto;
  margin-left: auto
}

@media (min-width:768px) {
  .elementor-widget-icon-box.elementor-vertical-align-top .elementor-icon-box-wrapper {
    align-items: flex-start
  }

  .elementor-widget-icon-box.elementor-vertical-align-bottom .elementor-icon-box-wrapper {
    align-items: flex-end
  }
}

.elementor-widget-icon-box.elementor-position-left .elementor-icon-box-wrapper {
  display: flex
}

.elementor-widget-icon-box.elementor-position-left .elementor-icon-box-icon {
  display: inline-flex;
  flex: 0 0 auto
}

.elementor-widget-icon-box.elementor-position-left .elementor-icon-box-wrapper {
  text-align: left;
  flex-direction: row
}

.elementor-widget-icon-box.elementor-position-left .elementor-icon-box-icon {
  margin-right: var(--icon-box-icon-margin, 15px);
  margin-left: 0;
  margin-bottom: unset
}

@media (max-width:767px) {
  .elementor-widget-icon-box.elementor-mobile-position-top .elementor-icon-box-wrapper {
    display: block;
    text-align: center;
    flex-direction: unset
  }

  .elementor-widget-icon-box.elementor-mobile-position-top .elementor-icon-box-icon {
    margin-bottom: var(--icon-box-icon-margin, 15px);
    margin-right: auto;
    margin-left: auto
  }

  .elementor-widget-icon-box.elementor-position-left .elementor-icon-box-icon {
    display: block;
    flex: unset
  }
}

.elementor-widget-icon-box .elementor-icon-box-title a {
  color: inherit
}

.elementor-widget-icon-box .elementor-icon-box-content {
  flex-grow: 1
}

.elementor-widget-icon-box .elementor-icon-box-description {
  margin: 0
}

.elementor-widget .elementor-icon-list-items {
  list-style-type: none;
  margin: 0;
  padding: 0
}

.elementor-widget .elementor-icon-list-item {
  margin: 0;
  padding: 0;
  position: relative
}

.elementor-widget .elementor-icon-list-item:after {
  position: absolute;
  bottom: 0;
  width: 100%
}

.elementor-widget .elementor-icon-list-item,
.elementor-widget .elementor-icon-list-item a {
  display: flex;
  font-size: inherit;
  align-items: var(--icon-vertical-align, center)
}

.elementor-widget .elementor-icon-list-icon+.elementor-icon-list-text {
  align-self: center;
  padding-left: 5px
}

.elementor-widget .elementor-icon-list-icon {
  display: flex;
  position: relative;
  top: var(--icon-vertical-offset, initial)
}

.elementor-widget .elementor-icon-list-icon svg {
  width: var(--e-icon-list-icon-size, 1em);
  height: var(--e-icon-list-icon-size, 1em)
}

.elementor-widget .elementor-icon-list-icon i {
  width: 1.25em;
  font-size: var(--e-icon-list-icon-size)
}

.elementor-widget.elementor-widget-icon-list .elementor-icon-list-icon {
  text-align: var(--e-icon-list-icon-align)
}

.elementor-widget.elementor-widget-icon-list .elementor-icon-list-icon svg {
  margin: var(--e-icon-list-icon-margin, 0 calc(var(--e-icon-list-icon-size, 1em) * .25) 0 0)
}

.elementor-widget.elementor-list-item-link-full_width a {
  width: 100%
}

.elementor-widget.elementor-align-center .elementor-icon-list-item,
.elementor-widget.elementor-align-center .elementor-icon-list-item a {
  justify-content: center
}

.elementor-widget.elementor-align-center .elementor-icon-list-item:after {
  margin: auto
}

.elementor-widget.elementor-align-left .elementor-icon-list-item,
.elementor-widget.elementor-align-left .elementor-icon-list-item a {
  justify-content: flex-start;
  text-align: left
}

.elementor-widget.elementor-align-right .elementor-icon-list-item,
.elementor-widget.elementor-align-right .elementor-icon-list-item a {
  justify-content: flex-end;
  text-align: right
}

.elementor-widget.elementor-align-right .elementor-icon-list-items {
  justify-content: flex-end
}

.elementor-widget:not(.elementor-align-right) .elementor-icon-list-item:after {
  left: 0
}

.elementor-widget:not(.elementor-align-left) .elementor-icon-list-item:after {
  right: 0
}

@media (max-width:1024px) {

  .elementor-widget.elementor-tablet-align-center .elementor-icon-list-item,
  .elementor-widget.elementor-tablet-align-center .elementor-icon-list-item a {
    justify-content: center
  }

  .elementor-widget.elementor-tablet-align-center .elementor-icon-list-item:after {
    margin: auto
  }

  .elementor-widget:not(.elementor-tablet-align-right) .elementor-icon-list-item:after {
    left: 0
  }

  .elementor-widget:not(.elementor-tablet-align-left) .elementor-icon-list-item:after {
    right: 0
  }
}

@media (max-width:1) {
  .elementor-widget:not(.elementor-tablet_extra-align-right) .elementor-icon-list-item:after {
    left: 0
  }

  .elementor-widget:not(.elementor-tablet_extra-align-left) .elementor-icon-list-item:after {
    right: 0
  }

  .elementor-widget:not(.elementor-mobile_extra-align-right) .elementor-icon-list-item:after {
    left: 0
  }

  .elementor-widget:not(.elementor-mobile_extra-align-left) .elementor-icon-list-item:after {
    right: 0
  }
}

@media (max-width:767px) {

  .elementor-widget.elementor-mobile-align-center .elementor-icon-list-item,
  .elementor-widget.elementor-mobile-align-center .elementor-icon-list-item a {
    justify-content: center
  }

  .elementor-widget.elementor-mobile-align-center .elementor-icon-list-item:after {
    margin: auto
  }

  .elementor-widget:not(.elementor-mobile-align-right) .elementor-icon-list-item:after {
    left: 0
  }

  .elementor-widget:not(.elementor-mobile-align-left) .elementor-icon-list-item:after {
    right: 0
  }
}

.elementor-testimonial-wrapper {
  overflow: hidden;
  text-align: center
}

.elementor-testimonial-wrapper .elementor-testimonial-content {
  font-size: 1.3em;
  margin-bottom: 20px
}

.elementor-testimonial-wrapper .elementor-testimonial-name {
  line-height: 1.5;
  display: block
}

.elementor-testimonial-wrapper .elementor-testimonial-job {
  font-size: .85em;
  display: block
}

.elementor-testimonial-wrapper .elementor-testimonial-meta {
  width: 100%;
  line-height: 1
}

.elementor-testimonial-wrapper .elementor-testimonial-meta-inner {
  display: inline-block
}

.elementor-testimonial-wrapper .elementor-testimonial-meta .elementor-testimonial-details,
.elementor-testimonial-wrapper .elementor-testimonial-meta .elementor-testimonial-image {
  display: table-cell;
  vertical-align: middle
}

.elementor-testimonial-wrapper .elementor-testimonial-meta .elementor-testimonial-image img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
  max-width: none
}

.elementor-testimonial-wrapper .elementor-testimonial-meta.elementor-testimonial-image-position-aside .elementor-testimonial-image {
  padding-right: 15px
}

.elementor-testimonial-wrapper .elementor-testimonial-meta.elementor-testimonial-image-position-aside .elementor-testimonial-details {
  text-align: center;
}

#BeaconFabButtonFrameFacade {
  border-radius: 60px;
  height: 60px;
  position: fixed;
  transform: scale(1);
  width: 60px;
  z-index: 1049;
  bottom: 40px;
  right: 40px
}

@media (max-height:740px) {
  #BeaconFabButtonFrameFacade {
    bottom: 10px;
    right: 20px
  }
}

#BeaconFabButtonFrameFacade #beacon-facade-container-body {
  margin: 0;
  overflow: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  font-family: var(--HSDSGlobalFontFamily);
  font-size: 14px;
  line-height: 1.5
}

#BeaconFabButtonFrameFacade #beacon-facade-container *,
#BeaconFabButtonFrameFacade #beacon-facade-container-body * {
  box-sizing: border-box
}

#BeaconFabButtonFrameFacade .c-FabButtonCritical__button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  align-items: center;
  border-radius: 200px;
  border: none;
  bottom: 0;
  color: #fff;
  cursor: pointer;
  display: block;
  height: 60px;
  justify-content: center;
  min-width: 60px;
  margin: 0;
  line-height: 60px;
  outline: 0;
  padding: 0;
  position: relative;
  transition: transform .2s linear;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  z-index: 999;
  -webkit-tap-highlight-color: transparent;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden
}

#BeaconFabButtonFrameFacade .c-FabButtonCritical__button:focus {
  outline: 0
}

#BeaconFabButtonFrameFacade .c-FabButtonCritical__button:hover {
  background-color: var(--background-hover-color, inherit) !important;
  box-shadow: inset 0 0 0 30px rgba(0, 0, 0, .06)
}

#BeaconFabButtonFrameFacade .c-FabButtonCritical__button:active {
  background-color: var(--background-active-color, inherit) !important;
  box-shadow: inset 0 0 0 30px rgba(0, 0, 0, .1);
  cursor: pointer;
  transform: scale(.96)
}

#BeaconFabButtonFrameFacade .c-FabButtonCritical__button::-moz-focus-inner {
  border: 0
}

#BeaconFabButtonFrameFacade .c-FabButtonCritical__icon {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  opacity: 1;
  pointer-events: none;
  position: absolute;
  text-indent: -99999px;
  top: 0;
  width: 60px
}

#BeaconFabButtonFrameFacade .c-FabButtonCritical__icon path {
  fill: currentColor
}

@keyframes bounce {

  20%,
  53%,
  80%,
  from,
  to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    transform: translate3d(0, 0, 0)
  }

  40%,
  43% {
    animation-timing-function: cubic-bezier(.755, .050, .855, .060);
    transform: translate3d(0, -30px, 0)
  }

  70% {
    animation-timing-function: cubic-bezier(.755, .050, .855, .060);
    transform: translate3d(0, -15px, 0)
  }

  90% {
    transform: translate3d(0, -4px, 0)
  }
}

.bounce {
  animation-name: bounce;
  transform-origin: center bottom
}

@keyframes flash {

  50%,
  from,
  to {
    opacity: 1
  }

  25%,
  75% {
    opacity: 0
  }
}

.flash {
  animation-name: flash
}

@keyframes pulse {

  from,
  to {
    transform: scale3d(1, 1, 1)
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05)
  }
}

.pulse {
  animation-name: pulse
}

@keyframes rubberBand {

  from,
  to {
    transform: scale3d(1, 1, 1)
  }

  30% {
    transform: scale3d(1.25, .75, 1)
  }

  40% {
    transform: scale3d(.75, 1.25, 1)
  }

  50% {
    transform: scale3d(1.15, .85, 1)
  }

  65% {
    transform: scale3d(.95, 1.05, 1)
  }

  75% {
    transform: scale3d(1.05, .95, 1)
  }
}

.rubberBand {
  animation-name: rubberBand
}

@keyframes shake {

  from,
  to {
    transform: translate3d(0, 0, 0)
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate3d(-10px, 0, 0)
  }

  20%,
  40%,
  60%,
  80% {
    transform: translate3d(10px, 0, 0)
  }
}

.shake {
  animation-name: shake
}

@keyframes headShake {
  0% {
    transform: translateX(0)
  }

  6.5% {
    transform: translateX(-6px) rotateY(-9deg)
  }

  18.5% {
    transform: translateX(5px) rotateY(7deg)
  }

  31.5% {
    transform: translateX(-3px) rotateY(-5deg)
  }

  43.5% {
    transform: translateX(2px) rotateY(3deg)
  }

  50% {
    transform: translateX(0)
  }
}

.headShake {
  animation-timing-function: ease-in-out;
  animation-name: headShake
}

@keyframes swing {
  20% {
    transform: rotate3d(0, 0, 1, 15deg)
  }

  40% {
    transform: rotate3d(0, 0, 1, -10deg)
  }

  60% {
    transform: rotate3d(0, 0, 1, 5deg)
  }

  80% {
    transform: rotate3d(0, 0, 1, -5deg)
  }

  to {
    transform: rotate3d(0, 0, 1, 0deg)
  }
}

.swing {
  transform-origin: top center;
  animation-name: swing
}

@keyframes tada {

  from,
  to {
    transform: scale3d(1, 1, 1)
  }

  10%,
  20% {
    transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg)
  }

  30%,
  50%,
  70%,
  90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg)
  }

  40%,
  60%,
  80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg)
  }
}

.tada {
  animation-name: tada
}

@keyframes wobble {

  from,
  to {
    transform: none
  }

  15% {
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg)
  }

  30% {
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg)
  }

  45% {
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg)
  }

  60% {
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg)
  }

  75% {
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg)
  }
}

.wobble {
  animation-name: wobble
}

@keyframes jello {

  11.1%,
  from,
  to {
    transform: none
  }

  22.2% {
    transform: skewX(-12.5deg) skewY(-12.5deg)
  }

  33.3% {
    transform: skewX(6.25deg) skewY(6.25deg)
  }

  44.4% {
    transform: skewX(-3.125deg) skewY(-3.125deg)
  }

  55.5% {
    transform: skewX(1.5625deg) skewY(1.5625deg)
  }

  66.6% {
    transform: skewX(-.78125deg) skewY(-.78125deg)
  }

  77.7% {
    transform: skewX(.390625deg) skewY(.390625deg)
  }

  88.8% {
    transform: skewX(-.1953125deg) skewY(-.1953125deg)
  }
}

.jello {
  animation-name: jello;
  transform-origin: center
}

@keyframes bounceIn {

  20%,
  40%,
  60%,
  80%,
  from,
  to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1)
  }

  0% {
    opacity: 0;
    transform: scale3d(.3, .3, .3)
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1)
  }

  40% {
    transform: scale3d(.9, .9, .9)
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03)
  }

  80% {
    transform: scale3d(.97, .97, .97)
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1)
  }
}

.bounceIn {
  animation-name: bounceIn
}

@keyframes bounceInDown {

  60%,
  75%,
  90%,
  from,
  to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1)
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0)
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0)
  }

  75% {
    transform: translate3d(0, -10px, 0)
  }

  90% {
    transform: translate3d(0, 5px, 0)
  }

  to {
    transform: none
  }
}

.bounceInDown {
  animation-name: bounceInDown
}

@keyframes bounceInLeft {

  60%,
  75%,
  90%,
  from,
  to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1)
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0)
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0)
  }

  75% {
    transform: translate3d(-10px, 0, 0)
  }

  90% {
    transform: translate3d(5px, 0, 0)
  }

  to {
    transform: none
  }
}

.bounceInLeft {
  animation-name: bounceInLeft
}

@keyframes bounceInRight {

  60%,
  75%,
  90%,
  from,
  to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1)
  }

  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0)
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0)
  }

  75% {
    transform: translate3d(10px, 0, 0)
  }

  90% {
    transform: translate3d(-5px, 0, 0)
  }

  to {
    transform: none
  }
}

.bounceInRight {
  animation-name: bounceInRight
}

@keyframes bounceInUp {

  60%,
  75%,
  90%,
  from,
  to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1)
  }

  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0)
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0)
  }

  75% {
    transform: translate3d(0, 10px, 0)
  }

  90% {
    transform: translate3d(0, -5px, 0)
  }

  to {
    transform: translate3d(0, 0, 0)
  }
}

.bounceInUp {
  animation-name: bounceInUp
}

@keyframes fadeIn {
  from {
    opacity: 0
  }

  to {
    opacity: 1
  }
}

.fadeIn {
  animation-name: fadeIn
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0)
  }

  to {
    opacity: 1;
    transform: none
  }
}

.fadeInDown {
  animation-name: fadeInDown
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0)
  }

  to {
    opacity: 1;
    transform: none
  }
}

.fadeInLeft {
  animation-name: fadeInLeft
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0)
  }

  to {
    opacity: 1;
    transform: none
  }
}

.fadeInRight {
  animation-name: fadeInRight
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0)
  }

  to {
    opacity: 1;
    transform: none
  }
}

.fadeInUp {
  animation-name: fadeInUp
}

@keyframes lightSpeedIn {
  from {
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0
  }

  60% {
    transform: skewX(20deg);
    opacity: 1
  }

  80% {
    transform: skewX(-5deg);
    opacity: 1
  }

  to {
    transform: none;
    opacity: 1
  }
}

.lightSpeedIn {
  animation-name: lightSpeedIn;
  animation-timing-function: ease-out
}

@keyframes rotateIn {
  from {
    transform-origin: center;
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0
  }

  to {
    transform-origin: center;
    transform: none;
    opacity: 1
  }
}

.rotateIn {
  animation-name: rotateIn
}

@keyframes rotateInDownLeft {
  from {
    transform-origin: left bottom;
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0
  }

  to {
    transform-origin: left bottom;
    transform: none;
    opacity: 1
  }
}

.rotateInDownLeft {
  animation-name: rotateInDownLeft
}

@keyframes rotateInDownRight {
  from {
    transform-origin: right bottom;
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0
  }

  to {
    transform-origin: right bottom;
    transform: none;
    opacity: 1
  }
}

.rotateInDownRight {
  animation-name: rotateInDownRight
}

@keyframes rotateInUpLeft {
  from {
    transform-origin: left bottom;
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0
  }

  to {
    transform-origin: left bottom;
    transform: none;
    opacity: 1
  }
}

.rotateInUpLeft {
  animation-name: rotateInUpLeft
}

@keyframes rotateInUpRight {
  from {
    transform-origin: right bottom;
    transform: rotate3d(0, 0, 1, -90deg);
    opacity: 0
  }

  to {
    transform-origin: right bottom;
    transform: none;
    opacity: 1
  }
}

.rotateInUpRight {
  animation-name: rotateInUpRight
}

@keyframes rollIn {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg)
  }

  to {
    opacity: 1;
    transform: none
  }
}

.rollIn {
  animation-name: rollIn
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(.3, .3, .3)
  }

  50% {
    opacity: 1
  }
}

.zoomIn {
  animation-name: zoomIn
}

@keyframes zoomInDown {
  from {
    opacity: 0;
    transform: scale3d(.1, .1, .1) translate3d(0, -1000px, 0);
    animation-timing-function: cubic-bezier(.55, .055, .675, .19)
  }

  60% {
    opacity: 1;
    transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
    animation-timing-function: cubic-bezier(.175, .885, .32, 1)
  }
}

.zoomInDown {
  animation-name: zoomInDown
}

@keyframes zoomInLeft {
  from {
    opacity: 0;
    transform: scale3d(.1, .1, .1) translate3d(-1000px, 0, 0);
    animation-timing-function: cubic-bezier(.55, .055, .675, .19)
  }

  60% {
    opacity: 1;
    transform: scale3d(.475, .475, .475) translate3d(10px, 0, 0);
    animation-timing-function: cubic-bezier(.175, .885, .32, 1)
  }
}

.zoomInLeft {
  animation-name: zoomInLeft
}

@keyframes zoomInRight {
  from {
    opacity: 0;
    transform: scale3d(.1, .1, .1) translate3d(1000px, 0, 0);
    animation-timing-function: cubic-bezier(.55, .055, .675, .19)
  }

  60% {
    opacity: 1;
    transform: scale3d(.475, .475, .475) translate3d(-10px, 0, 0);
    animation-timing-function: cubic-bezier(.175, .885, .32, 1)
  }
}

.zoomInRight {
  animation-name: zoomInRight
}

@keyframes zoomInUp {
  from {
    opacity: 0;
    transform: scale3d(.1, .1, .1) translate3d(0, 1000px, 0);
    animation-timing-function: cubic-bezier(.55, .055, .675, .19)
  }

  60% {
    opacity: 1;
    transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);
    animation-timing-function: cubic-bezier(.175, .885, .32, 1)
  }
}

.zoomInUp {
  animation-name: zoomInUp
}

@keyframes slideInDown {
  from {
    transform: translate3d(0, -100%, 0);
    visibility: visible
  }

  to {
    transform: translate3d(0, 0, 0)
  }
}

.slideInDown {
  animation-name: slideInDown
}

@keyframes slideInLeft {
  from {
    transform: translate3d(-100%, 0, 0);
    visibility: visible
  }

  to {
    transform: translate3d(0, 0, 0)
  }
}

.slideInLeft {
  animation-name: slideInLeft
}

@keyframes slideInRight {
  from {
    transform: translate3d(100%, 0, 0);
    visibility: visible
  }

  to {
    transform: translate3d(0, 0, 0)
  }
}

.slideInRight {
  animation-name: slideInRight
}

@keyframes slideInUp {
  from {
    transform: translate3d(0, 100%, 0);
    visibility: visible
  }

  to {
    transform: translate3d(0, 0, 0)
  }
}

.slideInUp {
  animation-name: slideInUp
}

.elementor-animation-grow {
  transition-duration: .3s;
  transition-property: transform
}

.elementor-animation-grow:active,
.elementor-animation-grow:focus,
.elementor-animation-grow:hover {
  transform: scale(1.1)
}

.elementor-animation-shrink {
  transition-duration: .3s;
  transition-property: transform
}

.elementor-animation-shrink:active,
.elementor-animation-shrink:focus,
.elementor-animation-shrink:hover {
  transform: scale(.9)
}

@keyframes elementor-animation-pulse {
  25% {
    transform: scale(1.1)
  }

  75% {
    transform: scale(.9)
  }
}

.elementor-animation-pulse:active,
.elementor-animation-pulse:focus,
.elementor-animation-pulse:hover {
  animation-name: elementor-animation-pulse;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite
}

@keyframes elementor-animation-pulse-grow {
  to {
    transform: scale(1.1)
  }
}

.elementor-animation-pulse-grow:active,
.elementor-animation-pulse-grow:focus,
.elementor-animation-pulse-grow:hover {
  animation-name: elementor-animation-pulse-grow;
  animation-duration: .3s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate
}

@keyframes elementor-animation-pulse-shrink {
  to {
    transform: scale(.9)
  }
}

.elementor-animation-pulse-shrink:active,
.elementor-animation-pulse-shrink:focus,
.elementor-animation-pulse-shrink:hover {
  animation-name: elementor-animation-pulse-shrink;
  animation-duration: .3s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate
}

@keyframes elementor-animation-push {
  50% {
    transform: scale(.8)
  }

  100% {
    transform: scale(1)
  }
}

.elementor-animation-push:active,
.elementor-animation-push:focus,
.elementor-animation-push:hover {
  animation-name: elementor-animation-push;
  animation-duration: .3s;
  animation-timing-function: linear;
  animation-iteration-count: 1
}

@keyframes elementor-animation-pop {
  50% {
    transform: scale(1.2)
  }
}

.elementor-animation-pop:active,
.elementor-animation-pop:focus,
.elementor-animation-pop:hover {
  animation-name: elementor-animation-pop;
  animation-duration: .3s;
  animation-timing-function: linear;
  animation-iteration-count: 1
}

.elementor-animation-bounce-in {
  transition-duration: .5s
}

.elementor-animation-bounce-in:active,
.elementor-animation-bounce-in:focus,
.elementor-animation-bounce-in:hover {
  transform: scale(1.2);
  transition-timing-function: cubic-bezier(0.47, 2.02, .31, -.36)
}

.elementor-animation-bounce-out {
  transition-duration: .5s
}

.elementor-animation-bounce-out:active,
.elementor-animation-bounce-out:focus,
.elementor-animation-bounce-out:hover {
  transform: scale(.8);
  transition-timing-function: cubic-bezier(0.47, 2.02, .31, -.36)
}

.elementor-animation-rotate {
  transition-duration: .3s;
  transition-property: transform
}

.elementor-animation-rotate:active,
.elementor-animation-rotate:focus,
.elementor-animation-rotate:hover {
  transform: rotate(4deg)
}

.elementor-animation-grow-rotate {
  transition-duration: .3s;
  transition-property: transform
}

.elementor-animation-grow-rotate:active,
.elementor-animation-grow-rotate:focus,
.elementor-animation-grow-rotate:hover {
  transform: scale(1.1) rotate(4deg)
}

.elementor-animation-float {
  transition-duration: .3s;
  transition-property: transform;
  transition-timing-function: ease-out
}

.elementor-animation-float:active,
.elementor-animation-float:focus,
.elementor-animation-float:hover {
  transform: translateY(-8px)
}

.elementor-animation-sink {
  transition-duration: .3s;
  transition-property: transform;
  transition-timing-function: ease-out
}

.elementor-animation-sink:active,
.elementor-animation-sink:focus,
.elementor-animation-sink:hover {
  transform: translateY(8px)
}

@keyframes elementor-animation-bob {
  0% {
    transform: translateY(-8px)
  }

  50% {
    transform: translateY(-4px)
  }

  100% {
    transform: translateY(-8px)
  }
}

@keyframes elementor-animation-bob-float {
  100% {
    transform: translateY(-8px)
  }
}

.elementor-animation-bob:active,
.elementor-animation-bob:focus,
.elementor-animation-bob:hover {
  animation-name: elementor-animation-bob-float, elementor-animation-bob;
  animation-duration: .3s, 1.5s;
  animation-delay: 0s, .3s;
  animation-timing-function: ease-out, ease-in-out;
  animation-iteration-count: 1, infinite;
  animation-fill-mode: forwards;
  animation-direction: normal, alternate
}

@keyframes elementor-animation-hang {
  0% {
    transform: translateY(8px)
  }

  50% {
    transform: translateY(4px)
  }

  100% {
    transform: translateY(8px)
  }
}

@keyframes elementor-animation-hang-sink {
  100% {
    transform: translateY(8px)
  }
}

.elementor-animation-hang:active,
.elementor-animation-hang:focus,
.elementor-animation-hang:hover {
  animation-name: elementor-animation-hang-sink, elementor-animation-hang;
  animation-duration: .3s, 1.5s;
  animation-delay: 0s, .3s;
  animation-timing-function: ease-out, ease-in-out;
  animation-iteration-count: 1, infinite;
  animation-fill-mode: forwards;
  animation-direction: normal, alternate
}

.elementor-animation-skew {
  transition-duration: .3s;
  transition-property: transform
}

.elementor-animation-skew:active,
.elementor-animation-skew:focus,
.elementor-animation-skew:hover {
  transform: skew(-10deg)
}

.elementor-animation-skew-forward {
  transition-duration: .3s;
  transition-property: transform;
  transform-origin: 0 100%
}

.elementor-animation-skew-forward:active,
.elementor-animation-skew-forward:focus,
.elementor-animation-skew-forward:hover {
  transform: skew(-10deg)
}

.elementor-animation-skew-backward {
  transition-duration: .3s;
  transition-property: transform;
  transform-origin: 0 100%
}

.elementor-animation-skew-backward:active,
.elementor-animation-skew-backward:focus,
.elementor-animation-skew-backward:hover {
  transform: skew(10deg)
}

@keyframes elementor-animation-wobble-vertical {
  16.65% {
    transform: translateY(8px)
  }

  33.3% {
    transform: translateY(-6px)
  }

  49.95% {
    transform: translateY(4px)
  }

  66.6% {
    transform: translateY(-2px)
  }

  83.25% {
    transform: translateY(1px)
  }

  100% {
    transform: translateY(0)
  }
}

.elementor-animation-wobble-vertical:active,
.elementor-animation-wobble-vertical:focus,
.elementor-animation-wobble-vertical:hover {
  animation-name: elementor-animation-wobble-vertical;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1
}

@keyframes elementor-animation-wobble-horizontal {
  16.65% {
    transform: translateX(8px)
  }

  33.3% {
    transform: translateX(-6px)
  }

  49.95% {
    transform: translateX(4px)
  }

  66.6% {
    transform: translateX(-2px)
  }

  83.25% {
    transform: translateX(1px)
  }

  100% {
    transform: translateX(0)
  }
}

.elementor-animation-wobble-horizontal:active,
.elementor-animation-wobble-horizontal:focus,
.elementor-animation-wobble-horizontal:hover {
  animation-name: elementor-animation-wobble-horizontal;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1
}

@keyframes elementor-animation-wobble-to-bottom-right {
  16.65% {
    transform: translate(8px, 8px)
  }

  33.3% {
    transform: translate(-6px, -6px)
  }

  49.95% {
    transform: translate(4px, 4px)
  }

  66.6% {
    transform: translate(-2px, -2px)
  }

  83.25% {
    transform: translate(1px, 1px)
  }

  100% {
    transform: translate(0, 0)
  }
}

.elementor-animation-wobble-to-bottom-right:active,
.elementor-animation-wobble-to-bottom-right:focus,
.elementor-animation-wobble-to-bottom-right:hover {
  animation-name: elementor-animation-wobble-to-bottom-right;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1
}

@keyframes elementor-animation-wobble-to-top-right {
  16.65% {
    transform: translate(8px, -8px)
  }

  33.3% {
    transform: translate(-6px, 6px)
  }

  49.95% {
    transform: translate(4px, -4px)
  }

  66.6% {
    transform: translate(-2px, 2px)
  }

  83.25% {
    transform: translate(1px, -1px)
  }

  100% {
    transform: translate(0, 0)
  }
}

.elementor-animation-wobble-to-top-right:active,
.elementor-animation-wobble-to-top-right:focus,
.elementor-animation-wobble-to-top-right:hover {
  animation-name: elementor-animation-wobble-to-top-right;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1
}

@keyframes elementor-animation-wobble-top {
  16.65% {
    transform: skew(-12deg)
  }

  33.3% {
    transform: skew(10deg)
  }

  49.95% {
    transform: skew(-6deg)
  }

  66.6% {
    transform: skew(4deg)
  }

  83.25% {
    transform: skew(-2deg)
  }

  100% {
    transform: skew(0)
  }
}

.elementor-animation-wobble-top {
  transform-origin: 0 100%
}

.elementor-animation-wobble-top:active,
.elementor-animation-wobble-top:focus,
.elementor-animation-wobble-top:hover {
  animation-name: elementor-animation-wobble-top;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1
}

@keyframes elementor-animation-wobble-bottom {
  16.65% {
    transform: skew(-12deg)
  }

  33.3% {
    transform: skew(10deg)
  }

  49.95% {
    transform: skew(-6deg)
  }

  66.6% {
    transform: skew(4deg)
  }

  83.25% {
    transform: skew(-2deg)
  }

  100% {
    transform: skew(0)
  }
}

.elementor-animation-wobble-bottom {
  transform-origin: 100% 0
}

.elementor-animation-wobble-bottom:active,
.elementor-animation-wobble-bottom:focus,
.elementor-animation-wobble-bottom:hover {
  animation-name: elementor-animation-wobble-bottom;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1
}

@keyframes elementor-animation-wobble-skew {
  16.65% {
    transform: skew(-12deg)
  }

  33.3% {
    transform: skew(10deg)
  }

  49.95% {
    transform: skew(-6deg)
  }

  66.6% {
    transform: skew(4deg)
  }

  83.25% {
    transform: skew(-2deg)
  }

  100% {
    transform: skew(0)
  }
}

.elementor-animation-wobble-skew:active,
.elementor-animation-wobble-skew:focus,
.elementor-animation-wobble-skew:hover {
  animation-name: elementor-animation-wobble-skew;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1
}

@keyframes elementor-animation-buzz {
  50% {
    transform: translateX(3px) rotate(2deg)
  }

  100% {
    transform: translateX(-3px) rotate(-2deg)
  }
}

.elementor-animation-buzz:active,
.elementor-animation-buzz:focus,
.elementor-animation-buzz:hover {
  animation-name: elementor-animation-buzz;
  animation-duration: .15s;
  animation-timing-function: linear;
  animation-iteration-count: infinite
}

@keyframes elementor-animation-buzz-out {
  10% {
    transform: translateX(3px) rotate(2deg)
  }

  20% {
    transform: translateX(-3px) rotate(-2deg)
  }

  30% {
    transform: translateX(3px) rotate(2deg)
  }

  40% {
    transform: translateX(-3px) rotate(-2deg)
  }

  50% {
    transform: translateX(2px) rotate(1deg)
  }

  60% {
    transform: translateX(-2px) rotate(-1deg)
  }

  70% {
    transform: translateX(2px) rotate(1deg)
  }

  80% {
    transform: translateX(-2px) rotate(-1deg)
  }

  90% {
    transform: translateX(1px) rotate(0)
  }

  100% {
    transform: translateX(-1px) rotate(0)
  }
}

.elementor-animation-buzz-out:active,
.elementor-animation-buzz-out:focus,
.elementor-animation-buzz-out:hover {
  animation-name: elementor-animation-buzz-out;
  animation-duration: .75s;
  animation-timing-function: linear;
  animation-iteration-count: 1
}

.elementor section {
  padding-top: 0rem;
  padding-bottom: 0rem;
}

.home {
  background-color: #F8D546;
}

.inner_pages_nav .navbar-nav .dropdown-item,
.navbar-nav .nav-item .nav-link {
  /* color: #fff!important; */
}

.inner_pages_nav .navbar {

  background-color: #000;
}

.justify-content {
  justify-content: center
}

.height-260 {
  height: 260px;
}

.custom-carousel .carousel .control-dots {
  /* Customize the positioning properties here */
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.custom-carousel .carousel .control-dots .dot {
  /* Customize the dot styles here */
  background-color: #999;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 5px;
}

.custom-carousel .carousel {
  min-height: 175px;
}

.carousel-container {
  display: flex;
  gap: 16px;
  /* Adjust the value to control the gap size */
}

.carousel-item {
  margin: 0;
  /* Reset default margins */
  padding: 16px;
  /* Adjust the value to control the item spacing */
}

.pb-10 {
  padding-bottom: 20px;
}

.vUlQj:hover,
.vUlQj:focus {
  box-shadow: rgb(22, 22, 22) 0px 0px 1px 3px !important;
}

.vUlQj {
  box-shadow: rgb(22, 22, 22) 0px 0px 1px 3px !important;
}

.carousel-item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  /* Adjust the height as per your requirement */
}

.slider_img_border {
  /* border-radius: 12px 12px 0px 0px!important; */
  /* border-radius: 12px!important; */
  /* min-height: 400px!important; */
}

.featured-card {
  background: no-repeat;
  background-color: #000;
  border: none;
  border-radius: 12px;
  min-height: 460px;
}

.slider_img_border_aboutus {
  border-radius: 12px !important;
}


.faq-container {
  width: 100%;
  margin: 0 auto;
}

.faq-item {
  margin-bottom: 20px;
}

.faq-question {
  cursor: pointer;
  /* font-weight: bold; */
  margin-bottom: 5px;
  padding: 5px;
  /* background-color: #f2f2f2; */
  /* border-radius: 5px; */
  /* transition: background-color 0.3s ease; */
  border-bottom: 1px solid darkgray;
}

/* 
.faq-question:hover {
  background-color: #e2e2e2;
} */

/* .faq-question.expanded {
  background-color: #d2d2d2;
} */

.faq-answer {
  padding: 10px;
  /* background-color: #f9f9f9; */
  border-radius: 5px;
}

.float-right-faq-icon {
  float: right;
}

.margin-top-10 {
  margin-top: 10px;
}

.faq-image {
  margin: auto;
  display: flex;
  box-shadow: 0px 0px 10px 10px lightgray;
  border-radius: 12px;
}

.padding-left-faq-0 {
  padding-left: 0;
}

.faq-font-size {
  font-size: 1.2rem;
}

/* .margin-left-8{
  margin-left: 8px;
} */

/* .faq-question {
  cursor: pointer;
  font-weight: bold;
  margin-bottom: 5px;
  padding: 5px;
  background-color: #f2f2f2;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
} */

.faq-question .arrow {
  margin-right: 5px;
}

/* .faq-question.expanded {
  background-color: #d2d2d2;
} */

.top-menu-btn button.navbar-toggler {
  background-color: #fff !important;
}


.scroll-animation {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s, transform 0.5s;
}

.scroll-animation.visible {
  opacity: 1;
  transform: translateY(0);
}

/*start textSlider css*/


.text-slider-container {
  height: 60px;
  overflow: hidden;
  position: relative;
}

.text-slide {
  position: absolute;
  bottom: 0;
  width: 100%;
  opacity: 0;
}

.slide-in {
  animation: slideIn 0.5s forwards;
}

.slide-out {
  animation: slideOut 0.5s forwards;
}

@keyframes slideIn {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }

  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes slideOut {
  0% {
    transform: translateY(10%);
    opacity: 1;
  }

  /* 50% {
    transform: translateY(0%);
    opacity: 0;
  } */
}

/*start boxSlider css*/
.box.focused {
  background-color: #F8D546;
  border-radius: 12px;
}

.overlay_boxes {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1;
  transition: opacity 0.3s ease;
  border-radius: 12px;
}

/*start carousel css*/

/* ImageSlider.css */
/* 
.image-slide .zoom-img {
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.5s ease-in-out;
} */

/*.image-slide:hover,*/

/* .image-slide:hover, .zoom-img {
  transform: scale(1.1);
} */

.rec-item-wrapper {
  border-radius: 12px;
}

.font-size-50 {
  font-size: 50px;
}

.text-color-white {
  color: #fff;
}

.slick-prev,
.slick-next {
  display: none !important;
}



.image-carousel .slick-slide {
  transform-style: preserve-3d;
  transition: transform 0.5s ease-in-out;
  border-radius: 12px;
  overflow: hidden;
}

.image-carousel-aboutus .slick-slide {
  transform-style: preserve-3d;
  transition: transform 0.5s ease-in-out;
  border-radius: 12px;
  overflow: hidden;
}

.image-carousel .slick-slide.slick-current .featured-card {
  transform: scale(1.1);
  /* Adjust the scale value for desired zoom level */
}

.image-carousel-aboutus .slick-slide.slick-current img {
  transform: scale(1.1) !important;
  /* Adjust the scale value for desired zoom level */
}

.slick-dots li,
.slick-dots li button {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  padding: 0;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  padding: 5px;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.slick-dots li,
.slick-dots li button {
  width: 22px;
  height: 22px;
  cursor: pointer;
}

.slick-dots {
  margin-left: 0;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}


.slick-dots li button:focus:before,
.slick-dots li button:hover:before {
  opacity: 1;
}

.slick-dots li button:before {
  font-family: slick;
  font-size: 46px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "\2022";
  text-align: center;
  opacity: .25;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  opacity: .75;
  color: #F8D546;
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.cursor {
  animation: blink 1s infinite;
}

.featured-section {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.submit-contact {
  border: none;
}

.padding-4 {
  padding: 4px;
}


.image-carousel {
  position: relative;
}

/* Carousel Next & Prev */
.image-carousel .slick-prev,
.image-carousel .slick-next {
  width: 50px;
  /* Customize the width of the arrow container */
  height: 90px;
  /* Customize the height of the arrow container */
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  cursor: pointer;
  transform: translateY(-50%);
  z-index: 1;
  /* Ensure the arrows appear above the carousel content */
  display: block !important;
  background-color: #00000080;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
}

.arrowPrev,
.arrowNext {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-weight: bold;
  font-size: 30px;
  background: #00000080;
}

.image-carousel .slick-prev {
  left: 0px;
}

.image-carousel .slick-next {
  right: 0px;
}


@media (max-width:768px) {

  .image-carousel .slick-prev,
  .image-carousel .slick-next {
    display: none !important;
  }
}

/* Basic styling for emojis overlay */
.emojis-overlay {
  position: relative;
  height: 100vh;
}

/* Styling for content */
.faq-container {
  position: relative;
  z-index: 1;
  padding: 20px;
}

/* Styling for emojis container */
.emojis-flow {
  position: absolute;
  bottom: 0;
  text-align: center;
  left: 50%;
  right: 50%;
  transform: translateX(-50%);
  width: 100%;
  animation: emojiFlow 2s linear forwards;
  /* Adjust animation duration as needed */
}

/* Styling for individual emoji spans */
.emojis-flow .emoji {
  display: inline-block;
  font-size: 4em;
  animation: emojiAppear 1s ease-in-out forwards, emojiMove 2s linear infinite alternate;
  /* Adjust animation duration and timing */
  opacity: 0;
}

/* Animation keyframes for emoji appearance */
@keyframes emojiAppear {
  to {
    opacity: 1;
  }
}

/* Animation keyframes for emoji movement */
@keyframes emojiMove {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-100vh);
  }
}

.refer-earn>h3 {
  padding-left: 0;
}

.padding-top-10 {
  padding-top: 10px;
}

.margin-top-8 {
  margin-top: -8px;
}

/* Container styling */
.contact-us-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  text-align: left;
}

/* Wrapper around input fields and their error message */
.contact-us-input-field-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  min-height: 70px;
}

/* Error message styling */
.invalid-feedback-contact-us {
  color: #d9534f;
  font-size: 0.875rem;
  /* Slightly smaller font size for errors */
  margin-top: 5px;
  /* Space between input and error message */
  min-height: 20px;
}

/* Display error message if there's an error */
input:invalid+.invalid-feedback-contact-us,
textarea:invalid+.invalid-feedback-contact-us {
  display: block;
}

/* Show the error message if there's an error (conditionally based on your error handling) */
input.error+.invalid-feedback-contact-us,
textarea.error+.invalid-feedback-contact-us {
  display: block;
}

/* Header and title styling */
.contact-header {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.contact-title {
  font-size: 48px;
  font-weight: 300;
  margin-bottom: 30px;
  color: #323232;
  line-height: 52px;
}

.askvasi-home a {
  color: #323232;
}

.highlight {
  color: transparent;
  -webkit-text-stroke: 1px #323232;
  text-stroke: 1px #323232;
  font-weight: 700;
}

/* Form styling */
.contact-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contact-us-header {
  font-family: Open Sans, sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  text-align: left;
}

/* Flexbox layout for input fields in a single row */
.input-row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.input-field,
.input-message {
  flex: 1;
  padding: 15px;
  border: none;
  border-bottom: 1px solid #ccc;
  font-size: 1rem;
  background-color: transparent;
  outline: none;
  width: 100%;
  /* Ensure the inputs take full width */
  box-sizing: border-box;
  /* Ensure padding and borders are included in the width */
}

/* Message Textarea */
.input-message {
  height: 150px;
  resize: vertical;
}

/* Submit button */
.submit-container {
  text-align: left;
  margin-top: 20px;
}

.submit-button-contact-us {
  background-color: #FFD700;
  border: none;
  color: #323232;
  font-size: 16px;
  font-weight: 600;
  border-radius: 11px;
  transition: background-color 0.3s ease;
  width: 229px;
  height: 44px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  line-height: 21px;
}

/* Disabled button styling */
.submit-button-contact-us:disabled {
  background-color: #cccccc;
  /* Gray color for disabled button */
  cursor: not-allowed;
  color: #ffffff;
  /* Text color when disabled */
  opacity: 0.6;
  /* Optional: slight transparency to indicate disabled state */
}

.submit-button-contact-us .arrow {
  margin-left: 5px;
  font-size: 1.2rem;
}

.submit-button-contact-us::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  /* Black color with opacity */
  transition: width 0.5s ease-in-out;
  /* Smooth transition */
  z-index: -1;
  /* Behind the button text */
}

.submit-button-contact-us:hover::before {
  width: 100%;
  /* The overlay grows from 0% to 100% on hover */
}

.submit-button-contact-us:hover {
  color: #fff;
  /* Optional: Change text color on hover if needed */
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {

  /* Tablet view */
  .input-row {
    flex-direction: column;
    /* Stack input fields vertically on smaller screens */
    gap: 10px;
  }

  .input-field {
    font-size: 0.9rem;
  }

  .submit-button-contact-us {
    width: 100%;
  }
}

@media (max-width: 576px) {

  /* Small mobile view */
  .input-field,
  .input-message {
    font-size: 0.85rem;
  }

  .submit-button-contact-us {
    font-size: 0.85rem;
    width: 100%;
  }

  .input-row {
    gap: 10px;
  }

  .submit-container {
    text-align: center;
    /* Center-align button for small screens */
  }
}

.navbar .navbar-toggler,
.navbar .navbar-toggler:active,
.navbar .navbar-toggler:focus,
.navbar .navbar-toggler:hover {
  border: none;
  box-shadow: none;
}

.cross-icon {
  width: 1.5em;
  height: 1.5em;
  display: inline-block;
  position: relative;
  background-color: #323232;

  /* Square background color */
  border-radius: 4px;
  background-image: none;
  /* Optional: to make the background slightly rounded */
}

.cross-icon::before,
.cross-icon::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  /* Adjust width to control cross thickness */
  height: 2px;
  /* Thickness of the cross lines */
  background-color: #fff;
  /* Cross color */
  transform-origin: center;
  transform: translate(-50%, -50%) rotate(45deg);
}

.cross-icon::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.navbar-toggler:focus {
  box-shadow: none;
}