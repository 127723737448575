.phone-container {
    position: relative;
    width: 330px;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: 50px;
}

.phone-container .phone-frame {
    position: absolute;
    width: 85%;
    transition: transform 1s ease-in-out, opacity 1s ease-in-out;
    border-radius: 32px;
}

.phone-container .center {
    z-index: 3;
    transform: scale(1);
    opacity: 1;
}

.phone-container .left {
    z-index: 2;
    transform: translateX(-40%) scale(0.8);
    opacity: 0.3;
}

.phone-container .right {
    z-index: 2;
    transform: translateX(40%) scale(0.8);
    opacity: 0.3;
}

.phone-container .hidden {
    z-index: 1;
    transform: scale(0);
    opacity: 0;
}

.consumer-page .blurred-images-container-hero-section {
    padding-top: 55px;
}




/* 📌 Full Hero Section */
.hero-17-mockup-image-holder-2 {
    z-index: 2;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 60%;
    height: 200vh;
    margin-top: 0;
    display: flex;
    position: relative;
    left: 5%;
    overflow: visible;
}

/* 📌 Sticky iPhone Frame */
.hero-mockup-main-holder {
    z-index: 1;
    position: sticky;
    top: 100px;
}

/* 📌 iPhone Frame Container */
.home-mockup-holder.hero {
    width: 580px;
    height: 100%;
    /* top: 5%; */
    overflow: hidden;
    position: relative;
}

/* 📌 iPhone Frame Image */
.hero-iphone-mockup-image {
    z-index: 7;
    opacity: 1;
    width: 100%;
    height: 100%;
    position: relative;
    top: 0;
    overflow: hidden;
}

/* 📌 Container for the Inner Mockup Scroll */
.main-image-parent {
    border-radius: 30px;
    width: 53%;
    height: 86%;
    position: absolute;
    inset: 1.9% 4.7% 4.5% 12.7%;
    overflow: hidden;
}



.main-app-image-holder-2 {
    border-radius: 30px;
    width: 100%;
    position: absolute;
    inset: 1% 0% 0%;
}




/* Hide scrollbar for Webkit browsers */
.main-app-image-holder-2::-webkit-scrollbar {
    display: none;
}




/* 📌 Responsive Adjustments */
@media (max-width: 768px) {
    .hero-mockup-main-holder {
        top: 50px;
    }

    .home-mockup-holder.hero {
        width: 80%;
        margin: auto;
    }

    .main-image-parent {
        width: 60%;
        height: 80%;
    }


   

    .main-image-parent {
        width: 90%;
        height: 95%;
        inset: 2.9% 3.7% 6.5% 1.7%
    }

   

    .hero-17-mockup-image-holder-2 {
        z-index: 3;
        flex-flow: column;
        width: 100%;
        /* margin-top: 2em; */
        left: 0%;
    }

    /* .hero-17-mockup-image-holder-2 {
        width: 100%;
        height: 100%;
        padding-bottom: 10px;
        left: 0%;
    } */

    .hero-mockup-main-holder {
        flex-flow: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: auto;
        display: flex;
        inset: 90px 100px 40px;
        overflow: clip;
    }

    .home-mockup-holder.hero {
        border-top-left-radius: 50px;
        border-top-right-radius: 50px;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        margin-top: 0;
        display: flex;
        top: 0%;
    }

    .main-image-parent {
        border-radius: 50px;
        width: 84%;
        height: 94%;
        margin: auto;
        /* margin-top: 10px;
        margin-left: auto;
        margin-right: auto; */
        position: absolute;
        top: 6%;
        /* left: 7.7%;
        right: 7.7%; */
    }

    .main-app-images {       
        /* outline-offset: 0px;
        object-fit: cover;
        border: 1px #000;
        outline: 3px #000;
        width: 89.3%; */
    }
}

